import { Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { required } from '../../utils/errorsTextHelper';
import { UpdatePasswordProps } from '../../views/Profile/ProfileView.types';
import AcCard from '../AcCard/AcCard';
import AcInput from '../AcInput/AcInput';
import ActionButton from '../ActionButton/ActionButton';

import './style.scss';

const PasswordTab = ({ changePassword }: UpdatePasswordProps) => {
    let updatePasswordSchema = yup.object().shape({
        oldPassword: yup
            .string()
            .password()
            .required(required('Password'))
            .label('Password'),
        rawNewPassword: yup
            .string()
            .password()
            .required(required('New Password'))
            .label('New Password'),
        password2: yup
            .string()
            .oneOf([yup.ref('rawNewPassword'), null], 'Passwords must match')
            .required(required('Confirm Password'))
    });
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        resetForm,
        submitForm,
        dirty,
        isSubmitting,
        isValid
    } = useFormik({
        initialValues: {
            oldPassword: '',
            rawNewPassword: '',
            password2: ''
        },
        validationSchema: updatePasswordSchema,
        onSubmit: async (values) => {
            const data = {
                oldPassword: values.oldPassword,
                rawNewPassword: values.rawNewPassword
            };
            changePassword(data, () => {
                resetForm();
            });
        }
    });

    return (
        <Grid container className="formContent">
            <Grid item xs={5} className="formContent-minWIdth">
                <Stack>
                    <AcCard stackContainer={false} title="Password">
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Current Password"
                                    name="oldPassword"
                                    type="password"
                                    value={values.oldPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.oldPassword &&
                                        !!errors.oldPassword
                                    }
                                    helperText={
                                        (touched.oldPassword &&
                                            errors.oldPassword) ||
                                        ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="New Password"
                                    name="rawNewPassword"
                                    type="password"
                                    value={values.rawNewPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.rawNewPassword &&
                                        !!errors.rawNewPassword
                                    }
                                    helperText="Your password must be at least 8 characters long and contain at least
          one capital letter (A-Z), one lowercase letter (a-z), one numeral
          (0-9) and at least one special symbol (e.g., !, @, #, $, %, etc.)"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Confirm Password"
                                    name="password2"
                                    type="password"
                                    value={values.password2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.password2 && !!errors.password2
                                    }
                                    helperText={
                                        (touched.password2 &&
                                            errors.password2) ||
                                        ''
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                </Stack>
                <Grid item mt={3} xs={12}>
                    <ActionButton
                        text="Update"
                        variant="outlined"
                        onClick={submitForm}
                        disabled={!isValid || !dirty || isSubmitting}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PasswordTab;
