import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { GridActionsCellItem, GridSortModel } from '@mui/x-data-grid';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useOffers from '../../api/useOffers';
import { Offer } from '../../common/contracts';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import DialogModal from '../../components/Dialog/Dialog';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { DATE_TIME_FORMAT } from '../../constants/constants';
import {
    EButtonColor,
    ELocalStorageKeys,
    ENotificationType,
    GridFeatureMode,
    OfferType,
    SortingDirection,
    SortingOffersValue
} from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { usePaginationFromURL } from '../../hooks/usePaginationFromURL';
import { AuthSliceState } from '../../store/store.types';
import { getConvertedPrice } from '../../utils/getConvertedPrice';
import { permissionsUtil } from '../../utils/permissionsUtil';

const dayjsUTC = dayjs.extend(utc);

const OffersTable = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const navigate = useNavigate();
    const location = useLocation();
    const { page, rows, sortValue, direction } = usePaginationFromURL('offers');
    const [currentPage, setCurrentPage] = useState(page);
    const [rowsPerPage, setRowsPerPage] = useState(rows);
    const [sorting, setSorting] = useState<{
        sortValue: SortingOffersValue;
        direction: SortingDirection;
    }>({
        sortValue: sortValue,
        direction: direction
    });
    const { getOffers, deleteOffer } = useOffers(
        currentPublisherId,
        undefined,
        OfferType.BUNDLE,
        { currentPage, rowsPerPage },
        sorting
    );
    const totalCount = getOffers.data?.totalCount || 0;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
    const { enqueueSnackbar } = useNotifications();

    useEffect(() => {
        const queryParams = new URLSearchParams();
        queryParams.set('page', String(currentPage));
        queryParams.set('rows', String(rowsPerPage));
        queryParams.set('sortValue', sorting.sortValue);
        queryParams.set('direction', sorting.direction);

        navigate(`${location.pathname}?${queryParams.toString()}`, {
            replace: true
        });
        getOffers.refetch();
    }, [currentPage, rowsPerPage, sorting]);

    const handleDeleteOffer = async () => {
        if (!selectedOffer) return;
        deleteOffer.mutate(selectedOffer, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Offer deleted successfully',
                    ENotificationType.SUCCESS
                );
                getOffers.refetch();
            },
            onError: (e: any) => {
                enqueueSnackbar(
                    'Failed to delete offer',
                    ENotificationType.ERROR,
                    e.response.data.message ?? ''
                );
            },
            onSettled: () => {
                setIsDeleteDialogOpen(false);
                setSelectedOffer(null);
            }
        });
    };

    const columns = [
        {
            field: 'offerId',
            flex: 1,
            headerName: 'ID',
            width: 100
        },
        {
            field: 'publisherOfferId',
            flex: 1,
            headerName: 'Publisher offer ID',
            width: 100
        },
        {
            field: 'offerUi.name',
            flex: 1,
            headerName: 'Offer Design',
            width: 100,
            renderCell: (params: any) => {
                if (getOffers.isLoading) return '';
                return <span>{params.row.offerUi?.name}</span>;
            }
        },
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            width: 100
        },
        {
            field: 'createdAt',
            flex: 1,
            headerName: 'Created at',
            width: 200,
            type: 'date',
            renderCell: (params: any) => {
                if (getOffers.isLoading) return '';
                return (
                    <span>
                        {dayjsUTC
                            .utc(params.row.createdAt)
                            .format(DATE_TIME_FORMAT)}
                    </span>
                );
            }
        },
        {
            field: 'type',
            flex: 1,
            headerName: 'Type',
            width: 200
        },
        {
            field: 'price',
            flex: 1,
            headerName: 'Price',
            width: 100,
            renderCell: (params: any) => {
                if (getOffers.isLoading) return '';
                return getConvertedPrice(params);
            }
        },
        {
            field: 'actions',
            flex: 0,
            type: 'actions',
            width: 80,
            disableReorder: true,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<EditTwoToneIcon />}
                    label="Edit"
                    onClick={() => {
                        navigate(`./form/${params.row.offerId}`);
                    }}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.BUNDLES,
                                currentPublisherId,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<ContentCopyOutlinedIcon />}
                    label="Duplicate"
                    onClick={() => {
                        navigate(`./dup/${params.row.offerId}`);
                    }}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.BUNDLES,
                                currentPublisherId,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    className="danger"
                    icon={<DeleteOutlineTwoToneIcon className="danger" />}
                    label="Delete"
                    onClick={() => {
                        setIsDeleteDialogOpen(true);
                        setSelectedOffer(params.row.offerId);
                    }}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.BUNDLES,
                                currentPublisherId,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />
            ]
        }
    ];

    const fieldToSortingOrderValue: { [key: string]: SortingOffersValue } = {
        offerId: SortingOffersValue.ID,
        publisherOfferId: SortingOffersValue.PUBLISHER_OFFER_ID,
        name: SortingOffersValue.NAME,
        createdAt: SortingOffersValue.CREATED_AT,
        price: SortingOffersValue.PRICE
    };

    const sortValueToFieldMap: { [key in SortingOffersValue]: string } = {
        [SortingOffersValue.ID]: 'SortingOffersValue.ID',
        [SortingOffersValue.PUBLISHER_OFFER_ID]: 'publisherOfferId',
        [SortingOffersValue.NAME]: 'name',
        [SortingOffersValue.CREATED_AT]: 'createdAt',
        [SortingOffersValue.PRICE]: 'price'
    };

    const handleSortModelChange = (model: GridSortModel) => {
        if (model.length > 0) {
            const field = model[0].field;
            const mappedSortValue = fieldToSortingOrderValue[field];
            if (mappedSortValue) {
                setSorting({
                    sortValue: mappedSortValue,
                    direction:
                        model[0].sort === SortingDirection.ASC
                            ? SortingDirection.ASC
                            : SortingDirection.DESC
                });
            }
        }
    };

    return (
        <>
            <AcViewWrapper
                header={
                    <PageTopBar
                        headline="Bundles"
                        buttons={[
                            {
                                text: 'Add New Bundle',
                                action: () => navigate('./form'),
                                disabled: !permissionsUtil.canUserEdit(),
                                hidden:
                                    permissionsUtil.isNewVersion() &&
                                    !permissionsUtil.isActionEnabled(
                                        null,
                                        EPermissionGroup.BUNDLES,
                                        currentPublisherId,
                                        EPermissionAction.MANAGE
                                    )
                            }
                        ]}
                    />
                }
            >
                <AcContentWrapper>
                    <DataTable
                        pagination={true}
                        columns={columns}
                        rows={
                            !getOffers.isLoading && getOffers.data
                                ? getOffers.data.offers
                                    ? getOffers.data.offers.map(
                                          (offer: Offer) => ({
                                              // New format
                                              _id: offer.publisherOfferId,
                                              ...offer
                                          })
                                      )
                                    : getOffers.data.result
                                          .filter(
                                              (offer: Offer) =>
                                                  offer.type === 'Bundle'
                                          ) // Old format
                                          .map((offer: Offer) => ({
                                              _id: offer.publisherOfferId,
                                              ...offer
                                          }))
                                : []
                        }
                        loading={getOffers.isLoading || getOffers.isFetching}
                        onPageChange={(newPage) => setCurrentPage(newPage)}
                        onRowsPerPageChange={(newRowsPerPage) => {
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(0);
                        }}
                        sortingMode={GridFeatureMode.SERVER}
                        onSortModelChange={handleSortModelChange}
                        currentPage={currentPage}
                        totalCount={totalCount}
                        defaultHiddenFields={[]}
                        localStorageColumnsKey={
                            ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY
                        }
                        hideFooter={false}
                        error={false}
                        initialSorting={{
                            sortModel: [
                                {
                                    field: sortValueToFieldMap[
                                        sorting.sortValue
                                    ],
                                    sort:
                                        sorting.direction ===
                                        SortingDirection.ASC
                                            ? SortingDirection.ASC
                                            : SortingDirection.DESC
                                }
                            ]
                        }}
                        onNoData={
                            <FirstActionModal
                                headline="Create your first bundle"
                                text="Hit the “Add new bundle” button on the top right corner of the screen to get started"
                            />
                        }
                    />
                </AcContentWrapper>
            </AcViewWrapper>

            <DialogModal
                isOpen={isDeleteDialogOpen}
                headline="Delete Offer"
                text="Are you sure you want to delete this offer?"
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            setIsDeleteDialogOpen(false);
                        }
                    },
                    {
                        text: 'Delete',
                        color: EButtonColor.ERROR,
                        variant: 'contained',
                        func: handleDeleteOffer
                    }
                ]}
                closeDialog={() => setIsDeleteDialogOpen(false)}
            />
        </>
    );
};

export default OffersTable;
