import { useEffect, useState } from 'react';

import { Stack } from '@mui/system';
import usePermissions from 'api/usePermissions';
import dayjs from 'dayjs';
import { serializeDate } from 'utils/formattingUtil';
import { permissionsUtil } from 'utils/permissionsUtil';

import ProjectsSelect from 'components/ProjectsSelect/ProjectsSelect';

import useReports from '../../api/useReports';
import { User } from '../../common/contracts';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import ActionButton from '../../components/ActionButton/ActionButton';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import DatePickerOverlay from '../../components/DatePickerOverlay/DatePickerOverlay';
import DialogModal from '../../components/Dialog/Dialog';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { NEW_VERSION } from '../../constants/constants';
import { EButtonColor, ELocalStorageKeys, ENotificationType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { UserDetails } from '../../pages/Login/Login.types';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { getSelectProjectOptions } from '../../utils/projectsUtil';

import { EReportStatus, Interval, Report as ReportType } from './Report.types';
import { getColumns } from './utils';

import './style.scss';

const BASE_DATE_FOR_FINANCIAL_REPORTS = '2024-01-01';

const FinancialReportsTable = () => {
    const projectsDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.projects;
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const { getPermissions } = usePermissions();
    const permissions = getPermissions();
    const allowedPublishers =
        permissionsUtil.getPublishersByFinancialReports(permissions);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState<string | null>(null);
    const [selectedProjects, setSelectedProjects] = useState<string[]>(
        projectsDetails
            ? getSelectProjectOptions(projectsDetails, allowedPublishers)
                      .map((project) => project.value)
            : []
    );
    const { getReportDownload, getReports, deleteReport, createReport } =
        useReports(
            versionDetails,
            projectsDetails
                ? getSelectProjectOptions(projectsDetails, allowedPublishers)[0]
                      .value
                : undefined
        );

    const { enqueueSnackbar } = useNotifications();
    const userDetails = localStorageUtil.get<UserDetails>(
        ELocalStorageKeys.USER_DETAILS
    );

    const onDownloadClick = (reportId: string) => {
        getReportDownload(reportId);
    };

    const deleteReportHandler = () => {
        if (selectedReport)
            deleteReport.mutate(selectedReport, {
                onSuccess: () => {
                    getReports.refetch();
                    enqueueSnackbar(
                        'Report has been deleted successfully!',
                        ENotificationType.SUCCESS
                    );
                },
                onError: (err) => {
                    console.error(err);
                    enqueueSnackbar(
                        `Couldn't delete report ${selectedReport}, please try again soon`,
                        ENotificationType.ERROR
                    );
                }
            });
    };

    useEffect(() => {
        getReports.refetch();
        if (getReports.data?.length > 0) {
            const hasInProgressReport = getReports.data.some(
                (report: Pick<ReportType, 'status'>) =>
                    report.status === EReportStatus.IN_PROGRESS
            );
        }
    }, []);

    const createNewReport = (v: any): void => {
        const data: {
            interval: Interval;
            createdBy: string;
            projects?: string;
        } = {
            interval: {
                startDate: serializeDate(v[0].$d),
                endDate: serializeDate(v[1].$d, true)
            },
            createdBy: `${userDetails.firstName} ${userDetails.lastName}`
        };

        const mutationParams: {
            data: { interval: Interval; createdBy: string };
            projects?: string;
        } = {
            data
        };

        if (selectedProjects.length > 0) {
            mutationParams.projects = selectedProjects.reduce(
                (acc, project, index) => {
                    const separator =
                        index === selectedProjects.length - 1 ? '' : ',';
                    return acc + `${project}${separator}`;
                },
                ''
            );
        }

        createReport.mutate(mutationParams, {
            onSuccess: () => {
                getReports.refetch();
                enqueueSnackbar(
                    'Your report request was successful.',
                    ENotificationType.SUCCESS
                );
            },
            onError: () => {
                enqueueSnackbar(
                    'Report creation failed. Please try again later.',
                    ENotificationType.ERROR
                );
            }
        });
    };

    const handleDeleteAction = (id: string) => {
        setDeleteDialogOpen(true);
        setSelectedReport(id);
    };

    const getReportRowsData = () => {
        if (!getReports.data) return [];
        const rowsData = getReports.data.map((report: ReportType) => report);
        if (versionDetails === NEW_VERSION) {
            return rowsData.filter((data: any) => data.publishers.every((item: string) => allowedPublishers.includes(item)));
        }
        return rowsData.reverse();
    };

    return (
        <AcViewWrapper
            header={
                versionDetails !== NEW_VERSION ? (
                    <PageTopBar
                        disable={false}
                        headline="Financial Reports"
                        buttons={
                            !versionDetails
                                ? [
                                      {
                                          text: 'Generate New',
                                          action: () =>
                                              setIsDatePickerOpen(true),
                                          disabled: false
                                      }
                                  ]
                                : []
                        }
                    />
                ) : null
            }
        >
            <AcContentWrapper>
                <Stack>
                    <Stack
                        direction="row"
                        gap={2}
                        className="reports-action-block"
                    >
                        {projectsDetails && (
                            <>
                                <ProjectsSelect
                                    projectsDetails={projectsDetails}
                                    selectedProjects={selectedProjects}
                                    setSelectedProjects={setSelectedProjects}
                                    isApplyBtnEnabled={true}
                                    allowedPublishers={allowedPublishers}
                                />
                                <ActionButton
                                    size="small"
                                    onClick={() => setIsDatePickerOpen(true)}
                                    sx={{
                                        textTransform: 'capitalize'
                                    }}
                                    text={'Generate New'}
                                    disabled={selectedProjects.length === 0}
                                />
                            </>
                        )}
                    </Stack>
                </Stack>
                <div className="list">
                    <DataTable
                        className="financial-reports-table"
                        columns={getColumns(
                            (reportId) => onDownloadClick(reportId),
                            (id) => handleDeleteAction(id),
                            versionDetails,
                            projectsDetails
                        )}
                        rows={getReportRowsData()}
                        rowIdIdentifier={'reportId'}
                        loading={getReports.isLoading}
                        error={getReports.isError}
                        onNoData={
                            <FirstActionModal
                                headline="No reports to display"
                                text="Create a new report to display it here"
                                height="auto"
                            />
                        }
                    />
                    {getReports.data?.length > 0 && (
                        <div className={'disclaimer'}>
                            *The finalized financial data will be sent by
                            Appcharge at the end of the month
                        </div>
                    )}
                </div>
            </AcContentWrapper>
            <DatePickerOverlay
                isOpen={isDatePickerOpen}
                isHourPicker={false}
                setIsOpen={setIsDatePickerOpen}
                onApply={createNewReport}
                minDate={dayjs(BASE_DATE_FOR_FINANCIAL_REPORTS)}
                onApplyButtonText={'Generate'}
            />
            <DialogModal
                isOpen={isDeleteDialogOpen}
                headline="Delete Report"
                text="Are you sure you want to delete this report?"
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            setDeleteDialogOpen(false);
                        }
                    },
                    {
                        text: 'Delete',
                        color: EButtonColor.ERROR,
                        variant: 'contained',
                        func: () => {
                            deleteReportHandler();
                            setDeleteDialogOpen(false);
                        }
                    }
                ]}
                closeDialog={() => setDeleteDialogOpen(false)}
            />
        </AcViewWrapper>
    );
};

export default FinancialReportsTable;
