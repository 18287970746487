import { useEffect, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useSetCssVars } from '@appcharge/shared-ui';
import { LinearProgress, Stack } from '@mui/material';

import useTheme from '../../api/useTheme';
import useUsers from '../../api/useUsers';
import { User } from '../../common/contracts';
import DialogModal from '../../components/Dialog/Dialog';
import PricingView from '../../components/Pricing/PricingView';
import SettingsView from '../../components/Settings/SettingsView';
import Sidebar from '../../components/Sidebar/Sidebar';
import ThemeView from '../../components/Theme/Theme';
import { DEFAULT_LOGOUT_TIMEOUT_LONG, DEFAULT_LOGOUT_TIMEOUT_SHORT, NEW_VERSION } from '../../constants/constants';
import { EButtonColor, ELocalStorageKeys } from '../../constants/enums';
import { authActions } from '../../store/authSlice';
import { AuthSliceState, UISliceState } from '../../store/store.types';
import { uiActions } from '../../store/uiSlice';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { unsavedChangesUtil } from '../../utils/unsavedChangesUtil';
import AssetsView from '../../views/Assets/AssetsView';
import BadgesView from '../../views/Badges/BadgesView';
import OffersView from '../../views/Offers/OffersView';
import OffersUIView from '../../views/OffersUI/OffersUIView';
import OrdersView from '../../views/Orders/OrdersView';
import PopupsView from '../../views/Popups/PopupsView';
import ProductsView from '../../views/Products/ProductsView';
import ProfileView from '../../views/Profile/ProfileView';
import ProjectsView from '../../views/Projects/ProjectsView';
import PromotionsView from '../../views/Promotions/PromotionsView';
import FinancialReportsTable from '../../views/Reports/FinancialReports';
import PayoutsTable from '../../views/Reports/Payouts';
import ReportsView from '../../views/Reports/ReportsView';
import SegmentsView from '../../views/Segments/SegmentsView';
import StatisticsView from '../../views/Statistics/StatisticsView';

import './style.scss';

const BackOffice = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
    const uiState = useSelector(({ ui }: { ui: UISliceState }) => ui);
    const dispatch = useDispatch();

    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );

    const { getStoreTheme } = useTheme(currentPublisherId);
    useSetCssVars(getStoreTheme.data, document);

    const { logout } = useUsers(
        false,
        versionDetails,
        currentPublisherId,
        false
    );
    const url = window.location.href;

    const DEFAULT_LOGOUT_TIMEOUT =
        url.includes('dashboard-dev') || url.includes('dashboard-staging')
            ? DEFAULT_LOGOUT_TIMEOUT_LONG
            : DEFAULT_LOGOUT_TIMEOUT_SHORT;

    useEffect(() => {
        return () => {
            dispatch(authActions.clearState());
        };
    }, [dispatch]);

    const onIdle = () => {
        const now = new Date();
        const lastActiveTime = getLastActiveTime();
        if (lastActiveTime) {
            const idleDuration = now.getTime() - lastActiveTime.getTime();

            if (idleDuration >= DEFAULT_LOGOUT_TIMEOUT) {
                logout.mutate();
            }
        }
    };

    const { getLastActiveTime } = useIdleTimer({
        timeout: DEFAULT_LOGOUT_TIMEOUT,
        onIdle
    });

    return (
        <div id="backOffice">
            {localStorageUtil.get(ELocalStorageKeys.MOCK_API) &&
                Object.entries(
                    localStorageUtil.get<Record<string, boolean>>(
                        ELocalStorageKeys.MOCK_API
                    )
                ).find(([_, v]) => !!v) && (
                    <div
                        style={{
                            background: 'red',
                            position: 'fixed',
                            zIndex: 9999,
                            width: '100vw',
                            color: 'white',
                            textAlign: 'center'
                        }}
                    >
                        SOME ROUTES MOCKED
                    </div>
                )}
            <LinearProgress
                value={0}
                sx={{
                    transition: 'opacity 0.5s',
                    opacity: uiState.globalLoading ? 1 : 0,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 999
                }}
            />
            <Sidebar
                updateMainSize={setIsSidebarOpen}
                isSidebarOpen={isSidebarOpen}
            />
            <div id="main" className={isSidebarOpen ? '' : 'collapsed'}>
                <Routes>
                    <Route path="/project" element={<ProjectsView />} />
                    <Route
                        path="project/:id?/statistics"
                        element={<StatisticsView />}
                    />
                    <Route
                        path="project/:id?/orders/*"
                        element={<OrdersView />}
                    />
                    <Route
                        path="project/:id?/segments/*"
                        element={<SegmentsView />}
                    />
                    {versionDetails === NEW_VERSION ? (
                        <Route
                            path="project/:id?/reports/*"
                            element={<ReportsView />}
                        >
                            <Route
                                index
                                path="financialreports"
                                element={<FinancialReportsTable />}
                            />
                            <Route path="payouts" element={<PayoutsTable />} />
                        </Route>
                    ) : (
                        <>
                            <Route
                                path="project/:id?/reports/*"
                                element={<FinancialReportsTable />}
                            />
                            <Route
                                path="project/:id?/payouts/*"
                                element={<PayoutsTable />}
                            />
                        </>
                    )}
                    <Route
                        path="project/:id?/settings"
                        element={<SettingsView />}
                    />
                    <Route
                        path="project/:id?/profile"
                        element={<ProfileView />}
                    />
                    <Route path="project/:id?/theme/*" element={<ThemeView />} />
                    <Route
                        path="project/:id?/products/*"
                        element={<ProductsView />}
                    />
                    <Route
                        path="project/:id?/offersui/*"
                        element={<OffersUIView />}
                    />
                    <Route
                        path="project/:id?/badges/*"
                        element={<BadgesView />}
                    />
                    <Route
                        path="project/:id?/assets"
                        element={<AssetsView />}
                    />
                    <Route
                        path="project/:id?/offers/*"
                        element={<OffersView />}
                    />
                    <Route
                        path="project/:id?/promotions/*"
                        element={<PromotionsView />}
                    />
                    <Route
                        path="project/:id?/popups/*"
                        element={<PopupsView />}
                    />
                    <Route
                        path="project/:id?/pricing/*"
                        element={<PricingView />}
                    />
                    <Route
                        path="projects/test"
                        element={
                            <Stack
                                width="100vw"
                                height="100vh"
                                style={{ background: 'red' }}
                            />
                        }
                    />
                </Routes>
            </div>
            <DialogModal
                isOpen={
                    uiState.isUnsavedChanges &&
                    uiState.isUnsavedChangesModalOpen
                }
                headline="Unsaved Changes"
                text="You have unsaved changes. Are you sure you want to leave?"
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            dispatch(
                                uiActions.setUnsavedChangesModalOpen(false)
                            );
                        }
                    },
                    {
                        text: 'Leave',
                        color: EButtonColor.PRIMARY,
                        variant: 'contained',
                        func: () => {
                            unsavedChangesUtil.func();
                            dispatch(
                                uiActions.setUnsavedChangesModalOpen(false)
                            );
                            dispatch(uiActions.setUnsavedChanges(false));
                        }
                    }
                ]}
                closeDialog={() => {
                    dispatch(uiActions.setUnsavedChangesModalOpen(false));
                    dispatch(uiActions.setUnsavedChanges(false));
                }}
            />
            <ToastContainer
                position="bottom-right"
                bodyClassName="notifications-body"
                bodyStyle={{
                    alignItems: 'flex-start'
                }}
            />
        </div>
    );
};

export default BackOffice;
