export interface FormatSettings {
    currency?: 'USD' | 'EUR';
    ratio?: number;
}

export const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (c) => c.toUpperCase());
};

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const transformQuantityValue = (inputValue: string) => {
    let value = inputValue;
    if (inputValue.startsWith('0') && inputValue !== '0') {
        value = inputValue.replace(/^0+/, '');
    }
    const parsedNumber = parseInt(value);
    if (!isNaN(parsedNumber) && String(parsedNumber) === value) {
        return parsedNumber;
    }
    try {
        return BigInt(inputValue).toString();
    } catch (error) {
        return 0;
    }
};

export const serializeDate = (date: Date | null | undefined, endOfDay: boolean = false) => {
    if (!date) return '';

    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    let hours: number | string = date.getHours();
    let minutes: number | string = date.getMinutes();
    let seconds: number | string = date.getSeconds();
    let milliseconds: number | string = date.getMilliseconds();

    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }

    if (hours < 10) {
        hours = `0${hours}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    if (milliseconds < 10) {
        milliseconds = `00${milliseconds}`;
    } else if (milliseconds < 100) {
        milliseconds = `0${milliseconds}`;
    }

    if (endOfDay) {
        hours = 23;
        minutes = 59;
        seconds = 59;
        milliseconds = 999;
    }

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

export const formatReceiptNumber = (receiptNumber: string): string => {
    if (!/^\d{9}$/.test(receiptNumber)) {
        return receiptNumber;
    }
    return receiptNumber.replace(/(\d{3})(?=\d)/g, '$1-');
}
