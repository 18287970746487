import {
    EBadgePosition,
    EFontWeights
} from '@appcharge/shared-ui/lib/components/Helper/enums';
import { Gradient } from '@appcharge/shared-ui/lib/components/Helper/types';
import { EPopupSubType, ESalePercentageDisplayType } from 'constants/enums';
import Decimal from 'decimal.js';

import { GradientDirection } from '../components/AcGradientInput/AcGraidentInput.types';
import { SectionsItem } from '../components/Theme/StoreTheme/storeTheme.types';

export enum UserRole {
    ADMIN = 'admin',
    VIEWER = 'viewer',
    EDITOR = 'editor',
    SUPER_ADMIN = 'appcharge',
    SUPER_ADMIN_NEW_VERSION = 'Super Administrator'
}

export interface UserRoleV2 {
    roleId: string;
    roleName: string;
}

export enum EUserTypeId {
    APPCHARGE = 'Appcharge'
}

export interface Project {
    publisherId: string;
    publisherName: string;
    publisherLogo: string;
    role: string;
    projectType?: EPublisherSolutionModel;
}
export interface User {
    _id: string;
    userId: string;
    publisherId: string;
    groupId: string;
    typeId: string;
    email: string;
    name: string;
    password: string;
    active: boolean;
    lastLogin?: Date | null;
    createdAt: Date;
    updatedAt: Date;
    role: UserRole;
    projects?: Project[];
    version?: number;
    company?: string;
}

export interface Image {
    createdAt: string;
    name: string;
    publisherId: string;
    type: string;
    updatedAt: string;
    url: string;
    _id: string;
}

export interface OrderDetails {
    _id: string;
    playerId: string;
    bundleSku: string;
    bundleName: string;
    receiptId: string;
    bundleId: string;
    createdAt: string;
    currency: string;
    status: string;
    currencySymbol: string;
    providerPaymentId: string;
    publisherPurchaseId?: string;
    amountInDollar: Decimal;
    paymentResolution: string;
    taxRatePoints: Decimal;
    taxTotal: Decimal;
    amountNetInDollar: Decimal;
    amountNetTotal: Decimal;
    estimatedAppchargeFee: number;
    estimatedPublisherNetAmount: number;
    products: {
        sku: string;
        name: string;
        quantity: number;
    }[];
    history: {
        status: string;
        createdAt: string;
        event: string;
    }[];
    paymentMethod: {
        primaryMethod: string;
        secondaryMethod?: string;
    };
    refundThresholdDays: number;
    dispute: Dispute;
    purchaseId?: string;
    playerGeo: {
        countryCode2: string;
        state: string;
        city: string;
        timezone: string;
        latitude: number;
        longitude: number;
        zipCode: string;
        createdByIp: string;
    };
    playerEmail?: string;
    offerSnapshot: {
        currencyCode: string;
        description: string;
        mixpanelSessionId: string;
        name: string;
        price: number;
        priceInUsdCents: number;
        offerId: string;
        publisherId: string;
        sequenceIndex: number;
        sequenceNextIndex: number;
        sku: string;
        subType: string;
        type: string;
    };
    state?: string;
    zipCode?: string;
}

export interface Dispute {
    _id: string;
    publisherId: string;
    status: string;
    evidences: DisputeEvidence[];
}

export interface DisputeEvidence {
    name: string;
    link: string;
    description: string;
    username: string;
    createdAt: string;
    updatedAt: string;
    _id: string;
}

export interface OfferUI {
    _id?: string;
    active: boolean;
    offerUiType: string;
    name: string;
    description: string;
    backgroundImage: string;
    badgeCoverImage?: string;
    externalId?: string;
    specialOffer?: {
        templateType: string;
        presentOfferEndTimer: boolean;
        title: string;
        fontSize: number;
        fontWeight: EFontWeights;
        fontColor: Gradient;
        backgroundColor: Gradient;
    };
    popup?: {
        templateType: string;
    };
    offerUiSubType?: string;
    borderColor?: Gradient;
    borderWidth?: number;
}

export enum EBadgeType {
    RIBBON = 'ribbon',
    EMBLEM = 'emblem'
}

export interface ColorDto {
    gradientDirection?: GradientDirection;
    colorOne: string;
    colorTwo?: string;
}

export interface Badge {
    badgeId?: string;
    name: string;
    type: EBadgeType;
    publisherBadgeId?: string;
    position: EBadgePosition;
    emblem?: {
        imageUrl: string;
    };
    ribbon?: {
        text: string;
        backgroundColor: ColorDto;
        textColor: ColorDto;
    };
}

export interface BundleBadge {
    content: string;
    key: string;
    name: string;
    position: string;
    url?: string;
    value: string;
}

export interface Interval {
    startDate: string;
    endDate: string;
}

export interface ProductsSequenceProduct {
    product?: string;
    productId?: string;
    quantity: number | string;
    publisherProductId?: string;
}

export interface ProductsSequence {
    index: number;
    products: ProductsSequenceProduct[];
    priceInUsdCents: number;
    playerAvailability?: number;
}

export interface ImageDetails {
    url: string;
    type: string;
}

export interface PopUpProductDetails {
    productId?: string;
    images?: ImageDetails[];
    name?: string;
    textFontColorHex?: string;
    prefix?: string;
    suffix?: string;
    priority?: string;
    type?: string;
    publisherProductId?: string;
}

export interface PopUpSequenceProduct {
    product?: PopUpProductDetails;
    quantity?: number | string;
}

export interface PopUpProductSequence {
    index: number;
    products: PopUpSequenceProduct[];
    priceInUsdCents: number;
    playerAvailability?: number;
    id?: string;
}

export interface DynamicOfferUIBadge {
    salesPercentage?: number;
    badgeId: string;
    badge?: Badge;
    publisherBadgeId: string;
    position?: EBadgePosition;
}

export interface DynamicOfferUI {
    badges: DynamicOfferUIBadge[];
    salePercentage?: number | null;
    salePercentageDisplayType?: ESalePercentageDisplayType;
}

export interface TriggerRule {
    fieldName: string;
    operator: string;
    value: string[];
}

export interface TriggersObject {
    type: string;
    eventName: string;
    every: number;
    rules?: TriggerRule[];
}

export interface Offer {
    publisherOfferId: string;
    name: string;
    description: string;
    type: 'Bundle' | 'SpecialOffer' | 'PopUp';
    subType?: EPopupSubType;
    priority?: number;
    intervals: Interval[];
    offerUiId?: string;
    offerUi?: {
        offerUiId?: string;
        _id?: string;
        active: boolean;
        offerUiType: string;
        name: string;
        description: string;
        backgroundImage: string;
        specialOffer: {
            templateType: string;
            presentOfferEndTimer: boolean;
            title: string;
            fontSize: number;
            fontWeight: string;
            fontColor: {
                colorOne: string;
                colorTwo: string;
                direction: string;
            };
        };
    };
    active: boolean;
    coolDownInHours: number;
    segments: string[];
    productsSequence: ProductsSequence[] | PopUpProductSequence[];
    createdBy: 'API';
    dynamicOfferUi: DynamicOfferUI;
    playerAvailability?: number;
    triggers: Array<TriggersObject>;
    showAfter?: any;
    section?: Partial<SectionsItem> | null;
    sectionId?: Partial<SectionsItem> | null;
    startOver?: boolean;
}

export interface ConvertedPriceParams {
    row: {
        productsSequence: ProductsSequence[];
    };
}

export enum EPublisherSolutionModel {
    STORE = 'webStore',
    CHECKOUT = 'independentCheckout'
}

export interface PublisherInfo {
    _id: string;
    companyName: string;
    phoneNumberPrefix: string;
    phoneNumber: number;
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    address: string;
    supportMail: string;
    supportImageUrl: string;
    domain: string;
    active: boolean;
    activeStore: boolean;
    storeTabName: string;
    publisherSolutionModel: EPublisherSolutionModel;
    awardMail: string;
    createdAt: string;
    updatedAt: string;
}

export interface Section {
    publisherSectionId: string;
    name: string;
    image?: string;
    sectionId?: string;
}
