import { useLocation } from 'react-router-dom';

import { PAGINATION_ROWS_PER_PAGE } from '../constants/constants';
import {
    SortingDirection,
    SortingOffersValue,
    SortingOrderValue
} from '../constants/enums';

type SortingType = 'offers' | 'orders' | '';

export function usePaginationFromURL<T extends SortingType>(type: T) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = Number(queryParams.get('page') || 0);
    const rows = Number(queryParams.get('rows') || PAGINATION_ROWS_PER_PAGE);

    type SortValue = T extends 'offers'
        ? SortingOffersValue
        : SortingOrderValue;
    let sortValue: SortValue, direction: SortingDirection;

    if (type === 'offers') {
        sortValue = ((queryParams.get('sortValue') as SortingOffersValue) ||
            SortingOffersValue.CREATED_AT) as SortValue;
    } else {
        sortValue = ((queryParams.get('sortValue') as SortingOrderValue) ||
            SortingOrderValue.DATE) as SortValue;
    }

    direction =
        (queryParams.get('direction') as SortingDirection) ||
        SortingDirection.DESC;

    return { page, rows, sortValue, direction };
}
