import { ELocalStorageKeys } from '../constants/enums';

export const localStorageUtil = {
    set: (key: ELocalStorageKeys, value: any, isSession = false) => {
        (window as any)[isSession ? 'sessionStorage' : 'localStorage'].setItem(
            key,
            JSON.stringify(value)
        );
    },
    getAny: <T = any>(key: ELocalStorageKeys): T | null => {
        return window.localStorage.getItem(key)
            ? (JSON.parse(window.localStorage.getItem(key)!) as T)
            : window.sessionStorage.getItem(key)
              ? (JSON.parse(window.sessionStorage.getItem(key)!) as T)
              : null;
    },
    get: <T = any>(key: ELocalStorageKeys, isSession = false): T => {
        return (
            (window as any)[
                isSession ? 'sessionStorage' : 'localStorage'
            ].getItem(key) &&
            (JSON.parse(
                window[isSession ? 'sessionStorage' : 'localStorage'].getItem(
                    key
                )!
            ) as T)
        );
    },
    remove: (key: ELocalStorageKeys, isSession = false) => {
        return (window as any)[
            isSession ? 'sessionStorage' : 'localStorage'
        ].removeItem(key);
    },
    removeAny: (key: ELocalStorageKeys) => {
        (window as any).localStorage.removeItem(key);
        (window as any).sessionStorage.removeItem(key);
    }
};
