import { useQuery } from '@tanstack/react-query';
import Decimal from 'decimal.js';

import { User } from '../common/contracts';
import { AcChartValue } from '../components/AcChartBox/AcChartBox.types';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';
import { EStatisticType } from '../views/Statistics/StatisticsView.types';

import { fetchConfig } from './api.utils';

export enum EMetric {
    TOTAL_REVENUE = 'totalRevenue',
    DEPOSITS = 'deposits',
    ARPPU = 'arppu',
    AVERAGE_DEPOSITS_PER_DEPOSITOR = 'averageDepositsPerDepositor',
    DEPOSITORS = 'depositors',
    ARPU = 'arpu',
    ACTIVE_USERS = 'activeUsers',
    SESSIONS = 'sessions',
    CONVERSION_RATE = 'conversionRate',
    DAU = 'dau',
    TOTAL_REVENUE_WITHOUT_TAX = 'totalRevenueWithoutTax'
}

export enum EMetricType {
    PRICE = 'price',
    AMOUNT = 'amount'
}

export const metricDictionary: Record<
    EMetric,
    {
        title: string;
        type?: EStatisticType;
    }
> = {
    [EMetric.TOTAL_REVENUE]: {
        title: 'Total Revenue'
    },
    [EMetric.TOTAL_REVENUE_WITHOUT_TAX]: {
        title: 'Total Revenue Without Tax'
    },
    [EMetric.DEPOSITS]: { title: 'Total Deposits' },
    [EMetric.ARPPU]: {
        title: 'ARPPU',
        type: EStatisticType.AMOUNT_AVERAGE
    },
    [EMetric.AVERAGE_DEPOSITS_PER_DEPOSITOR]: {
        title: 'Average Deposits Per Depositor'
    },
    [EMetric.DEPOSITORS]: {
        title: 'Depositors'
    },
    [EMetric.ARPU]: {
        title: 'ARPU',
        type: EStatisticType.AMOUNT_AVERAGE
    },
    [EMetric.ACTIVE_USERS]: {
        title: 'Active Users'
    },
    [EMetric.SESSIONS]: {
        title: 'Total Sessions'
    },
    [EMetric.CONVERSION_RATE]: {
        title: 'Conversion Rate'
    },
    [EMetric.DAU]: {
        title: 'Average Daily Active Users'
    }
};

export interface StatsResult {
    name: EMetric;
    type: EStatisticType;
    currentValue?: number;
    previousValue?: number;
    changePercentage?: number;
}

export interface TrendsResult {
    name: EMetric;
    type: EStatisticType;
    values: { value: number; date: string }[];
}

export default function useAnalytics(
    startDateTime: string,
    endDateTime: string,
    statsMetrics: EMetric[],
    trendsMetrics: EMetric[],
    currentPublisherId?: string
) {
    const axios = useAxios();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getAnalytics = useQuery({
        ...fetchConfig.general,
        queryKey: [
            `${EQueryKeys.ANALYTICS}_${startDateTime}_${endDateTime}_${statsMetrics}`
        ],
        queryFn: async () => {
            let data = await axios.get('/dashboard/analytics/stats', {
                startDate: startDateTime,
                endDate: endDateTime,
                metrics: statsMetrics
            }, currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined);
            if (localStorageUtil.get(ELocalStorageKeys.OVERRIDE_STATS)) {
                data = JSON.parse(
                    localStorageUtil.get(ELocalStorageKeys.OVERRIDE_STATS)
                );
            }
            const result: StatsResult[] = data.stats.map((el: any) => {
                if (
                    el.type === EStatisticType.CURRENCY &&
                    el.name !== EMetric.ARPPU &&
                    el.name !== EMetric.ARPU
                ) {
                    return {
                        ...el,
                        currentValue: el
                            ? new Decimal(el.currentValue || 0).div(100)
                            : 0
                    };
                }
                return el;
            });

            return result;
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const getTrends = useQuery({
        ...fetchConfig.general,
        queryKey: [
            `${EQueryKeys.TRENDS}_${startDateTime}_${endDateTime}_${trendsMetrics}`
        ],
        queryFn: async () => {
            let data = await axios.get('/dashboard/analytics/trends', {
                startDate: startDateTime,
                endDate: endDateTime,
                metrics: trendsMetrics
            }, currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined);

            if (localStorageUtil.get(ELocalStorageKeys.OVERRIDE_TRENDS)) {
                data = JSON.parse(
                    localStorageUtil.get(ELocalStorageKeys.OVERRIDE_TRENDS)
                );
            }

            const result: TrendsResult[] = data.trends.map((el: any) => {
                if (
                    el.type === EStatisticType.CURRENCY ||
                    el.name === EMetric.ARPPU ||
                    el.name === EMetric.ARPU
                ) {
                    return {
                        ...el,
                        type:
                            el.name === EMetric.ARPPU ||
                            el.name === EMetric.ARPU
                                ? EStatisticType.CURRENCY
                                : el.type,
                        values: el.values.map((v: AcChartValue) => {
                            return {
                                ...v,
                                value: new Decimal(v.value || 0).div(100)
                            };
                        })
                    };
                }
                return el;
            });

            return result;
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    return {
        getAnalytics,
        getTrends
    };
}
