import usePricing from '../api/usePricing';

const DEFAULT_MULTIPLIER = 100;

export const useMultiplier = (currentPublisherId: string) => {
    const { getGeo } = usePricing(currentPublisherId, undefined, false);
    const geoItems = getGeo.data || [];

    const getOrderMultiplier = (currency: string = ''): number => {
        const matchingGeo = geoItems?.find(
            ({ currencyDetails }) => currencyDetails?.currencyCode === currency
        );
        return matchingGeo
            ? matchingGeo.currencyDetails.multiplier
            : DEFAULT_MULTIPLIER;
    };

    return { getOrderMultiplier };
};
