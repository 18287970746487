import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CloseOutlined, RefreshOutlined, ViewListOutlined } from '@mui/icons-material';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Divider, Grid, Stack, Tab, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { AuthSliceState } from 'store/store.types';
import * as yup from 'yup';

import usePricing from '../../api/usePricing';
import usePublisher from '../../api/usePublisher';
import { DATE_TIME_FORMAT } from '../../constants/constants';
import { EButtonColor, ENotificationType, ESetting } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { errorResponse } from '../../utils/errorsTextHelper';
import { permissionsUtil } from '../../utils/permissionsUtil';
import AcContentWrapper from '../AcContentWrapper/AcContentWrapper';
import AcTabs from '../AcTabs/AcTabs';
import ActionButton from '../ActionButton/ActionButton';
import AcViewWrapper from '../AcViewWrapper/AcViewWrapper';
import DataTable from '../DataTable/DataTable';
import TableHeaderButton from '../DataTable/TableHeaderButton';
import DialogModal from '../Dialog/Dialog';
import FirstActionModal from '../FirstActionModal/FirstActionModal';
import CustomizedSwitch from '../SwitchButton/SwitchButton';
import PageTopBar from '../Topbar/PageTopBar';

import { ImportedPricingPoint } from './Pricing.types';

import './style.scss';

enum EPricingFormState {
    PRICING_POINTS = 'pricingPoints',
    SETTINGS = 'settings'
}

const PricingTable = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );

    const navigate = useNavigate();
    const { getPublisherSettings, updatePublisherSettings } = usePublisher(
        currentPublisherId ? currentPublisherId : undefined
    );
    const [tab, setTab] = useState(EPricingFormState.PRICING_POINTS);
    const {
        getPricings,
        deletePricing,
        importCsvFile,
        updateExchangeRates,
        getPricingPointsTemplate
    } = usePricing(currentPublisherId);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState<ESetting | null>(null);
    const [selectedPricing, setSelectedPricing] = useState<string | null>(null);
    const { enqueueSnackbar } = useNotifications();
    const [settings, setSettings] = useState({
        roundingRulesEnabled: true,
        taxEnabled: false
    });
    const [updateExchangeRatesDialogOpen, setUpdateExchangeRatesDialogOpen] =
        useState(false);
    const [tableLoader, setTableLoader] = useState(false);

    const fileRef = useRef<HTMLInputElement>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadCSVOpen, setUploadCSVOpen] = useState(false);
    const [isMessagesDialogOpen, setMessagesDialogOpen] = useState(false);
    const [
        importedPricingPointsWithErrors,
        setImportedPricingPointsWithErrors
    ] = useState<ImportedPricingPoint[]>([]);
    const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

    const handleTemplateDownload = () => {
        getPricingPointsTemplate
            .refetch()
            .then((response) => {
                const { data } = response;
                if (data) {
                    const url = window.URL.createObjectURL(
                        new Blob([data], { type: 'text/csv' })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        'Import_price_point_template.csv'
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }
            })
            .catch((error) => {
                if (error instanceof AxiosError) {
                    enqueueSnackbar(
                        errorResponse(error),
                        ENotificationType.ERROR
                    );
                }
            });
    };

    const calculateFileSizeDisplay = (sizeInBytes: number) => {
        const sizeInKB = sizeInBytes / 1024;
        if (sizeInKB < 1024) {
            return sizeInKB.toFixed(2) + ' KB';
        } else {
            const sizeInMB = sizeInKB / 1024;
            return sizeInMB.toFixed(2) + ' MB';
        }
    };

    const csvUploadSchema = yup.object().shape({
        file: yup
            .mixed()
            .required('CSV file is required')
            .test(
                'fileFormat',
                'Unsupported Format',
                (value: { type: string }) => value && value.type === 'text/csv'
            )
    });

    const { submitForm, resetForm, setFieldValue } = useFormik({
        validateOnMount: true,
        validationSchema: csvUploadSchema,
        enableReinitialize: true,
        initialValues: {
            file: null
        },
        onSubmit: async (values, { resetForm }) => {
            const file = fileRef?.current?.files?.[0];
            const formData = new FormData();
            if (!file) return;
            if (file && file.size > 2000000) {
                enqueueSnackbar(
                    `File size limit is 2MB`,
                    ENotificationType.ERROR
                );
                return;
            }
            setIsUploading(true);

            formData.append('pricePointsFile', file as any);

            importCsvFile.mutate(formData, {
                onSuccess: async (response: ImportedPricingPoint[]) => {
                    enqueueSnackbar(
                        `File has been uploaded successfully`,
                        ENotificationType.SUCCESS
                    );

                    resetForm();
                    setIsUploading(false);
                    setUploadCSVOpen(false);
                    getPricings.refetch();

                    const pricingPointsWithErrors = response.filter(
                        (point) =>
                            point.message !== '' ||
                            (point.body.errors && point.body.errors.length > 0)
                    );

                    if (pricingPointsWithErrors.length > 0) {
                        setImportedPricingPointsWithErrors(
                            pricingPointsWithErrors
                        );
                        setMessagesDialogOpen(true);
                    }
                },
                onError(error) {
                    enqueueSnackbar(
                        `File has failed to upload`,
                        ENotificationType.ERROR,
                        (
                            (error as AxiosError).response?.data as {
                                message: string;
                            }
                        ).message
                    );
                },
                onSettled() {
                    setIsUploading(false);
                }
            });
        }
    });

    function onImportCSV() {
        setUploadCSVOpen(true);
    }

    const handleFileChange = (event: any) => {
        const file = event.currentTarget.files[0];
        if (file.type !== 'text/csv') {
            enqueueSnackbar(
                'Please upload a CSV file.',
                ENotificationType.ERROR
            );
            return;
        }
        setFieldValue('file', file);
    };

    useEffect(() => {
        getPublisherSettings
            .refetch()
            .then((response) => {
                const settings = response.data;
                setSettings({
                    roundingRulesEnabled: settings?.roundingOn,
                    taxEnabled: settings?.taxOn
                });
            })
            .catch((error) => {
                enqueueSnackbar(
                    'Failed to load settings',
                    ENotificationType.ERROR
                );
                console.error('Error fetching publisher settings:', error);
            });
    }, []);

    const handleTabChange = (event: any, newValue: EPricingFormState) => {
        setTab(newValue);
    };

    const onDeletePricing = () => {
        if (!selectedPricing) return;
        deletePricing.mutate(selectedPricing, {
            onSuccess: () => {
                setDeleteDialogOpen(false);
                setSelectedPricing(null);
                enqueueSnackbar(
                    'Pricing deleted successfully',
                    ENotificationType.SUCCESS
                );
                getPricings.refetch();
            },
            onError: () => {
                setDeleteDialogOpen(false);
                setSelectedPricing(null);
                enqueueSnackbar(
                    'There was a problem deleting the Pricing',
                    ENotificationType.ERROR
                );
            }
        });
    };

    const handleSettingChange = (settingType: ESetting) => {
        setDialogType(settingType);
        setDialogOpen(true);
    };

    const submitSettingChange = () => {
        if (!dialogType) return;
        const settingName =
            dialogType === ESetting.ROUNDING_ON ? 'Rounding rules' : 'Tax';
        const isActive =
            dialogType === ESetting.ROUNDING_ON
                ? !settings.roundingRulesEnabled
                : !settings.taxEnabled;
        updatePublisherSettings.mutate(
            { setting: dialogType, isActive },
            {
                onSuccess: () => {
                    enqueueSnackbar(
                        `${settingName} setting updated successfully`,
                        ENotificationType.SUCCESS
                    );
                    setSettings((prevSettings) => ({
                        ...prevSettings,
                        [dialogType === ESetting.ROUNDING_ON
                            ? 'roundingRulesEnabled'
                            : 'taxEnabled']: isActive
                    }));
                    setDialogOpen(false);
                },
                onError: () => {
                    enqueueSnackbar(
                        `Error updating ${settingName} setting`,
                        ENotificationType.ERROR
                    );
                    setDialogOpen(false);
                }
            }
        );
    };

    const pricingPointsErrorsContent = (
        <div className={'price-points__errors-content-block'}>
            {importedPricingPointsWithErrors.length > 0 && (
                <>
                    {/* Display the first error message */}
                    <div className={'price-points__error-message'}>
                        <div>{importedPricingPointsWithErrors[0].record}</div>
                        {/* Check and render message if no errors array or it's empty */}
                        {(!importedPricingPointsWithErrors[0].body.errors ||
                            importedPricingPointsWithErrors[0].body.errors
                                .length === 0) && (
                            <div>
                                {importedPricingPointsWithErrors[0].message}
                            </div>
                        )}
                        {/* Render first error or more based on toggle if errors exist */}
                        {importedPricingPointsWithErrors[0].body.errors
                            ?.slice(0, showAllErrors ? undefined : 1)
                            .map((err, index) => <div key={index}>{err}</div>)}
                        {!showAllErrors &&
                            importedPricingPointsWithErrors[0].body.errors &&
                            importedPricingPointsWithErrors[0].body.errors
                                .length > 1 && (
                                <div
                                    className={
                                        'price-points__toggle-visibility'
                                    }
                                    onClick={() => setShowAllErrors(true)}
                                >
                                    Show more
                                </div>
                            )}
                    </div>
                    {/* Handle subsequent errors if "Show all" is active */}
                    {showAllErrors &&
                        importedPricingPointsWithErrors
                            .slice(1)
                            .map((point, index) => (
                                <div
                                    key={index}
                                    className={'price-points__error-message'}
                                >
                                    <div>{point.record}</div>
                                    {point.body.errors?.map((err, idx) => (
                                        <div key={idx}>{err}</div>
                                    ))}
                                    {/* Handle cases where only message is present */}
                                    {(!point.body.errors ||
                                        point.body.errors.length === 0) && (
                                        <div>{point.message}</div>
                                    )}
                                </div>
                            ))}
                    {/* Toggle to "Show less" if all errors are being displayed */}
                    {showAllErrors && (
                        <div
                            className={'price-points__toggle-visibility'}
                            onClick={() => setShowAllErrors(false)}
                        >
                            Show less
                        </div>
                    )}
                </>
            )}
        </div>
    );

    const handlePricingSettingsTabShown = () => {
        if (!permissionsUtil.isNewVersion())
            return permissionsUtil.isSuperAdmin();
        return currentPublisherId
            ? permissionsUtil.isSuperAdminByProject(currentPublisherId)
            : false;
    };

    const updateExchangeRatesHandler = async () => {
        setTableLoader(true);
        try {
            await updateExchangeRates.mutateAsync();
        } catch (error) {
            console.log(error);
        }
        setTableLoader(false);
        setUpdateExchangeRatesDialogOpen(false);
    };

    return (
        <AcViewWrapper
            header={
                <>
                    <PageTopBar
                        withTabsDesign={true}
                        disable={false}
                        headline="Pricing"
                        buttons={[
                            {
                                text: 'Create New Pricing',
                                action: () => navigate('./form'),
                                disabled:
                                    !permissionsUtil.canUserEdit() ||
                                    getPricings.isLoading,
                                hidden:
                                    permissionsUtil.isNewVersion() &&
                                    !permissionsUtil.isActionEnabled(
                                        null,
                                        EPermissionGroup.PRICING,
                                        null,
                                        EPermissionAction.MANAGE
                                    )
                            }
                        ]}
                    />
                    <Box pl={'3rem'} pr={'3rem'}>
                        <AcTabs value={tab} onChange={handleTabChange}>
                            <Tab
                                label="Pricing Points"
                                value={EPricingFormState.PRICING_POINTS}
                            />
                            {handlePricingSettingsTabShown() && (
                                <Tab
                                    label="Settings"
                                    value={EPricingFormState.SETTINGS}
                                />
                            )}
                        </AcTabs>
                    </Box>
                </>
            }
        >
            <AcContentWrapper>
                <TabContext value={tab}>
                    <TabPanel
                        value={EPricingFormState.PRICING_POINTS}
                        sx={{ padding: 0 }}
                    >
                        <div className="import-export-wrapper">
                            <ActionButton
                                variant="outlined"
                                text="Import"
                                onClick={onImportCSV}
                            />
                            <ActionButton
                                variant="outlined"
                                text="Export"
                                onClick={handleTemplateDownload}
                            />
                        </div>
                        <DataTable
                            hideFooter={false}
                            columns={[
                                {
                                    field: 'usd',
                                    headerName: 'USD Price Point',
                                    width: 200,
                                    renderCell: (params: any) => {
                                        return `$${params.value}`;
                                    }
                                },
                                {
                                    field: 'lastEdited',
                                    headerName: 'Last Edited',
                                    flex: 1,
                                    disableColumnMenu: true,
                                    renderCell: (params: any) => {
                                        return (
                                            <span>
                                                {dayjs(params.row.lastEdited)
                                                    .utc()
                                                    .format(DATE_TIME_FORMAT)}
                                            </span>
                                        );
                                    },
                                    valueGetter: (params: any) => {
                                        return params.row.lastEdited;
                                    }
                                },
                                {
                                    field: 'actions',
                                    renderHeader: () => {
                                        return (
                                            <TableHeaderButton
                                                cb={() =>
                                                    setUpdateExchangeRatesDialogOpen(
                                                        true
                                                    )
                                                }
                                                text="Update exchange rates"
                                                icon={<RefreshOutlined />}
                                            />
                                        );
                                    },
                                    headerClassName: 'actions-header',
                                    flex: 0,
                                    align: 'right',
                                    type: 'actions',
                                    width: 200,
                                    disableReorder: true,
                                    getActions: (params: any) => [
                                        <GridActionsCellItem
                                            icon={<EditTwoToneIcon />}
                                            label="Edit"
                                            onClick={() => {
                                                navigate(`./form/${params.id}`);
                                            }}
                                            disabled={
                                                !permissionsUtil.canUserEdit() ||
                                                (permissionsUtil.isNewVersion() &&
                                                    !permissionsUtil.isActionEnabled(
                                                        null,
                                                        EPermissionGroup.PRICING,
                                                        null,
                                                        EPermissionAction.MANAGE
                                                    ))
                                            }
                                            showInMenu
                                        />,
                                        <GridActionsCellItem
                                            className="danger"
                                            icon={
                                                <DeleteOutlineTwoToneIcon className="danger" />
                                            }
                                            label="Delete"
                                            onClick={() => {
                                                setDeleteDialogOpen(true);
                                                setSelectedPricing(params.id);
                                            }}
                                            disabled={
                                                !permissionsUtil.canUserEdit() ||
                                                (permissionsUtil.isNewVersion() &&
                                                    !permissionsUtil.isActionEnabled(
                                                        null,
                                                        EPermissionGroup.PRICING,
                                                        null,
                                                        EPermissionAction.MANAGE
                                                    ))
                                            }
                                            showInMenu
                                        />
                                    ]
                                }
                            ]}
                            rows={
                                !getPricings.isLoading &&
                                getPricings.data?.result.map(
                                    (pricing: any) => ({
                                        _id: pricing._id,
                                        usd: pricing.priceInCents / 100,
                                        lastEdited: pricing.updatedAt
                                    })
                                )
                            }
                            loading={getPricings.isLoading || tableLoader}
                            onNoData={
                                <FirstActionModal
                                    headline="No pricing points yet"
                                    text="Currently there is no pricing poits in your store"
                                />
                            }
                        />
                        <DialogModal
                            isOpen={isDeleteDialogOpen}
                            headline="Delete Pricing"
                            text="Are you sure you want to delete this pricing?"
                            buttons={[
                                {
                                    text: 'Cancel',
                                    color: EButtonColor.SECONDARY,
                                    variant: 'outlined',
                                    func: () => {
                                        setDeleteDialogOpen(false);
                                    }
                                },
                                {
                                    text: 'Delete',
                                    color: EButtonColor.ERROR,
                                    variant: 'contained',
                                    func: onDeletePricing
                                }
                            ]}
                            closeDialog={() => setDeleteDialogOpen(false)}
                        />
                    </TabPanel>

                    <TabPanel
                        value={EPricingFormState.SETTINGS}
                        sx={{ padding: 0 }}
                    >
                        <Grid
                            item
                            container
                            rowSpacing={2}
                            columnSpacing={1.5}
                            xs={6}
                            direction="column"
                            display="flex"
                        >
                            <Grid item>
                                <Stack
                                    justifyContent="space-between"
                                    alignContent="center"
                                    direction="row"
                                >
                                    <h3
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        Add Tax
                                    </h3>
                                    <CustomizedSwitch
                                        tooltip={''}
                                        status={settings.taxEnabled}
                                        texts={[]}
                                        functions={[
                                            () =>
                                                handleSettingChange(
                                                    ESetting.TAX_ON
                                                ),
                                            () =>
                                                handleSettingChange(
                                                    ESetting.TAX_ON
                                                )
                                        ]}
                                    />
                                </Stack>
                                <Divider />
                                <Typography
                                    mt={1.5}
                                    fontFamily="'Montserrat', sans-serif"
                                    fontSize={'12px'}
                                    color={'#717188'}
                                >
                                    Tax will be automatically added to local
                                    prices of countries where the tax is
                                    included
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack
                                    justifyContent="space-between"
                                    alignContent="center"
                                    direction="row"
                                >
                                    <h3
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        Rounding Rules
                                    </h3>
                                    <CustomizedSwitch
                                        tooltip={''}
                                        status={settings.roundingRulesEnabled}
                                        texts={[]}
                                        functions={[
                                            () =>
                                                handleSettingChange(
                                                    ESetting.ROUNDING_ON
                                                ),
                                            () =>
                                                handleSettingChange(
                                                    ESetting.ROUNDING_ON
                                                )
                                        ]}
                                    />
                                </Stack>
                                <Divider />
                                <Typography
                                    mt={1.5}
                                    fontFamily="'Montserrat', sans-serif"
                                    fontSize={'12px'}
                                    color={'#717188'}
                                >
                                    Rounding rules will be automatically applied
                                    on all local prices
                                </Typography>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
                <DialogModal
                    isOpen={isUploadCSVOpen}
                    withDivider={false}
                    closeButton={true}
                    headline="Import CSV"
                    width={'392px'}
                    text={''}
                    content={
                        <div style={{ fontFamily: `'Montserrat', sans-serif` }}>
                            <div>
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    ref={fileRef}
                                    accept=".csv"
                                    onChange={handleFileChange}
                                />
                                <Stack
                                    height="144px"
                                    bgcolor="rgba(202, 203, 212, 0.2)"
                                    border="1px solid rgba(202, 203, 212, 1)"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius={1}
                                    gap={1}
                                    onClick={() => {
                                        fileRef?.current?.click();
                                    }}
                                    sx={{
                                        cursor: isUploading
                                            ? 'unset'
                                            : 'pointer'
                                    }}
                                >
                                    {isUploading ? (
                                        <CircularProgress />
                                    ) : fileRef?.current?.files &&
                                      fileRef?.current?.files?.length > 0 ? (
                                        <>
                                            <div
                                                style={{
                                                    width: '64px',
                                                    height: '64px',
                                                    background: '#717188',
                                                    position: 'relative'
                                                }}
                                            >
                                                <CloseOutlined
                                                    className="close-icon"
                                                    style={{
                                                        width: '16px',
                                                        height: '16px'
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        if (fileRef.current) {
                                                            fileRef.current.value =
                                                                '';
                                                        }
                                                        setFieldValue(
                                                            'file',
                                                            null
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="file-name">
                                                {fileRef.current.files[0].name}
                                            </div>
                                            <div className="file-size">
                                                {calculateFileSizeDisplay(
                                                    fileRef.current.files[0]
                                                        .size
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <ViewListOutlined
                                                sx={{
                                                    fontSize: '64px',
                                                    color: 'rgba(168, 169, 178, 1)'
                                                }}
                                            />
                                            <Typography
                                                fontFamily="'Montserrat', sans-serif"
                                                variant="body1"
                                                sx={{
                                                    color: 'rgba(97, 9, 255, 1)',
                                                    textDecoration: 'underline'
                                                }}
                                                color="textSecondary"
                                            >
                                                Upload your file here
                                            </Typography>
                                        </>
                                    )}
                                </Stack>
                            </div>
                        </div>
                    }
                    buttons={[
                        {
                            text: 'Import',
                            color: EButtonColor.PRIMARY,
                            variant: 'contained',
                            func: () => submitForm(),
                            disabled:
                                !fileRef?.current?.files ||
                                fileRef?.current?.files?.length === 0,
                            fullWidth: true
                        }
                    ]}
                    closeDialog={() => {
                        resetForm();
                        setUploadCSVOpen(false);
                    }}
                />
                <DialogModal
                    isOpen={isMessagesDialogOpen}
                    closeButton={true}
                    headline="Please note"
                    width={'392px'}
                    content={pricingPointsErrorsContent}
                    text={'Following currencies cannot be created:'}
                    buttons={[]}
                    closeDialog={() => {
                        setMessagesDialogOpen(false);
                        setShowAllErrors(false);
                    }}
                />
                <DialogModal
                    isOpen={updateExchangeRatesDialogOpen}
                    closeButton={false}
                    headline="Update Exchange Rates"
                    width={'392px'}
                    text={
                        'All existing price points will be updated according to the latest exchange rate. Players will automatically see the new prices at the store'
                    }
                    buttons={[
                        {
                            text: 'Cancel',
                            color: EButtonColor.SECONDARY,
                            variant: 'outlined',
                            func: () => setUpdateExchangeRatesDialogOpen(false),
                            disabled: false,
                            fullWidth: false
                        },
                        {
                            text: 'Update',
                            color: EButtonColor.PRIMARY,
                            variant: 'contained',
                            func: () => updateExchangeRatesHandler(),
                            disabled: false,
                            fullWidth: false
                        }
                    ]}
                />
            </AcContentWrapper>
            <DialogModal
                isOpen={isDialogOpen}
                headline={'Changes Approval'}
                text={
                    'Enabling this toggle will affect all existing price points. Do you approve?'
                }
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            setDialogOpen(false);
                        }
                    },
                    {
                        text: 'Approve',
                        color: EButtonColor.PRIMARY,
                        variant: 'contained',
                        func: submitSettingChange
                    }
                ]}
                closeDialog={() => setDialogOpen(false)}
            />
        </AcViewWrapper>
    );
};

export default PricingTable;
