import { AcTooltipProps } from './AcTooltip.types';

const AcTooltip = ({ content }: AcTooltipProps) => {
    return (
        <span
            style={{
                color: 'white',
                maxWidth: '150px',
                display: 'flex',
                textAlign: 'center'
            }}
        >
            {content}
        </span>
    );
};

export default AcTooltip;
