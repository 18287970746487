import { TokenResponse, useGoogleLogin } from '@react-oauth/google';

import ActionButton from '../../ActionButton/ActionButton';
import { ELoginAuthMethod, LoginData } from '../../LoginModal/LoginModal.types';
import { GoogleLoginProps } from '../LoginForm.types';

export const GoogleLogin = ({ login, onLoginFailed }: GoogleLoginProps) => {
    const googleLogin = useGoogleLogin({
        onSuccess: (response: TokenResponse) => {
            const loginData: LoginData = {
                loginToken: response.access_token
            };

            login(
                loginData,
                (success: boolean) => {
                    if (success) return;
                    onLoginFailed();
                },
                ELoginAuthMethod.GOOGLE
            );
        },
        onNonOAuthError: () => {
            onLoginFailed();
        }
    });
    const onGoogleLoginClicked = () => {
        googleLogin();
    };

    return (
        <ActionButton
            buttonClasses={'google-login-button'}
            onClick={onGoogleLoginClicked}
            variant="contained"
            style={{
                textTransform: 'capitalize',
                backgroundColor: 'white',
                border: '2px solid var(--google-grey)',
                color: 'black'
            }}
            text={'Sign In With Google'}
            icon={
                <img
                    src="https://media.appcharge.com/defaults/google_icon.svg"
                    alt="google icon"
                    style={{ marginRight: '1em' }}
                />
            }
        />
    );
};
