import { useSelector } from 'react-redux';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Grid } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { AuthSliceState } from 'store/store.types';

import DataTable from '../../../components/DataTable/DataTable';
import { permissionsUtil } from '../../../utils/permissionsUtil';

import '../style.scss';

const ProductTable = ({ products, removeProduct, editProducts }: any) => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    return (
        <Grid item xs={12} mt={-3}>
            <DataTable
                columns={[
                    {
                        field: 'image',
                        headerName: "",
                        flex: 0.5,
                        renderCell: (params) => (
                            <img
                                style={{ maxHeight: '50%', objectFit: 'contain' }}
                                src={params.row.image}
                                alt="productImage"
                            />
                        )
                    },
                    { field: 'name', headerName: "Name", flex: 1.5 },
                    { field: 'amount', headerName: "Amount", flex: 1 },
                    { field: 'type', headerName: "Type", flex: 1 },
                    {
                        field: 'actions',
                        flex: 0,
                        type: 'actions',
                        width: 80,
                        disableReorder: true,
                        getActions: (params: any) => [
                            <GridActionsCellItem
                                icon={<EditTwoToneIcon />}
                                label="Edit"
                                onClick={() => {
                                    editProducts(params.row._id)
                                }}
                                disabled={
                                    !permissionsUtil.canUserEdit() ||
                                    (permissionsUtil.isNewVersion() &&
                                        !permissionsUtil.isActionEnabled(
                                            null,
                                            EPermissionGroup.OFFERS,
                                            currentPublisherId,
                                            EPermissionAction.MANAGE
                                        ))
                                }
                                showInMenu
                            />,
                            <GridActionsCellItem
                                className="danger"
                                icon={<DeleteOutlineTwoToneIcon className="danger" />}
                                label="Delete"
                                onClick={() => {
                                    removeProduct(params.row._id)
                                }}
                                disabled={
                                    !permissionsUtil.canUserEdit() ||
                                    (permissionsUtil.isNewVersion() &&
                                        !permissionsUtil.isActionEnabled(
                                            null,
                                            EPermissionGroup.OFFERS,
                                            currentPublisherId,
                                            EPermissionAction.MANAGE
                                        ))
                                }
                                showInMenu
                            />
                        ]
                    }
                ]}
                rows={products}
                loading={false}
                headerHeight={products.length > 0 ? 56 : 0}
            />
        </Grid>)
}

export default ProductTable;
