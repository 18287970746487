import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';

import { EButtonColor, ELocalStorageKeys } from '../../constants/enums';
import { permissionsUtil } from '../../utils/permissionsUtil';
import AcContentWrapper from '../AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../AcViewWrapper/AcViewWrapper';
import DataTable from '../DataTable/DataTable';
import DialogModal from '../Dialog/Dialog';
import FirstActionModal from '../FirstActionModal/FirstActionModal';
import PageTopBar from '../Topbar/PageTopBar';

import { ProductsListProps } from './ProductsList.types';

const ProductsList = ({
    isLoading,
    isError,
    data,
    deleteProduct
}: ProductsListProps) => {
    const navigate = useNavigate();
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dialogProductId, setDialogProductId] = useState<string>('');

    const deleteProductHandler = () => {
        deleteProduct(dialogProductId);
    };

    const openNewProductForm = () => {
        navigate('./form');
    };

    const openDeleteDialog = (productId: string) => {
        setDialogProductId(productId);
        setDeleteDialogOpen(true);
    };

    const columns = [
        {
            field: 'images',
            flex: 1,
            headerName: 'Image',
            minWidth: 150,
            renderCell: (params: any) => {
                return (
                    <img
                        style={{ height: '80%' }}
                        alt={params.row.name}
                        src={
                            params.row.images.filter(
                                (image: any) => image.type === 'product'
                            )[0].url
                        }
                    />
                );
            }
        },
        { field: 'name', headerName: 'Product Name', minWidth: 150, flex: 1 },
        {
            field: 'publisherProductId',
            minWidth: 150,
            headerName: 'SKU',
            flex: 1
        },
        {
            field: 'textFontColorHex',
            headerName: 'Font Color',
            flex: 1,
            renderCell: (params: any) => (
                <Stack direction="row" alignItems="center" gap={1}>
                    <Box
                        sx={{
                            width: 16,
                            height: 16,
                            backgroundColor: `${params.row.textFontColorHex}`,
                            border: '1px solid #a4a4a4'
                        }}
                    ></Box>
                    <span>{params.row.textFontColorHex.toUpperCase()}</span>
                </Stack>
            )
        },
        {
            field: 'actions',
            flex: 0,
            type: 'actions',
            width: 80,
            disableReorder: true,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<EditTwoToneIcon />}
                    label="Edit"
                    onClick={() => navigate(`./form/${params.row.productId}`)}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.PRODUCTS,
                                null,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<ContentCopyOutlinedIcon />}
                    label="Duplicate"
                    onClick={() => navigate(`./dup/${params.row.productId}`)}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.PRODUCTS,
                                null,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    className="danger"
                    icon={<DeleteOutlineTwoToneIcon className="danger" />}
                    label="Delete"
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.PRODUCTS,
                                null,
                                EPermissionAction.MANAGE
                            ))
                    }
                    onClick={() => openDeleteDialog(params.row.productId)}
                    showInMenu
                />
            ]
        }
    ];

    return (
        <AcViewWrapper
            header={
                <PageTopBar
                    headline="Products"
                    buttons={[
                        {
                            text: 'Add New Product',
                            action: openNewProductForm,
                            disabled: !permissionsUtil.canUserEdit(),
                            hidden:
                                permissionsUtil.isNewVersion() &&
                                !permissionsUtil.isActionEnabled(
                                    null,
                                    EPermissionGroup.PRODUCTS,
                                    null,
                                    EPermissionAction.MANAGE
                                )
                        }
                    ]}
                />
            }
        >
            <AcContentWrapper>
                <div className="list">
                    {data && data.length > 0 && (
                        <DataTable
                            rowIdIdentifier="productId"
                            columns={columns}
                            rows={data || []}
                            loading={isLoading}
                            localStorageColumnsKey={
                                ELocalStorageKeys.PRODUCTS_COLUMN_VISIBILITY
                            }
                            defaultHiddenFields={['_id']}
                            onNoData={
                                <FirstActionModal
                                    headline="Add products so you can create offers"
                                    text="Hit the “Add new product” button on the top right corner of the screen and let’s get going!"
                                />
                            }
                            error={isError}
                        />
                    )}
                </div>
                <DialogModal
                    isOpen={isDeleteDialogOpen}
                    headline="Delete Product"
                    text="Are you sure you want to delete this product?"
                    buttons={[
                        {
                            text: 'Cancel',
                            color: EButtonColor.SECONDARY,
                            variant: 'outlined',
                            func: () => {
                                setDeleteDialogOpen(false);
                            }
                        },
                        {
                            text: 'Delete',
                            color: EButtonColor.ERROR,
                            variant: 'contained',
                            func: () => {
                                deleteProductHandler();
                                setDeleteDialogOpen(false);
                            }
                        }
                    ]}
                    closeDialog={() => setDeleteDialogOpen(false)}
                />
            </AcContentWrapper>
        </AcViewWrapper>
    );
};

export default ProductsList;
