import { useDispatch, useSelector } from 'react-redux';

import { Divider, Grid, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { AuthSliceState } from 'store/store.types';
import * as yup from 'yup';

import useImages from '../../../api/useImages';
import usePublisher from '../../../api/usePublisher';
import useTheme from '../../../api/useTheme';
import {
    BannerAspectRatio,
    EBalancesTheme,
    EBundlesInternalViewModel,
    EBundlesViewModel,
    ELogoSize,
    ENotificationType,
    ESectionViewModel,
    ESpecialOfferInternalViewModel
} from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import { uiActions } from '../../../store/uiSlice';
import { required } from '../../../utils/errorsTextHelper';
import AcCard from '../../AcCard/AcCard';
import AcSelect from '../../AcSelect/AcSelect';
import ActionButton from '../../ActionButton/ActionButton';
import { defaultTheme } from '../../Theme/Theme';

import '../../../style/forms.scss';
import '../../Theme/style.scss';

const getBundleTypesBasedOnLayout = (layout: EBundlesViewModel) => {
    switch (layout) {
        case EBundlesViewModel.LINEAR:
            return [
                {
                    content: 'Multiple Products',
                    key: EBundlesInternalViewModel.AK,
                    value: EBundlesInternalViewModel.AK
                },
                {
                    content: 'Main Sub',
                    key: EBundlesInternalViewModel.MAIN_SUB,
                    value: EBundlesInternalViewModel.MAIN_SUB
                },
                {
                    content: 'Main Sub - Alternative',
                    key: EBundlesInternalViewModel.MAIN_SUB_ALT,
                    value: EBundlesInternalViewModel.MAIN_SUB_ALT
                },
                {
                    content: 'PM - Jackpot Slots',
                    key: EBundlesInternalViewModel.JACKPOT_SLOTS,
                    value: EBundlesInternalViewModel.JACKPOT_SLOTS
                },
                {
                    content: 'PM - Big Fish Casino',
                    key: EBundlesInternalViewModel.BIG_FISH_CASINO,
                    value: EBundlesInternalViewModel.BIG_FISH_CASINO
                },
                {
                    content: 'Me2on',
                    key: EBundlesInternalViewModel.ME2ON,
                    value: EBundlesInternalViewModel.ME2ON
                },
                {
                    content: 'Playsimple',
                    key: EBundlesInternalViewModel.PLAY_SIMPLE,
                    value: EBundlesInternalViewModel.PLAY_SIMPLE
                }
            ];
        case EBundlesViewModel.TWO_IN_ONE:
            return [
                {
                    content: 'Loco Bingo',
                    key: EBundlesInternalViewModel.LOCO_BINGO,
                    value: EBundlesInternalViewModel.LOCO_BINGO
                },
                {
                    content: 'PM Cashman Casino',
                    key: EBundlesInternalViewModel.CASHMAN_CASINO,
                    value: EBundlesInternalViewModel.CASHMAN_CASINO
                }
            ];
        case EBundlesViewModel.THREE_IN_ONE:
            return [
                {
                    content: 'Communix',
                    key: EBundlesInternalViewModel.COMMUNIX,
                    value: EBundlesInternalViewModel.COMMUNIX
                },
                {
                    content: 'Candivore',
                    key: EBundlesInternalViewModel.MATCH,
                    value: EBundlesInternalViewModel.MATCH
                },
                {
                    content: 'Bagelcode',
                    key: EBundlesInternalViewModel.BAGELCODE,
                    value: EBundlesInternalViewModel.BAGELCODE
                }
            ];
    }
};

const StoreLayout = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const pictures = useImages(currentPublisherId).getImages;
    const themeActions = useTheme(currentPublisherId);
    const { data } = themeActions.getStoreTheme;
    const publisherActions = usePublisher(currentPublisherId || undefined);
    const publisher = publisherActions.getPublisher;
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useNotifications();
    let themeSchema = yup.object().shape({
        login: yup.object().shape({
            text: yup.string().required(required('Header')),
            textColor: yup.string().required(required('Font color')),
            textSize: yup.string().required(required('Font size')),
            textWeight: yup.string().required(required('Font weight'))
        }),
        general: yup.object().shape({
            logo: yup.string().required(required('Logo')),
            logoSize: yup.string().required(required('Logo size')),
            font: yup.string().required(required('Font')),
            tabName: yup.string().required(required('Store Tab Title')),
            favicon: yup.string().required(required('Favicon')),
            backgroundImageMobile: yup
                .string()
                .required(required('Background image')),
            backgroundImageDesktop: yup
                .string()
                .required(required('Background image')),
            buttonColor: yup.object().shape({
                colorOne: yup.string().required(required('Button main color')),
                colorTwo: yup.string()
            })
        }),
        loaderScreen: yup.object().shape({
            headerText: yup.string().required(required('Header')),
            headerColor: yup.object().shape({
                colorOne: yup.string().required(required('Button main color')),
                colorTwo: yup.string()
            }),
            headerSize: yup.string().required(required('Header font size')),
            headerWeight: yup.string().required(required('Header font weight')),
            text: yup.string(),
            textColor: yup.string(),
            textSize: yup.string(),
            textWeight: yup.string()
        }),
        completedScreen: yup.object().shape({
            headerText: yup.string().required(required('Header')),
            headerColor: yup.object().shape({
                colorOne: yup.string().required(required('Button main color')),
                colorTwo: yup.string()
            }),
            headerSize: yup.string().required(required('Header font size')),
            headerWeight: yup.string().required(required('Header font weight')),
            text: yup.string(),
            textColor: yup.string(),
            textSize: yup.string(),
            textWeight: yup.string(),
            backToStoreText: yup
                .string()
                .required(required('Back to store text')),
            backToGameButtonText: yup
                .string()
                .required(required('Back to game text'))
        }),
        storeScreen: yup.object().shape({
            bundleBorderColor: yup.object().shape({
                colorOne: yup
                    .string()
                    .required(required('Bundle frame main color')),
                colorTwo: yup.string()
            })
        }),
        integration: yup.object().shape({
            deepLinks: yup.object().shape({
                ios: yup
                    .string()
                    .matches(
                        /.+:\/\/.*/,
                        'Apple deep link must be a valid url'
                    ),
                android: yup
                    .string()
                    .matches(
                        /.+:\/\/.*/,
                        'Android deep link must be a valid url'
                    ),
                web: yup
                    .string()
                    .matches(/.+:\/\/.*/, 'Web deep link must be a valid url')
            })
        })
    });
    const {
        values,
        errors,
        touched,
        dirty,
        isValid,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        submitForm,
        setSubmitting
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: {
                logo: data?.general?.logo || defaultTheme.general.logo,
                logoSize: data?.general?.logoSize || ELogoSize.SMALL,
                font: data?.general?.font || defaultTheme.general.font,
                tabName:
                    publisher.data?.storeTabName ||
                    defaultTheme.general.tabName,
                favicon: data?.general?.favicon || defaultTheme.general.favicon,
                buttonColor: {
                    colorOne:
                        data?.general?.buttonColor.colorOne ||
                        defaultTheme.general.buttonColor.colorOne,
                    colorTwo:
                        data?.general?.buttonColor.colorTwo ||
                        defaultTheme.general.buttonColor.colorTwo,
                    gradientDirection:
                        data?.general?.buttonColor.gradientDirection ||
                        defaultTheme.general.buttonColor.gradientDirection
                },
                backgroundImageMobile:
                    data?.general?.backgroundImageMobile ||
                    defaultTheme.general.backgroundImageMobile,
                backgroundImageDesktop:
                    data?.general?.backgroundImageDesktop ||
                    defaultTheme.general.backgroundImageDesktop,
                buttonTextColor:
                    data?.general?.buttonTextColor ||
                    defaultTheme.general.buttonTextColor,
                bundlesViewModel:
                    data?.general?.bundlesViewModel ||
                    defaultTheme.general.bundlesViewModel,
                bundlesInternalViewModel:
                    data?.general?.bundlesInternalViewModel ||
                    defaultTheme.general.bundlesInternalViewModel,
                specialOffersViewModel:
                    data?.general?.specialOffersViewModel ||
                    defaultTheme.general.specialOffersViewModel,
                specialOffersInternalViewModel:
                    data?.general?.specialOffersInternalViewModel ||
                    defaultTheme.general.specialOffersInternalViewModel,
                balancesTheme:
                    data?.general?.balancesTheme ||
                    defaultTheme.general.balancesTheme,
                sectionViewModel:
                    data?.general?.sectionViewModel ||
                    defaultTheme.general.sectionViewModel,
                popupRatio:
                    data?.general?.popupRatio || defaultTheme.general.popupRatio
            },
            login: {
                text: data?.login?.text || defaultTheme.login.text,
                font: data?.login?.font || defaultTheme.login.font,
                textSize: data?.login?.textSize || defaultTheme.login.textSize,
                textColor:
                    data?.login?.textColor || defaultTheme.login.textColor,
                textWeight:
                    data?.login?.textWeight || defaultTheme.login.textWeight
            },
            loaderScreen: {
                headerText:
                    data?.loaderScreen?.headerText ||
                    defaultTheme.loaderScreen.headerText,
                headerColor: {
                    colorOne:
                        data?.loaderScreen?.headerColor.colorOne ||
                        defaultTheme.loaderScreen.headerColor.colorOne,
                    colorTwo:
                        data?.loaderScreen?.headerColor.colorTwo ||
                        defaultTheme.loaderScreen.headerColor.colorTwo,
                    gradientDirection:
                        data?.loaderScreen?.headerColor.gradientDirection ||
                        defaultTheme.loaderScreen.headerColor.gradientDirection
                },
                headerSize:
                    data?.loaderScreen?.headerSize ||
                    defaultTheme.loaderScreen.headerSize,
                headerWeight:
                    data?.loaderScreen?.headerWeight ||
                    defaultTheme.loaderScreen.headerWeight,
                text:
                    data?.loaderScreen?.text || defaultTheme.loaderScreen.text,
                textColor:
                    data?.loaderScreen?.textColor ||
                    defaultTheme.loaderScreen.textColor,
                textSize:
                    data?.loaderScreen?.textSize ||
                    defaultTheme.loaderScreen.textSize,
                textWeight:
                    data?.loaderScreen?.textWeight ||
                    defaultTheme.loaderScreen.textWeight
            },
            completedScreen: {
                headerText:
                    data?.completedScreen?.headerText ||
                    defaultTheme.completedScreen.headerText,
                headerColor: {
                    colorOne:
                        data?.completedScreen?.headerColor.colorOne ||
                        defaultTheme.completedScreen.headerColor.colorOne,
                    colorTwo:
                        data?.completedScreen?.headerColor.colorTwo ||
                        defaultTheme.completedScreen.headerColor.colorTwo,
                    gradientDirection:
                        data?.completedScreen?.headerColor.gradientDirection ||
                        defaultTheme.completedScreen.headerColor
                            .gradientDirection
                },
                headerSize:
                    data?.completedScreen?.headerSize ||
                    defaultTheme.completedScreen.headerSize,
                headerWeight:
                    data?.completedScreen?.headerWeight ||
                    defaultTheme.completedScreen.headerWeight,
                text:
                    data?.completedScreen?.text ||
                    defaultTheme.completedScreen.text,
                textColor:
                    data?.completedScreen?.textColor ||
                    defaultTheme.completedScreen.textColor,
                textSize:
                    data?.completedScreen?.textSize ||
                    defaultTheme.completedScreen.textSize,
                textWeight:
                    data?.completedScreen?.textWeight ||
                    defaultTheme.completedScreen.textWeight,
                backToStoreText:
                    data?.completedScreen?.backToStoreText ||
                    defaultTheme.completedScreen.backToStoreText,
                backToGameButtonText:
                    data?.completedScreen?.backToGameButtonText ||
                    defaultTheme.completedScreen.backToGameButtonText
            },
            storeScreen: {
                bundleBorderColor: {
                    colorOne:
                        data?.storeScreen?.bundleBorderColor.colorOne ||
                        defaultTheme.storeScreen?.bundleBorderColor.colorOne,
                    colorTwo:
                        data?.storeScreen?.bundleBorderColor.colorTwo ||
                        defaultTheme.storeScreen?.bundleBorderColor.colorTwo,
                    gradientDirection:
                        data?.storeScreen?.bundleBorderColor
                            .gradientDirection ||
                        defaultTheme.storeScreen?.bundleBorderColor
                            .gradientDirection
                },
                noOffersTitleText:
                    data?.storeScreen?.noOffersTitleText ||
                    defaultTheme.storeScreen,
                noOffersMessageText: data?.storeScreen?.noOffersMessageText
            }
        },
        validationSchema: themeSchema,
        onSubmit: async (values) => {
            try {
                const publisherValues = {
                    storeTabName: values.general.tabName
                };
                await themeActions.updateStoreTheme.mutateAsync(values);
                await publisherActions.updatePublisher.mutateAsync(
                    publisherValues
                );
                enqueueSnackbar('Theme updated', ENotificationType.SUCCESS);
                dispatch(uiActions.setUnsavedChanges(false));
                setSubmitting(false);
            } catch (error) {
                enqueueSnackbar(
                    'Error updating theme',
                    ENotificationType.ERROR,
                    (
                        (error as AxiosError).response?.data as {
                            message: string;
                        }
                    ).message || 'Unknown error occurred'
                );
            }
        }
    });

    return (
        <Grid container className="formContent">
            <Grid item xs={5}>
                {!pictures.isLoading && (
                    <Stack>
                        <AcCard stackContainer={false} title="Gallery">
                            <Grid container rowSpacing={2} columnSpacing={1.5}>
                                <Grid item xs={12}>
                                    <AcSelect
                                        header="Special Offers Internal"
                                        name="general.specialOffersInternalViewModel"
                                        value={
                                            values.general
                                                .specialOffersInternalViewModel
                                        }
                                        items={[
                                            {
                                                content: 'Huge',
                                                key: ESpecialOfferInternalViewModel.HUGE,
                                                value: ESpecialOfferInternalViewModel.HUGE
                                            },
                                            {
                                                content: 'Match Masters',
                                                key: ESpecialOfferInternalViewModel.MATCH_MASTERS,
                                                value: ESpecialOfferInternalViewModel.MATCH_MASTERS
                                            },
                                            {
                                                content: 'Poker Face',
                                                key: ESpecialOfferInternalViewModel.POKER_FACE,
                                                value: ESpecialOfferInternalViewModel.POKER_FACE
                                            },
                                            {
                                                content: 'Bagelcode',
                                                key: ESpecialOfferInternalViewModel.BAGELCODE,
                                                value: ESpecialOfferInternalViewModel.BAGELCODE
                                            },
                                            {
                                                content: 'Loco Bingo',
                                                key: ESpecialOfferInternalViewModel.LOCO_BINGO,
                                                value: ESpecialOfferInternalViewModel.LOCO_BINGO
                                            },
                                            {
                                                content: 'Me2on',
                                                key: ESpecialOfferInternalViewModel.ME2ON,
                                                value: ESpecialOfferInternalViewModel.ME2ON
                                            }
                                        ]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            touched.general
                                                ?.specialOffersInternalViewModel &&
                                            Boolean(
                                                errors.general
                                                    ?.specialOffersInternalViewModel
                                            )
                                        }
                                        tooltip="This will be the layout of your bundles."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AcSelect
                                        header="Special Offers"
                                        name="general.specialOffersViewModel"
                                        value={
                                            values.general
                                                .specialOffersViewModel
                                        }
                                        items={[
                                            {
                                                content:
                                                    values.general
                                                        .specialOffersViewModel,
                                                key: values.general
                                                    .specialOffersViewModel,
                                                value: values.general
                                                    .specialOffersViewModel
                                            }
                                        ]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            touched.general
                                                ?.specialOffersViewModel &&
                                            Boolean(
                                                errors.general
                                                    ?.specialOffersViewModel
                                            )
                                        }
                                        tooltip="This will be the layout of your bundles."
                                    />
                                </Grid>
                            </Grid>
                        </AcCard>
                        <Divider />
                        <AcCard stackContainer={false} title="Bundles">
                            <Grid container rowSpacing={2} columnSpacing={1.5}>
                                <Grid item xs={12}>
                                    <AcSelect
                                        header="Store Template"
                                        name="general.bundlesViewModel"
                                        value={values.general.bundlesViewModel}
                                        items={[
                                            {
                                                content: '1 in a row',
                                                key: EBundlesViewModel.LINEAR,
                                                value: EBundlesViewModel.LINEAR
                                            },
                                            {
                                                content: '2 in a row',
                                                key: EBundlesViewModel.TWO_IN_ONE,
                                                value: EBundlesViewModel.TWO_IN_ONE
                                            },
                                            {
                                                content: '3 in a row',
                                                key: EBundlesViewModel.THREE_IN_ONE,
                                                value: EBundlesViewModel.THREE_IN_ONE
                                            }
                                        ]}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'general.bundlesInternalViewModel',
                                                getBundleTypesBasedOnLayout(
                                                    e.target
                                                        .value as EBundlesViewModel
                                                )[0].key
                                            );
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                            touched.general?.bundlesViewModel &&
                                            Boolean(
                                                errors.general?.bundlesViewModel
                                            )
                                        }
                                        tooltip="This will be the layout of your store."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AcSelect
                                        header="Bundle Template"
                                        name="general.bundlesInternalViewModel"
                                        value={
                                            values.general
                                                .bundlesInternalViewModel
                                        }
                                        items={getBundleTypesBasedOnLayout(
                                            values.general.bundlesViewModel
                                        )}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            touched.general
                                                ?.bundlesInternalViewModel &&
                                            Boolean(
                                                errors.general
                                                    ?.bundlesInternalViewModel
                                            )
                                        }
                                        tooltip="This will be the layout of your bundles."
                                    />
                                </Grid>
                            </Grid>
                        </AcCard>
                        <Divider />
                        <AcCard stackContainer={false} title="Popups">
                            <Grid container rowSpacing={2} columnSpacing={1.5}>
                                <Grid item xs={12}>
                                    <AcSelect
                                        header="Popup ratio"
                                        name="general.popupRatio"
                                        value={values.general?.popupRatio}
                                        items={[
                                            {
                                                content: '1:1',
                                                key: BannerAspectRatio.SQUARE,
                                                value: BannerAspectRatio.SQUARE
                                            },
                                            {
                                                content: '2:3',
                                                key: BannerAspectRatio.TWO_ON_THREE,
                                                value: BannerAspectRatio.TWO_ON_THREE
                                            }
                                        ]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        tooltip="This will be the ratio of your popups"
                                    />
                                </Grid>
                            </Grid>
                        </AcCard>
                        <Divider />
                        <AcCard stackContainer={false} title="Sections">
                            <Grid container rowSpacing={2} columnSpacing={1.5}>
                                <Grid item xs={12}>
                                    <AcSelect
                                        header="Section Theme"
                                        name="general.sectionViewModel"
                                        value={values.general.sectionViewModel}
                                        items={[
                                            {
                                                content: 'Default',
                                                key: ESectionViewModel.DEFAULT,
                                                value: ESectionViewModel.DEFAULT
                                            },
                                            {
                                                content: 'Bagelcode',
                                                key: ESectionViewModel.BAGELCODE,
                                                value: ESectionViewModel.BAGELCODE
                                            }
                                        ]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            touched.general?.sectionViewModel &&
                                            Boolean(
                                                errors.general?.sectionViewModel
                                            )
                                        }
                                        tooltip="This will be the layout of your sections."
                                    />
                                </Grid>
                            </Grid>
                        </AcCard>
                        <Divider />
                        <AcCard stackContainer={false} title="Balance">
                            <Grid container rowSpacing={2} columnSpacing={1.5}>
                                <Grid item xs={12}>
                                    <AcSelect
                                        header="Balance Theme"
                                        name="general.balancesTheme"
                                        value={values.general.balancesTheme}
                                        placeholder="Select"
                                        items={[
                                            {
                                                content: 'Default',
                                                key: EBalancesTheme.DEFAULT,
                                                value: EBalancesTheme.DEFAULT
                                            },
                                            {
                                                content: 'BagelCode',
                                                key: EBalancesTheme.BAGELCODE,
                                                value: EBalancesTheme.BAGELCODE
                                            },
                                            {
                                                content: 'SpaceGO',
                                                key: EBalancesTheme.SPACEGO,
                                                value: EBalancesTheme.SPACEGO
                                            },
                                            {
                                                content: 'Playsimple',
                                                key: EBalancesTheme.PLAYSIMPLE,
                                                value: EBalancesTheme.PLAYSIMPLE
                                            },
                                            {
                                                content: 'Me2on',
                                                key: EBalancesTheme.ME2ON,
                                                value: EBalancesTheme.ME2ON
                                            }
                                        ]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            touched.general?.balancesTheme &&
                                            Boolean(
                                                errors.general?.balancesTheme
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </AcCard>
                    </Stack>
                )}
                <Grid item xs={6} mt={3}>
                    <ActionButton
                        variant="outlined"
                        text="Update"
                        onClick={submitForm}
                        disabled={!isValid || !dirty || isSubmitting}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StoreLayout;
