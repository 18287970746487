import { Typography } from '@mui/material';

import { NotificationProps } from './Notifcation.types';

const Notification = ({ text, description }: NotificationProps) => {
    return (
        <div className="notification">
            <Typography>{text}</Typography>
            {description && (
                <div>
                    <small>{description}</small>
                </div>
            )}
        </div>
    );
};

export default Notification;
