import { useMutation, useQuery } from '@tanstack/react-query';

import { IntegrationSettingsData } from 'components/Settings/Settings.types';

import { User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useIntegration(currentPublisherId: string | null) {
    const axios = useAxios();

    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getIntegration = useQuery<IntegrationSettingsData, Error>({
        ...fetchConfig.general,
        queryKey: [EQueryKeys.INTEGRATION],
        queryFn: async () => {
            return await axios.get(getApiRouteOrMock(EApiRoutes.INTEGRATION), {}, currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined);
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const updateIntegration = useMutation(async (data: any) => {
        return await axios.put(getApiRouteOrMock(EApiRoutes.INTEGRATION), data);
    }, {});

    return {
        getIntegration,
        updateIntegration
    };
}
