import React, { useCallback, useMemo, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { DataGridPro, GridCallbackDetails } from '@mui/x-data-grid-pro';

import { GridFeatureMode } from '../../constants/enums';
import { usePaginationFromURL } from '../../hooks/usePaginationFromURL';
import { localStorageUtil } from '../../utils/localStorageUtil';
import ErrorBox from '../ErrorBox/ErrorBox';

import { DataTableProps } from './DataTable.types';

const DataTable = React.memo(
    ({
        columns,
        rows,
        headerText,
        loading,
        onNoData,
        onSelectionModelChange,
        rowIdIdentifier = '_id',
        autoHeight = true,
        defaultHiddenFields = [],
        localStorageColumnsKey,
        checkboxSelection = false,
        hideFooter = true,
        pagination = false,
        error = false,
        headerHeight = 56,
        initialSorting = undefined,
        allowEmptyRows = false,
        rowHeight = 52,
        onPageChange,
        onRowsPerPageChange,
        totalCount,
        currentPage,
        onSortModelChange,
        className,
        sortingMode = GridFeatureMode.CLIENT,
        filterMode = GridFeatureMode.CLIENT,
        onFilterModelChange,
        rowReordering,
        onRowOrderChange,
        onRowMouseEnter
    }: DataTableProps) => {
        const { rows: pageSize } = usePaginationFromURL('');
        const [currentPageSize, setCurrentPageSize] = useState(pageSize);

        const getHiddenColumns = useCallback(() => {
            return defaultHiddenFields.map((hiddenField) => ({
                [hiddenField]: false
            }));
        }, [defaultHiddenFields]);

        const handleMouseEnter = useCallback(
            (event: any) => {
                const rowElement =
                    event.currentTarget.closest('.MuiDataGrid-row');
                const rowId = rowElement?.getAttribute('data-id');
                onRowMouseEnter?.(rowId);
            },
            [onRowMouseEnter]
        );

        const handlePageChange = useCallback(
            (page: number, details: GridCallbackDetails<any>) => {
                onPageChange && onPageChange(page);
            },
            [onPageChange]
        );

        const handleRowsPerPageChange = useCallback(
            (pageSize: number) => {
                setCurrentPageSize(pageSize);
                onRowsPerPageChange && onRowsPerPageChange(pageSize);
            },
            [onRowsPerPageChange]
        );

        const initialState = useMemo(
            () => ({
                columns: {
                    columnVisibilityModel: {
                        ...Object.assign({}, ...getHiddenColumns()),
                        ...(localStorageColumnsKey &&
                            localStorageUtil.get(localStorageColumnsKey))
                    }
                },
                sorting: initialSorting
            }),
            [getHiddenColumns, initialSorting, localStorageColumnsKey]
        );

        return (
            <div className={className}>
                {headerText && <h2>{headerText}</h2>}
                {error ? (
                    <Stack
                        mt={0}
                        width="100%"
                        height="calc(100vh - 150px)"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ErrorBox />
                    </Stack>
                ) : (
                    <>
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    height: '60px',
                                    width: '60px'
                                }}
                            />
                        )}
                        {!loading && (rows.length > 0 || allowEmptyRows) && (
                            <DataGridPro
                                selectionModel={undefined}
                                columns={columns || []}
                                rows={rows || []}
                                page={currentPage}
                                paginationMode="server"
                                sortingMode={sortingMode}
                                rowCount={totalCount || 0}
                                onPageChange={handlePageChange}
                                onPageSizeChange={handleRowsPerPageChange}
                                onSortModelChange={onSortModelChange}
                                pageSize={currentPageSize}
                                autoHeight={autoHeight}
                                disableColumnResize={false}
                                rowHeight={rowHeight}
                                loading={loading}
                                pagination={pagination}
                                checkboxSelection={checkboxSelection}
                                onSelectionModelChange={onSelectionModelChange}
                                onColumnVisibilityModelChange={(data) =>
                                    localStorageColumnsKey &&
                                    localStorageUtil.set(
                                        localStorageColumnsKey,
                                        data
                                    )
                                }
                                initialState={initialState}
                                getRowId={(row) => row[rowIdIdentifier]}
                                hideFooter={hideFooter}
                                filterMode={filterMode}
                                onFilterModelChange={onFilterModelChange}
                                rowReordering={rowReordering}
                                onRowOrderChange={onRowOrderChange}
                                headerHeight={headerHeight}
                                componentsProps={{
                                    row: {
                                        onMouseEnter: handleMouseEnter
                                    }
                                }}
                            />
                        )}
                        {!loading && rows.length === 0 && onNoData}
                    </>
                )}
            </div>
        );
    }
);

export default DataTable;
