import { Dispatch, ReactNode, SetStateAction } from 'react';

import { MenuItemProps, SelectProps } from '@mui/material';

import { EAssetType } from '../../constants/enums';

export interface AcSelectItem extends MenuItemProps {
    content: string | ReactNode | number;
    key?: string | number;
    renderFunction?: (item: AcSelectItem) => ReactNode;
    filterBy?: string;
}

export interface AcSelectImageItem extends AcSelectItem {
    url: string;
    name?: string;
}

export enum EAcSelectItemRenderType {
    TEXT = 'text',
    IMAGE = 'image'
}

export interface AcSelectUploadConfig {
    onUploadSuccess: Function;
    uploadType: EAssetType;
}

export interface AcSelectProps extends SelectProps {
    items: AcSelectItem[] | AcSelectImageItem[];
    header?: string;
    helperText?: string;
    tooltip?: string;
    renderType?: EAcSelectItemRenderType;
    uploadConfig?: AcSelectUploadConfig;
    onClear?: () => void;
    required?: boolean;
    headerSize?: string;
    imgHeight?: string;
    isCheckBox?: boolean;
    loading?: boolean;
    setSelectedItems?: Dispatch<SetStateAction<any[]>>;
    className?: string;
    isRepeatSelect?: any;
    defaultCheckedItems?: string[];
    value: any;
    isSelectAllEnabled? : boolean;
    isApplyBtnEnabled?: boolean;
    isLimited?: boolean;
    isFree?: boolean;
}
