import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LoyaltyOutlined from '@mui/icons-material/LoyaltyOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { EPermissionGroup, IPermissions } from 'common/permissions.types';

import { EPublisherSolutionModel, Project } from '../../common/contracts';
import { NEW_VERSION } from '../../constants/constants';
import { EFeatureFlag, ELocalStorageKeys } from '../../constants/enums';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { permissionsUtil } from '../../utils/permissionsUtil';

enum ESideBarGroup {
    OVERVIEW = 'overview',
    STUDIO = 'studio',
    OFFERS = 'offers',
    REPORTS = 'reports',
    PROJECTS = 'projects',
    MANAGEMENT = 'management'
}

const getProjectsRoute = (project: Project) => {
    const isCheckoutProject =
        project.projectType === EPublisherSolutionModel.CHECKOUT;
    if (isCheckoutProject)
        return `project/${project.publisherId}/statistics/?range=2`;
    const canAccessAnalytics = permissionsUtil.canAccessAnalytics(
        localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) || {},
        project.publisherId
    );
    const canAccessBuilder = permissionsUtil.canAccessBuilder(
        localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS) || {},
        project.publisherId
    );

    return canAccessBuilder
        ? `project/${project.publisherId}/theme/store`
        : canAccessAnalytics
          ? `project/${project.publisherId}/statistics/?range=2`
          : `project/${project.publisherId}/offers`;
};

export const getMainNavItems = (
    projectsDetails: Project[] | undefined,
    showReportsNotification: boolean,
    permissions: any
) => {
    if (projectsDetails) {
        const mainNavItems = [
            {
                title: 'Orders',
                icon: <CreditCardOutlinedIcon />,
                route: `project/orders`,
                group: ESideBarGroup.OVERVIEW,
                hide:
                    permissionsUtil.getAccessPublisherOrders(permissions)
                        .length === 0
            },
            {
                title: 'Reports',
                icon: <TextSnippetOutlinedIcon />,
                route: `project/reports/financialreports`,
                group: ESideBarGroup.OVERVIEW,
                notification: showReportsNotification,
                hide:
                    permissionsUtil.getPublishersByFinancialReports(permissions)
                        .length === 0
            }
        ];

        const projectsNavItems = projectsDetails.map((item) => ({
            title: item.publisherName,
            icon: (
                <img
                    className="project-logo"
                    src={item.publisherLogo}
                    alt="logo"
                />
            ),
            route: getProjectsRoute(item),
            group: ESideBarGroup.PROJECTS
        }));
        return mainNavItems.concat(projectsNavItems as any);
    }
    return [];
};

export const getSubNavItems = (
    showReportsNotification: boolean,
    activeProjectId?: string,
    featureFlags?: Record<EFeatureFlag, boolean>,
    version?: number,
    permissions?: any,
    isProjectCheckout?: boolean
) => {
    const hideFinReports = !(
        permissionsUtil.isSuperAdmin() ||
        (permissionsUtil.isAdmin() &&
            featureFlags?.[EFeatureFlag.DASHBOARD_FINANCIAL_REPORTS])
    );

    const popUpFeatureFlag = featureFlags?.[EFeatureFlag.DASHBOARD_POPUP];

    const subNavItems = [
        {
            title: 'Analytics',
            icon: <InsertChartOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/statistics/`
                : `project/statistics`,
            defaultQuery: 'range=2',
            group:
                version === NEW_VERSION
                    ? ESideBarGroup.MANAGEMENT
                    : ESideBarGroup.OVERVIEW,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.ANALYTICS,
                    activeProjectId
                )
        },
        {
            title: 'Orders',
            icon: <CreditCardOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/orders`
                : `project/orders`,
            group: ESideBarGroup.OVERVIEW,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.ORDERS,
                    activeProjectId
                )
        },
        {
            title: 'Segments',
            icon: <PieChartOutlineIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/segments`
                : `project/segments`,
            group: ESideBarGroup.OVERVIEW,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.SEGMENTS,
                    activeProjectId
                ),
            disabled: isProjectCheckout
        },
        {
            title: 'Pricing',
            icon: <DiamondOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/pricing`
                : `project/pricing`,
            group: ESideBarGroup.OVERVIEW,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.PRICING,
                    activeProjectId
                )
        },
        {
            title: 'Builder',
            icon: <StorefrontIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/theme/store`
                : `project/theme/store`,
            group: ESideBarGroup.STUDIO,
            disabled: isProjectCheckout,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.BUILDER,
                    activeProjectId
                )
        },
        {
            title: 'Products',
            icon: <LocalOfferOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/products`
                : `project/products`,
            group: ESideBarGroup.STUDIO,
            disabled: isProjectCheckout,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.PRODUCTS,
                    activeProjectId
                )
        },
        {
            title: 'Offers Design',
            icon: <WebAssetIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/offersui`
                : `project/offersui`,
            group: ESideBarGroup.STUDIO,
            disabled: isProjectCheckout,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.OFFER_DESIGN,
                    activeProjectId
                )
        },
        {
            title: 'Badges',
            icon: <LoyaltyOutlined />,
            route: activeProjectId
                ? `project/${activeProjectId}/badges`
                : `project/badges`,
            group: ESideBarGroup.STUDIO,
            disabled: isProjectCheckout,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.BADGES,
                    activeProjectId
                )
        },
        {
            title: 'Assets Library',
            icon: <PhotoOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/assets`
                : `project/assets`,
            group: ESideBarGroup.STUDIO,
            disabled: isProjectCheckout,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.ASSET_LIBRARY,
                    activeProjectId
                )
        },
        {
            title: 'Bundles',
            icon: <ShoppingBagOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/offers`
                : `project/offers`,
            group: ESideBarGroup.OFFERS,
            disabled: isProjectCheckout,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.BUNDLES,
                    activeProjectId
                )
        },
        {
            title: 'Promotions',
            icon: <AccessAlarmIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/promotions`
                : `project/promotions`,
            group: ESideBarGroup.OFFERS,
            disabled: isProjectCheckout,
            hide:
                version === NEW_VERSION &&
                permissionsUtil.shouldHideGroupInPermission(
                    permissions,
                    EPermissionGroup.PROMOTIONS,
                    activeProjectId
                )
        },
        {
            title: 'Popups',
            icon: <EmojiEventsOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/popups`
                : `project/popups`,
            group: ESideBarGroup.OFFERS,
            hide:
                !popUpFeatureFlag ||
                (version === NEW_VERSION &&
                    permissionsUtil.shouldHideGroupInPermission(
                        permissions,
                        EPermissionGroup.PRODUCTS,
                        activeProjectId
                    )),
            disabled: isProjectCheckout
        },
        {
            title: 'Financial Reports',
            icon: <TextSnippetOutlinedIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/reports`
                : `project/reports`,
            group: ESideBarGroup.REPORTS,
            notification: showReportsNotification,
            hide:
                hideFinReports ||
                (version === NEW_VERSION &&
                    permissionsUtil.shouldHideGroupInPermission(
                        permissions,
                        EPermissionGroup.FINANCIAL_REPORT,
                        activeProjectId
                    ))
        },
        {
            title: 'Payouts',
            icon: <AttachMoneyIcon />,
            route: activeProjectId
                ? `project/${activeProjectId}/payouts`
                : `project/payouts`,
            group: ESideBarGroup.REPORTS,
            hide:
                !featureFlags?.[EFeatureFlag.DASHBOARD_PAYOUTS_CENTER] ||
                (version === NEW_VERSION &&
                    permissionsUtil.shouldHideGroupInPermission(
                        permissions,
                        EPermissionGroup.PAYOUTS,
                        activeProjectId
                    ))
        }
    ];
    if (version === NEW_VERSION) {
        const filteredNavItems = subNavItems
            .slice(4, subNavItems.length - 1)
            .filter((item) => item.group !== ESideBarGroup.REPORTS);

        filteredNavItems.push(subNavItems[0]);
        return filteredNavItems.concat([
            {
                title: 'Segments',
                icon: <PieChartOutlineIcon />,
                route: activeProjectId
                    ? `project/${activeProjectId}/segments`
                    : `project/segments`,
                group: ESideBarGroup.MANAGEMENT,
                hide:
                    version === NEW_VERSION &&
                    permissionsUtil.shouldHideGroupInPermission(
                        permissions,
                        EPermissionGroup.SEGMENTS,
                        activeProjectId
                    ),
                disabled: isProjectCheckout
            },
            {
                title: 'Pricing',
                icon: <DiamondOutlinedIcon />,
                route: activeProjectId
                    ? `project/${activeProjectId}/pricing`
                    : `project/pricing`,
                group: ESideBarGroup.MANAGEMENT,
                hide:
                    version === NEW_VERSION &&
                    permissionsUtil.shouldHideGroupInPermission(
                        permissions,
                        EPermissionGroup.PRICING,
                        activeProjectId
                    )
            },
            {
                // TODO: Change hide condition
                title: 'Settings',
                icon: <SettingsOutlinedIcon />,
                route: activeProjectId
                    ? `project/${activeProjectId}/settings`
                    : `project/settings`,
                group: ESideBarGroup.MANAGEMENT,
                hide:
                    version === NEW_VERSION &&
                    !permissionsUtil.canAccessSettings(
                        permissions,
                        activeProjectId
                    )
            }
        ]);
    }

    return subNavItems;
};
