import React, { useState } from 'react';

import {
    ContentCopy as ContentCopyIcon,
    ExitToApp as ExitToAppIcon,
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import {
    FormHelperText,
    IconButton,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';

import { useNotifications } from '../../hooks/useNotifications';

import { AcInputProps } from './AcInput.types';

import './style.scss';

const AcInput = React.forwardRef(
    (
        {
            header = '',
            tooltip = '',
            textarea = false,
            helperText = '',
            headerSize = '14',
            enableCopy = false,
            enableNewTabOpen = false,
            required = false,
            fontFamily = '',
            disabled = false,
            customClass = '',
            dataTestId = '',
            ...props
        }: AcInputProps,
        ref
    ) => {
        const [showPassword, setShowPassword] = useState(false);
        const { enqueueSnackbar } = useNotifications();

        const handleNavigate = () => {
            if (!props.value) return;
            const path = props.value as string;
            const navigationPath = /^https?:\/\//i.test(path)
                ? path
                : `https://${path}`;
            window.open(navigationPath, '_blank');
        };

        return (
            <div>
                <Stack
                    className={`input-container formContent-input ${customClass}`}
                    direction="column"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        marginBottom={'4px'}
                        gap={'5px'}
                        className="input-headerBlock formContent-input-fieldTitle"
                    >
                        {header && (
                            <Tooltip arrow title={tooltip} placement="top">
                                <h3>
                                    {required && (
                                        <span
                                            style={{
                                                color: 'red',
                                                marginRight: '6px'
                                            }}
                                        >
                                            *
                                        </span>
                                    )}
                                    {header}
                                </h3>
                            </Tooltip>
                        )}
                        {enableCopy && (
                            <IconButton
                                aria-label="copy"
                                sx={{ padding: 'unset' }}
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        props.value as string
                                    );
                                    enqueueSnackbar(
                                        `${
                                            header ? header : ''
                                        } copied to clipboard`
                                    );
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        )}
                        {enableNewTabOpen && (
                            <IconButton
                                aria-label="open in new tab"
                                sx={{
                                    padding: 'unset',
                                    color: '#000000',
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '16px'
                                    }
                                }}
                                onClick={handleNavigate}
                            >
                                <ExitToAppIcon />
                            </IconButton>
                        )}
                    </Stack>
                    <TextField
                        variant={props.variant ?? 'outlined'}
                        sx={{ width: '100%' }}
                        {...props}
                        value={props.value}
                        ref={ref as any}
                        multiline={!!textarea}
                        rows={textarea ? 2 : undefined}
                        type={showPassword ? 'text' : props.type}
                        InputProps={{
                            style: { fontFamily: fontFamily },
                            endAdornment: props.type === 'password' && (
                                <Stack
                                    width={64}
                                    height={'100%'}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        sx={{
                                            marginTop: 0
                                        }}
                                    >
                                        {showPassword ? (
                                            <VisibilityOff sx={{ width: 22 }} />
                                        ) : (
                                            <Visibility sx={{ width: 22 }} />
                                        )}
                                    </IconButton>
                                </Stack>
                            ),
                            inputProps: { 'data-testid': `${dataTestId}` },
                            ...props.InputProps
                        }}
                        className={`formContent-input-inputField ${customClass}`}
                        disabled={disabled}
                    />
                    {helperText && (
                        <FormHelperText>{helperText}</FormHelperText>
                    )}
                </Stack>
            </div>
        );
    }
);

export default AcInput;
