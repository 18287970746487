import { Route,Routes } from 'react-router-dom';

import PromotionsForm from './PromotionsForm';
import PromotionsTable from './PromotionsTable';

import '../../views/view.scss';

const OffersView = () => {
    return (
        <Routes>
            <Route path="/" element={<PromotionsTable />} />
            <Route path="/form" element={<PromotionsForm />} />
            <Route path="/form/:specialOfferId" element={<PromotionsForm edit={true} />} />
            <Route
                path="/dup/:specialOfferId"
                element={<PromotionsForm edit={false} dup={true} />}
            />
        </Routes>
    );
};

export default OffersView;
