import { useMutation,useQuery } from '@tanstack/react-query';

import { Badge, User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig,getApiRouteOrMock } from './api.utils';

export default function useBadges(currentPublisherId: string | null, badgeId?: string) {
    const axios = useAxios();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getBadges = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.BADGES],
        queryFn: async () => {
            const badgesRaw = await axios.get(
                getApiRouteOrMock(EApiRoutes.GET_BADGES),
                {},
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );
            return badgesRaw;
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const getBadge = useQuery({
        ...fetchConfig.single,
        queryKey: [`${EQueryKeys.BADGES}_${badgeId}`],
        queryFn: async () => {
            if (!badgeId) return null;
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.BADGES)}/${badgeId}`,
                {},
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId && !!badgeId : !!badgeId
    });

    const addBadge = useMutation(async (newBadge: Badge) => {
        return await axios.post(getApiRouteOrMock(EApiRoutes.BADGES), newBadge);
    }, {});

    const deleteBadge = useMutation(async (badgeIdToDelete: string) => {
        return await axios.del(
            `${getApiRouteOrMock(EApiRoutes.BADGES)}/${badgeIdToDelete}`
        );
    }, {});

    const updateBadge = useMutation(
        async ({
            form,
            badgeId
        }: {
            form: Partial<Badge>;
            badgeId: string;
        }) => {
            return axios.put(
                `${getApiRouteOrMock(EApiRoutes.BADGES)}/${badgeId}`,
                form
            );
        },
        {}
    );

    return {
        getBadge,
        getBadges,
        addBadge,
        deleteBadge,
        updateBadge
    };
}
