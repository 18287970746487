import { useMutation, useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig,getApiRouteOrMock } from './api.utils';

export default function useSections(currentPublisherId: string | null) {
    const axios = useAxios();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getSections = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.SECTIONS],
        queryFn: async () => {
            return await axios.get(getApiRouteOrMock(EApiRoutes.SECTIONS));
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const addSections = useMutation(async (data: any) => {
        return await axios.post(getApiRouteOrMock(EApiRoutes.SECTIONS), data);
    }, {});

    const updateSections = useMutation(async (data: any) => {
        return await axios.put(getApiRouteOrMock(EApiRoutes.SECTIONS), data);
    }, {});

    const deleteSections = useMutation(async (data: any) => {
        return await axios.del(getApiRouteOrMock(EApiRoutes.SECTIONS), data);
    }, {});

    const upsertSections = useMutation(async (data: any) => {
        return await axios.post(getApiRouteOrMock(EApiRoutes.UPSERT_SECTIONS), data);
    }, {});

    return {
        getSections,
        addSections,
        updateSections,
        upsertSections,
        deleteSections
    };
}
