import { useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';

import {
    PaymentMethod,
    PaymentMethodConfigDataItem
} from '../../../api/payments/payments.types';
import usePayments from '../../../api/payments/usePayments';
import useTheme from '../../../api/useTheme';
import { ENotificationType } from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import { AuthSliceState } from '../../../store/store.types';
import ActionButton from '../../ActionButton/ActionButton';
import CustomizedSwitch from '../../SwitchButton/SwitchButton';

import '../style.scss';
import '../../../common/styles.scss';

const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const PaymentSettings = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const themeActions = useTheme(currentPublisherId);
    const { data: themeData, isLoading: isThemeLoading } =
        themeActions.getStoreTheme;
    const payments = usePayments();
    const { data: paymentsData, isLoading: paymentsIsLoading } =
        payments.getPaymentMethods;
    const { data: paymentsConfigData, isLoading: paymentsConfigIsLoading } =
        payments.getPaymentsMethodConfig;
    const [paymentMethodOrder, setPaymentMethodOrder] = useState<
        PaymentMethod[]
    >([]);
    const [paymentMethodConfig, setPaymentMethodConfig] = useState<
        PaymentMethodConfigDataItem[]
    >([]);
    const [crossPaymentConfig, setCrossPaymentConfig] = useState<
        Array<PaymentMethod & PaymentMethodConfigDataItem>
    >([]);
    const { enqueueSnackbar } = useNotifications();

    useEffect(() => {
        if (
            !paymentsIsLoading &&
            paymentsData &&
            !paymentsConfigIsLoading &&
            paymentsConfigData
        ) {
            const initialPCD = paymentsConfigData?.paymentMethods.map((pmc) => {
                const paymentMethod = paymentsData?.find(
                    (pm) => pm.id === pmc.paymentMethodId
                );
                return paymentMethod;
            });

            setPaymentMethodOrder(initialPCD as PaymentMethod[]);
        }
    }, [
        paymentMethodConfig,
        paymentsConfigData,
        paymentsConfigIsLoading,
        paymentsData,
        paymentsIsLoading
    ]);

    useEffect(() => {
        if (!paymentsConfigIsLoading && paymentsConfigData) {
            setPaymentMethodConfig(paymentsConfigData.paymentMethods);
        }
    }, [paymentsConfigData, paymentsConfigIsLoading]);

    useEffect(() => {
        const paymentCross = paymentMethodOrder?.map((paymentMethod) => {
            const config = paymentMethodConfig?.find(
                (pmc) => pmc.paymentMethodId === paymentMethod.id
            );
            return {
                ...paymentMethod,
                ...config
            };
        }) as Array<PaymentMethod & PaymentMethodConfigDataItem>;
        setCrossPaymentConfig(paymentCross);
    }, [
        paymentMethodConfig,
        paymentMethodOrder,
        paymentsConfigData,
        paymentsConfigIsLoading,
        paymentsData,
        paymentsIsLoading
    ]);

    function savePayments() {
        const dbPaymentConfig = crossPaymentConfig?.map((paymentMethod) => {
            const newPaymentMethod = {} as Partial<
                PaymentMethod & PaymentMethodConfigDataItem & { rules: {} }
            >;
            newPaymentMethod.paymentMethodId = paymentMethod.paymentMethodId;
            newPaymentMethod.enabled = paymentMethod.enabled;
            return newPaymentMethod;
        }) as PaymentMethodConfigDataItem[];
        payments.updatePaymentMethods.mutate(
            { paymentMethods: dbPaymentConfig },
            {
                onSuccess: async (data) => {
                    enqueueSnackbar(
                        'Payment methods has been updated successfully',
                        ENotificationType.SUCCESS
                    );
                },
                onError(error) {
                    enqueueSnackbar(
                        `Something went wrong with updating the payment methods`,
                        ENotificationType.ERROR,
                        (
                            (error as AxiosError).response?.data as {
                                message: string;
                            }
                        ).message
                    );
                }
            }
        );
    }

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const quotes = reorder(
            paymentMethodOrder,
            result.source.index,
            result.destination.index
        );

        setPaymentMethodOrder(quotes as any);
    }

    const changePaymentEnabled = (quote: PaymentMethod) => {
        setPaymentMethodConfig((previousPaymentMethodConfig) => {
            return previousPaymentMethodConfig.map((ppmc) => {
                if (ppmc.paymentMethodId === quote.id) {
                    ppmc.enabled = !ppmc.enabled;
                }
                return ppmc;
            });
        });
    };

    return !paymentsIsLoading && !paymentsConfigIsLoading ? (
        <Grid item xs={5}>
            <Grid item xs={12}>
                <Typography fontWeight="normal" fontSize={18}>
                    Payment Methods
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                mt={2}
                overflow={'auto'}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {paymentMethodOrder?.map(
                                    (quote: PaymentMethod, index: number) => {
                                        return (
                                            <Draggable
                                                draggableId={quote.id}
                                                key={quote.id}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <Stack
                                                        justifyContent="space-between"
                                                        direction="row"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        py={2}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            gap={1}
                                                        >
                                                            <DragHandleIcon
                                                                sx={{
                                                                    color: 'black'
                                                                }}
                                                            />
                                                            <h3>
                                                                {
                                                                    quote.paymentMethodDisplayName
                                                                }
                                                            </h3>
                                                        </Stack>
                                                        <Box>
                                                            <CustomizedSwitch
                                                                texts={[]}
                                                                status={
                                                                    !!paymentMethodConfig?.find(
                                                                        (
                                                                            pm: PaymentMethodConfigDataItem
                                                                        ) =>
                                                                            pm.paymentMethodId ===
                                                                            quote.id
                                                                    )?.enabled
                                                                }
                                                                functions={[
                                                                    () =>
                                                                        changePaymentEnabled(
                                                                            quote
                                                                        ),
                                                                    () =>
                                                                        changePaymentEnabled(
                                                                            quote
                                                                        )
                                                                ]}
                                                            />
                                                        </Box>
                                                    </Stack>
                                                )}
                                            </Draggable>
                                        );
                                    }
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
            <Grid item xs={6} mt={4}>
                <ActionButton
                    variant="outlined"
                    text="Update"
                    onClick={savePayments}
                />
            </Grid>
        </Grid>
    ) : (
        <></>
    );
};

export default PaymentSettings;
