import { EFeatureFlag, ELocalStorageKeys } from '../constants/enums';

import { localStorageUtil } from './localStorageUtil';

export const featureFlagUtil = {
    isFeatureEnabled: (featureFlag: EFeatureFlag) => {
        const featureFlags = localStorageUtil.get(
            ELocalStorageKeys.FEATURE_FLAGS
        );

        return (
            featureFlags &&
            featureFlag in featureFlags &&
            featureFlags[featureFlag] === true
        );
    }
};
