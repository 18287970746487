import { useMutation,useQuery } from '@tanstack/react-query';

import { OfferUI, User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig,getApiRouteOrMock } from './api.utils';

export default function useOfferUI(currentPublisherId: string | null, offerId?: string) {
    const axios = useAxios();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getOffersUI = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.OFFERS_UI],
        queryFn: async () => {
            const offersUIRaw = await axios.get(
                getApiRouteOrMock(EApiRoutes.OFFERS_UI),
                {},
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );
            return offersUIRaw;
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const getOfferUI = useQuery({
        ...fetchConfig.single,
        queryKey: [`${EQueryKeys.OFFERS_UI}_${offerId}`],
        queryFn: async () => {
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.OFFERS_UI)}/${offerId}`,
                {},
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId && !!offerId : !!offerId
    });

    const addOfferUI = useMutation(async (newOfferUI: OfferUI) => {
        return await axios.post(
            getApiRouteOrMock(EApiRoutes.OFFERS_UI),
            newOfferUI
        );
    }, {});

    const deleteOfferUI = useMutation(async (offerUIId: string) => {
        return await axios.del(
            `${getApiRouteOrMock(EApiRoutes.OFFERS_UI)}/${offerUIId}`
        );
    }, {});

    const updateOfferUI = useMutation(
        async ({
            form,
            offerId
        }: {
            form: Partial<OfferUI>;
            offerId: string;
        }) => {
            return axios.put(
                `${getApiRouteOrMock(EApiRoutes.OFFERS_UI)}/${offerId}`,
                form
            );
        },
        {}
    );

    return {
        getOfferUI,
        getOffersUI,
        addOfferUI,
        deleteOfferUI,
        updateOfferUI
    };
}
