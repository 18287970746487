import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Divider, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useIntegration from '../../../api/useIntegration';
import { ENotificationType } from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import { AuthSliceState } from '../../../store/store.types';
import AcCard from '../../AcCard/AcCard';
import AcSelect from '../../AcSelect/AcSelect';
import {
    EPlayersAuthenticationModel,
    IntegrationSettingsData,
    PlayerAuthSettingsProps
} from '../Settings.types';

import { AuthCard } from './AuthCard';

import '../../../style/forms.scss';
import '../style.scss';

const PlayerAuthSettings = ({ data }: PlayerAuthSettingsProps) => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const integration = useIntegration(currentPublisherId);
    const { enqueueSnackbar } = useNotifications();
    const [facebookApp, setFacebookApp] = useState(
        !!data?.playersAuthentication?.fbOn
    );
    const [googleApp, setGoogleApp] = useState(
        !!data?.playersAuthentication?.googleOn
    );
    const [appleApp, setAppleApp] = useState(
        !!data?.playersAuthentication?.appleOn
    );
    const [userToken, setUserToken] = useState(
        !!data?.playersAuthentication?.userTokenOn
    );
    const [usernameAndPassword, setUsernameAndPassword] = useState(
        !!data?.playersAuthentication?.usernamePasswordOn
    );
    const [otp, setOtp] = useState(!!data?.playersAuthentication?.otpOn);

    const playersAuthenticationSchema = yup.object().shape({
        playersAuthentication: yup.object().shape({
            appleModel: yup.string(),
            appleAppSecret: yup.string(),
            appleAppId: yup.string(),
            appleOn: yup.boolean(),
            googleAppId: yup.string(),
            googleAppSecret: yup.string(),
            googleOn: yup.boolean(),
            googleModel: yup.string(),
            fbAppSecret: yup.string(),
            fbAppId: yup.string(),
            fbModel: yup.string(),
            fbOn: yup.boolean(),
            usernamePasswordModel: yup.string(),
            usernamePasswordOn: yup.boolean(),
            otpTextModel: yup.string()
        }),
        otpGenerateDeeplinkUrl: yup.string()
    });

    const getInitialValues = () => ({
        isToggleOnOff:
            facebookApp ||
            googleApp ||
            appleApp ||
            userToken ||
            usernameAndPassword ||
            otp,
        otpGenerateDeeplinkUrl: data?.otpGenerateDeeplinkUrl || '',
        playersAuthentication: {
            appleModel:
                data?.playersAuthentication?.appleModel ||
                EPlayersAuthenticationModel.APPCHARGE,
            appleAppSecret: data?.playersAuthentication?.appleAppSecret || '',
            appleAppId: data?.playersAuthentication?.appleAppId || '',
            appleOn: data?.playersAuthentication?.appleOn || false,
            googleAppId: data?.playersAuthentication?.googleAppId || '',
            googleAppSecret: data?.playersAuthentication?.googleAppSecret || '',
            googleModel:
                data?.playersAuthentication?.googleModel ||
                EPlayersAuthenticationModel.APPCHARGE,
            googleOn: data?.playersAuthentication?.googleOn || false,
            fbAppSecret: data?.playersAuthentication?.fbAppSecret || '',
            fbAppId: data?.playersAuthentication?.fbAppId || '',
            fbOn: data?.playersAuthentication?.fbOn || false,
            fbModel: data?.playersAuthentication?.fbModel || false,
            usernamePasswordOn:
                data?.playersAuthentication?.usernamePasswordOn || false,
            usernamePasswordModel: data?.usernamePasswordModel || false,
            userTokenOn: data?.playersAuthentication?.userTokenOn || false,
            userTokenModel:
                data?.playersAuthentication?.userTokenModel || false,
            otpTextModel: data?.playersAuthentication?.otpTextModel || ''
        }
    });

    const initialValues = getInitialValues();
    const prevAuthSettingsValues = useRef(initialValues);

    useEffect(() => {
        if (facebookApp !== data?.playersAuthentication?.fbOn)
            setFacebookApp(Boolean(data?.playersAuthentication?.fbOn));
        if (googleApp !== data?.playersAuthentication?.googleOn)
            setGoogleApp(Boolean(data?.playersAuthentication?.googleOn));
        if (appleApp !== data?.playersAuthentication?.appleOn)
            setAppleApp(Boolean(data?.playersAuthentication?.appleOn));
        if (userToken !== data?.playersAuthentication?.userTokenOn)
            setUserToken(Boolean(data?.playersAuthentication?.userTokenOn));
        if (
            usernameAndPassword !==
            data?.playersAuthentication?.usernamePasswordOn
        )
            setUsernameAndPassword(
                Boolean(data?.playersAuthentication?.usernamePasswordOn)
            );
        if (otp !== data?.playersAuthentication?.otpOn)
            setOtp(Boolean(data?.playersAuthentication?.otpOn));
    }, [data]);

    const playersAuthenticationForm = useFormik({
        initialValues,
        validationSchema: playersAuthenticationSchema,
        onSubmit: async (values) => {
            const newValues: any = {};
            if (values.otpGenerateDeeplinkUrl) {
                newValues.otpGenerateDeeplinkUrl =
                    values.otpGenerateDeeplinkUrl;
            }
            if (
                values.isToggleOnOff !==
                prevAuthSettingsValues.current.isToggleOnOff
            ) {
                newValues.isToggleOnOff = values.isToggleOnOff;
            }
            const changedValues = Object.keys(
                values.playersAuthentication
            ).reduce((acc, key) => {
                const typedKey =
                    key as keyof typeof initialValues.playersAuthentication;
                if (
                    JSON.stringify(
                        prevAuthSettingsValues.current.playersAuthentication[
                            typedKey
                        ]
                    ) !== JSON.stringify(values.playersAuthentication[typedKey])
                ) {
                    acc[typedKey] = values.playersAuthentication[typedKey];
                }
                return acc;
            }, {} as any);
            newValues.playersAuthentication = changedValues;
            savePlayersAuthenticationDetails(newValues);
            prevAuthSettingsValues.current = values;
            playersAuthenticationForm.resetForm({
                values: {
                    otpGenerateDeeplinkUrl: values.otpGenerateDeeplinkUrl,
                    isToggleOnOff: values.isToggleOnOff,
                    playersAuthentication: {
                        appleAppId: values.playersAuthentication.appleAppId,
                        appleAppSecret:
                            values.playersAuthentication.appleAppSecret,
                        appleModel: values.playersAuthentication.appleModel,
                        appleOn: values.playersAuthentication.appleOn,
                        fbAppId: values.playersAuthentication.fbAppId,
                        fbAppSecret: values.playersAuthentication.fbAppSecret,
                        fbModel: values.playersAuthentication.fbModel,
                        fbOn: values.playersAuthentication.fbOn,
                        googleAppId: values.playersAuthentication.googleAppId,
                        googleAppSecret:
                            values.playersAuthentication.googleAppSecret,
                        googleModel: values.playersAuthentication.googleModel,
                        googleOn: values.playersAuthentication.googleOn,
                        // otpTextModel: values.playersAuthentication.otpTextModel,
                        userTokenModel:
                            values.playersAuthentication.userTokenModel,
                        userTokenOn: values.playersAuthentication.userTokenOn,
                        usernamePasswordModel:
                            values.playersAuthentication.usernamePasswordModel,
                        usernamePasswordOn:
                            values.playersAuthentication.usernamePasswordOn,
                        otpTextModel: values.playersAuthentication.otpTextModel
                    }
                }
            });
        }
    });

    useEffect(() => {
        prevAuthSettingsValues.current = getInitialValues();
        playersAuthenticationForm.resetForm({ values: getInitialValues() });
    }, [data]);

    const savePlayersAuthenticationDetails = (
        values: Partial<IntegrationSettingsData> & { isToggleOnOff: boolean }
    ) => {
        integration.updateIntegration.mutate(values, {
            onSuccess: () => {
                enqueueSnackbar(
                    values.isToggleOnOff
                        ? 'App Authentication details updated successfully'
                        : 'Authentication details updated successfully',
                    ENotificationType.SUCCESS
                );
            },
            onError: () => {
                enqueueSnackbar(
                    'Failed to update Player Authentication details',
                    ENotificationType.ERROR
                );
            }
        });
    };

    const appStates = [
        appleApp,
        googleApp,
        facebookApp,
        usernameAndPassword,
        userToken,
        otp
    ];

    return (
        <Grid container className="formContent">
            <Grid item xs={5} className="formContent-minWIdth">
                <Stack>
                    <AcCard
                        stackContainer={false}
                        title="Authentication"
                        description="Choose the log in methods you wish to enable"
                    >
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={12} mt={1}>
                                <AuthCard
                                    title="Facebook App"
                                    setStateFunction={setFacebookApp}
                                    state={facebookApp}
                                    form={playersAuthenticationForm}
                                    id="fbAppId"
                                    model="fbModel"
                                    on="fbOn"
                                    secret="fbAppSecret"
                                    logo="facebook"
                                    appStates={appStates}
                                />
                            </Grid>
                            <Divider />
                            <Grid item xs={12} mt="-16px">
                                <AuthCard
                                    title="Google App"
                                    setStateFunction={setGoogleApp}
                                    state={googleApp}
                                    form={playersAuthenticationForm}
                                    id="googleAppId"
                                    model="googleModel"
                                    on="googleOn"
                                    secret="googleAppSecret"
                                    logo="google"
                                    appStates={appStates}
                                />
                            </Grid>
                            <Divider />
                            <Grid item mt="-16px" xs={12}>
                                <AuthCard
                                    title="Apple App"
                                    setStateFunction={setAppleApp}
                                    state={appleApp}
                                    form={playersAuthenticationForm}
                                    id="appleAppId"
                                    model="appleModel"
                                    on="appleOn"
                                    secret="appleAppSecret"
                                    logo="apple"
                                    appStates={appStates}
                                />
                            </Grid>
                            <Divider />
                            <Grid item mt="-16px" xs={12}>
                                <AuthCard
                                    title="Username and Password"
                                    setStateFunction={setUsernameAndPassword}
                                    state={usernameAndPassword}
                                    form={playersAuthenticationForm}
                                    on="usernamePasswordOn"
                                    appStates={appStates}
                                />
                            </Grid>
                            <Divider />
                            <Grid item mt="-16px" xs={12}>
                                <AuthCard
                                    title="Player ID"
                                    setStateFunction={setUserToken}
                                    state={userToken}
                                    form={playersAuthenticationForm}
                                    on="userTokenOn"
                                    appStates={appStates}
                                />
                            </Grid>
                            <Divider />
                            <Grid item mt="-16px" xs={12}>
                                <AuthCard
                                    title="OTP"
                                    setStateFunction={setOtp}
                                    state={otp}
                                    form={playersAuthenticationForm}
                                    on="otpOn"
                                    appStates={appStates}
                                    inputs={[
                                        {
                                            header: 'OTP Generation Deeplink Url',
                                            name: 'otpGenerateDeeplinkUrl'
                                        }
                                    ]}
                                >
                                    <Box marginTop={1}>
                                        <AcSelect
                                            header="Desktop Text Model"
                                            name="playersAuthentication.otpTextModel"
                                            value={
                                                playersAuthenticationForm.values
                                                    .playersAuthentication
                                                    .otpTextModel
                                            }
                                            items={[
                                                {
                                                    content: '4 Digits',
                                                    key: 'fourDigits',
                                                    value: 'fourDigits'
                                                },
                                                {
                                                    content: '6 Digits',
                                                    key: 'sixDigits',
                                                    value: 'sixDigits'
                                                },
                                                {
                                                    content: 'Mobile Only',
                                                    key: 'mobileOnly',
                                                    value: 'mobileOnly'
                                                }
                                            ]}
                                            onChange={playersAuthenticationForm.handleChange}
                                        />
                                    </Box>
                                </AuthCard>
                            </Grid>
                            <Divider />
                        </Grid>
                    </AcCard>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default PlayerAuthSettings;
