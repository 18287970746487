import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { useProduct } from '../../api/useProduct';
import ProductFormEditWrapper from '../../components/ProductFormEditWrapper/ProductFormEditWrapper';
import ProductsForm from '../../components/ProductsForm/ProductsForm';
import ProductsList from '../../components/ProductsList/ProductsList';
import { ENotificationType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { AuthSliceState } from '../../store/store.types';

import '../view.scss';

const ProductsView = () => {
    const { enqueueSnackbar } = useNotifications();
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { deleteProduct, addProduct, updateProduct, getProducts } =
        useProduct(currentPublisherId);
    const navigate = useNavigate();

    const deleteProductHandler = (productId: string) => {
        deleteProduct.mutate(productId, {
            onSuccess: (data: any) => {
                getProducts.refetch();
                enqueueSnackbar(
                    `Product has been deleted`,
                    ENotificationType.SUCCESS
                );
            },
            onError: (data: any) => {
                enqueueSnackbar(
                    data.response.data.message,
                    ENotificationType.ERROR
                );
            }
        });
    };

    const addProductHandler = (form: any, onMutationComplete: () => void) => {
        addProduct.mutate(form, {
            onSuccess: () => {
                enqueueSnackbar(
                    `Product has been created`,
                    ENotificationType.SUCCESS
                );
                navigate('./');
                getProducts.refetch();
                onMutationComplete();
            },
            onError: (data: any) => {
                enqueueSnackbar(
                    data.response.data.message,
                    ENotificationType.ERROR
                );
                onMutationComplete();
            }
        });
    };

    const updateProductHandler = (
        form: any,
        onMutationComplete: () => void
    ) => {
        updateProduct.mutate(form, {
            onSuccess: () => {
                enqueueSnackbar(
                    `Product "${form.data.name}" has been updated`,
                    ENotificationType.SUCCESS
                );
                navigate('./');
                getProducts.refetch();
                onMutationComplete();
            },
            onError: (data: any) => {
                enqueueSnackbar(
                    data.response.data.message,
                    ENotificationType.ERROR
                );
                onMutationComplete();
            }
        });
    };

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <ProductsList
                        isLoading={getProducts.isLoading}
                        isError={getProducts.isError}
                        data={getProducts.data?.result}
                        error={getProducts.error}
                        deleteProduct={deleteProductHandler}
                    />
                }
            />
            <Route
                path="/form"
                element={
                    <ProductsForm
                        postProduct={addProductHandler}
                        headline="New Product"
                        buttonText="Save Product"
                        data={undefined}
                        emptyCache={undefined}
                    />
                }
            />
            <Route
                path="/form/:id"
                element={
                    <ProductFormEditWrapper
                        updateProduct={updateProductHandler}
                        postProduct={addProductHandler}
                    />
                }
            />
            <Route
                path="/dup/:id"
                element={
                    <ProductFormEditWrapper
                        updateProduct={updateProductHandler}
                        postProduct={addProductHandler}
                        dup={true}
                    />
                }
            />
        </Routes>
    );
};

export default ProductsView;
