import { useMutation, useQuery } from '@tanstack/react-query';

import { Offer, User } from '../common/contracts';
import { NEW_VERSION, PAGINATION_ROWS_PER_PAGE } from '../constants/constants';
import {
    ELocalStorageKeys,
    EQueryKeys,
    OfferType,
    SortingDirection,
    SortingOffersValue
} from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useOffers(
    currentPublisherId: string | null,
    offerId?: string,
    offerType: string = OfferType.BUNDLE,
    pagination = { currentPage: 0, rowsPerPage: PAGINATION_ROWS_PER_PAGE },
    sorting = {
        sortValue: SortingOffersValue.CREATED_AT,
        direction: SortingDirection.DESC
    }
) {
    const axios = useAxios();
    const offset = pagination.currentPage * pagination.rowsPerPage;
    const recordLimit = pagination.rowsPerPage;
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const { sortValue, direction } = sorting;

    const getOffers = useQuery({
        ...fetchConfig.list,
        queryKey: [`${EQueryKeys.OFFERS}${offerType}`],
        queryFn: async () => {
            const payload = {
                offerType,
                offset,
                recordLimit,
                direction,
                sortValue
            };

            const response = await axios.get(
                getApiRouteOrMock(EApiRoutes.OFFERS),
                payload,
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );

            if (response.result && response.result.offers) {
                // New format
                const totalCount = response.result.totalCount;

                return {
                    offers: [...response.result.offers],
                    currentPage: pagination.currentPage || 0,
                    rowsPerPage: pagination.rowsPerPage || 25,
                    totalCount
                };
            } else {
                // Old format
                return response;
            }
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const getOffer = useQuery({
        ...fetchConfig.single,
        queryKey: [`${EQueryKeys.OFFERS}_${offerId}`],
        queryFn: async () => {
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerId}`,
                {},
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId && !!offerId : !!offerId
    });

    const addOffer = useMutation(
        async (newOffer: Partial<Offer> & { sectionId?: string | null }) => {
            return await axios.post(
                getApiRouteOrMock(EApiRoutes.OFFERS),
                newOffer
            );
        },
        {}
    );

    const addPopUpOffer = useMutation(
        async (newOffer: Partial<Offer> & { sectionId?: string | null }) => {
            return await axios.post(
                getApiRouteOrMock(EApiRoutes.POPUP_OFFERS),
                newOffer
            );
        },
        {}
    );

    const deleteOffer = useMutation(async (offerIdToDelete: string) => {
        return await axios.del(
            `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerIdToDelete}`
        );
    }, {});

    const updateOffer = useMutation(
        async ({
            form,
            offerId
        }: {
            form: Partial<Offer>;
            offerId: string;
        }) => {
            return axios.put(
                `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerId}`,
                form
            );
        },
        {}
    );

    const updatePopUpOffer = useMutation(
        async ({
            form,
            offerId
        }: {
            form: Partial<Offer>;
            offerId: string;
        }) => {
            return axios.put(
                `${getApiRouteOrMock(EApiRoutes.POPUP_OFFERS)}/${offerId}`,
                form
            );
        },
        {}
    );

    const updatePopUpActiveStatus = useMutation(
        async ({ active, offerId }: { active: boolean; offerId: string }) => {
            const url = `${getApiRouteOrMock(EApiRoutes.POPUP_OFFERS)}/${offerId}`;
            return axios.patch(url, { active });
        },
        {}
    );

    return {
        getOffer,
        getOffers,
        addOffer,
        addPopUpOffer,
        deleteOffer,
        updateOffer,
        updatePopUpOffer,
        updatePopUpActiveStatus
    };
}
