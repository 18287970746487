import { Box, Card, Stack } from '@mui/material';

import AcInput from '../../AcInput/AcInput';
import ActionButton from '../../ActionButton/ActionButton';
import CustomizedSwitch from '../../SwitchButton/SwitchButton';

import { AuthCardProps } from './PlayerAuthSettings.types';

export const AuthCard = ({
    title,
    setStateFunction,
    state,
    form,
    model,
    on,
    id,
    secret,
    logo,
    inputs,
    appStates,
    children
}: AuthCardProps) => {
    const isDisabled = () => {
        return form.values.playersAuthentication?.[id!] === '';
    };

    return (
        <Card
            variant="outlined"
            sx={{
                boxShadow: 0,
                border: 0
            }}
        >
            <Stack justifyContent="space-between" direction="row">
                <h3
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {logo && (
                        <img
                            src={`./assets/images/${logo}.png`}
                            alt={logo}
                            style={{ height: 16, marginRight: 8 }}
                        />
                    )}
                    {title}
                </h3>
                <Box mt={-1} mr={-3} mb={-1}>
                    <CustomizedSwitch
                        tooltip={
                            state &&
                            appStates.reduce((acc, cur) => {
                                return !cur ? acc + 1 : acc;
                            }, 0) >=
                                appStates.length - 1
                                ? `You can't disable this method because at least one authentication method has to be enabled.`
                                : ''
                        }
                        texts={[]}
                        status={state}
                        disabled={
                            state &&
                            appStates.reduce(
                                (acc, cur) => (!cur ? acc + 1 : acc),
                                0
                            ) >=
                                appStates.length - 1
                        }
                        functions={[
                            () => {
                                form.setFieldValue(
                                    `playersAuthentication.${on}`,
                                    false
                                );
                                form.setFieldValue('isToggleOnOff', true);
                                form.submitForm();
                                setStateFunction(false);
                            },
                            () => {
                                form.setFieldValue(
                                    `playersAuthentication.${on}`,
                                    true
                                );
                                form.setFieldValue('isToggleOnOff', true);
                                form.submitForm();
                                setStateFunction(true);
                            }
                        ]}
                    />
                </Box>
            </Stack>

            <Stack
                style={{
                    maxHeight: !state ? '0px' : '400px',
                    overflow: 'hidden',
                    transition: '0.6s all'
                }}
            >
                {id && (
                    <Box mt={2}>
                        <AcInput
                            header={`${title} ID`}
                            headerSize="14"
                            name={`playersAuthentication.${id}`}
                            value={form.values.playersAuthentication[id]}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={
                                form.touched?.playersAuthentication?.[id] &&
                                Boolean(
                                    form.errors?.playersAuthentication?.[id]
                                )
                            }
                            helperText={
                                form.touched?.playersAuthentication?.[id]
                                    ? form.errors?.playersAuthentication?.[
                                          id
                                      ]?.toString()
                                    : ''
                            }
                        />
                    </Box>
                )}
                {inputs &&
                    inputs.map((input, index) => (
                        <Box mt={2} key={index}>
                            <AcInput
                                header={input.header}
                                headerSize="14"
                                name={input.name}
                                value={form.values[input.name]}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                error={
                                    form.touched?.[input.name] &&
                                    Boolean(form.errors?.[input.name])
                                }
                                helperText={
                                    form.touched?.[input.name]
                                        ? form.errors?.[input.name]?.toString()
                                        : ''
                                }
                            />
                        </Box>
                    ))}
                {children}
                {(model || secret || inputs) && (
                    <Box mt="12px">
                        <ActionButton
                            variant="outlined"
                            text="Update"
                            onClick={form.submitForm}
                            disabled={isDisabled()}
                        />
                    </Box>
                )}</Stack>
        </Card>
    );
};
