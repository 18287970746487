import React, { ChangeEvent, useRef } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { FormikProps } from 'formik';

import DialogModal from '../../../components/Dialog/Dialog';
import { DialogButton } from '../../../components/Dialog/Dialog.types';
import { EButtonColor } from '../../../constants/enums';
import { AssetsFormValues } from '../AssetsView';

import UploadAssetContent from './components/UploadAssetContent';

interface UploadAssetModalProps {
    onUpload: () => void;
    isUploadDisabled: boolean;
    isModalOpen: boolean;
    onModalClosed: () => void;
    setFieldValue:  FormikProps<AssetsFormValues>['setFieldValue'];
    isUploading: boolean;
    imageId: string;
    category: string;
    handleBlur: FormikProps<AssetsFormValues>['handleBlur'];
}
const UploadAssetModal = ({
    onUpload,
    isUploadDisabled,
    isModalOpen,
    onModalClosed,
    setFieldValue,
    isUploading,
    imageId,
    category,
    handleBlur
}: UploadAssetModalProps) => {
    const addPictureInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: any) => {
        const file = event.currentTarget.files[0];
        setFieldValue('file', file);
    };

    const handleCategoryChange = (event: SelectChangeEvent<unknown>) => {
        setFieldValue('category', event.target.value);
    };

    const handleTrimChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFieldValue(name, value.replace(/^\s+/g, ''));
    };

    const buttonsContent: DialogButton[] = [
        {
            text: 'Save',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: () => onUpload(),
            fullWidth: true,
            disabled: isUploadDisabled || !addPictureInputRef?.current?.files ||
                addPictureInputRef?.current?.files?.length === 0
        }
    ];

    return (
        <DialogModal
            isOpen={isModalOpen}
            headline="Add New Asset"
            width={'392px'}
            text={''}
            content={
                <UploadAssetContent
                    ref={addPictureInputRef}
                    handleFileChange = {handleFileChange}
                    handleTrimChange={handleTrimChange}
                    imageId={imageId}
                    category={category}
                    handleBlur={handleBlur}
                    isUploading={isUploading}
                    handleCategoryChange={handleCategoryChange}
                />
            }
            buttons={buttonsContent}
            closeDialog={() => onModalClosed()}
            closeButton={true}
        />
    )
}
export default UploadAssetModal;
