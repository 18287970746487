import { Route, Routes } from 'react-router-dom';

import OrdersTableWrapper from '../../components/OrdersTableWrapper/OrdersTableWrapper';

import OrderDetails from './OrderDetails';

import '../../views/view.scss';

const OrdersView = () => {
    return (
        <Routes>
            <Route path="/" element={<OrdersTableWrapper />} />
            <Route path="/free" element={<OrdersTableWrapper />} />
            <Route path="/:orderId" element={<OrderDetails isFree={false} />} />
            <Route
                path="/free/:orderId"
                element={<OrderDetails isFree={true} />}
            />
        </Routes>
    );
};

export default OrdersView;
