import { useMutation, useQuery } from '@tanstack/react-query';
import { NEW_VERSION } from 'constants/constants';

import { PublisherInfo, User } from '../common/contracts';
import { IPermissions } from '../common/permissions.types';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';
import { permissionsUtil } from '../utils/permissionsUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

interface UpdateSettingsParams {
    setting: string;
    isActive: boolean;
}

export default function usePublisher(
    publisherId?: string,
    deactivate?: boolean
) {
    const axios = useAxios();

    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const canAccessUsers =
        versionDetails !== NEW_VERSION
            ? true
            : publisherId
              ? !!permissionsUtil.canAccessUsers(
                    localStorageUtil.get<IPermissions>(
                        ELocalStorageKeys.PERMISSIONS
                    ) || {},
                    publisherId
                )
              : false;

    const canAccessPublisher =
        versionDetails !== NEW_VERSION
            ? true
            : publisherId
              ? !!permissionsUtil.canAccessPublisher(
                    localStorageUtil.get<IPermissions>(
                        ELocalStorageKeys.PERMISSIONS
                    ) || {},
                    publisherId
                )
              : false;

    const getPublisher = useQuery<PublisherInfo, Error>({
        ...fetchConfig.general,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        enabled: canAccessPublisher,
        queryKey: [EQueryKeys.PUBLISHER],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(EApiRoutes.PUBLISHER),
                {},
                publisherId ? { 'x-publishers': publisherId } : undefined
            );
        },
        onSuccess: (data) => {
            if (data?.publisherSolutionModel) {
                localStorageUtil.set(
                    ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL,
                    data.publisherSolutionModel
                );
            }
        }
    });

    const getPublisherSettings = useQuery<any, Error>({
        ...fetchConfig.general,
        queryKey: [EQueryKeys.PUBLISHER_SETTINGS],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(EApiRoutes.PUBLISHER_SETTINGS),
                {},
                publisherId ? { 'x-publishers': publisherId } : undefined
            );
        },
        enabled: versionDetails !== NEW_VERSION
    });

    const getUsersByPublisher = useQuery({
        ...fetchConfig.single,
        queryKey: [
            versionDetails
                ? EApiRoutes.PUBLISHER_USERS_V2
                : EApiRoutes.PUBLISHER_USERS
        ],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(
                    versionDetails
                        ? EApiRoutes.PUBLISHER_USERS_V2
                        : EApiRoutes.PUBLISHER_USERS
                ),
                {},
                publisherId ? { 'x-publishers': publisherId } : undefined
            );
        },
        enabled: canAccessUsers
    });

    const updatePublisher = useMutation(async (data: any) => {
        return await axios.put(getApiRouteOrMock(EApiRoutes.PUBLISHER), data);
    }, {});

    const updatePublisherSettings = useMutation(
        async (params: UpdateSettingsParams) => {
            const apiRoute = getApiRouteOrMock(EApiRoutes.PUBLISHER_SETTINGS);
            const { setting, isActive } = params;
            const url = `${apiRoute}/${setting}?isActive=${isActive}`;
            return await axios.put(url, {});
        },
        {}
    );

    return {
        getPublisher,
        getPublisherSettings,
        updatePublisher,
        getUsersByPublisher,
        updatePublisherSettings
    };
}
