import {
    ApplovinBundle,
    BagelcodeBundle,
    CandivoreBundle,
    CashmanCasinoBundle,
    ClassicBundle,
    CommunixBundle,
    JackpotSlotsBundle,
    LocoBingoBundle,
    MainSubBundle,
    Me2onBundle,
    PlaySimpleBundle
} from '@appcharge/shared-ui';

import { EBundlesInternalViewModel } from '../constants/enums';

const getBundleComponent = (internalModel: EBundlesInternalViewModel) => {
    switch (internalModel) {
        case EBundlesInternalViewModel.AK:
            return ClassicBundle;
        case EBundlesInternalViewModel.ME2ON:
            return Me2onBundle;
        case EBundlesInternalViewModel.MAIN_SUB:
            return MainSubBundle;
        case EBundlesInternalViewModel.MAIN_SUB_ALT:
            return ApplovinBundle;
        case EBundlesInternalViewModel.COMMUNIX:
            return CommunixBundle;
        case EBundlesInternalViewModel.MATCH:
            return CandivoreBundle;
        case EBundlesInternalViewModel.BAGELCODE:
            return BagelcodeBundle;
        case EBundlesInternalViewModel.LOCO_BINGO:
            return LocoBingoBundle;
        case EBundlesInternalViewModel.JACKPOT_SLOTS:
            return JackpotSlotsBundle;
        case EBundlesInternalViewModel.BIG_FISH_CASINO:
            return JackpotSlotsBundle;
        case EBundlesInternalViewModel.CASHMAN_CASINO:
            return CashmanCasinoBundle;
        case EBundlesInternalViewModel.PLAY_SIMPLE:
            return PlaySimpleBundle;

    }
};

export default getBundleComponent;
