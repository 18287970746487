import { useEffect } from 'react';

import { Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { IS_NO_IP_ONLY_HTTPS } from '../../../constants/constants';
import { required } from '../../../utils/errorsTextHelper';
import AcCard from '../../AcCard/AcCard';
import AcInput from '../../AcInput/AcInput';
import AcSelect from '../../AcSelect/AcSelect';
import ActionButton from '../../ActionButton/ActionButton';
import {
    IntegrationSettingsData,
    IntegrationSettingsProps
} from '../Settings.types';

import '../../../style/forms.scss';

export enum EEncryptionType {
    SYMMETRIC_ENCRYPTION = 'SymmetricEncryption',
    SIGNATURE_HASHING = 'SignatureHashing'
}

const IntegrationSettings = ({
    saveWebhooksDetails,
    data,
    integrationRefetch
}: IntegrationSettingsProps) => {
    useEffect(() => {
        return () => {
            integrationRefetch();
        };
    }, [integrationRefetch]);

    const webhooksSchema = yup.object().shape({
        ordersReportingApiUrl: yup
            .string()
            .required(required('Orders Reporting API URL'))
            .test(
                'Must be a valid URL, starting with https://, and not an IP address',
                'Must be a valid URL, starting with https://, and not an IP address',
                (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
            ),
        publisherToken: yup.string(),
        playerInfoSyncUrl: yup
            .string()
            .test(
                'Must be a valid URL, starting with https://, and not an IP address',
                'Must be a valid URL, starting with https://, and not an IP address',
                (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
            ),
        eventsWebhookUrl: yup
            .string()
            .test(
                'Must be a valid URL, starting with https://, and not an IP address',
                'Must be a valid URL, starting with https://, and not an IP address',
                (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
            ),
        playersAuthWebhook: yup
            .string()
            .test(
                'Must be a valid URL, starting with https://, and not an IP address',
                'Must be a valid URL, starting with https://, and not an IP address',
                (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
            ),
        apiUrlPrimaryKey: yup.string().required(required('Main Key')),
        apiUrlSecondaryKey: yup.string().required(required('Secondary Key'))
    });
    const webhooksForm = useFormik({
        initialValues: {
            publisherToken: data?.publisherToken || '',
            encryptionModel: data?.encryptionModel || '',
            playerInfoSyncUrl: data?.playerInfoSyncUrl || '',
            playersAuthWebhook: data?.playersAuthWebhook || '',
            ordersReportingApiUrl: data?.ordersReportingApiUrl || '',
            apiUrlPrimaryKey: data?.apiUrlPrimaryKey || '',
            apiUrlSecondaryKey: data?.apiUrlSecondaryKey || '',
            eventsWebhookUrl: data?.eventsWebhookUrl || ''
        },
        validationSchema: webhooksSchema,
        onSubmit: async (values) => {
            webhooksForm.resetForm({
                values: {
                    publisherToken: values.publisherToken,
                    playerInfoSyncUrl: values.playerInfoSyncUrl,
                    playersAuthWebhook: values.playersAuthWebhook,
                    encryptionModel: values.encryptionModel,
                    ordersReportingApiUrl: values.ordersReportingApiUrl,
                    apiUrlPrimaryKey: values.apiUrlPrimaryKey,
                    apiUrlSecondaryKey: values.apiUrlSecondaryKey,
                    eventsWebhookUrl: values.eventsWebhookUrl
                }
            });
            if (values) {
                saveWebhooksDetails(values as Partial<IntegrationSettingsData>);
            }
        }
    });

    return (
        <Grid container className="formContent">
            <Grid item xs={5} className="formContent-minWIdth">
                <Stack>
                    <AcCard stackContainer={false} title="Integration links">
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Orders Reporting API"
                                    name="ordersReportingApiUrl"
                                    value={
                                        webhooksForm.values
                                            .ordersReportingApiUrl
                                    }
                                    onChange={webhooksForm.handleChange}
                                    onBlur={webhooksForm.handleBlur}
                                    error={
                                        webhooksForm.touched
                                            .ordersReportingApiUrl &&
                                        Boolean(
                                            webhooksForm.errors
                                                .ordersReportingApiUrl
                                        )
                                    }
                                    helperText={
                                        webhooksForm.touched
                                            .ordersReportingApiUrl
                                            ? webhooksForm.errors.ordersReportingApiUrl?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Player Info Sync URL"
                                    name="playerInfoSyncUrl"
                                    value={
                                        webhooksForm.values.playerInfoSyncUrl
                                    }
                                    onChange={webhooksForm.handleChange}
                                    onBlur={webhooksForm.handleBlur}
                                    error={
                                        webhooksForm.touched
                                            .playerInfoSyncUrl &&
                                        Boolean(
                                            webhooksForm.errors
                                                .playerInfoSyncUrl
                                        )
                                    }
                                    helperText={
                                        webhooksForm.touched.playerInfoSyncUrl
                                            ? webhooksForm.errors.playerInfoSyncUrl?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Authentication Webhook"
                                    name="playersAuthWebhook"
                                    value={
                                        webhooksForm.values.playersAuthWebhook
                                    }
                                    onChange={webhooksForm.handleChange}
                                    onBlur={webhooksForm.handleBlur}
                                    error={
                                        webhooksForm.touched
                                            ?.playersAuthWebhook &&
                                        Boolean(
                                            webhooksForm.errors
                                                ?.playersAuthWebhook
                                        )
                                    }
                                    helperText={
                                        webhooksForm.touched?.playersAuthWebhook
                                            ? webhooksForm.errors?.playersAuthWebhook?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Events Webhook"
                                    name="eventsWebhookUrl"
                                    value={webhooksForm.values.eventsWebhookUrl}
                                    onChange={webhooksForm.handleChange}
                                    onBlur={webhooksForm.handleBlur}
                                    error={
                                        webhooksForm.touched
                                            ?.eventsWebhookUrl &&
                                        Boolean(
                                            webhooksForm.errors
                                                ?.eventsWebhookUrl
                                        )
                                    }
                                    helperText={
                                        webhooksForm.touched?.eventsWebhookUrl
                                            ? webhooksForm.errors?.eventsWebhookUrl?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcSelect
                                    items={[
                                        {
                                            value: EEncryptionType.SYMMETRIC_ENCRYPTION,
                                            content: 'Symmetric Encryption'
                                        },
                                        {
                                            value: EEncryptionType.SIGNATURE_HASHING,
                                            content: 'Signature Hashing'
                                        }
                                    ]}
                                    name="encryptionModel"
                                    value={webhooksForm.values.encryptionModel}
                                    header="Encryption Type"
                                    onChange={webhooksForm.handleChange}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Main Key"
                                    name="apiUrlPrimaryKey"
                                    value={webhooksForm.values.apiUrlPrimaryKey}
                                    onChange={webhooksForm.handleChange}
                                    onBlur={webhooksForm.handleBlur}
                                    error={
                                        webhooksForm.touched.apiUrlPrimaryKey &&
                                        Boolean(
                                            webhooksForm.errors.apiUrlPrimaryKey
                                        )
                                    }
                                    helperText={
                                        webhooksForm.touched.apiUrlPrimaryKey
                                            ? webhooksForm.errors.apiUrlPrimaryKey?.toString()
                                            : ''
                                    }
                                    type="password"
                                    enableCopy
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Checkout Public Key"
                                    name="checkoutPublicKey"
                                    value={data?.checkoutPublicKey}
                                    disabled={true}
                                    enableCopy
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    opacity:
                                        webhooksForm.values.encryptionModel ===
                                        EEncryptionType.SIGNATURE_HASHING
                                            ? 0.5
                                            : 1
                                }}
                            >
                                <AcInput
                                    header="Secondary Key"
                                    name="apiUrlSecondaryKey"
                                    value={
                                        webhooksForm.values.apiUrlSecondaryKey
                                    }
                                    onChange={webhooksForm.handleChange}
                                    onBlur={webhooksForm.handleBlur}
                                    error={
                                        webhooksForm.touched
                                            .apiUrlSecondaryKey &&
                                        Boolean(
                                            webhooksForm.errors
                                                .apiUrlSecondaryKey
                                        )
                                    }
                                    helperText={
                                        webhooksForm.touched.apiUrlSecondaryKey
                                            ? webhooksForm.errors.apiUrlSecondaryKey?.toString()
                                            : ''
                                    }
                                    disabled={
                                        webhooksForm.values.encryptionModel ===
                                        EEncryptionType.SIGNATURE_HASHING
                                    }
                                    type="password"
                                    enableCopy
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Publisher Token"
                                    name="publisherToken"
                                    value={webhooksForm.values.publisherToken}
                                    onChange={webhooksForm.handleChange}
                                    type="password"
                                    onBlur={webhooksForm.handleBlur}
                                    disabled
                                    error={
                                        webhooksForm.touched.publisherToken &&
                                        Boolean(
                                            webhooksForm.errors.publisherToken
                                        )
                                    }
                                    helperText={
                                        webhooksForm.touched.publisherToken
                                            ? webhooksForm.errors.publisherToken?.toString()
                                            : ''
                                    }
                                    enableCopy
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                </Stack>
                <Grid item mt={3} xs={6}>
                    <ActionButton
                        variant="outlined"
                        text="Update"
                        onClick={webhooksForm.submitForm}
                        disabled={
                            !webhooksForm.isValid ||
                            !webhooksForm.dirty ||
                            webhooksForm.isSubmitting
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IntegrationSettings;
