import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Background, BundlesLayout, DailyBonus } from '@appcharge/shared-ui';
import { EFontWeights } from '@appcharge/shared-ui/lib/components/Helper/enums';
import { Divider, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useImages from '../../../api/useImages';
import useOffersUI from '../../../api/useOffersUI';
import useTheme from '../../../api/useTheme';
import useUsers from '../../../api/useUsers';
import { OfferUI, User } from '../../../common/contracts';
import AcCard from '../../../components/AcCard/AcCard';
import AcContentWrapper from '../../../components/AcContentWrapper/AcContentWrapper';
import AcGradientInput from '../../../components/AcGradientInput/AcGradientInput';
import AcInput from '../../../components/AcInput/AcInput';
import AcSelect from '../../../components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from '../../../components/AcSelect/AcSelect.types';
import AcViewWrapper from '../../../components/AcViewWrapper/AcViewWrapper';
import PageTopBar from '../../../components/Topbar/PageTopBar';
import { NEW_VERSION } from '../../../constants/constants';
import {
    BannerAspectRatio,
    EAssetType,
    EBundlesViewModel,
    EFeatureFlag,
    ELocalStorageKeys,
    ENotificationType
} from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import { useUploads } from '../../../hooks/useUpload';
import { AuthSliceState } from '../../../store/store.types';
import { localStorageUtil } from '../../../utils/localStorageUtil';
import { permissionsUtil } from '../../../utils/permissionsUtil';
import { renderingUtil } from '../../../utils/renderingUtil';

import {
    OfferDesignTypeItem,
    OfferDesignTypes,
    OffersUIFormProps
} from './types';

import '../../../style/forms.scss';

const OffersUIForm = ({ edit = false, dup = false }: OffersUIFormProps) => {
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { offerUIId } = useParams();
    const navigate = useNavigate();
    const { fetchFeatureFlags } = useUsers(
        false,
        versionDetails,
        currentPublisherId,
        true
    );
    const { uploadImage, handleImageValue } = useUploads();
    const { getStoreTheme } = useTheme(currentPublisherId);
    const { addOfferUI, updateOfferUI, getOfferUI, getOffersUI } = useOffersUI(
        currentPublisherId,
        offerUIId
    );
    const addPictureInputRef = useRef<HTMLInputElement>(null);
    const [currentUploadingField, setCurrentUploadingField] = useState('');
    const { enqueueSnackbar } = useNotifications();
    const { getImages } = useImages(currentPublisherId);
    const [isNameUnique, setIsNameUnique] = useState(true);
    const [isExternalIdUnique, setIsExternalIdUnique] = useState(true);
    const { getStoreTheme: storeTheme } = useTheme(currentPublisherId);

    useEffect(() => {
        if (
            (versionDetails === NEW_VERSION &&
                offerUIId &&
                currentPublisherId) ||
            (versionDetails !== NEW_VERSION && offerUIId)
        ) {
            getOfferUI.refetch();
        }
    }, [offerUIId, currentPublisherId, versionDetails]);

    const viewModel = getStoreTheme?.data?.general
        ?.bundlesViewModel as EBundlesViewModel;

    const popupRatio = getStoreTheme?.data?.general?.popupRatio as BannerAspectRatio;

    let updateInfoSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        backgroundImage: yup.string().when('offerUiType', {
            is: (offerUiType: any) => offerUiType !== OfferDesignTypes.Bundle,
            then: yup.string().required('Background image is required'),
            otherwise: yup.string().notRequired()
        }),
        badgeCoverImage: yup.string().when('offerUiType', {
            is: (offerUiType: any) => offerUiType === OfferDesignTypes.PopUp,
            then: yup.string().notRequired()
        }),
        offerUiType: yup.string().required(),
        specialOffer: yup.object().when('offerUiType', {
            is: (offerUiType: any) => offerUiType !== OfferDesignTypes.Bundle,
            then: yup.object().shape({})
        }),
        borderWidth: yup
            .string()
            .matches(/^[0-9]*$/, 'Border width cannot contain letters')
    });

    const getInitialData = () => ({
            offerUiType: getOfferUI.data?.offerUiType || 'SpecialOffer',
            name: getOfferUI.data?.name
                ? `${getOfferUI.data?.name}${dup ? '_copy' : ''}`
                : '',
            description: 'N/A',
            backgroundImage: getOfferUI.data?.backgroundImage || '',
            ...(getOfferUI.data?.offerUiType === OfferDesignTypes.PopUp && getOfferUI.data?.badgeCoverImage
                ? { badgeCoverImage: getOfferUI.data.badgeCoverImage }
                : {}),
            externalId: getOfferUI.data?.externalId || '',
            specialOffer: {
                templateType:
                    getOfferUI.data?.specialOffer?.templateType || 'Single',
                presentOfferEndTimer: false,
                backgroundColor: getOfferUI.data?.specialOffer
                    ?.backgroundColor || {
                    colorOne: '#323281'
                },
                title: getOfferUI.data?.specialOffer?.title || '',
                fontSize: getOfferUI.data?.specialOffer?.fontSize || 24,
                fontWeight:
                    getOfferUI.data?.specialOffer?.fontWeight || 'normal',
                fontColor: {
                    colorOne:
                        getOfferUI.data?.specialOffer?.fontColor?.colorOne ||
                        '#ffffff'
                }
            },
            borderColor: {
                colorOne: getOfferUI.data?.borderColor?.colorOne || '#ffffff',
                colorTwo: getOfferUI.data?.borderColor?.colorTwo || '',
                direction: getOfferUI.data?.borderColor?.gradientDirection || ''
            },
            borderWidth: getOfferUI.data?.borderWidth ?? '2'
        }),
        [initialValues, setInitialValues] = useState(getInitialData());

    useEffect(() => {
        if (getOfferUI.data || storeTheme.data) {
            setInitialValues(getInitialData());
        }
    }, [getOfferUI.data, storeTheme.data]);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        submitForm,
        isValid,
        setFieldValue
    } = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: updateInfoSchema,
        onSubmit: async (values) => {
            const newOfferUI: OfferUI = {
                active: true,
                offerUiType: values.offerUiType,
                name: values.name,
                description: 'description',
                backgroundImage: values.backgroundImage,
                ...(values.offerUiType === OfferDesignTypes.PopUp && values.badgeCoverImage
                    ? { badgeCoverImage: values.badgeCoverImage }
                    : {}),
                borderColor: values.borderColor,
                borderWidth: Number(values.borderWidth)
            };
            newOfferUI.externalId = values?.externalId || null;

            if (
                values?.offerUiType !== OfferDesignTypes.Bundle ||
                values.specialOffer.backgroundColor !==
                    getInitialData().specialOffer.backgroundColor
            ) {
                newOfferUI.specialOffer = {
                    templateType: values.specialOffer.templateType,
                    presentOfferEndTimer:
                        values.specialOffer.presentOfferEndTimer,
                    title: values.specialOffer.title,
                    fontSize: Number(values.specialOffer.fontSize),
                    fontWeight: values.specialOffer.fontWeight as EFontWeights,
                    fontColor: values.specialOffer.fontColor || {
                        colorOne: '#fff'
                    },
                    backgroundColor: values.specialOffer.backgroundColor
                };
            }

            if (newOfferUI.offerUiType === OfferDesignTypes.PopUp) {
                newOfferUI.offerUiSubType = 'DailyBonus';
            }

            if (edit && offerUIId && !dup) {
                updateOfferUI.mutate(
                    { offerId: offerUIId, form: newOfferUI },
                    {
                        onSuccess: () => {
                            enqueueSnackbar(
                                'Offer Design added successfully',
                                ENotificationType.SUCCESS
                            );
                            navigate('../');
                        },
                        onError: () => {
                            enqueueSnackbar(
                                'Error editing Offer Design',
                                ENotificationType.ERROR
                            );
                        }
                    }
                );
            } else {
                addOfferUI.mutate(newOfferUI, {
                    onSuccess: () => {
                        enqueueSnackbar(
                            'Offer Design added successfully',
                            ENotificationType.SUCCESS
                        );
                        navigate('../');
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'Error adding Offer Design',
                            ENotificationType.ERROR
                        );
                    }
                });
            }
        }
    });

    useEffect(() => {
        if (!edit && getOffersUI.data?.result) {
            const isUnique = !getOffersUI.data.result.some(
                (offerUI: OfferUI) => offerUI.name === values.name
            );
            setIsNameUnique(isUnique);
        }
    }, [getOffersUI.data, edit, values.name]);

    useEffect(() => {
        if (!edit && getOffersUI.data?.result) {
            const isUnique = !getOffersUI.data.result.some(
                (offerUI: OfferUI) => offerUI.externalId === values.externalId
            );
            setIsExternalIdUnique(isUnique);
        }
    }, [getOffersUI.data, edit, values.externalId]);

    let offerDesignTypesItems: OfferDesignTypeItem[] = [
        {
            content: 'Special Offer',
            key: OfferDesignTypes.SpecialOffer,
            value: OfferDesignTypes.SpecialOffer
        },
        {
            content: 'Bundle',
            key: OfferDesignTypes.Bundle,
            value: OfferDesignTypes.Bundle
        },
        {
            content: 'Pop Up',
            key: OfferDesignTypes.PopUp,
            value: OfferDesignTypes.PopUp
        }
    ];

    offerDesignTypesItems = fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_POPUP
    ]
        ? offerDesignTypesItems
        : offerDesignTypesItems.filter((item) => item.key !== 'PopUp');

    const OfferDesignTooltipTypes: Record<any, any> = {
        [OfferDesignTypes.SpecialOffer]: EAssetType.BG_SPECIAL_OFFER,
        [OfferDesignTypes.PopUp]: {
            [BannerAspectRatio.SQUARE]: EAssetType.BG_POPUP_SQUARE,
            [BannerAspectRatio.TWO_ON_THREE]: EAssetType.BG_POPUP_TWO_ON_THREE,
        },
        [OfferDesignTypes.Bundle]: {
            [EBundlesViewModel.LINEAR]: EAssetType.BG_BUNDLE_LINEAR,
            [EBundlesViewModel.TWO_IN_ONE]: EAssetType.BG_BUNDLE_TWO_IN_ONE,
            [EBundlesViewModel.THREE_IN_ONE]: EAssetType.BG_BUNDLE_THREE_IN_ONE,

        }
    }

    const getAssetUploadType = (offerUiType: OfferDesignTypes) => {
        if (offerUiType === OfferDesignTypes.Bundle) {
            return OfferDesignTooltipTypes[values.offerUiType][viewModel]
        }
        if (offerUiType === OfferDesignTypes.PopUp) {
            return OfferDesignTooltipTypes[values.offerUiType][popupRatio]
        }
        return OfferDesignTooltipTypes[values.offerUiType]
    }

    const hasFeatureFlagDailyBonusBadge = fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_DAILY_BONUS_BADGE
        ];

    return (
        <AcViewWrapper
            header={
                <PageTopBar
                    disable={false}
                    headline={`${edit ? 'Edit' : 'New'} Offer Design ${
                        edit
                            ? `(${
                                  getOfferUI.data ? getOfferUI.data._id : '...'
                              })`
                            : ''
                    }`}
                    buttons={[
                        {
                            text: 'Save',
                            action: submitForm,
                            disabled: !permissionsUtil.canUserEdit() || !isValid
                        }
                    ]}
                    backFunction={() => navigate('../')}
                />
            }
        >
            <AcContentWrapper className="formContent">
                {!getImages.isLoading && getImages.data && (
                    <Grid container pt={'24px'}>
                        <Grid item xs={5}>
                            <Stack>
                                <AcCard
                                    stackContainer={false}
                                    title="Offer Settings"
                                    padded={false}
                                    className="offersUIFormCard"
                                >
                                    <Grid container columnSpacing={{ xs: 1 }}>
                                        <Grid item xs={6}>
                                            <AcInput
                                                required
                                                header="Name"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    (touched.name &&
                                                        Boolean(errors.name)) ||
                                                    !isNameUnique
                                                }
                                                helperText={
                                                    (touched.name
                                                        ? errors.name?.toString()
                                                        : '') ||
                                                    (!isNameUnique
                                                        ? 'Name already exists'
                                                        : '')
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AcSelect
                                                header="Type"
                                                name="offerUiType"
                                                value={values.offerUiType}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                items={offerDesignTypesItems}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        mt={2}
                                        columnSpacing={{ xs: 1 }}
                                    >
                                        <Grid item xs={6}>
                                            <AcInput
                                                header="External ID"
                                                name="externalId"
                                                value={values.externalId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    (touched.externalId &&
                                                        Boolean(
                                                            errors.externalId
                                                        )) ||
                                                    !isExternalIdUnique
                                                }
                                                helperText={
                                                    touched.externalId
                                                        ? errors.externalId?.toString()
                                                        : '' ||
                                                          (!isExternalIdUnique
                                                              ? 'External ID already exists'
                                                              : '')
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </AcCard>
                                <Divider />
                                <AcCard
                                    stackContainer={false}
                                    title="Offer Asset"
                                    description="Customize your store by adding brand assets that will be displayed across your web store, with the option to change them anytime."
                                    padded={false}
                                    className="offersUIFormCard"
                                >
                                    <Grid container columnSpacing={{ xs: 1 }}>
                                        <Grid item xs={6}>
                                            <input
                                                style={{ display: 'none' }}
                                                type="file"
                                                onChange={(e) =>
                                                    uploadImage(
                                                        e,
                                                        addPictureInputRef.current as HTMLInputElement,
                                                        'backgroundImage',
                                                        setFieldValue,
                                                        setCurrentUploadingField,
                                                        currentUploadingField
                                                    )
                                                }
                                                ref={addPictureInputRef}
                                                accept="image/*"
                                            />
                                            <AcSelect
                                                required={
                                                    values.offerUiType !==
                                                    OfferDesignTypes.Bundle
                                                }
                                                header="Background Image"
                                                name="backgroundImage"
                                                value={handleImageValue(
                                                    'backgroundImage',
                                                    values.backgroundImage,
                                                    'https://media.appcharge.com/defaults/background.png',
                                                    getImages,
                                                    setFieldValue
                                                )}
                                                uploadConfig={{
                                                    onUploadSuccess: async (
                                                        uploadData: any
                                                    ) => {
                                                        await getImages.refetch();
                                                        setFieldValue(
                                                            'backgroundImage',
                                                            uploadData!.data.url
                                                        );
                                                    },
                                                    uploadType: EAssetType.BG_BUNDLE
                                                }}
                                                renderType={
                                                    EAcSelectItemRenderType.IMAGE
                                                }
                                                items={[
                                                    {
                                                        content:
                                                            'Default Appcharge background image',
                                                        key: 'https://media.appcharge.com/defaults/background.png',
                                                        value: 'https://media.appcharge.com/defaults/background.png',
                                                        url: 'https://media.appcharge.com/defaults/background.png',
                                                        name: 'Default Appcharge background image'
                                                    },
                                                    ...getImages.data
                                                        .filter(
                                                            (p: any) =>
                                                                p.type ===
                                                                EAssetType.BG_BUNDLE
                                                        )
                                                        .map((picture: any) => {
                                                            return {
                                                                content:
                                                                    picture.name,
                                                                key: picture.url,
                                                                value: picture.url,
                                                                url: picture.url,
                                                                name: picture.name
                                                            };
                                                        })
                                                ]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onClear={
                                                    values.offerUiType ===
                                                    OfferDesignTypes.Bundle
                                                        ? () => {
                                                              setFieldValue(
                                                                  'backgroundImage',
                                                                  ''
                                                              );
                                                          }
                                                        : undefined
                                                }
                                                error={
                                                    touched.backgroundImage &&
                                                    Boolean(
                                                        errors.backgroundImage
                                                    )
                                                }
                                                helperText={
                                                    touched.backgroundImage ||
                                                    dup
                                                        ? errors.backgroundImage?.toString()
                                                        : ''
                                                }
                                                imgHeight={'32'}
                                            />
                                        </Grid>
                                        {(values.offerUiType === OfferDesignTypes.PopUp && hasFeatureFlagDailyBonusBadge) && (
                                            <Grid item xs={6}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    onChange={(e) =>
                                                        uploadImage(
                                                            e,
                                                            addPictureInputRef.current as HTMLInputElement,
                                                            'badgeCoverImage',
                                                            setFieldValue,
                                                            setCurrentUploadingField,
                                                            currentUploadingField
                                                        )
                                                    }
                                                    ref={addPictureInputRef}
                                                    accept="image/*"
                                                />
                                                <AcSelect
                                                    header="Badge Image"
                                                    name="badgeCoverImage"
                                                    value={handleImageValue(
                                                        'badgeCoverImage',
                                                        values.badgeCoverImage,
                                                        '',
                                                        getImages,
                                                        setFieldValue
                                                    )}
                                                    uploadConfig={{
                                                        onUploadSuccess: async (uploadData: any) => {
                                                            await getImages.refetch();
                                                            setFieldValue('badgeCoverImage', uploadData!.data.url);
                                                        },
                                                        uploadType: EAssetType.BG_BUNDLE
                                                    }}
                                                    renderType={EAcSelectItemRenderType.IMAGE}
                                                    items={[
                                                        {
                                                            content: 'Default Appcharge badge image',
                                                            key: 'https://media.appcharge.com/defaults/background.png',
                                                            value: 'https://media.appcharge.com/defaults/background.png',
                                                            url: 'https://media.appcharge.com/defaults/background.png',
                                                            name: 'Default Appcharge badge image'
                                                        },
                                                        ...getImages.data
                                                            .filter((p: any) => p.type === EAssetType.BG_BUNDLE)
                                                            .map((picture: any) => ({
                                                                content: picture.name,
                                                                key: picture.url,
                                                                value: picture.url,
                                                                url: picture.url,
                                                                name: picture.name
                                                            }))
                                                    ]}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={touched.badgeCoverImage && Boolean(errors.badgeCoverImage)}
                                                    helperText={
                                                        touched.badgeCoverImage || dup
                                                            ? errors.badgeCoverImage?.toString()
                                                            : ''
                                                    }
                                                    imgHeight={'32'}
                                                />
                                            </Grid>
                                        )}
                                        {(values.offerUiType === 'Bundle' ||
                                            getStoreTheme?.data?.general
                                                .specialOffersInternalViewModel !==
                                                'hug') && (
                                            <Grid item xs={6}>
                                                <AcGradientInput
                                                    header="BG Color"
                                                    name="specialOffer.backgroundColor"
                                                    defaultValue={
                                                        values.specialOffer
                                                            .backgroundColor
                                                    }
                                                    onChange={handleChange}
                                                    setValue={setFieldValue}
                                                    onBlur={handleBlur}
                                                    error={
                                                        touched.specialOffer
                                                            ?.backgroundColor &&
                                                        Boolean(
                                                            errors.specialOffer
                                                                ?.backgroundColor
                                                        )
                                                    }
                                                    helperText={
                                                        touched.specialOffer
                                                            ?.backgroundColor
                                                            ? errors.specialOffer?.backgroundColor?.toString()
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </AcCard>
                                {values.offerUiType ===
                                    OfferDesignTypes.PopUp && (
                                    <>
                                        <Divider />
                                        <AcCard title="Border">
                                            <Grid
                                                container
                                                columnSpacing={{ xs: 1 }}
                                            >
                                                <Grid item xs={6}>
                                                    <AcGradientInput
                                                        header="Fill Color"
                                                        name="borderColor"
                                                        defaultValue={
                                                            values.borderColor
                                                        }
                                                        onChange={handleChange}
                                                        setValue={setFieldValue}
                                                        onBlur={handleBlur}
                                                        imgWidth={'32px'}
                                                        imgHeight={'32px'}
                                                        roundedBorders={false}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <AcInput
                                                        header="Width (in px)"
                                                        name="borderWidth"
                                                        value={
                                                            values.borderWidth ||
                                                            '0'
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.borderWidth &&
                                                            Boolean(
                                                                errors?.borderWidth
                                                            )
                                                        }
                                                        helperText={
                                                            touched?.borderWidth
                                                                ? errors?.borderWidth?.toString()
                                                                : ''
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AcCard>
                                    </>
                                )}
                                <Divider />
                                <>
                                    {values.offerUiType !==
                                        OfferDesignTypes.Bundle && (
                                        <AcCard
                                            title="Title"
                                            stackContainer={false}
                                            padded={false}
                                            className="offersUIFormTextCard"
                                        >
                                            <Grid
                                                container
                                                columnSpacing={{ xs: 1 }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className="offersUIFormTextCard-offerContent"
                                                >
                                                    <AcInput
                                                        header="Text"
                                                        name="specialOffer.title"
                                                        value={
                                                            values.specialOffer
                                                                .title
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={4}
                                                    sx={{
                                                        paddingTop: '12px'
                                                    }}
                                                >
                                                    <AcGradientInput
                                                        header="Color"
                                                        name="specialOffer.fontColor"
                                                        defaultValue={
                                                            values.specialOffer
                                                                ?.fontColor
                                                        }
                                                        onChange={handleChange}
                                                        setValue={setFieldValue}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.specialOffer
                                                                ?.fontColor &&
                                                            Boolean(
                                                                errors
                                                                    .specialOffer
                                                                    ?.fontColor
                                                            )
                                                        }
                                                        helperText={
                                                            touched.specialOffer
                                                                ?.fontColor
                                                                ? errors.specialOffer?.fontColor?.toString()
                                                                : ''
                                                        }
                                                        imgWidth={'32px'}
                                                        imgHeight={'32px'}
                                                        roundedBorders={false}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={4}
                                                    sx={{
                                                        paddingTop: '12px'
                                                    }}
                                                >
                                                    <AcInput
                                                        header="Size"
                                                        name="specialOffer.fontSize"
                                                        value={
                                                            values.specialOffer
                                                                ?.fontSize
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={4}
                                                    sx={{
                                                        paddingTop: '12px'
                                                    }}
                                                >
                                                    <AcSelect
                                                        header="Weight"
                                                        name="specialOffer.fontWeight"
                                                        defaultValue="normal"
                                                        value={
                                                            values.specialOffer
                                                                ?.fontWeight
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.specialOffer
                                                                ?.fontWeight &&
                                                            Boolean(
                                                                errors
                                                                    .specialOffer
                                                                    ?.fontWeight
                                                            )
                                                        }
                                                        items={[
                                                            {
                                                                content:
                                                                    'regular',
                                                                key: 'normal',
                                                                value: 'normal'
                                                            },
                                                            {
                                                                content: 'bold',
                                                                key: 'bold',
                                                                value: 'bold'
                                                            },
                                                            {
                                                                content:
                                                                    'bolder',
                                                                key: 'bolder',
                                                                value: 'bolder'
                                                            }
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AcCard>
                                    )}
                                </>
                            </Stack>
                        </Grid>
                        <Grid item xs={7} className="iphone-mock-wrapper">
                            {!getStoreTheme.isLoading && (
                                <div className="iphone-mock" id={'iphone-mock'}>
                                    <Background
                                        backgroundImageMobile={
                                            getStoreTheme.data.general
                                                .backgroundImageMobile
                                        }
                                        backgroundImageDesktop={
                                            getStoreTheme.data.general
                                                .backgroundImageDesktop
                                        }
                                        width="100%"
                                        height="100%"
                                        position="absolute"
                                    />
                                    {values.offerUiType === 'Bundle' && (
                                        <div className="bundles-wrapper">
                                            <BundlesLayout
                                                bundlesViewModel={viewModel}
                                                isPreview={true}
                                            >
                                                {Array.from(
                                                    {
                                                        length:
                                                            viewModel ===
                                                            EBundlesViewModel.TWO_IN_ONE
                                                                ? 2
                                                                : viewModel ===
                                                                    EBundlesViewModel.THREE_IN_ONE
                                                                  ? 3
                                                                  : 1
                                                    },
                                                    () => null
                                                ).map((_, index) =>
                                                    renderingUtil.renderBundleByInternalModel(
                                                        getStoreTheme.data
                                                            .general
                                                            .bundlesInternalViewModel,
                                                        {
                                                            index: index,
                                                            backgroundSrc:
                                                                values.backgroundImage,
                                                            backgroundColor:
                                                                values
                                                                    .specialOffer
                                                                    .backgroundColor,
                                                            bundleBorderColor:
                                                                getStoreTheme
                                                                    .data
                                                                    .storeScreen
                                                                    .bundleBorderColor,
                                                            productList: [],
                                                            bundleButtonColor:
                                                                getStoreTheme
                                                                    .data
                                                                    .general
                                                                    .buttonColor,
                                                            bundleButtonTextColor:
                                                                getStoreTheme
                                                                    .data
                                                                    .general
                                                                    .buttonTextColor,
                                                            fontFamily:
                                                                getStoreTheme
                                                                    .data
                                                                    .general
                                                                    .font,
                                                            price: {
                                                                price: 0,
                                                                isCents: true,
                                                                fractionalSeparator:
                                                                    '.',
                                                                milSeparator:
                                                                    ',',
                                                                spacing: false,
                                                                symbolPosition:
                                                                    'left',
                                                                currencySymbol:
                                                                    '$',
                                                                currencyCode: ''
                                                            }
                                                        }
                                                    )
                                                )}
                                            </BundlesLayout>
                                        </div>
                                    )}
                                    {values.offerUiType === 'SpecialOffer' && (
                                        <div className="bundles-wrapper">
                                            {renderingUtil.renderSpecialOfferByInternalModel(
                                                getStoreTheme.data.general
                                                    .specialOffersInternalViewModel,
                                                {
                                                    text: values.specialOffer
                                                        .title,
                                                    textColor:
                                                        values.specialOffer
                                                            .fontColor,
                                                    borderColor:
                                                        values.borderColor,
                                                    buttonColor:
                                                        getStoreTheme.data
                                                            .general
                                                            .buttonColor,
                                                    backgroundImage:
                                                        values.backgroundImage,
                                                    design: 'match',
                                                    price: {
                                                        price: 0,
                                                        isCents: true,
                                                        fractionalSeparator:
                                                            '.',
                                                        milSeparator: ',',
                                                        spacing: true,
                                                        symbolPosition:
                                                            'before',
                                                        currencySymbol: '$',
                                                        currencyCode: ''
                                                    },
                                                    productsList: [],
                                                    endTime: 0,
                                                    fontSize: 0,
                                                    fontWeight:
                                                        'normal' as EFontWeights,
                                                    endTimeActive: false,
                                                    selectOffer: () => {},
                                                    backgroundColor:
                                                        values.specialOffer
                                                            .backgroundColor
                                                }
                                            )}
                                        </div>
                                    )}
                                    {values.offerUiType === 'PopUp' && (
                                        <DailyBonus
                                            isPreview
                                            title={values.specialOffer.title}
                                            modalContainer={() =>
                                                document.getElementById(
                                                    'iphone-mock'
                                                )
                                            }
                                            backgroundColor={
                                                values.specialOffer
                                                    .backgroundColor.colorOne
                                            }
                                            backgroundImage={
                                                values.backgroundImage
                                            }
                                            titleWeight={
                                                values.specialOffer
                                                    .fontWeight as any
                                            }
                                            titleSize={
                                                values.specialOffer.fontSize +
                                                'px'
                                            }
                                            titleColor={
                                                values.specialOffer.fontColor
                                                    .colorOne
                                            }
                                            onCollect={async () => true}
                                            borderColor={values.borderColor}
                                            borderWidth={values.borderWidth}
                                        />
                                    )}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                )}
            </AcContentWrapper>
        </AcViewWrapper>
    );
};

export default OffersUIForm;
