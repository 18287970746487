import { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig,getApiRouteOrMock } from './api.utils';

export default function useMetadata(currentPublisherId: string | null, offerId?: string, hasFeatureFlagMetadata?: boolean) {
    const axios = useAxios();
    const [isOfferMetadataEnabled, setOfferMetadataEnabled] = useState(false);

    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    useEffect(() => {
        if (hasFeatureFlagMetadata) {
            setOfferMetadataEnabled(true);
        }
    }, [hasFeatureFlagMetadata]);

    const getPublisherMetadata = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.METADATA],
        queryFn: async () => {
            return await axios.get(getApiRouteOrMock(EApiRoutes.METADATA));
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const getOfferMetadata = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.OFFERS],
        queryFn: async () => {
            return hasFeatureFlagMetadata
                ? await axios.get(`${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerId}/metadata`)
                : [];
        },
        enabled: isOfferMetadataEnabled
    });

    const updateOfferMetadata = useMutation(
        async ({
            metadata,
            offerId
        }: {
            metadata: string;
            offerId: string;
        }) => {
            return axios.put(
                `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerId}/metadata/${metadata}`,
                {}
            );
        },
        {}
    );

    const deleteOfferMetadata = useMutation(
        async ({
            metadata,
            offerId
        }: {
            metadata: string;
            offerId: string;
        }) => {
            return axios.del(
                `${getApiRouteOrMock(EApiRoutes.OFFERS)}/${offerId}/metadata/${metadata}`,
                {}
            );
        },
        {}
    );

    return {
        getPublisherMetadata,
        getOfferMetadata,
        updateOfferMetadata,
        deleteOfferMetadata
    };
}
