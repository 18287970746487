import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { uiActions } from '../store/uiSlice';

export const useUnsavedChanges = ({ dirty }: { dirty: boolean }) => {
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     if (dirty) {
    //         dispatch(uiActions.setUnsavedChanges(true));
    //     } else {
    //         dispatch(uiActions.setUnsavedChanges(false));
    //     }
    // }, [dirty, dispatch]);

    return;
};
