import { useMutation, useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { IPermissions } from '../common/permissions.types';
import { LoginData } from '../components/LoginModal/LoginModal.types';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { useLogin } from '../hooks/useLogin';
import { localStorageUtil } from '../utils/localStorageUtil';
import { permissionsUtil } from '../utils/permissionsUtil';
import {
    ResetPasswordData,
    UpdateInfoData,
    UpdatePasswordData
} from '../views/Profile/ProfileView.types';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useUsers(
    shouldFetchUsersData = false,
    version?: number,
    publisherId?: string | null,
    enableFeatureFlags = true
) {
    const axios = useAxios();
    const loginUtils = useLogin();
    const { isLoggedIn } = useLogin();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const canAccessUsers = publisherId
        ? !!permissionsUtil.canAccessUsers(
              localStorageUtil.get<IPermissions>(
                  ELocalStorageKeys.PERMISSIONS
              ) || {},
              publisherId
          )
        : shouldFetchUsersData;

    const getUsers = useQuery<any, Error>({
        ...fetchConfig.general,
        queryKey: [
            version === NEW_VERSION
                ? EApiRoutes.USERS_NEW_VERSION
                : EApiRoutes.USERS
        ],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(
                    version === NEW_VERSION
                        ? EApiRoutes.USERS_NEW_VERSION
                        : EApiRoutes.USERS
                ),
                publisherId
                    ? { headers: { 'x-publishers': publisherId } }
                    : undefined
            );
        },
        enabled: canAccessUsers
    });

    const updateUser = useMutation(async (data: Partial<UpdateInfoData>) => {
        return await axios.put(
            getApiRouteOrMock(
                version === NEW_VERSION
                    ? EApiRoutes.USERS_NEW_VERSION
                    : EApiRoutes.USERS
            ),
            data
        );
    }, {});

    const updateRole = useMutation(async (data: Partial<UpdateInfoData>) => {
        if (!data.userId || !data.role) {
            throw new Error(
                'UserID and role are required for updating the user role.'
            );
        }
        return await axios.put(
            getApiRouteOrMock(EApiRoutes.UPDATE_USER_ROLE) + `/${data.userId}`,
            { role: data.role }
        );
    }, {});

    const updateRoleV2 = useMutation(
        async (data: { userId: string; roleId: string }) => {
            if (!data.userId || !data.roleId) {
                throw new Error(
                    'UserID and role are required for updating the user role.'
                );
            }
            if (version === NEW_VERSION) {
                return await axios.put(
                    getApiRouteOrMock(EApiRoutes.UPDATE_USER_ROLE_NEW_VERSION) +
                        `/${data.userId}/role`,
                    { roleId: data.roleId }
                );
            }
        },
        {}
    );

    const deleteUser = useMutation(async (id: string) => {
        const deleteUserRoute =
            version === NEW_VERSION
                ? EApiRoutes.DELETE_USER_NEW_VERSION
                : EApiRoutes.DELETE_USER;
        return await axios.del(
            `${getApiRouteOrMock(deleteUserRoute)}/${id}`,
            {},
            publisherId ? { 'x-publishers': publisherId } : undefined
        );
    }, {});

    const addUser = useMutation(async (userForm: any) => {
        const addUserRoute =
            version === NEW_VERSION
                ? EApiRoutes.ADD_USER_NEW_VERSION
                : EApiRoutes.USERS;
        return await axios.post(
            getApiRouteOrMock(addUserRoute),
            userForm,
            publisherId ? { 'x-publishers': publisherId } : undefined
        );
    }, {});

    const resetPassword = useMutation(async (data: ResetPasswordData) => {
        return await axios.put(getApiRouteOrMock(EApiRoutes.RESET_USER), data);
    }, {});

    const updatePassword = useMutation(async (data: UpdatePasswordData) => {
        return await axios.put(
            getApiRouteOrMock(EApiRoutes.UPDATE_USER_PASSWORD),
            data
        );
    }, {});

    const forgotPassword = useMutation(async (email: string) => {
        return await axios.put(
            getApiRouteOrMock(EApiRoutes.FORGOT_USER_PASSWORD),
            { email }
        );
    }, {});

    const login = useMutation(async (loginData: LoginData) => {
        const response = await axios.post(
            getApiRouteOrMock(EApiRoutes.LOGIN_USER),
            loginData
        );
        return response.data;
    }, {});

    const loginWithGoogle = useMutation(async (loginData: LoginData) => {
        const response = await axios.post(
            getApiRouteOrMock(EApiRoutes.LOGIN_USER_GOOGLE),
            loginData
        );
        return response.data;
    }, {});

    const fetchFeatureFlags = useQuery({
        refetchOnWindowFocus: false,
        refetchInterval: 300000,
        refetchIntervalInBackground: false,
        retry: false,
        queryKey: [EQueryKeys.FEATURE_FLAGS],
        queryFn: async () => {
            const response = await axios.get(
                getApiRouteOrMock(EApiRoutes.FEATURE_FLAGS),
                {},
                publisherId ? { 'x-publishers': publisherId } : undefined
            );
            return response;
        },
        enabled:
            versionDetails === NEW_VERSION
                ? enableFeatureFlags && !!publisherId
                : (isLoggedIn() && enableFeatureFlags) || false
    });

    const logout = useMutation(async () => {
        await axios.post(getApiRouteOrMock(EApiRoutes.LOGOUT_USER), {});
        loginUtils.logout();
    }, {});

    return {
        login,
        loginWithGoogle,
        logout,
        getUsers,
        addUser,
        updateUser,
        updateRole,
        updateRoleV2,
        deleteUser,
        resetPassword,
        updatePassword,
        forgotPassword,
        fetchFeatureFlags
    };
}
