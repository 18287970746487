import { useSelector } from 'react-redux';

import useSegments from '../../api/useSegments';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { AuthSliceState } from '../../store/store.types';

const SegmentsTable = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { isLoading, data, isError } = useSegments(currentPublisherId).getSegments;
    const columns = [
        {
            field: '_id',
            maxWidth: 30,
            headerName: '',
            disableReorder: true,
            flex: 1
        },
        {
            field: 'name',
            minWidth: 200,
            headerName: 'Name',
            disableReorder: true,
            flex: 1
        }
    ];
    return (
        <AcViewWrapper
            header={
                <PageTopBar disable={false} headline="Segments" buttons={[]} />
            }
        >
            <AcContentWrapper>
                <div className="list">
                    {!isLoading && (data || data === '') && (
                        <DataTable
                            columns={columns}
                            rows={
                                data
                                    ? data.map((segment: string, i: number) => {
                                          return {
                                              _id: i,
                                              name: segment
                                          };
                                      })
                                    : []
                            }
                            defaultHiddenFields={['_id']}
                            loading={isLoading}
                            error={isError}
                            onNoData={
                                <FirstActionModal
                                    headline="No segments to display"
                                    text="Please connect your segmetations"
                                />
                            }
                        />
                    )}
                </div>
            </AcContentWrapper>
        </AcViewWrapper>
    );
};

export default SegmentsTable;
