import Decimal from 'decimal.js';

import { EMetric } from '../../api/useAnalytics';

export interface BaseStatisticValue {
    value: number;
    date: string;
}

export type CurrencyValue = BaseStatisticValue & {
    value: Decimal;
};

export enum EStatisticType {
    CURRENCY = 'currency',
    NUMBER = 'number',
    PRECISE_NUMBER = 'precise_number',
    PERCENTAGE = 'percentage',
    AVERAGE = 'average',
    AMOUNT_AVERAGE = 'amount_average'
}

export interface BaseStatistic {
    name: string;
    values: BaseStatisticValue[];
    key?: EMetric;
}

export type CurrencyStatistic = BaseStatistic & {
    type: EStatisticType.CURRENCY;
    values: CurrencyValue[];
};
export type NumberStatistic = BaseStatistic & {
    type: EStatisticType.NUMBER;
};

export type Statistic = NumberStatistic | CurrencyStatistic;
