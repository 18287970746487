import { useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Background, Login } from '@appcharge/shared-ui';
import { ELoginMode } from '@appcharge/shared-ui/lib/components/Helper/enums';
import { Divider, Grid, Stack, Typography } from '@mui/material';

import useImages from '../../api/useImages';
import { EAssetType, ELogoSize, EStyleProperty } from '../../constants/enums';
import { useUploads } from '../../hooks/useUpload';
import { AuthSliceState } from '../../store/store.types';
import { getStyledSelectItem } from '../../utils/getStyledSelectItem';
import AcCard from '../AcCard/AcCard';
import AcGradientInput from '../AcGradientInput/AcGradientInput';
import AcInput from '../AcInput/AcInput';
import AcSelect from '../AcSelect/AcSelect';

import { getFonts } from './StoreTheme/utils';
import { ThemeTabProps } from './theme.types';

import '../../style/forms.scss';

const LoginTheme = ({
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    integration
}: ThemeTabProps) => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const pictures = useImages(currentPublisherId).getImages;
    const addPictureInputRef = useRef<HTMLInputElement>(null);
    const [currentUploadingField, setCurrentUploadingField] = useState('');
    const { uploadImage, handleImageValue } = useUploads();

    return (
        <Grid container className="formContent">
            <Grid item xs={5}>
                <Stack>
                    <AcCard
                        stackContainer={false}
                        title="Now Say 'Hi'"
                        description="Greet your players with a welcome message. This will be your players’ first encounter with your store, so be nice!"
                    >
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={12}>
                                <AcInput
                                    header="Text"
                                    name="login.text"
                                    value={values.login.text}
                                    tooltip="This will be the text that welcomes your users"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login?.text &&
                                        Boolean(errors.login?.text)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcGradientInput
                                    header="Color"
                                    name="login.textColor"
                                    defaultValue={{
                                        colorOne: values.login.textColor
                                    }}
                                    setValue={setFieldValue}
                                    onBlur={handleBlur}
                                    onlySolid
                                    outputAsSingleColor
                                    error={
                                        touched.login?.textColor &&
                                        Boolean(errors.login?.textColor)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcInput
                                    header="Size (px)"
                                    name="login.textSize"
                                    type="number"
                                    inputProps={{
                                        max: 32,
                                        min: 16
                                    }}
                                    value={values.login.textSize}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login?.textSize &&
                                        Boolean(errors.login?.textSize)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    header="Weight"
                                    name="login.textWeight"
                                    defaultValue="normal"
                                    value={values.login.textWeight}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login?.textWeight &&
                                        Boolean(errors.login?.textWeight)
                                    }
                                    items={[
                                        {
                                            content: 'Regular',
                                            key: 'regular',
                                            value: '300',
                                            renderFunction: () =>
                                                getStyledSelectItem(
                                                    'regular',
                                                    EStyleProperty.fontWeight
                                                )
                                        },
                                        {
                                            content: 'Medium',
                                            key: 'medium',
                                            value: '500',
                                            renderFunction: () =>
                                                getStyledSelectItem(
                                                    'medium',
                                                    EStyleProperty.fontWeight
                                                )
                                        },
                                        {
                                            content: 'Bold',
                                            key: 'bold',
                                            value: '700',
                                            renderFunction: () =>
                                                getStyledSelectItem(
                                                    'bold',
                                                    EStyleProperty.fontWeight
                                                )
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                    <Divider />
                    <AcCard stackContainer={false} title="Font">
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={12}>
                                <AcSelect
                                    header="Font"
                                    name="login.font"
                                    value={values.login.font}
                                    items={getFonts()}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login?.font &&
                                        Boolean(errors.login?.font)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                    <Divider />
                    <AcCard
                        stackContainer={false}
                        title="Login Assets"
                        description="The brand assets you’ll add here will appear only in this screen. You can change them any time."
                    >
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={6}>
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={(e) =>
                                        uploadImage(
                                            e,
                                            addPictureInputRef.current as HTMLInputElement,
                                            pictures,
                                            setFieldValue,
                                            setCurrentUploadingField,
                                            currentUploadingField
                                        )
                                    }
                                    ref={addPictureInputRef}
                                    accept="image/*"
                                />
                                <AcSelect
                                    header="Logo"
                                    name="login.loginLogoImage"
                                    value={
                                        handleImageValue(
                                            'login.loginLogoImage',
                                            values.login.loginLogoImage,
                                            'https://media.appcharge.com/defaults/logo.svg',
                                            pictures,
                                            setFieldValue
                                        ) ||
                                        handleImageValue(
                                            'login.loginLogoImage',
                                            values.general.logo,
                                            'https://media.appcharge.com/defaults/logo.svg',
                                            pictures,
                                            setFieldValue
                                        )
                                    }
                                    uploadConfig={{
                                        onUploadSuccess: async (
                                            uploadData: any
                                        ) => {
                                            await pictures.refetch();
                                            setFieldValue(
                                                'login.loginLogoImage',
                                                uploadData!.data.url
                                            );
                                        },
                                        uploadType: EAssetType.LOGO
                                    }}
                                    items={[
                                        {
                                            content: 'No image',
                                            key: 'no-image',
                                            value: '',
                                            renderFunction: () => (
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <span
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            display:
                                                                'inline-block',
                                                            background: '#eee',
                                                            textAlign: 'center',
                                                            lineHeight: '30px'
                                                        }}
                                                    ></span>
                                                    <Typography>
                                                        No Image
                                                    </Typography>
                                                </Stack>
                                            )
                                        },
                                        {
                                            content: 'Default Appcharge logo',
                                            key: 'https://media.appcharge.com/defaults/logo.svg',
                                            value: 'https://media.appcharge.com/defaults/logo.svg',
                                            renderFunction: () => {
                                                return (
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1}
                                                    >
                                                        <img
                                                            src="https://media.appcharge.com/defaults/logo.svg"
                                                            alt="Default Appcharge logo"
                                                            style={{
                                                                maxWidth: 26
                                                            }}
                                                        />
                                                        <Typography>
                                                            Default Appcharge
                                                            logo
                                                        </Typography>
                                                    </Stack>
                                                );
                                            }
                                        },
                                        ...(pictures.data
                                            ? pictures.data
                                                  .filter(
                                                      (p: any) =>
                                                          p.type ===
                                                          EAssetType.LOGO
                                                  )
                                                  .map((picture: any) => {
                                                      return {
                                                          content: picture.name,
                                                          key: picture.url,
                                                          value: picture.url,
                                                          renderFunction:
                                                              () => {
                                                                  return (
                                                                      <Stack
                                                                          direction="row"
                                                                          alignItems="center"
                                                                          gap={
                                                                              1
                                                                          }
                                                                      >
                                                                          <img
                                                                              src={
                                                                                  picture.url
                                                                              }
                                                                              alt={
                                                                                  picture.name
                                                                              }
                                                                              style={{
                                                                                  width: 26,
                                                                                  maxHeight: 30
                                                                              }}
                                                                          />
                                                                          <Typography>
                                                                              {
                                                                                  picture.name
                                                                              }
                                                                          </Typography>
                                                                      </Stack>
                                                                  );
                                                              }
                                                      };
                                                  })
                                            : [])
                                    ]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login?.loginLogoImage &&
                                        Boolean(errors.login?.loginLogoImage)
                                    }
                                    tooltip="This will be the logo of your login page."
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcSelect
                                    header="Logo Size"
                                    name="login.loginLogoSize"
                                    value={values.login.loginLogoSize}
                                    items={[
                                        {
                                            content: 'Small',
                                            key: ELogoSize.SMALL,
                                            value: ELogoSize.SMALL
                                        },
                                        {
                                            content: 'Large',
                                            key: ELogoSize.BIG,
                                            value: ELogoSize.BIG
                                        }
                                    ]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login?.loginLogoSize &&
                                        Boolean(errors.login?.loginLogoSize)
                                    }
                                    tooltip="Image with 1:1 ratio recommended, with at least 200px size for small view and 400px size for large view"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcSelect
                                    header="Login Background Image (Mobile)"
                                    name="login.loginBackgroundImageMobile"
                                    value={
                                        handleImageValue(
                                            'login.loginBackgroundImageMobile',
                                            values.login
                                                .loginBackgroundImageMobile,
                                            'https://media.appcharge.com/defaults/background.png',
                                            pictures,
                                            setFieldValue
                                        ) ||
                                        handleImageValue(
                                            'login.loginBackgroundImageMobile',
                                            values.general
                                                .backgroundImageMobile,
                                            'https://media.appcharge.com/defaults/background.png',
                                            pictures,
                                            setFieldValue
                                        )
                                    }
                                    uploadConfig={{
                                        onUploadSuccess: async (
                                            uploadData: any
                                        ) => {
                                            await pictures.refetch();
                                            setFieldValue(
                                                'login.loginBackgroundImageMobile',
                                                uploadData!.data.url
                                            );
                                        },
                                        uploadType: EAssetType.BG_MOBILE
                                    }}
                                    items={[
                                        {
                                            content: 'No image',
                                            key: 'no-image',
                                            value: '',
                                            renderFunction: () => (
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <span
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            display:
                                                                'inline-block',
                                                            background: '#eee',
                                                            textAlign: 'center',
                                                            lineHeight: '30px'
                                                        }}
                                                    ></span>
                                                    <Typography>
                                                        No Image
                                                    </Typography>
                                                </Stack>
                                            )
                                        },
                                        {
                                            content:
                                                'Default Appcharge background',
                                            key: 'https://media.appcharge.com/defaults/background.png',
                                            value: 'https://media.appcharge.com/defaults/background.png',
                                            renderFunction: () => {
                                                return (
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1}
                                                    >
                                                        <img
                                                            src="https://media.appcharge.com/defaults/background.png"
                                                            alt="Default Appcharge background"
                                                            style={{
                                                                width: 30,
                                                                maxHeight: 30
                                                            }}
                                                        />
                                                        <Typography>
                                                            Default Appcharge
                                                            background
                                                        </Typography>
                                                    </Stack>
                                                );
                                            }
                                        },
                                        ...(pictures.data
                                            ? pictures.data
                                                  .filter(
                                                      (p: any) =>
                                                          p.type ===
                                                          EAssetType.BG_MOBILE
                                                  )
                                                  .map((picture: any) => {
                                                      return {
                                                          content: picture.name,
                                                          key: picture.url,
                                                          value: picture.url,
                                                          renderFunction:
                                                              () => {
                                                                  return (
                                                                      <Stack
                                                                          direction="row"
                                                                          alignItems="center"
                                                                          gap={
                                                                              1
                                                                          }
                                                                      >
                                                                          <img
                                                                              src={
                                                                                  picture.url
                                                                              }
                                                                              alt={
                                                                                  picture.name
                                                                              }
                                                                              style={{
                                                                                  width: 30,
                                                                                  maxHeight: 30
                                                                              }}
                                                                          />
                                                                          <Typography>
                                                                              {
                                                                                  picture.name
                                                                              }
                                                                          </Typography>
                                                                      </Stack>
                                                                  );
                                                              }
                                                      };
                                                  })
                                            : [])
                                    ]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login
                                            ?.loginBackgroundImageMobile &&
                                        Boolean(
                                            errors.login
                                                ?.loginBackgroundImageMobile
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcSelect
                                    header="Login Background Image (Desktop)"
                                    name="login.loginBackgroundImageDesktop"
                                    value={
                                        handleImageValue(
                                            'login.loginBackgroundImageDesktop',
                                            values.login
                                                .loginBackgroundImageDesktop,
                                            'https://media.appcharge.com/defaults/background.png',
                                            pictures,
                                            setFieldValue
                                        ) ||
                                        handleImageValue(
                                            'login.loginBackgroundImageDesktop',
                                            values.general
                                                .backgroundImageDesktop,
                                            'https://media.appcharge.com/defaults/background.png',
                                            pictures,
                                            setFieldValue
                                        )
                                    }
                                    uploadConfig={{
                                        onUploadSuccess: async (
                                            uploadData: any
                                        ) => {
                                            await pictures.refetch();
                                            setFieldValue(
                                                'login.loginBackgroundImageDesktop',
                                                uploadData!.data.url
                                            );
                                        },
                                        uploadType: EAssetType.BG_DESKTOP
                                    }}
                                    items={[
                                        {
                                            content: 'No image',
                                            key: 'no-image',
                                            value: '',
                                            renderFunction: () => (
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <span
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            display:
                                                                'inline-block',
                                                            background: '#eee',
                                                            textAlign: 'center',
                                                            lineHeight: '30px'
                                                        }}
                                                    ></span>
                                                    <Typography>
                                                        No Image
                                                    </Typography>
                                                </Stack>
                                            )
                                        },
                                        {
                                            content:
                                                'Default Appcharge background',
                                            key: 'https://media.appcharge.com/defaults/background.png',
                                            value: 'https://media.appcharge.com/defaults/background.png',
                                            renderFunction: () => {
                                                return (
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1}
                                                    >
                                                        <img
                                                            src="https://media.appcharge.com/defaults/background.png"
                                                            alt="Default Appcharge background"
                                                            style={{
                                                                width: 30,
                                                                maxHeight: 30
                                                            }}
                                                        />
                                                        <Typography>
                                                            Default Appcharge
                                                            background
                                                        </Typography>
                                                    </Stack>
                                                );
                                            }
                                        },
                                        ...(pictures.data
                                            ? pictures.data
                                                  .filter(
                                                      (p: any) =>
                                                          p.type ===
                                                          EAssetType.BG_DESKTOP
                                                  )
                                                  .map((picture: any) => {
                                                      return {
                                                          content: picture.name,
                                                          key: picture.url,
                                                          value: picture.url,
                                                          renderFunction:
                                                              () => {
                                                                  return (
                                                                      <Stack
                                                                          direction="row"
                                                                          alignItems="center"
                                                                          gap={
                                                                              1
                                                                          }
                                                                      >
                                                                          <img
                                                                              src={
                                                                                  picture.url
                                                                              }
                                                                              alt={
                                                                                  picture.name
                                                                              }
                                                                              style={{
                                                                                  width: 30,
                                                                                  maxHeight: 30
                                                                              }}
                                                                          />
                                                                          <Typography>
                                                                              {
                                                                                  picture.name
                                                                              }
                                                                          </Typography>
                                                                      </Stack>
                                                                  );
                                                              }
                                                      };
                                                  })
                                            : [])
                                    ]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.login
                                            ?.loginBackgroundImageDesktop &&
                                        Boolean(
                                            errors.login
                                                ?.loginBackgroundImageDesktop
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                    {integration.playersAuthentication.otpOn && (
                        <>
                            <Divider />
                            <AcCard
                                stackContainer={false}
                                title="OTP"
                                description="Control your OTP login button design"
                            >
                                <Grid
                                    container
                                    rowSpacing={2}
                                    columnSpacing={1.5}
                                >
                                    <Grid item xs={12}>
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            onChange={(e) =>
                                                uploadImage(
                                                    e,
                                                    addPictureInputRef.current as HTMLInputElement,
                                                    pictures,
                                                    setFieldValue,
                                                    setCurrentUploadingField,
                                                    currentUploadingField
                                                )
                                            }
                                            ref={addPictureInputRef}
                                            accept="image/*"
                                        />
                                        <AcSelect
                                            header="OTP Icon"
                                            name="login.otpButton.icon"
                                            value={handleImageValue(
                                                'login.otpButton.icon',
                                                values.login.otpButton.icon,
                                                'https://media.appcharge.com/defaults/logo.svg',
                                                pictures,
                                                setFieldValue
                                            )}
                                            uploadConfig={{
                                                onUploadSuccess: async (
                                                    uploadData: any
                                                ) => {
                                                    await pictures.refetch();
                                                    setFieldValue(
                                                        'login.otpButton.icon',
                                                        uploadData!.data.url
                                                    );
                                                },
                                                uploadType: EAssetType.LOGO
                                            }}
                                            items={[
                                                {
                                                    content: 'No image',
                                                    key: 'no-image',
                                                    value: '',
                                                    renderFunction: () => (
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            gap={1}
                                                        >
                                                            <span
                                                                style={{
                                                                    width: 30,
                                                                    height: 30,
                                                                    display:
                                                                        'inline-block',
                                                                    background:
                                                                        '#eee',
                                                                    textAlign:
                                                                        'center',
                                                                    lineHeight:
                                                                        '30px'
                                                                }}
                                                            ></span>
                                                            <Typography>
                                                                No Image
                                                            </Typography>
                                                        </Stack>
                                                    )
                                                },
                                                {
                                                    content:
                                                        'Default Appcharge logo',
                                                    key: 'https://media.appcharge.com/defaults/logo.svg',
                                                    value: 'https://media.appcharge.com/defaults/logo.svg',
                                                    renderFunction: () => {
                                                        return (
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                gap={1}
                                                            >
                                                                <img
                                                                    src="https://media.appcharge.com/defaults/logo.svg"
                                                                    alt="Default Appcharge logo"
                                                                    style={{
                                                                        maxWidth: 26
                                                                    }}
                                                                />
                                                                <Typography>
                                                                    Default
                                                                    Appcharge
                                                                    logo
                                                                </Typography>
                                                            </Stack>
                                                        );
                                                    }
                                                },
                                                ...(pictures.data
                                                    ? pictures.data
                                                          .filter(
                                                              (p: any) =>
                                                                  p.type ===
                                                                  EAssetType.LOGO
                                                          )
                                                          .map(
                                                              (
                                                                  picture: any
                                                              ) => {
                                                                  return {
                                                                      content:
                                                                          picture.name,
                                                                      key: picture.url,
                                                                      value: picture.url,
                                                                      renderFunction:
                                                                          () => {
                                                                              return (
                                                                                  <Stack
                                                                                      direction="row"
                                                                                      alignItems="center"
                                                                                      gap={
                                                                                          1
                                                                                      }
                                                                                  >
                                                                                      <img
                                                                                          src={
                                                                                              picture.url
                                                                                          }
                                                                                          alt={
                                                                                              picture.name
                                                                                          }
                                                                                          style={{
                                                                                              width: 26,
                                                                                              maxHeight: 30
                                                                                          }}
                                                                                      />
                                                                                      <Typography>
                                                                                          {
                                                                                              picture.name
                                                                                          }
                                                                                      </Typography>
                                                                                  </Stack>
                                                                              );
                                                                          }
                                                                  };
                                                              }
                                                          )
                                                    : [])
                                            ]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.login?.otpButton
                                                    ?.icon &&
                                                Boolean(
                                                    errors.login?.otpButton
                                                        ?.icon
                                                )
                                            }
                                            tooltip="This will be the icon of your otp login button."
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    rowSpacing={2}
                                    columnSpacing={1.5}
                                    pt={2}
                                >
                                    <Grid item xs={12}>
                                        <AcInput
                                            headerSize="14"
                                            header="OTP Button Text"
                                            name="login.otpButton.text"
                                            value={values.login.otpButton.text}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.login?.otpButton
                                                    ?.text &&
                                                Boolean(
                                                    errors.login?.otpButton
                                                        ?.text
                                                )
                                            }
                                            helperText={
                                                touched.login?.otpButton?.text
                                                    ? errors.storeScreen?.noOffersTitleText?.toString()
                                                    : ''
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    rowSpacing={2}
                                    columnSpacing={1.5}
                                    pt={2}
                                >
                                    <Grid item xs={6}>
                                        <AcGradientInput
                                            header="Button Color"
                                            name="login.otpButton.backgroundColor"
                                            defaultValue={
                                                values.login.otpButton
                                                    ?.backgroundColor
                                            }
                                            setValue={setFieldValue}
                                            onBlur={handleBlur}
                                            error={
                                                touched.general?.otpButton
                                                    ?.colorOne &&
                                                Boolean(
                                                    errors.general?.otpButton
                                                        ?.colorOne
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AcGradientInput
                                            header="Text Color"
                                            name="login.otpButton.textColor"
                                            defaultValue={
                                                values.login?.otpButton
                                                    ?.textColor
                                            }
                                            setValue={setFieldValue}
                                            onBlur={handleBlur}
                                            error={
                                                touched.login?.otpButton
                                                    ?.textColor &&
                                                Boolean(
                                                    errors.login?.otpButton
                                                        ?.textColor
                                                )
                                            }
                                            helperText={
                                                touched.login?.otpButton
                                                    ?.textColor
                                                    ? errors.login?.otpButton?.textColor?.toString()
                                                    : ''
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AcCard>
                        </>
                    )}
                </Stack>
            </Grid>
            <Grid item xs={7} className="iphone-mock-wrapper">
                <div className="store-preview-wrapper iphone-mock">
                    <Background
                        backgroundImageMobile={
                            values.login.loginBackgroundImageMobile ||
                            values.general.backgroundImageMobile
                        }
                        backgroundImageDesktop={
                            values.login.loginBackgroundImageDesktop ||
                            values.general.backgroundImageMobile
                        }
                        width="100%"
                        height="100%"
                        position="absolute"
                    />
                    <Login
                        isMobilePreview
                        text={values.login.text}
                        textColor={values.login.textColor}
                        textSize={Number(values.login.textSize)}
                        textWeight={values.login.textWeight}
                        logo={
                            values.login.loginLogoImage || values.general.logo
                        }
                        loginLogoSize={values.login.loginLogoSize || 'small'}
                        width="100%"
                        height="100% - 200px"
                        fontFamily={values.login.font}
                        playerAuthData={{
                            usernamePasswordOn: true,
                            usernamePasswordModel: 'bcrypt',
                            userTokenOn: false,
                            userTokenModel: '',
                            googleAppSecret: 'kfdKd92h7KJH&*^%@!$',
                            googleAppId: '87391245034',
                            googleModel: 'OAuth2',
                            googleOn: true,
                            appleAppSecret: 'JHF@&$#hfdk17d',
                            appleAppId: '2734590234',
                            appleModel: 'Sign in with Apple',
                            appleOn: false,
                            fbAppSecret: 'jfdl65s4sfgsdf4&^#',
                            fbAppId: '958273405',
                            fbModel: 'Facebook Login',
                            fbOn: true,
                            otpOn: integration.playersAuthentication.otpOn,
                            otpLinks: [],
                            appleResponseType: 'code' as any,
                            userTokenText: '',
                            userTokenUrl: ''
                        }}
                        otpButton={{
                            icon: values.login.otpButton.icon,
                            text: values.login.otpButton.text,
                            backgroundColor:
                                values.login.otpButton.backgroundColor,
                            textColor: values.login.otpButton.textColor
                        }}
                        stopLoader={() => {}}
                        loginResponse={() => {}}
                        loginButtonClickWrapper={() => {}}
                        openInfoModal={() => {}}
                        startLoader={() => {}}
                        mode={'providers' as ELoginMode}
                        setMode={() => {}}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default LoginTheme;
