import { EEncryptionType } from './IntegrationSettings/IntegrationSettings';

export enum EPlayersAuthenticationModel {
    PUBLISHER = 'publisher',
    APPCHARGE = 'appcharge'
}

export interface CompanySettingsProps {
    savePublisherDetails: (data: any) => void;
    data: any;
}
export interface IntegrationSettingsProps {
    savePlayersAuthenticationDetails: (
        data: Partial<IntegrationSettingsData>
    ) => void;
    saveWebhooksDetails: (data: Partial<IntegrationSettingsData>) => void;
    data?: IntegrationSettingsData;
    integrationRefetch: () => void;
}

export interface IntegrationSettingsData {
    _id: string;
    apiUrlPrimaryKey: string;
    apiUrlSecondaryKey: string;
    checkoutPublicKey: string;
    backToGameDeepLink: string;
    ordersReportingApiUrl: string;
    playersAuthModel: string;
    publisherToken: string;
    playerInfoSyncUrl: string;
    playersAuthWebhook: string;
    publisherId: string;
    usernamePasswordOn: boolean;
    facebookModel: EPlayersAuthenticationModel;
    usernamePasswordModel: EPlayersAuthenticationModel;
    deepLinkButtonOn: boolean;
    backToStoreButtonOn: boolean;
    encryptionModel: EEncryptionType;
    eventsWebhookUrl: string;
    playersAuthentication: Partial<{
        appleModel: EPlayersAuthenticationModel;
        appleAppId: string;
        appleOn: boolean;
        appleAppSecret: string;
        googleAppSecret: string;
        googleAppId: string;
        googleModel: EPlayersAuthenticationModel;
        googleOn: boolean;
        fbAppSecret: string;
        fbAppId: string;
        fbModel: string;
        fbOn: boolean;
        userTokenOn: boolean;
        userTokenModel: string;
        usernamePasswordOn: boolean;
        usernamePasswordModel: string;
        otpOn: boolean;
        otpTextModel: string;
    }>;
    deepLinks: any;
    otpGenerateDeeplinkUrl: string;
}

export interface PlayerAuthSettingsProps {
    data?: Partial<IntegrationSettingsData>;
}
