import { useSelector } from 'react-redux';
import { Route,Routes } from 'react-router-dom';

import useIntegration from 'api/useIntegration';

import { AuthSliceState } from '../../store/store.types';

import OffersForm from './OffersForm';
import OffersTable from './OffersTable';

import '../../views/view.scss';

const OffersView = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { data } = useIntegration(currentPublisherId).getIntegration;
    return (
        <Routes>
            <Route path="/" element={<OffersTable />} />
            <Route path="/form" element={<OffersForm isPlayerInfoSync={!!data?.playerInfoSyncUrl} />} />
            <Route path="/form/:offerId" element={<OffersForm edit={true} isPlayerInfoSync={!!data?.playerInfoSyncUrl}/>} />
            <Route path="/dup/:offerId" element={<OffersForm dup={true} isPlayerInfoSync={!!data?.playerInfoSyncUrl}/>} />
        </Routes>
    );
};

export default OffersView;
