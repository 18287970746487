import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { Box, CircularProgress, Grid, Link, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import usePermissions from 'api/usePermissions';
import useUsers from 'api/useUsers';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { geo } from 'constants/geo';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useDisputes from '../../api/useDisputes';
import useOrders from '../../api/useOrders';
import { DisputeEvidence, Project, User } from '../../common/contracts';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcInput from '../../components/AcInput/AcInput';
import AcSeparator from '../../components/AcSeparator/AcSeparator';
import ActionButton from '../../components/ActionButton/ActionButton';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import DialogModal from '../../components/Dialog/Dialog';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import { chargeFailedFreeOrder, statusDictionary } from '../../components/OrdersTable/OrdersTable.types';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { DATE_TIME_FORMAT } from '../../constants/constants';
import { EButtonColor, EFeatureFlag, ELocalStorageKeys, ENotificationType, EOrderStatus } from '../../constants/enums';
import { StatusLabel } from '../../design-system/StatusLabel/StatusLabel';
import { useNotifications } from '../../hooks/useNotifications';
import { UserDetails } from '../../pages/Login/Login.types';
import { capitalizeFirstLetter } from '../../utils/formattingUtil';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { permissionsUtil } from '../../utils/permissionsUtil';

import './style.scss';

const OrderDetailsCell = ({
    header,
    value
}: {
    header: string;
    value: string | number;
}) => {
    return (
        <Stack gap={0.5} mb={2}>
            <Typography
                className="cell-title"
                fontFamily="'Montserrat', sans-serif"
                fontSize={12}
                fontWeight={600}
            >
                {header}
            </Typography>
            <Typography
                className="cell-value"
                fontFamily="'Montserrat', sans-serif"
                fontSize={14}
                fontWeight={400}
            >
                {value}
            </Typography>
        </Stack>
    );
};

const disputFormSchema = yup.object().shape({
    name: yup.string().required('name is required'),
    link: yup.string().required('link is required'),
    description: yup.string().required('description is required')
});

const OrderDetails = ({ isFree }: { isFree: boolean }) => {
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const { orderId } = useParams();
    const { state } = useLocation();
    const [useReceiptFeature, setUseReceiptFeature] = useState<boolean>(false);
    const [showRefundDialog, setShowRefundDialog] = useState<boolean>(false);
    const [showEvidenceDialog, setShowEvidenceDialog] =
        useState<boolean>(false);
    const [selectedEvidenceID, setSelectedEvidenceID] = useState<string | null>(
        null
    );
    const userDetails = localStorageUtil.get<UserDetails>(
        ELocalStorageKeys.USER_DETAILS
    );
    const [isDeleting, setIsDeleting] = useState(false);
    const { enqueueSnackbar } = useNotifications();
    const navigate = useNavigate();
    const [orderStatus, setOrderStatus] = useState<string>(
        state
            ? ((state as { state: EOrderStatus; isOver30Days: boolean })
                  .state as EOrderStatus)
            : ''
    );
    const [isOverThreshold, setIsOverThreshold] = useState<boolean>(true);
    const [isRefundHidden, setIsRefundHidden] = useState<boolean>(false);

    const { getOrderDetails: order, makeRefund } = useOrders(
        orderId,
        undefined,
        undefined,
        { selectedProjects: state.publisherId },
        versionDetails
    );

    const { fetchFeatureFlags } = useUsers(
        false,
        versionDetails,
        !order?.isLoading && order.isSuccess
            ? order?.data?.offerSnapshot?.publisherId
            : undefined,
        !order?.isLoading && order.isSuccess
    );

    const { addDisputeEvidence, deleteDisputeEvidence } = useDisputes(
        order.data?.dispute?._id,
        order?.data?.offerSnapshot?.publisherId
    );

    const { getPermissions } = usePermissions();
    const permissions = getPermissions();

    const projectsDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.projects;
    const getCurrentProject = (publisherId: string): Project | undefined =>
        projectsDetails?.find((item) => item.publisherId === publisherId);

    useEffect(() => {
        order.refetch();
    }, []);

    useEffect(() => {
        if (order.data) {
            const today = dayjs();
            setIsOverThreshold(
                today.diff(order.data.createdAt, 'day') >
                    order.data?.refundThresholdDays
            );
            setOrderStatus(order.data.status);
        }
    }, [order.data]);

    useEffect(() => {
        if (permissionsUtil.isNewVersion()) {
            if (!fetchFeatureFlags.data) return;
            setUseReceiptFeature(
                fetchFeatureFlags.data.featureFlags
                    .dashboard_display_receipt_number
            );
        } else setUseReceiptFeature(true);
    }, [fetchFeatureFlags.data]);

    const getPaymentMethod = (): string => {
        if (order.data?.paymentMethod) {
            return order.data?.paymentMethod.secondaryMethod
                ? capitalizeFirstLetter(
                      `${order.data?.paymentMethod.primaryMethod} (${order.data?.paymentMethod.secondaryMethod})`
                  )
                : capitalizeFirstLetter(
                      order.data?.paymentMethod.primaryMethod
                  );
        }

        return '-';
    };

    const { values, handleBlur, handleChange, isValid, submitForm } = useFormik<
        Partial<DisputeEvidence>
    >({
        validationSchema: disputFormSchema,
        validateOnMount: true,
        initialValues: {
            name: '',
            link: '',
            description: ''
        },
        onSubmit: (values, helpers) => {
            const newEvidence: Partial<DisputeEvidence> = {
                name: values.name,
                link: values.link,
                description: values.description,
                username: `${userDetails.firstName} ${userDetails.lastName}`
            };
            addDisputeEvidence.mutate(newEvidence, {
                onSuccess: () => {
                    order.refetch();
                    helpers.resetForm();
                    helpers.validateForm();
                },
                onError: () => {}
            });
        }
    });

    const onDeleteEvidence = () => {
        if (selectedEvidenceID) {
            deleteDisputeEvidence.mutate(selectedEvidenceID, {
                onSuccess: () => {
                    enqueueSnackbar(
                        `Evidence deleted successfully`,
                        ENotificationType.SUCCESS
                    );
                    order.refetch();
                    setSelectedEvidenceID(null);
                    setShowEvidenceDialog(false);
                },
                onError: () => {}
            });
        }
    };

    const showDisputeEvidences =
        Boolean(order.data?.dispute) &&
        fetchFeatureFlags.data?.featureFlags?.[
            EFeatureFlag.DASHBOARD_DISPUTE_EVIDENCES
        ];

    const multiplier =
        geo.find((c) => c.currencyCode === order.data?.currency)?.multiplier ||
        100;

    const tax = order?.data?.taxTotal.div(multiplier).toNumber();

    const totalExcludingTax = order?.data?.amountNetTotal
        .div(multiplier)
        .toNumber();

    const taxPercentage =
        order?.data?.taxRatePoints &&
        order?.data?.taxRatePoints.div(100).toNumber();

    const formatReceiptId = (receiptId: string): string => {
        if (!receiptId) return '-';
        return receiptId.replace(/(\d{3})(?=\d)/g, '$1-');
    };

    useEffect(() => {
        const per =
            permissionsUtil.isNewVersion() &&
            !permissionsUtil.isActionEnabled(
                permissions,
                EPermissionGroup.ORDERS,
                state.publisherId,
                EPermissionAction.REFUND
            );

        setIsRefundHidden(
            order.isLoading ||
                (orderStatus !== EOrderStatus.payment_succeed &&
                    orderStatus !== EOrderStatus.charge_succeed &&
                    orderStatus !== EOrderStatus.charge_failed &&
                    orderStatus !== EOrderStatus.payment_dispute_won &&
                    orderStatus !== EOrderStatus.payment_dispute_inquiry_won &&
                    orderStatus !== EOrderStatus.payment_refund_cancelled) ||
                isOverThreshold ||
                !order.data?.refundThresholdDays ||
                per
        );
    }, [
        isOverThreshold,
        order.data?.refundThresholdDays,
        order.isLoading,
        orderStatus,
        permissions,
        state.publisherId
    ]);

    return (
        <AcViewWrapper
            header={
                <PageTopBar
                    headline={`Order ID ${orderId}`}
                    buttons={[
                        {
                            text: 'Refund',
                            tooltip: isOverThreshold
                                ? ''
                                : "Order can't be refunded",
                            action: () => setShowRefundDialog(true),
                            hidden: isRefundHidden
                        }
                    ]}
                    backFunction={() => navigate(-1)}
                />
            }
        >
            <AcContentWrapper>
                {!order.isLoading && order.data ? (
                    <Stack direction="column" gap={1}>
                        <span className="header-title">
                            {!isFree ? 'TOTAL PAYMENT' : 'PRICE'}
                        </span>
                        <Stack
                            style={{ color: 'black' }}
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            <span className="order-price">
                                {isFree ? (
                                    <b>Free</b>
                                ) : order.data.amountInDollar ? (
                                    <>
                                        <b>
                                            {order.data.amountNetTotal
                                                .div(multiplier)
                                                .add(
                                                    order.data.taxTotal.div(
                                                        multiplier
                                                    )
                                                )
                                                .toNumber()}{' '}
                                            {order.data.currency}
                                        </b>
                                    </>
                                ) : (
                                    'N/A'
                                )}
                            </span>
                            <StatusLabel
                                text={
                                    isFree &&
                                    orderStatus === EOrderStatus.charge_failed
                                        ? chargeFailedFreeOrder
                                        : statusDictionary[
                                              orderStatus as EOrderStatus
                                          ]?.text
                                }
                                status={
                                    statusDictionary[
                                        orderStatus as EOrderStatus
                                    ]?.status
                                }
                                prefixIcon={
                                    statusDictionary[
                                        orderStatus as EOrderStatus
                                    ]?.prefixIcon
                                }
                            />
                        </Stack>
                        <AcSeparator marginTop={'4px'} marginBottom={'16px'} />
                        {isFree ? (
                            <Stack direction="row" className="order-details">
                                <Stack
                                    direction="column"
                                    style={{
                                        wordWrap: 'break-word',
                                        maxWidth: '200px'
                                    }}
                                >
                                    <OrderDetailsCell
                                        header="Date"
                                        value={dayjs(order.data?.createdAt)
                                            .utc()
                                            .format(DATE_TIME_FORMAT)}
                                    />
                                    <OrderDetailsCell
                                        header="Order ID"
                                        value={
                                            orderId ? orderId.toString() : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Player ID"
                                        value={
                                            order.data.playerId
                                                ? order.data.playerId
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Player Email"
                                        value={
                                            order.data.playerEmail
                                                ? order.data.playerEmail
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Purchase ID"
                                        value={
                                            order.data?.purchaseId
                                                ? order.data?.purchaseId
                                                : '-'
                                        }
                                    />
                                </Stack>
                                <Stack direction="column">
                                    <OrderDetailsCell
                                        header="Order external ID"
                                        value={
                                            order.data?.publisherPurchaseId
                                                ? order.data
                                                      ?.publisherPurchaseId
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Offer name"
                                        value={
                                            order.data.bundleName
                                                ? order.data.bundleName
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Offer external ID"
                                        value={
                                            order.data.bundleSku
                                                ? order.data.bundleSku
                                                : '-'
                                        }
                                    />
                                </Stack>
                                <Stack direction="column">
                                    <OrderDetailsCell
                                        header="Product sequence"
                                        value={
                                            order.data.offerSnapshot
                                                .sequenceIndex
                                                ? order.data.offerSnapshot
                                                      .sequenceIndex
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Project"
                                        value={
                                            getCurrentProject(
                                                order?.data?.offerSnapshot
                                                    ?.publisherId
                                            )?.publisherName ?? '-'
                                        }
                                    />
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack direction="row" className="order-details">
                                <Stack
                                    direction="column"
                                    style={{
                                        wordWrap: 'break-word',
                                        maxWidth: '200px'
                                    }}
                                >
                                    <OrderDetailsCell
                                        header="Date"
                                        value={dayjs(order.data?.createdAt)
                                            .utc()
                                            .format('DD/MM/YYYY HH:mm:ss')}
                                    />
                                    <OrderDetailsCell
                                        header="Total excluding tax"
                                        value={
                                            order.data.amountNetTotal
                                                ? `${order.data.amountNetTotal.div(multiplier).toNumber()} ${order.data.currency}`
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Payment method"
                                        value={getPaymentMethod()}
                                    />
                                    <OrderDetailsCell
                                        header="Player Email"
                                        value={
                                            order.data.playerEmail
                                                ? order.data.playerEmail
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Purchase ID"
                                        value={
                                            order.data?.purchaseId
                                                ? order.data?.purchaseId
                                                : '-'
                                        }
                                    />
                                </Stack>

                                <Stack direction="column">
                                    <OrderDetailsCell
                                        header="Country"
                                        value={
                                            order.data?.playerGeo?.countryCode2
                                                ? order.data?.playerGeo
                                                      ?.countryCode2
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="State"
                                        value={
                                            order.data?.state &&
                                            order.data?.playerGeo
                                                ?.countryCode2 === 'US'
                                                ? order.data?.state
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Tax"
                                        value={
                                            order.data.taxTotal
                                                ? `${tax} ${order.data.currency}`
                                                : '-'
                                        }
                                    />
                                    {order.data?.zipCode && (
                                        <OrderDetailsCell
                                            header="Zip Code"
                                            value={order.data?.zipCode}
                                        />
                                    )}
                                </Stack>

                                <Stack direction="column">
                                    <OrderDetailsCell
                                        header="Tax rate"
                                        value={
                                            taxPercentage !== undefined
                                                ? `${taxPercentage}%`
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Offer name"
                                        value={
                                            order.data.bundleName
                                                ? order.data.bundleName
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Offer SKU"
                                        value={
                                            order.data.bundleSku
                                                ? order.data.bundleSku
                                                : '-'
                                        }
                                    />
                                </Stack>

                                <Stack direction="column">
                                    {useReceiptFeature && (
                                        <OrderDetailsCell
                                            header="Receipt number"
                                            value={
                                                order.data.receiptId
                                                    ? formatReceiptId(
                                                          order.data.receiptId
                                                      )
                                                    : '-'
                                            }
                                        />
                                    )}
                                    <OrderDetailsCell
                                        header="Player ID"
                                        value={
                                            order.data.playerId
                                                ? order.data.playerId
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Order ID"
                                        value={
                                            orderId ? orderId.toString() : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Publisher transaction ID"
                                        value={
                                            order.data?.publisherPurchaseId
                                                ? order.data
                                                      ?.publisherPurchaseId
                                                : '-'
                                        }
                                    />
                                    <OrderDetailsCell
                                        header="Project"
                                        value={
                                            getCurrentProject(
                                                order?.data?.offerSnapshot
                                                    ?.publisherId
                                            )?.publisherName ?? '-'
                                        }
                                    />
                                </Stack>
                            </Stack>
                        )}

                        <AcSeparator marginTop={'4px'} marginBottom={'16px'} />
                        {order.data.products.length !== 0 && (
                            <>
                                <h2 className="order__section-title">
                                    Products
                                </h2>
                                <DataTable
                                    className="order__products-table"
                                    columns={[
                                        {
                                            field: 'name',
                                            headerName: 'Name',
                                            flex: 1
                                        },
                                        {
                                            field: 'sku',
                                            headerName: 'SKU',
                                            flex: 1
                                        },
                                        {
                                            field: 'quantity',
                                            headerName: isFree
                                                ? 'Amount'
                                                : 'Quantity',
                                            flex: 1
                                        }
                                    ]}
                                    rows={order.data.products.map(
                                        (product, index) => ({
                                            _id: product?.sku + index,
                                            name: product.name,
                                            sku: product?.sku || 'N/A',
                                            quantity: product.quantity
                                        })
                                    )}
                                    loading={false}
                                ></DataTable>
                            </>
                        )}
                        {order.data.history && (
                            <>
                                <Box mt="1.5em" />
                                <h2 className="order__section-title">
                                    History
                                </h2>
                                <DataTable
                                    className="order__products-table"
                                    columns={[
                                        {
                                            field: 'date',
                                            headerName: 'Date',
                                            flex: 1
                                        },
                                        {
                                            field: 'status',
                                            headerName: 'Status',
                                            flex: 1,
                                            renderCell: (params) => (
                                                <span>
                                                    {isFree &&
                                                    params.value.toLowerCase() ===
                                                        EOrderStatus.charge_failed
                                                        ? chargeFailedFreeOrder
                                                        : statusDictionary[
                                                              params.value.toLowerCase() as EOrderStatus
                                                          ]?.text}
                                                </span>
                                            )
                                        },
                                        {
                                            field: 'event',
                                            headerName: 'Event',
                                            flex: 1
                                        }
                                    ]}
                                    rows={order.data.history.map(
                                        (history, i) => ({
                                            _id: i,
                                            date: history.createdAt
                                                ? dayjs(history.createdAt)
                                                      .utc()
                                                      .format(
                                                          'DD/MM/YYYY HH:mm:ss'
                                                      )
                                                : '',
                                            status: history.status
                                                ? capitalizeFirstLetter(
                                                      history.status
                                                  )
                                                : '',
                                            event: history.event
                                                ? capitalizeFirstLetter(
                                                      history.event
                                                  )
                                                : ''
                                        })
                                    )}
                                    loading={false}
                                ></DataTable>
                            </>
                        )}
                        {showDisputeEvidences && (
                            <>
                                <AcSeparator
                                    marginTop={0}
                                    marginBottom={'16px'}
                                />
                                <Stack spacing={2} pt={2}>
                                    <h2 className="order__section-title">
                                        Dispute Evidences
                                    </h2>
                                    <Box pt={4} maxWidth={'520px'}>
                                        <Grid
                                            container
                                            rowSpacing={1.5}
                                            columnSpacing={{
                                                xs: 1,
                                                sm: 2,
                                                md: 2
                                            }}
                                        >
                                            <Grid item xs={6}>
                                                <AcInput
                                                    header="Evidence Name"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <AcInput
                                                    header="Link"
                                                    name="link"
                                                    value={values.link}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AcInput
                                                    header="Description"
                                                    name="description"
                                                    textarea
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ActionButton
                                                    disabled={
                                                        !isValid ||
                                                        addDisputeEvidence.isLoading
                                                    }
                                                    text={'Add Evidence'}
                                                    variant="outlined"
                                                    onClick={submitForm}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                                <DataTable
                                    // rowHeight={70}
                                    className="order__products-table"
                                    columns={[
                                        {
                                            field: 'name',
                                            headerName: 'Evidence Name',
                                            flex: 1,
                                            renderCell: ({
                                                row
                                            }: GridRenderCellParams<
                                                any,
                                                DisputeEvidence,
                                                any
                                            >) => {
                                                const url = row.link.startsWith(
                                                    'http'
                                                )
                                                    ? row.link
                                                    : `https://${row.link}`;
                                                return (
                                                    <Link
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={url}
                                                        sx={{
                                                            color: '#0334E1',
                                                            textDecorationColor:
                                                                '#0334E1'
                                                        }}
                                                    >
                                                        {row.name}
                                                    </Link>
                                                );
                                            }
                                        },
                                        {
                                            field: 'description',
                                            headerName: 'Description',
                                            flex: 1,
                                            renderCell: (
                                                params: GridRenderCellParams<
                                                    any,
                                                    DisputeEvidence,
                                                    any
                                                >
                                            ) => {
                                                const description =
                                                    params.row.description;
                                                return (
                                                    <Tooltip
                                                        title={description}
                                                    >
                                                        <div
                                                            style={{
                                                                overflow:
                                                                    'hidden',
                                                                textOverflow:
                                                                    'ellipsis'
                                                            }}
                                                        >
                                                            {description}
                                                        </div>
                                                    </Tooltip>
                                                );
                                            }
                                        },
                                        {
                                            field: 'username',
                                            headerName: 'User Name',
                                            flex: 1
                                        },
                                        {
                                            field: 'updatedAt',
                                            headerName: 'Last Update',
                                            flex: 1,
                                            renderCell: (
                                                params: GridRenderCellParams<
                                                    any,
                                                    DisputeEvidence,
                                                    any
                                                >
                                            ) => {
                                                return (
                                                    <span>
                                                        {dayjs(
                                                            params.row.updatedAt
                                                        )
                                                            .utc()
                                                            .format(
                                                                DATE_TIME_FORMAT
                                                            )}
                                                    </span>
                                                );
                                            }
                                        },
                                        {
                                            field: 'actions',
                                            flex: 0,
                                            type: 'actions',
                                            width: 80,
                                            disableReorder: true,
                                            getActions: (
                                                params: GridRowParams<DisputeEvidence>
                                            ) => [
                                                <GridActionsCellItem
                                                    className="danger"
                                                    icon={
                                                        <DeleteOutlineTwoToneIcon className="danger" />
                                                    }
                                                    label="Delete"
                                                    onClick={() => {
                                                        setSelectedEvidenceID(
                                                            params.row._id
                                                        );
                                                        setShowEvidenceDialog(
                                                            true
                                                        );
                                                    }}
                                                    disabled={
                                                        !permissionsUtil.canUserEdit()
                                                    }
                                                    showInMenu
                                                />
                                            ]
                                        }
                                    ]}
                                    rows={order.data?.dispute.evidences?.reverse()}
                                    loading={false}
                                ></DataTable>
                            </>
                        )}
                    </Stack>
                ) : !order.isError ? (
                    <Stack
                        width="100%"
                        height="calc(100vh - 150px)"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Stack>
                ) : (
                    <Stack
                        width="100%"
                        height="calc(100vh - 150px)"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ErrorBox description="Something went wrong fetching the order" />
                    </Stack>
                )}
                {order.data && (
                    <DialogModal
                        width="392px"
                        isOpen={showRefundDialog}
                        headline="Refund payment"
                        text="Refunds take 5-10 days to appear on a customer's statement."
                        buttons={[
                            {
                                text: 'Cancel',
                                color: EButtonColor.SECONDARY,
                                variant: 'outlined',
                                func: () => {
                                    setShowRefundDialog(false);
                                }
                            },
                            {
                                text: 'Refund',
                                color: EButtonColor.PRIMARY,
                                variant: 'contained',
                                disabled: isDeleting,
                                func: () => {
                                    setIsDeleting(true);
                                    makeRefund.mutate(
                                        { orderId: orderId! },
                                        {
                                            onSuccess: () => {
                                                enqueueSnackbar(
                                                    `Order ${orderId!} Refund attempt sent`,
                                                    ENotificationType.SUCCESS
                                                );
                                                setOrderStatus(
                                                    EOrderStatus.payment_refund_pending
                                                );
                                            },
                                            onError: (data: any) => {
                                                enqueueSnackbar(
                                                    "Couldn't refund order",
                                                    ENotificationType.ERROR
                                                );
                                            },
                                            onSettled: () => {
                                                setIsDeleting(false);
                                                setShowRefundDialog(false);
                                            }
                                        }
                                    );
                                }
                            }
                        ]}
                        closeDialog={() => setShowRefundDialog(false)}
                        content={
                            <Stack>
                                <Box>
                                    <Typography
                                        fontSize="12px"
                                        marginTop="16px"
                                    >
                                        Refund amount
                                    </Typography>
                                    <Typography fontSize="20px">
                                        <b>
                                            {order.data.amountNetTotal
                                                .div(multiplier)
                                                .add(
                                                    order.data.taxTotal.div(
                                                        multiplier
                                                    )
                                                )
                                                .toNumber()}{' '}
                                            {order.data.currency}
                                        </b>
                                    </Typography>
                                </Box>
                            </Stack>
                        }
                    />
                )}
                <DialogModal
                    isOpen={showEvidenceDialog}
                    headline="Delete Evidence"
                    text="Are you sure you want to delete this evidence? This can't be undone."
                    buttons={[
                        {
                            text: 'Cancel',
                            color: EButtonColor.SECONDARY,
                            variant: 'outlined',
                            func: () => {
                                setShowEvidenceDialog(false);
                            }
                        },
                        {
                            text: 'Delete',
                            color: EButtonColor.ERROR,
                            variant: 'contained',
                            disabled: deleteDisputeEvidence.isLoading,
                            func: onDeleteEvidence
                        }
                    ]}
                    closeDialog={() => setShowEvidenceDialog(false)}
                />
            </AcContentWrapper>
        </AcViewWrapper>
    );
};

export default OrderDetails;
