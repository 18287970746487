import { SyntheticEvent, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, Tab } from '@mui/material';

import AcTabs from '../../components/AcTabs/AcTabs';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import PageTopBar from '../../components/Topbar/PageTopBar';

import '../view.scss';

enum EReportsTabs {
    FINANCIAL = 'financialreports',
    PAYOUTS = 'payouts'
}

const ReportsView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentTab = location.pathname.split('/')[3];
    const [activeTab, setActiveTab] = useState(currentTab);

    const handleTabChange = (e: SyntheticEvent, value: string) => {
        setActiveTab(value);
        const basePath = location.pathname.split(currentTab)[0];
        navigate(`${basePath}${value}`);
    };
    return (
        <AcViewWrapper
            header={
                <>
                    <PageTopBar withTabsDesign={true} headline="Reports" />
                    <Box pl={'3rem'} pr={'3rem'}>
                        <AcTabs value={activeTab} onChange={handleTabChange}>
                            <Tab
                                label="Financial"
                                value={EReportsTabs.FINANCIAL}
                            />
                            <Tab label="Payouts" value={EReportsTabs.PAYOUTS} />
                        </AcTabs>
                    </Box>
                </>
            }
        >
            <Outlet />
        </AcViewWrapper>
    );
};

export default ReportsView;
