import { useMutation,useQuery } from '@tanstack/react-query';

import { EQueryKeys } from '../../constants/enums';
import useAxios from '../../hooks/useAxios';
import { EApiRoutes, getApiRouteOrMock } from '../api.utils';

import { PaymentMethod, PaymentMethodConfigData } from './payments.types';

export default function usePayments() {
    const axios = useAxios();

    const getPaymentMethods = useQuery<PaymentMethod[], Error>({
        queryKey: [EQueryKeys.PAYMENTS],
        queryFn: async () => {
            return await axios.get(getApiRouteOrMock(EApiRoutes.PAYMENTS));
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
    });

    const getPaymentsMethodConfig = useQuery<PaymentMethodConfigData, Error>({
        queryKey: [EQueryKeys.PAYMENTS_CONFIG],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(EApiRoutes.PAYMENTS_CONFIG)
            );
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
    });

    const updatePaymentMethods = useMutation(
        async (paymentMethods: PaymentMethodConfigData) => {
            try {
                const response = await axios.put(
                    '/dashboard/financial/configuration',
                    paymentMethods
                );
                return response.data;
            } catch (error: any) {
                if (error.response) {
                    throw error;
                }
            }
        },
        {}
    );

    return {
        getPaymentMethods,
        getPaymentsMethodConfig,
        updatePaymentMethods
    };
}
