import { useEffect, useState } from 'react';

import { Box, Stack, TextFieldProps } from '@mui/material';

import AcInput from '../AcInput/AcInput';

import { AcPhoneInputProps } from './AcPhoneInput.types';

import './style.scss';

const AcPhoneInput = (props: TextFieldProps & AcPhoneInputProps) => {
    const [helperText, setHelperText] = useState('');
    const handleChange = (e: any, fieldName: string) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            props.setFieldValue(fieldName, e.target.value);
        }
    };

    useEffect(() => {
        if (props.helpers[0]) setHelperText(props.helpers[0]);
        else if (props.helpers[1]) setHelperText(props.helpers[1]);
        else setHelperText('');
    }, [props.helpers]);

    return (
        <div className="ac-phone-input-box formContent-input-fieldTitle">
            {props.header && <h3>{props.header}</h3>}
            <Stack className="ac-phone-inputs" display="flex" direction="row">
                <Box flexGrow={0}>
                    <AcInput
                        name={props.names[0]}
                        value={props.values[0]}
                        onChange={(e) => handleChange(e, props.names[0])}
                        onBlur={props.onBlur}
                        inputProps={{ maxLength: 3 }}
                        error={props.errors[0]}
                    />
                </Box>
                <Box flexGrow={1}>
                    <AcInput
                        name={props.names[1]}
                        value={props.values[1]}
                        onChange={(e) => handleChange(e, props.names[1])}
                        onBlur={props.onBlur}
                        error={props.errors[1]}
                        fullWidth={true}
                    />
                </Box>
            </Stack>
            <span style={{ color: 'red', fontSize: '10px' }}>{helperText}</span>
        </div>
    );
};

export default AcPhoneInput;
