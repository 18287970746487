import { useMutation, useQuery } from '@tanstack/react-query';

import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';
import { PayoutRequest } from '../views/Reports/Report.types';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

const BASE_URL =
    localStorageUtil.getAny(ELocalStorageKeys.ENVIRONMENT) ||
    process.env.REACT_APP_API_BASE_URL;

export default function usePayouts(version?: number, projects?: string) {
    const axios = useAxios();
    const payoutsRoute =
        version === NEW_VERSION
            ? EApiRoutes.PAYOUTS_NEW_VERSION
            : EApiRoutes.PAYOUTS;

    const getPayouts = useQuery({
        ...fetchConfig.list,
        refetchInterval: 1000 * 15,
        refetchIntervalInBackground: false,
        retry: false,
        queryKey: [EQueryKeys.PAYOUTS],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(payoutsRoute),
                undefined,
                projects ? { 'x-publishers': projects } : undefined
            );
        }
    });

    const createPayout = useMutation(async (newPayout: PayoutRequest) => {
        return await axios.post(
            getApiRouteOrMock(EApiRoutes.PAYOUTS),
            newPayout
        );
    }, {});

    const updatePayout = useMutation(
        async ({
            id,
            newPayout
        }: {
            id: string;
            newPayout: Partial<PayoutRequest>;
        }) => {
            return await axios.put(
                `${getApiRouteOrMock(EApiRoutes.PAYOUTS)}/${id}`,
                newPayout
            );
        },
        {}
    );

    // todo: remove when New Version is fully implemented
    const getPayoutDownload = (reportId: string) => {
        axios
            .get(
                `${BASE_URL}${getApiRouteOrMock(
                    payoutsRoute
                )}/${reportId}/download`
            )
            .then((response) => {
                const url = response?.downloadLink;
                window.open(url, '_blank');
            })
            .catch((error) => {
                console.error('Error downloading payout:', error);
            });
    };

    const getPayoutDownloadNewVersion = (
        reportId: string,
        publisherId: string,
        period: string
    ) =>
        axios
            .get(
                `${BASE_URL}${getApiRouteOrMock(
                    EApiRoutes.PAYOUTS
                )}/${reportId}/download`,
                undefined,
                {
                    responseType: 'text',
                    'x-publishers': publisherId
                }
            )
            .then((response) => {
                const url = response?.downloadLink;
                window.open(url, '_blank');
            })
            .catch((error) => {
                console.error('Error downloading payout:', error);
            });

    const deletePayout = useMutation(async (payoutId: string) => {
        return await axios.del(
            `${getApiRouteOrMock(EApiRoutes.PAYOUTS)}/${payoutId}`
        );
    }, {});

    return {
        getPayouts,
        createPayout,
        updatePayout,
        deletePayout,
        getPayoutDownload,
        getPayoutDownloadNewVersion
    };
}
