import {
    ApplovinBundle,
    BagelcodeBundle,
    BagelCodeSpecialOffer,
    CandivoreBundle,
    CashmanCasinoBundle,
    ClassicBundle,
    CommunixBundle,
    JackpotSlotsBundle,
    LocoBingoBundle,
    LocoBingoSpecialOffer,
    MainSubBundle,
    MatchMastersSpecialOffer,
    Me2onBundle,
    Me2onSpecialOffer, PlaySimpleBundle,
    PokerFaceSpecialOffer,
    SpecialOffer
} from '@appcharge/shared-ui';

import {
    EBundlesInternalViewModel,
    ESpecialOfferInternalViewModel
} from '../constants/enums';

export const renderingUtil = {
    renderBundleByInternalModel: (
        internalModel: EBundlesInternalViewModel,
        props: any
    ) => {
        switch (internalModel) {
            case EBundlesInternalViewModel.AK:
                return (
                    <div className="bundle">
                        <ClassicBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.MAIN_SUB:
                return (
                    <div className="bundle">
                        <MainSubBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.COMMUNIX:
                return (
                    <div className="bundle" key={props.index}>
                        <CommunixBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.MATCH:
                return (
                    <div className="bundle" key={props.index}>
                        <CandivoreBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.BAGELCODE:
                return (
                    <div className="bundle" key={props.index}>
                        <BagelcodeBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.ME2ON:
                return (
                    <div className="bundle" key={props.index}>
                        <Me2onBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.MAIN_SUB_ALT:
                return (
                    <div className="bundle">
                        <ApplovinBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.LOCO_BINGO:
                return (
                    <div className="bundle" key={props.index}>
                        <LocoBingoBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.JACKPOT_SLOTS:
                return (
                    <div className="bundle">
                        <JackpotSlotsBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.BIG_FISH_CASINO:
                return (
                    <div className="bundle">
                        <JackpotSlotsBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.CASHMAN_CASINO:
                return (
                    <div className="bundle">
                        <CashmanCasinoBundle {...props} />
                    </div>
                );
            case EBundlesInternalViewModel.PLAY_SIMPLE:
                return (
                    <div className="bundle" key={props.index}>
                        <PlaySimpleBundle {...props} />
                    </div>
                );
        }
    },
    renderSpecialOfferByInternalModel: (
        internalModel: ESpecialOfferInternalViewModel,
        props: any
    ) => {
        switch (internalModel) {
            case ESpecialOfferInternalViewModel.HUGE:
                return (
                    <div className="bundle">
                        <SpecialOffer {...props} />
                    </div>
                );
            case ESpecialOfferInternalViewModel.MATCH_MASTERS:
                return (
                    <div className="bundle">
                        <MatchMastersSpecialOffer {...props} />
                    </div>
                );
            case ESpecialOfferInternalViewModel.POKER_FACE:
                return (
                    <div className="bundle">
                        <PokerFaceSpecialOffer {...props} />
                    </div>
                );
            case ESpecialOfferInternalViewModel.BAGELCODE:
                return (
                    <div className="bundle">
                        <BagelCodeSpecialOffer {...props} />
                    </div>
                );
            case ESpecialOfferInternalViewModel.LOCO_BINGO:
                return (
                    <div className="bundle">
                        <LocoBingoSpecialOffer {...props} />
                    </div>
                );
            case ESpecialOfferInternalViewModel.ME2ON:
                return (
                    <div className="bundle">
                        <Me2onSpecialOffer {...props} />
                    </div>
                );
        }
    }
};
