import {
    BagelCodeSection,
    DefaultSection} from '@appcharge/shared-ui';

import { ESectionViewModel } from '../constants/enums';

const getSectionLayout = (sectionViewModel: ESectionViewModel) => {
    switch (sectionViewModel) {
        case ESectionViewModel.DEFAULT:
            return DefaultSection;
        case ESectionViewModel.BAGELCODE:
            return BagelCodeSection;
    }
};

export default getSectionLayout;
