import { EStyleProperty } from '../../../constants/enums';
import { getStyledSelectItem } from '../../../utils/getStyledSelectItem';

export const getFonts = () => {
    return [
        {
            key: 'Francois One',
            content: 'Francois One',
            value: 'Francois One',
            renderFunction: () =>
                getStyledSelectItem('Francois One', EStyleProperty.fontFamily)
        },
        {
            key: 'Lato',
            content: 'Lato',
            value: 'Lato',
            renderFunction: () =>
                getStyledSelectItem('Lato', EStyleProperty.fontFamily)
        },
        {
            key: 'Libre Franklin',
            content: 'Libre Franklin',
            value: 'Libre Franklin',
            renderFunction: () =>
                getStyledSelectItem('libre Franklin', EStyleProperty.fontFamily)
        },
        {
            key: 'Lilita One',
            content: 'Lilita One',
            value: 'Lilita One',
            renderFunction: () =>
                getStyledSelectItem('Lilita One', EStyleProperty.fontFamily)
        },
        {
            key: 'Montserrat',
            content: 'Montserrat',
            value: 'montserrat',
            renderFunction: () =>
                getStyledSelectItem('montserrat', EStyleProperty.fontFamily)
        },
        {
            key: 'Mouse Memoirs',
            content: 'Mouse Memoirs',
            value: 'Mouse Memoirs',
            renderFunction: () =>
                getStyledSelectItem('Mouse Memoirs', EStyleProperty.fontFamily)
        },
        {
            key: 'Neuron Heavy 2',
            content: 'Neuron Heavy 2',
            value: 'Neuron Heavy 2',
            renderFunction: () =>
                getStyledSelectItem('Neuron Heavy 2', EStyleProperty.fontFamily)
        },
        {
            key: 'Nunito Sans',
            content: 'Nunito Sans',
            value: 'Nunito Sans',
            renderFunction: () =>
                getStyledSelectItem('Nunito Sans', EStyleProperty.fontFamily)
        },
        {
            key: 'Open Sans',
            content: 'Open Sans',
            value: 'open Sans',
            renderFunction: () =>
                getStyledSelectItem('open Sans', EStyleProperty.fontFamily)
        },
        {
            key: 'Oswald',
            content: 'Oswald',
            value: 'oswald',
            renderFunction: () =>
                getStyledSelectItem('oswald', EStyleProperty.fontFamily)
        },
        {
            key: 'Roboto',
            content: 'Roboto',
            value: 'roboto',
            renderFunction: () =>
                getStyledSelectItem('roboto', EStyleProperty.fontFamily)
        },
        {
            key: 'Signika',
            content: 'Signika',
            value: 'Signika',
            renderFunction: () =>
                getStyledSelectItem('Signika', EStyleProperty.fontFamily)
        },
        {
            key: 'HP Simplified',
            content: 'HP Simplified',
            value: 'HP Simplified',
            renderFunction: () =>
                getStyledSelectItem(
                    'HP Simplified',
                    EStyleProperty.fontFamily
                )
        },
        {
            key: 'Source Sans Pro',
            content: 'Source Sans Pro',
            value: 'Source Sans Pro',
            renderFunction: () =>
                getStyledSelectItem(
                    'Source Sans Pro',
                    EStyleProperty.fontFamily
                )
        }
    ];
};
