import {
    CustomPricing,
    Pricing,
    PricingRaw
} from './Pricing.types';

export const pricingToNumber = (pricing: Pricing): PricingRaw => ({
    ...pricing,
    priceInCents: pricing.priceInCents.toNumber(),
    customPricing: pricing.customPricing.map((info: CustomPricing) => ({
        ...info,
        price: info.price.toNumber(),
        _id: info.countryCode3
    }))
});
