import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CachedIcon from '@mui/icons-material/Cached';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useImages from '../../api/useImages';
import { useProduct } from '../../api/useProduct';
import { TRIMMED_INPUT_REGEX } from '../../constants/constants';
import {
    EAssetType,
    EProductPriority,
    EProductType
} from '../../constants/enums';
import { useUnsavedChanges } from '../../hooks/useUnsavedChanges';
import { AuthSliceState } from '../../store/store.types';
import { min, required } from '../../utils/errorsTextHelper';
import AcCard from '../AcCard/AcCard';
import AcContentWrapper from '../AcContentWrapper/AcContentWrapper';
import AcInput from '../AcInput/AcInput';
import AcSelect from '../AcSelect/AcSelect';
import AcViewWrapper from '../AcViewWrapper/AcViewWrapper';
import PageTopBar from '../Topbar/PageTopBar';

import { ImageObject, ProductsFormProps } from './ProductsForm.types';

import './style.scss';
import './style.scss';
import '../../style/forms.scss';

const ProductsForm = ({
    headline,
    buttonText,
    postProduct,
    data,
    emptyCache,
    isLoading = false,
    edit,
    dup
}: ProductsFormProps) => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { getProducts } = useProduct(currentPublisherId);
    const pictures = useImages(currentPublisherId).getImages;
    const [disabled, setDisabled] = useState(true);
    const [skuExists, setSkuExists] = useState<boolean>(false);
    const navigate = useNavigate();
    let productSchema = yup.object().shape({
        name: yup
            .string()
            .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
            .required(required('Name'))
            .min(3, min('Name', 3)),
        publisherProductId: yup
            .string()
            .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
            .required(required('SKU'))
            .min(2, min('SKU', 2)),
        type: yup.string(),
        textFontColorHex: yup.string().required(required('Font color')),
        formImages: yup.object().shape({
            product: yup.string().url().required(required('Product image')),
            productPrefix: yup.string().url()
        }),
        priority: yup.string(),
        prefix: yup.string(),
        suffix: yup.string(),
        displayName: yup.string()
    });

    const {
        values,
        errors,
        touched,
        dirty,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        submitForm,
        setFieldValue
    } = useFormik({
        initialValues: {
            formImages: {
                product:
                    data?.images.filter(
                        (image: ImageObject) =>
                            image.type === EAssetType.PRODUCT
                    )[0]?.url || '',
                productPrefix:
                    data?.images.filter(
                        (image: ImageObject) =>
                            image.type === EAssetType.PRODUCT_PREFIX
                    )[0]?.url || ''
            } as Record<string, string>,
            name: data?.name ? `${data?.name}${dup ? '_copy' : ''}` : '',
            publisherProductId: data?.publisherProductId || '',
            type: data?.type || 'Quantity',
            priority: data?.priority || 'Main',
            prefix: data?.prefix || '',
            suffix: data?.suffix || '',
            textFontColorHex: data?.textFontColorHex
                ? `${data.textFontColorHex}`
                : '#FFFFFF',
            publisherId: '63e9ff6f062dde4c284603ca',
            displayName: data?.displayName || ''
        },
        validationSchema: productSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            const images = Object.keys(values.formImages).map((key: string) => {
                return {
                    type: key as EAssetType,
                    url: values.formImages[key]
                };
            });
            const payload = {
                images: images,
                ...values
            };
            postProduct(payload, () => setSubmitting(false));
        }
    });

    const backToList = () => {
        if (emptyCache) emptyCache();
        navigate('../');
    };

    useEffect(() => {
        setDisabled(!isValid || !dirty || isSubmitting);
    }, [isValid, dirty, isSubmitting]);

    useUnsavedChanges({ dirty });

    useEffect(() => {
        setSkuExists(
            getProducts.data?.result.find((p: any) => {
                return p.publisherProductId === values.publisherProductId;
            }) && !edit
        );
    }, [values.publisherProductId]);

    return (
        <AcViewWrapper
            header={
                <PageTopBar
                    headline={headline}
                    buttons={[
                        {
                            text: buttonText,
                            action: submitForm,
                            icon: edit ? (
                                <CachedIcon
                                    className={disabled ? 'disable' : ''}
                                />
                            ) : undefined
                        }
                    ]}
                    backFunction={backToList}
                    disable={disabled}
                />
            }
        >
            <AcContentWrapper className="formContent">
                {isLoading ? (
                    <Stack
                        height="calc(100vh - 180px)"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                    </Stack>
                ) : (
                    <form id="productForm">
                        <Grid container mt={'30px'}>
                            <Grid item xs={5} className="formContent-minWIdth">
                                <AcCard stackContainer={false} title="General">
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={1.5}
                                    >
                                        <Grid item xs={6}>
                                            <AcInput
                                                required
                                                header="Name"
                                                name="name"
                                                variant="outlined"
                                                value={values.name}
                                                onChange={(data) => {
                                                    handleChange(data);
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.name &&
                                                    Boolean(errors.name)
                                                }
                                                helperText={
                                                    touched.name
                                                        ? errors.name?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <AcInput
                                                header="Display Name"
                                                name="displayName"
                                                variant="outlined"
                                                value={values.displayName}
                                                onChange={(data) => {
                                                    handleChange(data);
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.displayName &&
                                                    Boolean(errors.displayName)
                                                }
                                                tooltip={
                                                    'Add your display name for this product'
                                                }
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <AcInput
                                                required
                                                header="SKU"
                                                name="publisherProductId"
                                                variant="outlined"
                                                value={
                                                    values.publisherProductId
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.publisherProductId &&
                                                    Boolean(
                                                        errors.publisherProductId
                                                    )
                                                }
                                                helperText={
                                                    touched.publisherProductId
                                                        ? errors.publisherProductId?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Divider
                                            style={{
                                                marginTop: '16px',
                                                marginBottom: 0
                                            }}
                                        />
                                        <Grid xs={6} item>
                                            <AcSelect
                                                header="Type"
                                                name="type"
                                                value={values.type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.type &&
                                                    Boolean(errors.type)
                                                }
                                                items={Object.values(
                                                    EProductType
                                                ).map((type: string) => {
                                                    return {
                                                        key: type,
                                                        value: type,
                                                        content: type
                                                    };
                                                })}
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <AcSelect
                                                header="Priority"
                                                name="priority"
                                                value={values.priority}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.priority &&
                                                    Boolean(errors.priority)
                                                }
                                                items={Object.values(
                                                    EProductPriority
                                                ).map((priority: string) => {
                                                    return {
                                                        key: priority,
                                                        value: priority,
                                                        content: priority
                                                    };
                                                })}
                                                tooltip={
                                                    'Used for offers designs containing sub products. In other cases use Main'
                                                }
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <AcInput
                                                header="Prefix"
                                                name="prefix"
                                                variant="outlined"
                                                value={values.prefix}
                                                onChange={(data) => {
                                                    handleChange(data);
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.prefix &&
                                                    Boolean(errors.prefix)
                                                }
                                                helperText={
                                                    touched.prefix
                                                        ? errors.prefix?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <AcInput
                                                header="Suffix"
                                                name="suffix"
                                                variant="outlined"
                                                value={values.suffix}
                                                onChange={(data) => {
                                                    handleChange(data);
                                                }}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.suffix &&
                                                    Boolean(errors.suffix)
                                                }
                                                helperText={
                                                    touched.suffix
                                                        ? errors.suffix?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </AcCard>
                                <Divider />
                                <AcCard
                                    stackContainer={false}
                                    title="Font Color"
                                >
                                    <Grid xs={12} item>
                                        <AcInput
                                            header="Fill Color"
                                            type="color"
                                            name="textFontColorHex"
                                            variant="outlined"
                                            value={values.textFontColorHex}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={
                                                touched.textFontColorHex &&
                                                Boolean(errors.textFontColorHex)
                                            }
                                            helperText={
                                                touched.textFontColorHex
                                                    ? errors.textFontColorHex?.toString()
                                                    : ''
                                            }
                                            tooltip={
                                                'This color will highlight the quantity of this product in the bundles'
                                            }
                                        />
                                    </Grid>
                                </AcCard>
                                <Divider />
                                <AcCard
                                    stackContainer={false}
                                    title="Product Assets"
                                    description="This image will appear above the item's quantity."
                                >
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={1.5}
                                    >
                                        <Grid xs={6} item>
                                            <AcSelect
                                                required
                                                header="Product Image"
                                                name="formImages.product"
                                                value={
                                                    values.formImages.product
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={
                                                    pictures.isLoading ||
                                                    pictures.isRefetching
                                                }
                                                uploadConfig={{
                                                    onUploadSuccess: async (
                                                        uploadData: any
                                                    ) => {
                                                        await pictures.refetch();
                                                        setFieldValue(
                                                            'formImages.product',
                                                            uploadData!.data.url
                                                        );
                                                    },
                                                    uploadType:
                                                        EAssetType.PRODUCT
                                                }}
                                                error={
                                                    touched.formImages
                                                        ?.product &&
                                                    Boolean(
                                                        errors.formImages
                                                            ?.product
                                                    )
                                                }
                                                items={
                                                    !pictures.isLoading &&
                                                    pictures.data
                                                        ? pictures.data
                                                              .filter(
                                                                  (p: any) =>
                                                                      p.type ===
                                                                      EAssetType.PRODUCT
                                                              )
                                                              .map(
                                                                  (
                                                                      picture: any
                                                                  ) => {
                                                                      if (
                                                                          picture.type ===
                                                                          EAssetType.PRODUCT
                                                                      ) {
                                                                          return {
                                                                              content:
                                                                                  picture.name,
                                                                              key: picture.url,
                                                                              value: picture.url,
                                                                              renderFunction:
                                                                                  () => (
                                                                                      <Stack
                                                                                          className="formContent-imageSelected"
                                                                                          direction="row"
                                                                                          spacing={
                                                                                              2
                                                                                          }
                                                                                      >
                                                                                          <img
                                                                                              alt={
                                                                                                  picture.name
                                                                                              }
                                                                                              src={
                                                                                                  picture.url
                                                                                              }
                                                                                          />
                                                                                          <div
                                                                                              style={{
                                                                                                  overflow:
                                                                                                      'hidden',
                                                                                                  textOverflow:
                                                                                                      'ellipsis',
                                                                                                  marginLeft: 0
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  picture.name
                                                                                              }
                                                                                          </div>
                                                                                      </Stack>
                                                                                  )
                                                                          };
                                                                      }
                                                                      return null;
                                                                  }
                                                              )
                                                        : []
                                                }
                                                tooltip={
                                                    'For optimized display, make sure your image are at least 300px with 1:1 aspect ratio'
                                                }
                                            />
                                        </Grid>
                                        <Grid xs={6} item>
                                            <AcSelect
                                                header="Prefix Image"
                                                name="formImages.productPrefix"
                                                value={
                                                    values.formImages
                                                        .productPrefix
                                                }
                                                onClear={() => {
                                                    setFieldValue(
                                                        'formImages.productPrefix',
                                                        ''
                                                    );
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                uploadConfig={{
                                                    onUploadSuccess: async (
                                                        uploadData: any
                                                    ) => {
                                                        await pictures.refetch();
                                                        setFieldValue(
                                                            'formImages.productPrefix',
                                                            uploadData!.data.url
                                                        );
                                                    },
                                                    uploadType:
                                                        EAssetType.PRODUCT_PREFIX
                                                }}
                                                error={
                                                    touched.formImages
                                                        ?.productPrefix &&
                                                    Boolean(
                                                        errors.formImages
                                                            ?.productPrefix
                                                    )
                                                }
                                                items={
                                                    !pictures.isLoading &&
                                                    pictures.data
                                                        ? pictures.data
                                                              .filter(
                                                                  (p: any) =>
                                                                      p.type ===
                                                                      EAssetType.PRODUCT_PREFIX
                                                              )
                                                              .map(
                                                                  (
                                                                      picture: any
                                                                  ) => {
                                                                      if (
                                                                          picture.type ===
                                                                          EAssetType.PRODUCT_PREFIX
                                                                      ) {
                                                                          return {
                                                                              content:
                                                                                  picture.name,
                                                                              key: picture.url,
                                                                              value: picture.url,
                                                                              renderFunction:
                                                                                  () => (
                                                                                      <Stack
                                                                                          className="formContent-imageSelected"
                                                                                          direction="row"
                                                                                          spacing={
                                                                                              2
                                                                                          }
                                                                                      >
                                                                                          <img
                                                                                              alt={
                                                                                                  picture.name
                                                                                              }
                                                                                              src={
                                                                                                  picture.url
                                                                                              }
                                                                                          />
                                                                                          <div
                                                                                              style={{
                                                                                                  overflow:
                                                                                                      'hidden',
                                                                                                  textOverflow:
                                                                                                      'ellipsis',
                                                                                                  marginLeft: 0
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  picture.name
                                                                                              }
                                                                                          </div>
                                                                                      </Stack>
                                                                                  )
                                                                          };
                                                                      }
                                                                      return null;
                                                                  }
                                                              )
                                                        : []
                                                }
                                                tooltip={
                                                    'For optimized display, make sure your image are at least 300px with 1:1 aspect ratio'
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </AcCard>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </AcContentWrapper>
        </AcViewWrapper>
    );
};

export default ProductsForm;
