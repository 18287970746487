import { useMutation, useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { Product } from '../components/ProductsForm/ProductsForm.types';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig,getApiRouteOrMock } from './api.utils';

export function useProduct(currentPublisherId: string | null, productId?: string) {
    const axios = useAxios();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getProduct = useQuery({
        ...fetchConfig.single,
        queryKey: [EQueryKeys.PRODUCT],
        queryFn: async () => {
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.PRODUCTS)}/${productId}`,
                {},
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId && !!productId : !!productId
    });

    const getProducts = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.PRODUCTS],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(EApiRoutes.PRODUCTS),
                {},
                currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
            );
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const addProduct = useMutation(async (newProduct: Product) => {
        return await axios.post(
            getApiRouteOrMock(EApiRoutes.PRODUCTS),
            newProduct
        );
    });

    const deleteProduct = useMutation(async (productId: string) => {
        return await axios.del(
            `${getApiRouteOrMock(EApiRoutes.PRODUCTS)}/${productId}`
        );
    });

    const updateProduct = useMutation(
        async (updatedProductForm: { id: string; data: Partial<Product> }) => {
            return await axios.put(
                `${getApiRouteOrMock(EApiRoutes.PRODUCTS)}`,
                {
                    id: updatedProductForm.id,
                    ...updatedProductForm.data
                }
            );
        }
    );

    return {
        getProduct,
        getProducts,
        addProduct,
        deleteProduct,
        updateProduct
    };
}
