export const AVAILABLE_COUNTRIES = [
    {
        _id: 'jpn',
        twoLetterId: 'jp'
    },
    {
        _id: 'bih',
        twoLetterId: 'ba'
    },
    {
        _id: 'srb',
        twoLetterId: 'rs'
    },
    {
        _id: 'fra',
        twoLetterId: 'fr'
    },
    {
        _id: 'deu',
        twoLetterId: 'de'
    },
    {
        _id: 'bra',
        twoLetterId: 'br'
    },
    {
        _id: 'isr',
        twoLetterId: 'il'
    },
    {
        _id: 'mne',
        twoLetterId: 'me'
    },
    {
        _id: 'arg',
        twoLetterId: 'ar'
    },
    {
        _id: 'aus',
        twoLetterId: 'au'
    },
    {
        _id: 'can',
        twoLetterId: 'ca'
    },
    {
        _id: 'che',
        twoLetterId: 'ch'
    },
    // {
    //     _id: 'chl',
    //     twoLetterId: 'cl'
    // },
    {
        _id: 'chn',
        twoLetterId: 'cn'
    },
    // {
    //     _id: 'col',
    //     twoLetterId: 'co'
    // },
    {
        _id: 'dnk',
        twoLetterId: 'dk'
    },
    {
        _id: 'egy',
        twoLetterId: 'eg'
    },
    {
        _id: 'gbr',
        twoLetterId: 'gb'
    },
    {
        _id: 'hkg',
        twoLetterId: 'hk'
    },
    {
        _id: 'hun',
        twoLetterId: 'hu'
    },
    {
        _id: 'idn',
        twoLetterId: 'id'
    },
    {
        _id: 'ind',
        twoLetterId: 'in'
    },
    // {
    //     _id: 'kor',
    //     twoLetterId: 'kr'
    // },
    {
        _id: 'mar',
        twoLetterId: 'ma'
    },
    {
        _id: 'mex',
        twoLetterId: 'mx'
    },
    {
        _id: 'mys',
        twoLetterId: 'my'
    },
    {
        _id: 'nga',
        twoLetterId: 'ng'
    },
    {
        _id: 'nor',
        twoLetterId: 'no'
    },
    {
        _id: 'nzl',
        twoLetterId: 'nz'
    },
    {
        _id: 'per',
        twoLetterId: 'pe'
    },
    {
        _id: 'phl',
        twoLetterId: 'ph'
    },
    {
        _id: 'pak',
        twoLetterId: 'pk'
    },
    {
        _id: 'pol',
        twoLetterId: 'pl'
    },
    {
        _id: 'rou',
        twoLetterId: 'ro'
    },
    {
        _id: 'sau',
        twoLetterId: 'sa'
    },
    {
        _id: 'sgp',
        twoLetterId: 'sg'
    },
    {
        _id: 'zaf',
        twoLetterId: 'za'
    },
    {
        _id: 'esp',
        twoLetterId: 'es'
    },
    {
        _id: 'tha',
        twoLetterId: 'th'
    },
    {
        _id: 'tur',
        twoLetterId: 'tr'
    },
    {
        _id: 'are',
        twoLetterId: 'ae'
    },
    {
        _id: 'usa',
        twoLetterId: 'us'
    },
    {
        _id: 'hrv',
        twoLetterId: 'hr'
    },
    {
        _id: 'aut',
        twoLetterId: 'at'
    },
    {
        _id: 'bel',
        twoLetterId: 'be'
    },
    {
        _id: 'cyp',
        twoLetterId: 'cy'
    },
    {
        _id: 'est',
        twoLetterId: 'ee'
    },
    {
        _id: 'fin',
        twoLetterId: 'fi'
    },
    {
        _id: 'grc',
        twoLetterId: 'gr'
    },
    {
        _id: 'irl',
        twoLetterId: 'ie'
    },
    {
        _id: 'ita',
        twoLetterId: 'it'
    },
    {
        _id: 'lva',
        twoLetterId: 'lv'
    },
    {
        _id: 'ltu',
        twoLetterId: 'lt'
    },
    {
        _id: 'lux',
        twoLetterId: 'lu'
    },
    {
        _id: 'mlt',
        twoLetterId: 'mt'
    },
    {
        _id: 'nld',
        twoLetterId: 'nl'
    },
    {
        _id: 'prt',
        twoLetterId: 'pt'
    },
    {
        _id: 'svk',
        twoLetterId: 'sk'
    },
    {
        _id: 'svn',
        twoLetterId: 'si'
    },
    {
        _id: 'bgr',
        twoLetterId: 'bg'
    },
    {
        _id: 'swe',
        twoLetterId: 'se'
    },
    {
        _id: 'cze',
        twoLetterId: 'cz'
    },
    {
        _id: 'twn',
        twoLetterId: 'tw'
    },
    {
        _id: 'vnm',
        twoLetterId: 'vn'
    },
    {
        _id: 'kor',
        twoLetterId: 'kr'
    },
    {
        _id: 'chl',
        twoLetterId: 'cl'
    },
    {
        _id: 'qat',
        twoLetterId: 'qa'
    },
    {
        _id: 'kaz',
        twoLetterId: 'kz'
    }
];
