import { Route,Routes } from 'react-router-dom';

import BadgesForm from './BadgesForm/BadgesForm';
import BadgesTable from './BadgesTable/BadgesTable';

import '../../views/view.scss';

const BadgesView = () => {
    return (
        <Routes>
            <Route path="/" element={<BadgesTable />} />
            <Route path="/form" element={<BadgesForm />} />
            <Route path="/form/:offerId" element={<BadgesForm edit={true} />} />
            <Route
                path="/dup/:offerId"
                element={<BadgesForm edit={false} dup={true} />}
            />
        </Routes>
    );
};

export default BadgesView;
