import { useEffect, useRef, useState } from 'react';

import { CSVLink } from 'react-csv';
import { useLocation, useNavigate } from 'react-router-dom';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { getGridStringOperators, GridActionsCellItem, GridSortModel } from '@mui/x-data-grid';
import usePermissions from 'api/usePermissions';
import useUsers from 'api/useUsers';
import { EPublisherSolutionModel, User } from 'common/contracts';
import { geo } from 'constants/geo';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { formatReceiptNumber } from 'utils/formattingUtil';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';

import FilterBar from 'components/FilterBar/FilterBar';

import useOrders from '../../api/useOrders';
import { DATE_TIME_FORMAT, NEW_VERSION } from '../../constants/constants';
import {
    EButtonColor,
    ELocalStorageKeys,
    ENotificationType,
    EOrderStatus,
    GridFeatureMode,
    SortingDirection,
    SortingOrderValue
} from '../../constants/enums';
import { StatusLabel } from '../../design-system/StatusLabel/StatusLabel';
import { useMultiplier } from '../../hooks/useMultiplier';
import { useNotifications } from '../../hooks/useNotifications';
import { usePaginationFromURL } from '../../hooks/usePaginationFromURL';
import { handleDateRangeApplied, handleSameDateRangeApplied } from '../../utils/getDateRange';
import { isEmptyString, NullableString } from '../../utils/isEmptyString';
import { getSelectProjectOptions } from '../../utils/projectsUtil';
import DataTable from '../DataTable/DataTable';
import DialogModal from '../Dialog/Dialog';
import FirstActionModal from '../FirstActionModal/FirstActionModal';
import ProjectsSelect from '../ProjectsSelect/ProjectsSelect';

import { statusDictionary } from './OrdersTable.types';

import './style.scss';

const DEFAULT_START_DATE = '2023-01-01T00:00:00.000Z';
const DEFAULT_END_DATE = dayjs()
    .utc()
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .toISOString();

const OrdersTable = () => {
    const projectsDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.projects;
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const { enqueueSnackbar } = useNotifications();
    const navigate = useNavigate();
    const location = useLocation();
    const { fetchFeatureFlags } = useUsers(
        false,
        versionDetails,
        undefined,
        false
    );
    const [useReceiptFeature, setUseReceiptFeature] = useState<boolean>(false);
    const { page, rows, sortValue, direction } = usePaginationFromURL('orders');
    const [currentPage, setCurrentPage] = useState(page);
    const [rowsPerPage, setRowsPerPage] = useState(rows);
    const [isSuccessSearch, setIsSuccessSearch] = useState<boolean>(false);
    const [playerId, setPlayerId] = useState<string>('');
    const [orderId, setOrderId] = useState<string>('');
    const [statuses, setStatuses] = useState<string[]>([]);
    const [formattedRangeDates, setFormattedRangeDates] = useState<
        string[] | null[]
    >([null, null]);
    const [, setPreviousFormattedRangeDates] = useState<string[] | null[]>([
        null,
        null
    ]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [rangeDates, setRangeDates] = useState([
        dayjs(DEFAULT_START_DATE),
        dayjs(DEFAULT_END_DATE)
    ]);
    const [publisherPurchaseId, setPublisherPurchaseId] = useState<string>('');
    const [receiptId, setReceiptId] = useState<string>('');
    const [sorting, setSorting] = useState<{
        sortValue: SortingOrderValue;
        direction: SortingDirection;
    }>({
        sortValue: sortValue,
        direction: direction
    });

    const { getPermissions } = usePermissions();
    const permissions = getPermissions();
    const allowedPublishers =
        permissionsUtil.getAccessPublisherOrders(permissions);

    const [selectedProjects, setSelectedProjects] = useState<string[]>(
        projectsDetails
            ?  getSelectProjectOptions(projectsDetails, allowedPublishers)
                    .map((project) => project.value)
            : []
    );

    let filters: any = {
        orderId,
        playerId,
        receiptId: receiptId?.replace(/-/g, ''),
        publisherPurchaseId,
        statuses,
        startDate: formattedRangeDates[0] || DEFAULT_START_DATE,
        endDate: formattedRangeDates[1] || DEFAULT_END_DATE,
        selectedProjects: selectedProjects.join(',')
    };
    const { getOrders: orders, makeRefund } = useOrders(
        undefined,
        { currentPage, rowsPerPage },
        sorting,
        {
            ...filters
        },
        versionDetails
    );

    const getSearchResult = (searchValue: NullableString) =>
        setIsSuccessSearch(!isEmptyString(searchValue));

    const timeout = useRef<any>(null);
    const debounce = (func: any, wait = 250) => {
        timeout && clearTimeout(timeout.current);
        timeout.current = setTimeout(func, wait);
    };

    const totalCount = orders.data?.totalCount || 0;
    const [csvData, setCsvData] = useState<any[]>([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const downloadCSVRef = useRef(null);

    const [selectedOrder, setSelectedOrder] = useState<{
        id: string;
        amount: number;
        amountFormatted: string;
        currency: string;
        publisherId: string;
    } | null>(null);

    const { getOrderMultiplier } = useMultiplier(selectedOrder?.publisherId ?? '');
    useEffect(() => {
        const queryParams = new URLSearchParams();
        queryParams.set('page', String(currentPage));
        queryParams.set('rows', String(rowsPerPage));
        queryParams.set('sortValue', sorting.sortValue);
        queryParams.set('direction', sorting.direction);

        const navigationOpt: {
            replace: boolean;
            state?: Record<string, boolean>;
        } = { replace: true };
        if (location?.state) {
            navigationOpt.state = location.state;
        }
        navigate(
            `${location.pathname}?${queryParams.toString()}`,
            navigationOpt
        );
    }, [currentPage, rowsPerPage, sorting]);

    useEffect(() => {
        if (!orders.data) return;
        const filteredData = orders.data?.orders.map((record: any) => {
            const modifiedRecord = {
                'Created at': record.createdAt,
                id: record._id,
                'Client Facebook ID': record.clientFbId,
                'Bundle name': record.bundleName,
                'Formatted amount': record.amountFormatted,
                Currency: record.currency,
                'Total amount': record.amountTotal.toNumber(),
                'UTM source': record.utmSource,
                'UTM medium': record.utmMedium,
                'UTM campaign': record.utmCampaign,
                State: record.state,
                Reason: record.reason,
                Provider: record.provider,
                'Publisher payment ID': record.publisherPurchaseId
            };
            return modifiedRecord;
        });
        setCsvData(filteredData);
    }, [orders.data]);

    useEffect(() => {
        if (permissionsUtil.isNewVersion()) {
            if (!fetchFeatureFlags.data) return;
            setUseReceiptFeature(
                fetchFeatureFlags.data.featureFlags
                    .dashboard_display_receipt_number
            );
        } else setUseReceiptFeature(true);
    }, [fetchFeatureFlags.data]);

    if (orders.isError) {
        enqueueSnackbar(
            'Could not load orders list from server',
            ENotificationType.ERROR
        );
    }

    let orderColumns: any = [
        {
            field: 'bundleName',
            minWidth: 200,
            headerName: 'Name',
            flex: 0.2
        },
        {
            field: '_id',
            headerName: 'ID',
            minWidth: 100,
            flex: 0.2,
            ColumnUnsortedIcon: null,
            disableReorder: true,
            filterable: false
        },
        {
            field: 'amountFormatted',
            headerName: 'Price',
            minWidth: 100,
            flex: 0.2,
            renderCell: (params: any) => {
                const multiplier =
                    geo.find((c) => c.currencyCode === params.row.currency)
                        ?.multiplier || 100;
                const amount = `${params.row.currencySymbol}${new Decimal(
                    params.row.amountTotal
                )
                    .div(multiplier)
                    .toNumber()}`;
                return amount || 'N/A';
            }
        },
        {
            field: 'createdAt',
            minWidth: 180,
            flex: 0.2,
            headerName: 'Date',
            renderCell: (params: any) => {
                if (orders.isLoading) return '';
                return (
                    <span>
                        {dayjs(params.row.createdAt)
                            .utc()
                            .format(DATE_TIME_FORMAT)}
                    </span>
                );
            },
            valueGetter: (params: any) => {
                return params.row.createdAt;
            }
        },
        {
            field: 'orderId',
            headerName: 'Order ID',
            minWidth: 200,
            flex: 0.2,
            renderCell: (params: any) => {
                return params.row.orderId || 'N/A';
            },
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains'
            )
        },
        {
            field: 'receiptId',
            headerName: 'Receipt number',
            minWidth: 100,
            filterable: false,
            flex: 0.2,
            renderCell: (params: any) => {
                return params.row.receiptId
                    ? formatReceiptNumber(params.row.receiptId)
                    : '';
            },
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains'
            )
        },
        {
            field: 'playerId',
            headerName: 'Player ID',
            minWidth: 100,
            flex: 0.2,
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains'
            )
        },
        {
            field: 'publisherPurchaseId',
            headerName: 'Transaction ID',
            minWidth: 300,
            flex: 0.2,
            ColumnUnsortedIcon: null,
            disableReorder: true,
            renderCell: (params: any) => {
                return params.row.publisherPurchaseId || 'N/A';
            },
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains'
            )
        },
        {
            field: 'state',
            headerName: 'Status',
            flex: 0.2,
            minWidth: 200,
            renderCell: ({ value }: any) => {
                if (orders.isLoading) return value;
                return (
                    <StatusLabel
                        text={statusDictionary[value as EOrderStatus]?.text}
                        status={statusDictionary[value as EOrderStatus]?.status}
                        prefixIcon={
                            statusDictionary[value as EOrderStatus]?.prefixIcon
                        }
                    />
                );
            },
            valueGetter: (params: any) => {
                return params.row.state;
            }
        },
        {
            field: 'Actions',
            flex: 0,
            type: 'actions',
            width: 50,
            disableReorder: true,
            filterable: false,
            hideable: false,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<VisibilityOutlinedIcon />}
                    label="View"
                    onClick={() => {
                        navigate(`./${params.id}`, {
                            state: {
                                state: params.row.state,
                                isOver30Days:
                                    dayjs().diff(
                                        dayjs(params.row.createdAt),
                                        'days'
                                    ) >= 30,
                                publisherId: params.row.publisherId
                            }
                        });
                    }}
                    showInMenu
                />
            ]
        }
    ];

    if (projectsDetails) {
        orderColumns = [
            {
                field: 'project',
                minWidth: 200,
                headerName: 'Project',
                flex: 0.2,
                valueGetter: (params: any) => {
                    const currentPublisherId = params.row.publisherId;
                    return projectsDetails?.find(
                        (project) => project.publisherId === currentPublisherId
                    )?.publisherName;
                }
            },
            ...orderColumns
        ];
    }

    const fieldToSortingOrderValue: { [key: string]: SortingOrderValue } = {
        bundleName: SortingOrderValue.BUNDLE,
        _id: SortingOrderValue.ID,
        provider: SortingOrderValue.PROVIDER,
        amountFormatted: SortingOrderValue.AMOUNT,
        playerId: SortingOrderValue.PLAYER,
        createdAt: SortingOrderValue.DATE,
        state: SortingOrderValue.STATUS
    };

    const sortValueToFieldMap: { [key in SortingOrderValue]: string } = {
        [SortingOrderValue.BUNDLE]: 'bundleName',
        [SortingOrderValue.ID]: '_id',
        [SortingOrderValue.PROVIDER]: 'provider',
        [SortingOrderValue.AMOUNT]: 'amountFormatted',
        [SortingOrderValue.PLAYER]: 'playerId',
        [SortingOrderValue.DATE]: 'createdAt',
        [SortingOrderValue.STATUS]: 'state'
    };

    const handleSortModelChange = (model: GridSortModel) => {
        if (model.length > 0) {
            const field = model[0].field;
            const mappedSortValue = fieldToSortingOrderValue[field];
            if (mappedSortValue) {
                setSorting({
                    sortValue: mappedSortValue,
                    direction:
                        model[0].sort === SortingDirection.ASC
                            ? SortingDirection.ASC
                            : SortingDirection.DESC
                });
            }
        }
    };

    const handleDateApply = (range: any, hours: any) => {
        if (!hours) {
            handleDateRangeApplied({
                range,
                setRangeDates,
                setFormattedRangeDates,
                setPreviousFormattedRangeDates
            });
            return;
        }
        handleSameDateRangeApplied({
            range,
            hours,
            setRangeDates,
            setFormattedRangeDates,
            setPreviousFormattedRangeDates
        });
    };

    return (
        <>
            <Stack>
                <div style={{ display: 'none' }}>
                    {csvData && (
                        <CSVLink
                            filename={'orders.csv'}
                            ref={downloadCSVRef}
                            data={csvData}
                        />
                    )}
                </div>
                {projectsDetails && (
                    <Stack direction="row" gap={2} mt={'14px'}>
                        <ProjectsSelect
                            projectsDetails={projectsDetails}
                            selectedProjects={selectedProjects}
                            setSelectedProjects={setSelectedProjects}
                            isApplyBtnEnabled={true}
                            allowedPublishers={permissionsUtil.getAccessPublisherOrders(
                                permissions
                            )}
                        />
                    </Stack>
                )}
                <FilterBar
                    projectsDetails={projectsDetails}
                    useReceiptFeature={useReceiptFeature}
                    orderId={orderId}
                    setOrderId={setOrderId}
                    setPlayerId={setPlayerId}
                    setPublisherPurchaseId={setPublisherPurchaseId}
                    setReceiptId={setReceiptId}
                    setStatuses={setStatuses}
                    setFormattedRangeDates={setFormattedRangeDates}
                    formattedRangeDates={formattedRangeDates}
                    playerId={playerId}
                    publisherPurchaseId={publisherPurchaseId}
                    receiptId={receiptId}
                    statuses={statuses}
                    getSearchResult={getSearchResult}
                />
                <DataTable
                    pagination={true}
                    columns={orderColumns}
                    rows={orders.data?.orders || []}
                    loading={orders.isLoading}
                    onPageChange={(newPage) => setCurrentPage(newPage)}
                    onRowsPerPageChange={(newRowsPerPage) => {
                        setRowsPerPage(newRowsPerPage);
                        setCurrentPage(0);
                    }}
                    sortingMode={GridFeatureMode.SERVER}
                    onSortModelChange={handleSortModelChange}
                    defaultHiddenFields={[
                        !useReceiptFeature && versionDetails !== NEW_VERSION
                            ? 'receiptId'
                            : '',
                        '_id',
                        'provider',
                        'paymentId',
                        'bundleId',
                        (localStorageUtil.get(
                            ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL
                        ) === EPublisherSolutionModel.STORE &&
                            'playerEmail') ||
                            ''
                    ]}
                    currentPage={currentPage}
                    totalCount={totalCount}
                    localStorageColumnsKey={
                        ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY
                    }
                    hideFooter={false}
                    error={orders.isError}
                    initialSorting={{
                        sortModel: [
                            {
                                field: sortValueToFieldMap[sorting.sortValue],
                                sort:
                                    sorting.direction === SortingDirection.ASC
                                        ? SortingDirection.ASC
                                        : SortingDirection.DESC
                            }
                        ]
                    }}
                    onNoData={
                        isSuccessSearch && orders.data?.orders.length === 0 ? (
                            <FirstActionModal
                                headline="No orders found"
                                text="Your order was not found. Please check your spelling or try different keywords."
                            />
                        ) : (
                            <FirstActionModal
                                headline="No exact matches"
                                text="Try changing or removing some of your filters or adjusting your search area."
                            />
                        )
                    }
                    filterMode={GridFeatureMode.SERVER}
                    onFilterModelChange={(p) => {
                        p.items.map(
                            (item: { columnField: string; value: string }) => {
                                item.columnField === 'orderId' &&
                                    item.value &&
                                    setOrderId(item.value);
                                item.columnField === 'playerId' &&
                                    item.value &&
                                    setPlayerId(item.value);
                                item.columnField === 'publisherPurchaseId' &&
                                    item.value &&
                                    setPublisherPurchaseId(item.value);
                                item.columnField === 'receiptId' &&
                                    item.value &&
                                    setReceiptId(item.value);
                            }
                        );
                    }}
                />
            </Stack>
            <DialogModal
                width="392px"
                isOpen={!!selectedOrder}
                headline="Refund payment"
                text="Refunds take 5-10 days to appear on a customer's statement."
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            setSelectedOrder(null);
                        }
                    },
                    {
                        text: 'Refund',
                        color: EButtonColor.PRIMARY,
                        variant: 'contained',
                        disabled: isDeleting,
                        func: () => {
                            setIsDeleting(true);
                            makeRefund.mutate(
                                {
                                    orderId: selectedOrder!.id,
                                    publisherId: selectedOrder!.publisherId
                                },
                                {
                                    onSuccess: () => {
                                        orders.refetch();
                                        enqueueSnackbar(
                                            `Order ${selectedOrder!.id} refunded`,
                                            ENotificationType.SUCCESS
                                        );
                                    },
                                    onError: () => {
                                        enqueueSnackbar(
                                            "Couldn't refund order",
                                            ENotificationType.ERROR
                                        );
                                    },
                                    onSettled: () => {
                                        setSelectedOrder(null);
                                        setIsDeleting(false);
                                    }
                                }
                            );
                        }
                    }
                ]}
                closeDialog={() => setSelectedOrder(null)}
                content={
                    <Stack>
                        <Box>
                            <Typography fontSize="12px" marginTop="16px">
                                Refund amount
                            </Typography>
                            <Typography fontSize="20px">
                                <b>
                                    {selectedOrder &&
                                        new Decimal(selectedOrder?.amount)
                                            .div(
                                                getOrderMultiplier(
                                                    selectedOrder.currency
                                                )
                                            )
                                            .toNumber()}{' '}
                                    {selectedOrder?.currency}
                                </b>
                            </Typography>
                        </Box>
                    </Stack>
                }
            />
        </>
    );
};

export default OrdersTable;
