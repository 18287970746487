import { ChangeEvent, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Background } from '@appcharge/shared-ui';
import {
    EBadgePosition,
    EFontWeights
} from '@appcharge/shared-ui/lib/components/Helper/enums';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Stack
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import { renderingUtil } from 'utils/renderingUtil';
import * as yup from 'yup';

import useImages from '../../api/useImages';
import useMetadata from '../../api/useMetadata';
import useOffers from '../../api/useOffers';
import useOffersUI from '../../api/useOffersUI';
import { useProduct } from '../../api/useProduct';
import useSegments from '../../api/useSegments';
import useTheme from '../../api/useTheme';
import useUsers from '../../api/useUsers';
import {
    Badge,
    DynamicOfferUIBadge,
    Offer,
    OfferUI,
    ProductsSequenceProduct,
    User
} from '../../common/contracts';
import AcAutoComplete from '../../components/AcAutoComplete/AcAutoComplete';
import AcCard from '../../components/AcCard/AcCard';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcInput from '../../components/AcInput/AcInput';
import AcSelect from '../../components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from '../../components/AcSelect/AcSelect.types';
import ActionButton from '../../components/ActionButton/ActionButton';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import DisplayTypeSelect from '../../components/DisplayTypeSelect/DisplayTypeSelect';
import { Product } from '../../components/ProductsForm/ProductsForm.types';
import PageTopBar from '../../components/Topbar/PageTopBar';
import {
    MIN_USD_PRICE,
    NEW_VERSION,
    PRICE_VALUE_LIMIT,
    TRIMMED_INPUT_REGEX
} from '../../constants/constants';
import {
    EAssetType,
    EFeatureFlag,
    ELocalStorageKeys,
    ENotificationType,
    EProductPriority,
    EProductType,
    ESalePercentageDisplayType,
    OfferType
} from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { useUnsavedChanges } from '../../hooks/useUnsavedChanges';
import { AuthSliceState } from '../../store/store.types';
import { transformQuantityValue } from '../../utils/formattingUtil';
import { localStorageUtil } from '../../utils/localStorageUtil';

import '../../style/forms.scss';

const PromotionsForm = ({
    edit = false,
    dup = false
}: {
    edit?: boolean;
    dup?: boolean;
}) => {
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { specialOfferId } = useParams();
    const { fetchFeatureFlags } = useUsers(
        false,
        versionDetails,
        currentPublisherId,
        true
    );
    const [data, setData] = useState<Partial<Offer>>({});
    const [currentOfferUI, setCurrentOfferUI] = useState('');
    const navigate = useNavigate();
    const { getOffer, updateOffer, addOffer, getOffers } = useOffers(
        currentPublisherId,
        specialOfferId,
        OfferType.SPECIAL_OFFER
    );
    const { getOffersUI, getOfferUI } = useOffersUI(
        currentPublisherId,
        currentOfferUI
    );
    const hasFeatureFlagMetadata = 
    fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_METADATA
    ];
    const { getStoreTheme } = useTheme(currentPublisherId);
    const { getImages } = useImages(currentPublisherId);
    const { getSegments } = useSegments(currentPublisherId);
    const { getPublisherMetadata, getOfferMetadata, updateOfferMetadata, deleteOfferMetadata } = useMetadata(currentPublisherId, specialOfferId, hasFeatureFlagMetadata);
    const [chosenSegment, setChosenSegment] = useState<string[]>([]);
    const { enqueueSnackbar } = useNotifications();
    const [segmentError, setSegmentError] = useState('');
    const [skuExists, setSkuExists] = useState<boolean>(false);
    const [offerMetadata, setOfferMetadata] = useState<string[]>([]);
    const [metadataError, setMetadataError] = useState('');

    let offerSchema = yup.object().shape({
        setAsFree: yup.boolean(),
        name: yup
            .string()
            .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
            .min(3, `Name length should be longer`)
            .required('Name is required'),
        offerUiId: yup.string().required('Offer Design is required'),
        publisherOfferId: yup
            .string()
            .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
            .required('SKU is required'),
        price: yup.number().when('setAsFree', {
            is: true,
            then: yup
                .number()
                .oneOf([0], 'Price must be 0 if the offer is set as free'),
            otherwise: yup
                .number()
                .min(0.8, 'Price must be at least 0.8')
                .max(5000, 'Price must be at most 5000')
                .required('Price is required')
                .test(
                    'maxDigits',
                    'Price must have at most 10 digits',
                    (value) =>
                        value
                            ? value.toString().length <= PRICE_VALUE_LIMIT
                            : true
                )
        }),
        playerAvailability: yup
            .number()
            .min(1, 'Availability must be at least 1')
            .max(99, 'Availability cannot exceed 99')
            .when('setAsFree', {
                is: true,
                then: yup
                    .number()
                    .required(
                        'Player availability is required when the offer is set as free'
                    ),
                otherwise: yup.number()
            })
            .max(99, 'Availability cannot exceed 99'),
        salePercentage: yup
            .number()
            .min(0, 'Sale amount cannot be negative')
            .test(
                'is-one-decimal',
                'Sale amount can have one decimal place at most',
                (value) =>
                    value === undefined ||
                    /^\d+(\.\d{1})?$/.test(value.toString())
            ),
        metadata: yup.array().of(yup.string())
    });

    useEffect(() => {
        currentOfferUI && getOfferUI.refetch();
    }, [currentOfferUI]);

    useEffect(() => {
        if (
            (versionDetails === NEW_VERSION &&
                specialOfferId &&
                currentPublisherId) ||
            (versionDetails !== NEW_VERSION && specialOfferId)
        ) {
            getOffer.refetch();
        }
    }, [specialOfferId, versionDetails, currentPublisherId]);

    const [products, setProducts] = useState<
        {
            _id: string;
            publisherProductId?: string;
            productId: string;
            image: string;
            imagePrefix: string;
            name: string;
            amount: string | number;
            textFontColorHex: string;
            prefix: string;
            suffix: string;
            priority?: string;
            type?: string;
        }[]
    >([]);
    const [currentProduct, setCurrentProduct] =
        useState<ProductsSequenceProduct | null>(null);
    const { data: productsData, isLoading: isLoadingProducts } =
        useProduct(currentPublisherId).getProducts;

    useEffect(() => {
        data?.segments && setChosenSegment(data?.segments || []);
    }, [data?.segments]);

    useEffect(() => {
        if (!!specialOfferId && !getOffer.isLoading && getOffer.data) {
            setData(getOffer.data.result);
            setCurrentOfferUI(getOffer.data.result.offerUi?._id || '');
            setProducts(
                getOffer.data.result.productsSequence[0].products.map(
                    (
                        p: { product: Partial<Product>; quantity: number },
                        index: number
                    ) => {
                        return {
                            _id: p.product?.productId || index,
                            productId: p.product?.productId,
                            image: p.product?.images?.[0].url,
                            imagePrefix: p.product?.images?.find(
                                (i) => i.type === EAssetType.PRODUCT_PREFIX
                            )?.url,
                            name: p.product?.name,
                            amount: p?.quantity,
                            type: p.product?.type,
                            textFontColorHex: p.product?.textFontColorHex,
                            prefix: p.product?.prefix,
                            suffix: p.product?.suffix,
                            publisherProductId: p.product?.publisherProductId
                        };
                    }
                )
            );
        }
    }, [getOffer.data, getOffer.isLoading, specialOfferId]);

    const {
        values,
        handleChange,
        handleBlur,
        submitForm,
        isValid,
        dirty,
        errors,
        touched,
        setFieldValue,
        setTouched,
        validateField,
        validateForm
    } = useFormik({
        validateOnMount: true,
        validationSchema: offerSchema,
        enableReinitialize: true,
        initialValues: {
            setAsFree:
                edit &&
                data.productsSequence?.[0]?.priceInUsdCents !== undefined &&
                new Decimal(data.productsSequence[0].priceInUsdCents)
                    .div(100)
                    .toNumber() === 0,
            publisherOfferId: data.publisherOfferId || '',
            price:
                data.productsSequence?.[0]?.priceInUsdCents !== undefined
                    ? new Decimal(data.productsSequence[0].priceInUsdCents)
                          .div(100)
                          .toNumber() === 0
                        ? 0
                        : new Decimal(data.productsSequence[0].priceInUsdCents)
                              .div(100)
                              .toNumber()
                    : MIN_USD_PRICE,
            playerAvailability:
                data.productsSequence?.[0]?.playerAvailability ?? undefined,
            name: data.name ? `${data.name}${dup ? '_copy' : ''}` : '',
            description: data.description || '',
            type: data.type || '',
            intervals: data.intervals || [],
            offerUiId: data.offerUi?._id || data.offerUi?.offerUiId || '',
            coolDownInHours: data.coolDownInHours || 0,
            segments: data.segments || [],
            quantity: '',
            productsSequence: data.productsSequence || {},
            leftBadge:
                (
                    data.dynamicOfferUi?.badges?.find(
                        (b) =>
                            b?.badge?.position === 'left' &&
                            b.badge?.type === 'emblem'
                    )?.badge as Badge
                )?.badgeId || '',
            rightBadge:
                (
                    data.dynamicOfferUi?.badges?.find(
                        (b) =>
                            b?.badge?.position === 'right' &&
                            b.badge?.type === 'emblem'
                    )?.badge as Badge
                )?.badgeId || '',
            salePercentage: data.dynamicOfferUi?.salePercentage || 0,
            salePercentageDisplayType:
                data.dynamicOfferUi?.salePercentageDisplayType ||
                ESalePercentageDisplayType.PERCENTAGE
        },
        onSubmit: async (values) => {
            const today = new Date();
            const in100years = new Date(
                today.getTime() + 60000 * 60 * 24 * 365 * 100
            );
            let dynamicOfferUiBadges: DynamicOfferUIBadge[] = [];
            if (values.leftBadge) {
                dynamicOfferUiBadges.push({
                    salesPercentage: 1,
                    badgeId: values.leftBadge,
                    publisherBadgeId: 'N/A',
                    position: 'left' as EBadgePosition.LEFT
                });
            }
            if (values.rightBadge) {
                dynamicOfferUiBadges.push({
                    salesPercentage: 1,
                    badgeId: values.rightBadge,
                    publisherBadgeId: 'N/A',
                    position: 'right' as EBadgePosition.RIGHT
                });
            }
            const newOffer: Partial<Offer> & { sectionId?: string | null } = {
                publisherOfferId: values.publisherOfferId,
                name: values.name,
                description: values.description || 'N/A',
                type: 'SpecialOffer',
                active: true,
                intervals: [
                    {
                        startDate: today.toISOString(),
                        endDate: in100years.toISOString()
                    }
                ],
                offerUiId: values.offerUiId,
                coolDownInHours: 1,
                segments: chosenSegment,
                productsSequence: [
                    {
                        index: 1,
                        products: products.map((p) => ({
                            productId: p.productId,
                            quantity: p.amount,
                            publisherProductId: p.publisherProductId
                        })),
                        priceInUsdCents: values.setAsFree
                            ? 0
                            : new Decimal(values.price).mul(100).toNumber(),
                        ...(values.playerAvailability
                            ? { playerAvailability: values.playerAvailability }
                            : undefined)
                    }
                ],
                dynamicOfferUi: {
                    badges: [],
                    salePercentage: values.salePercentage || null,
                    salePercentageDisplayType:
                        values.salePercentageDisplayType ||
                        ESalePercentageDisplayType.PERCENTAGE
                }
            };
            if (edit && specialOfferId && !dup) {
                updateOffer.mutate(
                    { offerId: specialOfferId, form: newOffer },
                    {
                        onSuccess: () => {
                            enqueueSnackbar(
                                'Promotion edited successfully',
                                ENotificationType.SUCCESS
                            );
                            navigate('../');
                        },
                        onError: () => {
                            enqueueSnackbar(
                                'Error editing promotion',
                                ENotificationType.ERROR
                            );
                        }
                    }
                );
            } else {
                await addOffer.mutate(newOffer, {
                    onSuccess: () => {
                        enqueueSnackbar(
                            'New Promotion added successfully',
                            ENotificationType.SUCCESS
                        );
                        navigate('../');
                    },
                    onError: () => {
                        enqueueSnackbar(
                            'Error adding Promotion',
                            ENotificationType.ERROR
                        );
                    }
                });
            }
        }
    });

    useEffect(() => {
        setOfferMetadata(getOfferMetadata?.data)
    }, [getOfferMetadata?.data])

    useEffect(() => {
        setSkuExists(
            getOffers.data?.offers.find((o: any) => {
                return o.publisherOfferId === values.publisherOfferId;
            }) && !edit
        );
    }, [values.publisherOfferId]);

    const removeProduct = (productId: string) => {
        const newProducts = products.filter((p) => p._id !== productId);
        setProducts(newProducts);
    };

    const editProduct = (productId: string) => {
        return products.forEach((p) => {
            if (p._id === productId) {
                removeProduct(productId);
                setCurrentProduct({
                    product: p.image,
                    productId: p._id,
                    quantity: p.amount
                });
            }
        });
    };

    useUnsavedChanges({ dirty });
    const transformPriceValue = () => {
        if (!values.price) return MIN_USD_PRICE;
        return String(values.price).length > PRICE_VALUE_LIMIT
            ? parseFloat(String(values.price).slice(0, PRICE_VALUE_LIMIT))
            : values.price;
    };

    const handleQuantityChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newQuantity =
            e.target.value.length !== 0
                ? transformQuantityValue(e.target.value)
                : e.target.value;
        setTouched({ ...touched, quantity: true });
        handleChange({
            target: {
                name: 'quantity',
                value: String(newQuantity)
            }
        });
        setCurrentProduct({
            productId: currentProduct?.productId || '',
            quantity: newQuantity
        });
    };

    const handleUpdateOfferMetadata = (val: string) => {
        updateOfferMetadata.mutate(
            { metadata: val, offerId: String(specialOfferId) },
            {
                onSuccess: () => {
                    setOfferMetadata((oldMetadata: string[]) => {
                        const newMetadata = [...oldMetadata, val];
                        setFieldValue('metadata', newMetadata)
                        return newMetadata;
                    });
                    enqueueSnackbar(
                        'Metadata added successfully',
                        ENotificationType.SUCCESS
                    );
                },
                onError: () => {
                    enqueueSnackbar(
                        'Error adding metadata',
                        ENotificationType.ERROR
                    );
                }
            }
        );
    }

    const handleDeleteOfferMetadata = (val: string) => {
        deleteOfferMetadata.mutate(
            { metadata: val, offerId: String(specialOfferId) },
            {
                onSuccess: () => {
                    setOfferMetadata((oldMetadata: string[]) => {
                        const newMetadata = oldMetadata.filter((existingVal: string) => existingVal !== val);
                        setFieldValue('metadata', newMetadata);
                        return newMetadata;
                    })
                    enqueueSnackbar(
                        'Metadata deleted successfully',
                        ENotificationType.SUCCESS
                    );
                },
                onError: () => {
                    enqueueSnackbar(
                        'Error deleting metadata',
                        ENotificationType.ERROR
                    );
                }
            }
        );
    }


    return (
        <AcViewWrapper
            header={
                <PageTopBar
                    disable={false}
                    headline={`${edit ? 'Edit' : 'New'} Promotion ${
                        edit
                            ? `(${
                                  !getOffer.isLoading && getOffer.data?.result
                                      ? getOffer.data?.result?.name
                                      : '...'
                              })`
                            : ''
                    }`}
                    buttons={[
                        {
                            text: 'Save',
                            action: () => submitForm()
                            // disabled:
                            //     !permissionsUtil.canUserEdit() ||
                            //     !isValid ||
                            //     !isEverythingValid
                        }
                    ]}
                    backFunction={() => navigate('../')}
                />
            }
        >
            {!getImages.isLoading && getImages.data && (
                <AcContentWrapper className="formContent">
                    <Grid container mt={'30px'}>
                        <Grid item xs={5}>
                            <Stack>
                                <AcCard stackContainer={false} title="General">
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={1.5}
                                    >
                                        <Grid item xs={6}>
                                            <AcInput
                                                header="Name"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                error={
                                                    touched.name &&
                                                    Boolean(errors.name)
                                                }
                                                helperText={
                                                    touched.name
                                                        ? errors.name?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AcInput
                                                header="SKU"
                                                name="publisherOfferId"
                                                value={values.publisherOfferId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                error={
                                                    (touched.publisherOfferId &&
                                                        Boolean(
                                                            errors.publisherOfferId
                                                        )) ||
                                                    skuExists
                                                }
                                                helperText={
                                                    (touched.publisherOfferId
                                                        ? errors.publisherOfferId?.toString()
                                                        : '') ||
                                                    (skuExists
                                                        ? 'SKU already exists'
                                                        : '')
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AcInput
                                                header="Availability"
                                                name="playerAvailability"
                                                value={
                                                    values.playerAvailability ||
                                                    ''
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={values.setAsFree}
                                                type="number"
                                                error={
                                                    touched.playerAvailability &&
                                                    Boolean(
                                                        errors.playerAvailability
                                                    )
                                                }
                                                helperText={
                                                    touched.playerAvailability
                                                        ? errors.playerAvailability?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AcSelect
                                                header="Offer Design"
                                                name="offerUiId"
                                                value={values.offerUiId}
                                                onChange={(e) => {
                                                    setCurrentOfferUI(
                                                        e.target.value as string
                                                    );
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                required={true}
                                                items={getOffersUI?.data?.result
                                                    .filter(
                                                        (oi: OfferUI) =>
                                                            oi.offerUiType ===
                                                            'SpecialOffer'
                                                    )
                                                    .map((offerUI: OfferUI) => {
                                                        return {
                                                            content:
                                                                offerUI.name,
                                                            key: offerUI._id,
                                                            value: offerUI._id
                                                        };
                                                    })}
                                                error={
                                                    touched.offerUiId &&
                                                    Boolean(errors.offerUiId)
                                                }
                                                helperText={
                                                    touched.offerUiId
                                                        ? errors.offerUiId?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {values.setAsFree ? (
                                                <AcInput
                                                    header="Price"
                                                    name="price"
                                                    value={
                                                        values.price === 0
                                                            ? 'Free'
                                                            : values.price.toString()
                                                    }
                                                    onChange={(e) => {
                                                        setTouched({
                                                            ...touched,
                                                            price: true
                                                        });
                                                        handleChange(e);
                                                        validateField('price');
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={true}
                                                    type="text"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0
                                                        },
                                                        readOnly: true
                                                    }}
                                                    error={
                                                        touched.price &&
                                                        Boolean(errors.price)
                                                    }
                                                    helperText={
                                                        touched.price
                                                            ? errors.price?.toString()
                                                            : ''
                                                    }
                                                />
                                            ) : (
                                                <AcInput
                                                    header="Price"
                                                    name="price"
                                                    value={values.price}
                                                    onChange={(e) => {
                                                        setTouched({
                                                            ...touched,
                                                            price: true
                                                        });
                                                        handleChange(e);
                                                        validateField('price');
                                                    }}
                                                    onBlur={handleBlur}
                                                    required={true}
                                                    type="number"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                $
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: MIN_USD_PRICE
                                                        }
                                                    }}
                                                    error={
                                                        touched.price &&
                                                        Boolean(errors.price)
                                                    }
                                                    helperText={
                                                        touched.price
                                                            ? errors.price?.toString()
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </AcCard>
                                {fetchFeatureFlags.data?.featureFlags?.[
                                    EFeatureFlag.DASHBOARD_OFFERS_FREE
                                ] && (
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            label="Set as Free"
                                            style={{
                                                marginTop: '12px',
                                                marginLeft: '-2px'
                                            }}
                                            control={
                                                <Checkbox
                                                    name="setAsFree"
                                                    style={{
                                                        padding: '0'
                                                    }}
                                                    checked={values.setAsFree}
                                                    onBlur={handleBlur}
                                                    onChange={async (e) => {
                                                        const isChecked =
                                                            e.target.checked;
                                                        await setFieldValue(
                                                            'setAsFree',
                                                            isChecked
                                                        );

                                                        // Update related fields programmatically and validate immediately
                                                        if (isChecked) {
                                                            await setFieldValue(
                                                                'price',
                                                                0
                                                            );
                                                            await setFieldValue(
                                                                'playerAvailability',
                                                                1
                                                            );
                                                        } else {
                                                            await setFieldValue(
                                                                'price',
                                                                MIN_USD_PRICE
                                                            );
                                                            await setFieldValue(
                                                                'playerAvailability',
                                                                undefined
                                                            ); // Or set a default valid value
                                                        }

                                                        await validateForm(); // Revalidate the entire form to reflect changes
                                                    }}
                                                />
                                            }
                                            componentsProps={{
                                                typography: {
                                                    fontSize: 14,
                                                    sx: {
                                                        marginLeft: '2px'
                                                    },
                                                    color: '#717188'
                                                }
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Divider />
                                <AcCard stackContainer={false} title="Segments">
                                    <Grid container columnSpacing={1.5}>
                                        <Grid xs={12} item>
                                            <AcAutoComplete
                                                startAdornment={
                                                    <GridSearchIcon />
                                                }
                                                label="Segment search / add"
                                                onEnter={(val) => {
                                                    setChosenSegment([
                                                        ...chosenSegment,
                                                        val
                                                    ]);
                                                }}
                                                onChangeEvent={(val) => {
                                                    if (
                                                        chosenSegment.includes(
                                                            val
                                                        )
                                                    ) {
                                                        setSegmentError(
                                                            "You can't add the same segment twice"
                                                        );
                                                    } else {
                                                        setSegmentError('');
                                                    }
                                                }}
                                                options={
                                                    (!getSegments.isLoading &&
                                                        getSegments.data?.filter(
                                                            (s: string) =>
                                                                !chosenSegment.includes(
                                                                    s
                                                                )
                                                        )) ||
                                                    []
                                                }
                                                additionalOptions={
                                                    chosenSegment
                                                }
                                                onChange={(e) => {
                                                    const selected = (
                                                        e.target as HTMLElement
                                                    ).textContent;
                                                    selected &&
                                                        setChosenSegment([
                                                            ...chosenSegment,
                                                            selected
                                                        ]);
                                                }}
                                                error={segmentError}
                                            />
                                            {!!chosenSegment.length && (
                                                <Stack
                                                    marginTop={1.5}
                                                    gap={1}
                                                    direction="row"
                                                    flexWrap="wrap"
                                                >
                                                    {chosenSegment.map(
                                                        (val) => (
                                                            <Chip
                                                                className="segment-chip"
                                                                key={val}
                                                                onClick={() => {
                                                                    setChosenSegment(
                                                                        (
                                                                            oldSegments
                                                                        ) => {
                                                                            const newSegments =
                                                                                oldSegments.filter(
                                                                                    (
                                                                                        existingVal
                                                                                    ) =>
                                                                                        existingVal !==
                                                                                        val
                                                                                );
                                                                            setFieldValue(
                                                                                'segments',
                                                                                newSegments
                                                                            );
                                                                            return newSegments;
                                                                        }
                                                                    );
                                                                }}
                                                                label={
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        gap={
                                                                            0.5
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                val
                                                                            }
                                                                        </span>
                                                                        <CloseIcon
                                                                            sx={{
                                                                                width: 14,
                                                                                height: 14
                                                                            }}
                                                                        />
                                                                    </Stack>
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Stack>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AcCard>
                                <Divider />
                                <AcCard stackContainer={false} title="Sale">
                                    <Grid container columnSpacing={1.5}>
                                        <Grid item xs={6}>
                                            <AcInput
                                                header="Amount"
                                                name="salePercentage"
                                                value={values.salePercentage}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="number"
                                                InputProps={{
                                                    startAdornment:
                                                        values?.salePercentageDisplayType ===
                                                        ESalePercentageDisplayType.PERCENTAGE ? (
                                                            <InputAdornment position="start">
                                                                %
                                                            </InputAdornment>
                                                        ) : undefined,
                                                    inputProps: {
                                                        min: 0,
                                                        step: 0.1
                                                    }
                                                }}
                                                error={
                                                    touched.salePercentage &&
                                                    Boolean(
                                                        errors.salePercentage
                                                    )
                                                }
                                                helperText={
                                                    touched.salePercentage
                                                        ? errors.salePercentage?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DisplayTypeSelect
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                value={
                                                    values?.salePercentageDisplayType
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </AcCard>
                                <Divider />
                                <AcCard stackContainer={false} title="Products">
                                    <Grid
                                        container
                                        rowSpacing={1.5}
                                        columnSpacing={1.5}
                                    >
                                        <Grid item xs={6}>
                                            <AcSelect
                                                header="Product"
                                                name=""
                                                value={
                                                    currentProduct?.productId ||
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    setCurrentProduct({
                                                        ...currentProduct,
                                                        productId:
                                                            (e.target
                                                                .value as string) ||
                                                            '',
                                                        quantity:
                                                            currentProduct?.quantity ||
                                                            0
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                                renderType={
                                                    EAcSelectItemRenderType.IMAGE
                                                }
                                                items={
                                                    (!isLoadingProducts &&
                                                        productsData &&
                                                        productsData.result
                                                            ?.map(
                                                                (
                                                                    product: Product
                                                                ) => {
                                                                    if (
                                                                        !products.find(
                                                                            (
                                                                                p
                                                                            ) =>
                                                                                p.productId ===
                                                                                product.productId
                                                                        )
                                                                    ) {
                                                                        return {
                                                                            url: product
                                                                                .images?.[0]
                                                                                ?.url,
                                                                            name: product.name,
                                                                            content:
                                                                                product.name,
                                                                            key: product.productId,
                                                                            value: product.productId
                                                                        };
                                                                    }
                                                                    return undefined;
                                                                }
                                                            )
                                                            ?.filter(
                                                                (v: any) =>
                                                                    v !==
                                                                    undefined
                                                            )) ||
                                                    []
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <AcInput
                                                header="Quantity"
                                                name="quantity"
                                                value={currentProduct?.quantity}
                                                type="number"
                                                inputProps={{
                                                    min: 0
                                                }}
                                                onChange={(e) =>
                                                    handleQuantityChange(e)
                                                }
                                                error={
                                                    touched.quantity &&
                                                    Boolean(errors.quantity)
                                                }
                                                helperText={
                                                    touched.quantity
                                                        ? errors.quantity?.toString()
                                                        : ''
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ActionButton
                                                text={'Add Product'}
                                                variant="outlined"
                                                disabled={
                                                    !currentProduct?.productId ||
                                                    !currentProduct?.quantity
                                                }
                                                onClick={() => {
                                                    const foundProduct =
                                                        productsData!.result.find(
                                                            (pd: Product) => {
                                                                return (
                                                                    pd.productId ===
                                                                    currentProduct?.productId
                                                                );
                                                            }
                                                        );
                                                    setProducts([
                                                        ...products,
                                                        {
                                                            priority: 'main',
                                                            _id: currentProduct!
                                                                .productId!,
                                                            productId:
                                                                currentProduct!
                                                                    .productId!,
                                                            image: foundProduct!
                                                                .images[0].url,
                                                            imagePrefix:
                                                                foundProduct.images?.find(
                                                                    (i: any) =>
                                                                        i.type ===
                                                                        EAssetType.PRODUCT_PREFIX
                                                                )?.url,
                                                            name: foundProduct!
                                                                .name,
                                                            amount: currentProduct!
                                                                .quantity,
                                                            type: foundProduct!
                                                                .type,
                                                            textFontColorHex:
                                                                foundProduct!
                                                                    .textFontColorHex,
                                                            prefix: foundProduct!
                                                                .prefix,
                                                            suffix: foundProduct!
                                                                .suffix
                                                        }
                                                    ]);
                                                    setCurrentProduct({
                                                        productId: '',
                                                        quantity: 0
                                                    });
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} mt={-3}>
                                            <DataTable
                                                columns={[
                                                    {
                                                        field: 'image',
                                                        headerName: '',
                                                        flex: 1,
                                                        renderCell: (
                                                            params: any
                                                        ) => {
                                                            return (
                                                                <img
                                                                    style={{
                                                                        maxHeight:
                                                                            '50%',
                                                                        objectFit:
                                                                            'contain'
                                                                    }}
                                                                    src={
                                                                        params
                                                                            .row
                                                                            .image
                                                                    }
                                                                    alt="productImage"
                                                                />
                                                            );
                                                        }
                                                    },
                                                    {
                                                        field: 'name',
                                                        headerName: 'Name',
                                                        flex: 1
                                                    },
                                                    {
                                                        field: 'amount',
                                                        headerName: 'Amount',
                                                        flex: 1
                                                    },
                                                    {
                                                        field: 'type',
                                                        headerName: 'Type',
                                                        flex: 1
                                                    },
                                                    {
                                                        field: 'remove',
                                                        headerName: 'Remove',
                                                        renderCell: (
                                                            params
                                                        ) => (
                                                            <IconButton
                                                                onClick={() =>
                                                                    removeProduct(
                                                                        params
                                                                            .row
                                                                            ._id
                                                                    )
                                                                }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        ),
                                                        flex: 1
                                                    },
                                                    {
                                                        field: 'edit',
                                                        headerName: 'Edit',
                                                        renderCell: (
                                                            params
                                                        ) => (
                                                            <IconButton
                                                                onClick={() =>
                                                                    editProduct(
                                                                        params
                                                                            .row
                                                                            ._id
                                                                    )
                                                                }
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        ),
                                                        flex: 1
                                                    }
                                                ]}
                                                rows={products}
                                                loading={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </AcCard>
                                <Divider />
                                {hasFeatureFlagMetadata && (<AcCard stackContainer={false} title="Metadata">
                                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid xs={12} item>
                                            <AcAutoComplete
                                                onEnter={(val) => handleUpdateOfferMetadata(val)}
                                                onChangeEvent={(val) => {
                                                    if (offerMetadata.includes(val)) {
                                                        setMetadataError("You can't add the same metadata twice");
                                                    } else {
                                                        setMetadataError('');
                                                    }
                                                }}
                                                options={
                                                    (!getPublisherMetadata?.isLoading &&
                                                        getPublisherMetadata?.data.filter((s: string) => !offerMetadata?.includes(s))) ||
                                                    []
                                                }
                                                additionalOptions={offerMetadata}
                                                startAdornment={<GridSearchIcon />}
                                                onChange={(e) => {
                                                    const selected = String((e.target as HTMLElement).textContent);
                                                    if (offerMetadata.includes(selected)) {
                                                        setMetadataError("You can't add the same metadata twice")
                                                    } else if (selected) {
                                                        handleUpdateOfferMetadata(selected)
                                                    }
                                                }}
                                                helperText={'Press ‘Enter’ to add a new metadata'}
                                                error={metadataError}
                                            />
                                            {offerMetadata && (
                                                <Stack marginTop={1.5} gap={1} direction="row" flexWrap="wrap">
                                                    {offerMetadata.map((val: string) => (
                                                        <Chip
                                                            className="metadata-chip"
                                                            key={val}
                                                            onClick={() => handleDeleteOfferMetadata(val)}
                                                            label={
                                                                <Stack direction="row" justifyContent="center" alignItems="center" gap={0.5}>
                                                                    <span>{val}</span>
                                                                    <CloseIcon sx={{ width: 14, height: 14 }} />
                                                                </Stack>
                                                            }
                                                        />
                                                    ))}
                                                </Stack>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AcCard>)}
                            </Stack>
                        </Grid>
                        <Grid item xs={7} className="iphone-mock-wrapper">
                            <div className="iphone-mock">
                                <Background
                                    backgroundImageMobile={
                                        !getStoreTheme.isLoading &&
                                        getStoreTheme.data.general
                                            .backgroundImageMobile
                                    }
                                    backgroundImageDesktop={
                                        !getStoreTheme.isLoading &&
                                        getStoreTheme.data.general
                                            .backgroundImageDesktop
                                    }
                                    width="100%"
                                    height="100%"
                                    position="absolute"
                                />
                                <div className="bundles-wrapper">
                                    {!getStoreTheme.isLoading &&
                                        renderingUtil.renderSpecialOfferByInternalModel(
                                            getStoreTheme.data.general
                                                .specialOffersInternalViewModel,
                                            {
                                                text:
                                                    getOfferUI.data
                                                        ?.specialOffer?.title ||
                                                    '',
                                                design: 'match',
                                                textColor: getOfferUI.data
                                                    ?.specialOffer
                                                    ?.fontColor || {
                                                    colorOne: '#000'
                                                },
                                                buttonTextColor: '#fff',
                                                fontSize: 0,
                                                fontWeight:
                                                    getOfferUI.data
                                                        ?.specialOffer
                                                        ?.fontWeight ||
                                                    ('normal' as EFontWeights),
                                                borderColor:
                                                    getOfferUI.data
                                                        ?.specialOffer
                                                        ?.borderColor ||
                                                    getStoreTheme.data
                                                        .storeScreen
                                                        .bundleBorderColor,
                                                endTimeActive: false,
                                                selectOffer: () => {},
                                                backgroundColor: {
                                                    colorOne:
                                                        getOfferUI.data
                                                            ?.specialOffer
                                                            ?.backgroundColor
                                                            ?.colorOne ||
                                                        '#ffffff00',
                                                    colorTwo:
                                                        getOfferUI.data
                                                            ?.specialOffer
                                                            ?.backgroundColor
                                                            ?.colorTwo || '',
                                                    direction:
                                                        getOfferUI.data
                                                            ?.specialOffer
                                                            ?.borderColor
                                                            ?.direction ||
                                                        'to right'
                                                },
                                                buttonColor:
                                                    getStoreTheme.data.general
                                                        .buttonColor,
                                                backgroundImage:
                                                    getOfferUI.data
                                                        ?.backgroundImage ||
                                                    'https://media.appcharge.com/defaults/background.png',
                                                price: {
                                                    price: transformPriceValue(),
                                                    isCents: true,
                                                    fractionalSeparator: '.',
                                                    milSeparator: ',',
                                                    spacing: false,
                                                    symbolPosition: 'left',
                                                    currencySymbol: '$',
                                                    currencyCode: 'USD'
                                                },
                                                productsList:
                                                    products.length > 0
                                                        ? products.map((p) => {
                                                              return {
                                                                  quantity:
                                                                      p?.amount,
                                                                  images: {
                                                                      product:
                                                                          p?.image,
                                                                      productPrefix:
                                                                          p?.imagePrefix
                                                                  },
                                                                  textFontColorHex:
                                                                      p?.textFontColorHex,
                                                                  prefix: p?.prefix,
                                                                  suffix: p?.suffix,
                                                                  priority:
                                                                      'Main' as EProductPriority,
                                                                  type: 'Quantity' as EProductType
                                                              };
                                                          })
                                                        : [],
                                                isFree: values.setAsFree,
                                                salePercentage:
                                                    values.salePercentage,
                                                salePercentageDisplayType:
                                                    values.salePercentageDisplayType
                                            }
                                        )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </AcContentWrapper>
            )}
        </AcViewWrapper>
    );
};

export default PromotionsForm;
