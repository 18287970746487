import { Dispatch, SetStateAction } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRowOrderChangeParams } from '@mui/x-data-grid-pro';

import { Section } from '../../../../common/contracts';
import DataTable from '../../../DataTable/DataTable';
import { CurrentSectionItem, SectionsItem } from '../storeTheme.types';

interface SectionsTableProps {
    sections: SectionsItem[];
    setCurrentSection: Dispatch<SetStateAction<CurrentSectionItem | null>>;
    setSections: Dispatch<SetStateAction<SectionsItem[]>>;
    setFieldValue: any;
    setIsEditing: Dispatch<SetStateAction<boolean>>
}

const StoreThemeSectionsTable = ({
    sections,
    setSections,
    setFieldValue,
    setCurrentSection,
    setIsEditing
}: SectionsTableProps) => {
    const editSection = (row: Section) => {
        setCurrentSection({...row});
        setSections((prevSections) => {
            return prevSections.map(prevSection => {
                if (prevSection.publisherSectionId === row.publisherSectionId) {
                    return {...prevSection, toBeEdited: true}
                }
                return prevSection
            })
        })
        setIsEditing(true);
    };

    const removeSection = (id: string) => {
        const existingSection = sections.find(
            (section: SectionsItem) =>
                section.publisherSectionId === id && section.sectionId
        );
        const updatedSectionsList = existingSection
            ? sections.map((section) => {
                  if (section.publisherSectionId === id) {
                      return { ...section, toBeDeleted: true };
                  }
                  return section;
              })
            : sections.filter((section) => section.publisherSectionId !== id);
        setSections(updatedSectionsList);
        setFieldValue('general.sections', updatedSectionsList);
    };

    const sectionsData = sections.filter((section) => !section.toBeDeleted)

    const handleSectionsOrderChange = (targetIndex: number, oldIndex: number) => {
        const sectionsList = [...sectionsData];
        const row = sectionsList.splice(oldIndex, 1)[0];
        sectionsList.splice(targetIndex, 0, row);
        const newSectionsList = sectionsList.map(item => ({...item, toBeEdited: true}));
        setSections(newSectionsList);
        const restSections = sections.filter(section => !sectionsList.includes(section));
        const updatedSectionsData = restSections.length > 0 ? [...newSectionsList, ...restSections] : newSectionsList;
        setFieldValue('general.sections', updatedSectionsData);
    };

    return (
        <DataTable
            columns={[
                {
                    field: 'publisherSectionId',
                    headerName: 'ID',
                    flex: 1
                },
                {
                    field: 'image',
                    headerName: '',
                    flex: 1,
                    renderCell: (params: any) => {
                        return (
                            params.row.image ?
                                <img
                                    style={{
                                        maxHeight: '50%',
                                        objectFit: 'contain'
                                    }}
                                    src={params.row.image}
                                    alt="productImage"
                                /> :
                                ''
                        );
                    }
                },
                {
                    field: 'name',
                    headerName: 'Name',
                    flex: 1
                },
                {
                    field: 'type',
                    headerName: 'Type',
                    flex: 1
                },
                {
                    field: 'remove',
                    headerName: 'Remove',
                    renderCell: (params) => (
                        <IconButton
                            onClick={() => removeSection(params.row._id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    ),
                    flex: 1
                },
                {
                    field: 'edit',
                    headerName: 'Edit',
                    renderCell: (params) => (
                        <IconButton onClick={() => editSection(params.row)}>
                            <EditIcon />
                        </IconButton>
                    ),
                    flex: 1
                }
            ]}
            rows={sectionsData}
            loading={false}
            rowReordering={true}
            onRowOrderChange={(params: GridRowOrderChangeParams) => handleSectionsOrderChange(params.oldIndex, params.targetIndex)}
        />
    );
};

export default StoreThemeSectionsTable;
