import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Background, DailyBonus, PostPurchase } from '@appcharge/shared-ui';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import useMetadata from 'api/useMetadata';
import useUsers from 'api/useUsers';
import { AxiosError } from 'axios';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useImages from '../../api/useImages';
import useOffers from '../../api/useOffers';
import useOffersUI from '../../api/useOffersUI';
import useTheme from '../../api/useTheme';
import { Offer, PopUpProductSequence, User } from '../../common/contracts';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcTabs from '../../components/AcTabs/AcTabs';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DialogModal from '../../components/Dialog/Dialog';
import { Product } from '../../components/ProductsForm/ProductsForm.types';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { NEW_VERSION, TRIMMED_INPUT_REGEX } from '../../constants/constants';
import {
    EAssetType,
    EBundlesInternalViewModel,
    EButtonColor,
    EFeatureFlag,
    ELocalStorageKeys,
    ENotificationType,
    EPopupsFormState,
    EPopupSubType,
    EProductType,
    OfferType
} from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { useUnsavedChanges } from '../../hooks/useUnsavedChanges';
import { AuthSliceState } from '../../store/store.types';
import { errorResponse } from '../../utils/errorsTextHelper';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { permissionsUtil } from '../../utils/permissionsUtil';

import ProductsTab from './ProductsTab';
import SettingsTab from './SettingsTab';
import TriggersTab from './TriggersTab';
import { PopupsFormProps } from './types';


const PopupsForm = ({ edit = false, dup = false }: PopupsFormProps) => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const { fetchFeatureFlags } = useUsers(
        false,
        versionDetails,
        currentPublisherId,
        true
    );
    const hasFeatureFlagMetadata =
    fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_METADATA
    ];

    const { popupId } = useParams();
    const navigate = useNavigate();
    const { addPopUpOffer, updatePopUpOffer, getOffer, getOffers } = useOffers(
        currentPublisherId,
        popupId,
        OfferType.POPUP
    );
    const getBundleOffers= useOffers(
        currentPublisherId
    ).getOffers;
    const getPromotions = useOffers(
        currentPublisherId,
        undefined,
        OfferType.SPECIAL_OFFER
    ).getOffers;
    const { getStoreTheme } = useTheme(currentPublisherId);
    const [tab, setTab] = useState(EPopupsFormState.GENERAL);
    const [currentOfferUI, setCurrentOfferUI] = useState('');
    const { getOfferUI } = useOffersUI(
        currentPublisherId,
        currentOfferUI
    );
    const { getOfferMetadata } = useMetadata(currentPublisherId, popupId, hasFeatureFlagMetadata);
    const [offerMetadata, setOfferMetadata]= useState<string[]>([]);
    const [chosenSegment, setChosenSegment] = useState<string[]>([]);
    const [data, setData] = useState<Partial<Offer>>({});
    const { enqueueSnackbar } = useNotifications();
    const { getImages } = useImages(currentPublisherId);
    const [currentSubType, setCurrentSubType] = useState(EPopupSubType.DAILY_BONUS);
    const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [hoveredProduct, setHoveredProduct] = useState<any>([]);

    const [products, setProducts] = useState<
        {
            _id: string;
            productId: string;
            image: string;
            imagePrefix: string;
            name: string;
            amount: number | string;
            type: string;
            textFontColorHex: string;
            prefix: string;
            suffix: string;
            publisherProductId?: string;
        }[]
    >([]);
    const [bundleModel, setBundleModel] = useState<EBundlesInternalViewModel>();
    const [idValue, setIdValue] = useState<string[]>([]);
    const [offersIds, setOffersIds] = useState<any>([]);
    const [repeatValue, setRepeatValue] = useState<number>(1);

    useEffect(() => {
        if (!getStoreTheme.data?.general?.bundlesInternalViewModel) return;
        setBundleModel(getStoreTheme.data.general.bundlesInternalViewModel);
    }, [getStoreTheme.data]);

    const checkUniqueness = (value: any, field: keyof Offer) => {
        const isTaken = getOffers.data?.offers.find((o: any) => {
            if (!edit) return o[field] === value;
            return o[field] === value && data[field] !== value;
        });
        return !isTaken;
    };

    const offerSchema = yup.object().shape({
        setAsFree: yup.boolean(),
        name: yup
            .string()
            .transform((value) => value.trim())
            .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
            .required('Name is required')
            .when('edit', {
                is: false,
                then: yup.string()
                .test('uniqueness', 'Name already exists', (value) => {
                     return checkUniqueness(value, 'name');
             })
            }),
        offerUiId: yup.string()
            .required('Offer Design is required'),
        publisherOfferId: yup
            .string()
            .transform((value) => value.trim())
            .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
            .required('SKU is required')
            .when('edit', {
                is: false,
                then: yup.string()
                .test('uniqueness', 'SKU already exists', (value) => {
                return checkUniqueness(value, 'publisherOfferId');
            })
            }),
        priority: yup
          .number()
          .min(1, 'Priority must be at least 1')
          .max(10, 'Priority must be at most 10')
          .required('Priority is required'),
        price: yup
          .number()
          .when('setAsFree', {
            is: true,
            then: yup.number()
            .required('Price is required'),
          }),
        repeatEvery: yup
          .number()
          .when('subType', {
            is: EPopupSubType.POST_PURCHASE,
            then: yup.number()
              .min(1, 'It must be at least 1')
              .max(999, 'It must be at most 999')
              .required('Is required')
          })
      });

    useEffect(() => {
        currentOfferUI && getOfferUI.refetch();
    }, [currentOfferUI]);

    const [productSequence, setProductSequence] = useState<
        PopUpProductSequence[]
    >([]);


    useEffect(() => {
        data?.segments && setChosenSegment(data?.segments || []);
    }, [data?.segments]);

    useEffect(() => {
        if (!!popupId && !getOffer.isLoading && getOffer.data) {
            setData(getOffer.data.result);
            setCurrentSubType(getOffer.data.result.subType || EPopupSubType.DAILY_BONUS);
            setRepeatValue(Number(getOffer.data.result.repeatEvery) || 1);
            setIdValue(getOffer.data.result.offerExternalIds?.length === offersIds?.length ? [] : getOffer.data.result.offerExternalIds);
            setCurrentOfferUI(getOffer.data.result.offerUi?._id || '');
            setProductSequence(getOffer.data.result.productsSequence);
            setProducts(
                getOffer.data.result.productsSequence[0].products.map(
                    (
                        p: { product: Partial<Product>; quantity: number },
                        index: number
                    ) => {
                        return {
                            _id: p.product?.productId || index,
                            productId: p.product?.productId,
                            image: p.product?.images?.[0].url,
                            imagePrefix: p.product?.images?.find(
                                (i) => i.type === EAssetType.PRODUCT_PREFIX
                            )?.url,
                            name: p.product?.name,
                            amount: p?.quantity,
                            type: p.product?.type,
                            textFontColorHex: p.product?.textFontColorHex,
                            prefix: p.product?.prefix,
                            suffix: p.product?.suffix,
                            publisherProductId: p.product?.publisherProductId
                        };
                    }
                )
            );
        }
    }, [getOffer.data, getOffer.isLoading, popupId]);

    useEffect(() => {
        const arr: any = [];
        const externalIds = getBundleOffers?.data?.offers.map((offer: any) => {
            const id = offer.publisherOfferId;
            return { content: id, key: id, value: id }
        }) || [];
        const externalIdsPromotions = getPromotions?.data?.offers.map((offer: any) => {
            const id = offer.publisherOfferId;
            return { content: id, key: id, value: id }
        }) || [];
        const allIDs = arr.concat(externalIds, externalIdsPromotions);
        setOffersIds(allIDs);
    }, [getBundleOffers?.data?.offers, getPromotions?.data?.offers]);

    useEffect(() => {
        if (
            (versionDetails === NEW_VERSION && popupId && currentPublisherId) ||
            (versionDetails !== NEW_VERSION && popupId)
        ) {
            getOffer.refetch();
        }
    }, [versionDetails, popupId, currentPublisherId]);

    const {
        values,
        handleChange,
        handleBlur,
        submitForm,
        isValid,
        dirty,
        errors,
        touched,
        setFieldValue,
        setTouched,
        validateField,
    } = useFormik({
        validateOnMount: true,
        validationSchema: offerSchema,
        enableReinitialize: true,
        initialValues: {
            setAsFree:
            edit &&
            data.productsSequence?.[0]?.priceInUsdCents !== undefined &&
            new Decimal(data.productsSequence[0].priceInUsdCents)
                .div(100)
                .toNumber() === 0,
            active: edit ? data?.active : true,
            startOver: true,
            publisherOfferId: data.publisherOfferId || '',
            price: data.productsSequence?.[0]?.priceInUsdCents
            ? new Decimal(data.productsSequence?.[0]?.priceInUsdCents)
                .div(100)
                .toNumber()
            : 0,
            priority: data.priority || 1,
            name: data.name ? `${data.name}${dup ? '_copy' : ''}` : '',
            description: data.description || '',
            subType: data?.subType || EPopupSubType.DAILY_BONUS,
            intervals: data.intervals || [],
            offerUiId: data.offerUi?._id || data.offerUi?.offerUiId || '',
            coolDownInHours: data.coolDownInHours || 0,
            segments: data.segments || [],
            productsSequence: data.productsSequence || [{
                index: 1,
                priceInUsdCents: 0,
                products: []
            }],
            quantity: '',
            repeatEvery: data.triggers ? Number(data.triggers[0].every) : 1,
            triggers: data.triggers,
            offerExternalIds: data.triggers ? data.triggers[0].rules ? data.triggers[0].rules.length !== 0 ? data.triggers[0]?.rules[0].value : [] : [] : [],
        },
        onSubmit: async (values) => {
            const today = new Date();
            const in100years = new Date(
                today.getTime() + 60000 * 60 * 24 * 365 * 100
            );

            const newOffer: Partial<Offer> & { sectionId?: string | null } = {
                active: values.active,
                startOver: values.startOver,
                publisherOfferId: values.publisherOfferId,
                name: values.name,
                description: values.description || 'N/A',
                type: OfferType.POPUP,
                subType: currentSubType,
                priority: values.priority,
                showAfter:
                    currentSubType === EPopupSubType.DAILY_BONUS ? 'login' : 'purchase',
                triggers: (currentSubType === EPopupSubType.POST_PURCHASE || values.subType === EPopupSubType.POST_PURCHASE) ? [{
                        type: 'event',
                        eventName: 'purchase',
                        every: Number(repeatValue),
                        rules: (idValue?.length === 0) || (idValue?.length === offersIds?.length) || (idValue == null) ? [] : [{
                            value: idValue,
                            fieldName: "publisherOfferId",
                            operator: "in"
                        }]
                }] : [{
                        type: 'time',
                        eventName: 'impression',
                        every: 1440,
                    }
                ],
                intervals: [
                    {
                        startDate: today.toISOString(),
                        endDate: in100years.toISOString()
                    }
                ],
                offerUiId: values.offerUiId,
                coolDownInHours: 1,
                segments: chosenSegment,
                productsSequence:
                    currentSubType === EPopupSubType.DAILY_BONUS && productSequence.length >= 1
                        ?  productSequence.map((seq) => {
                            return {
                                index: seq.index || 1,
                                products: seq.products.map((product) => ({
                                    productId: product.product?.productId,
                                    quantity: product.quantity,
                                    publisherProductId:
                                        product.product?.publisherProductId
                                })),
                                priceInUsdCents: !values.setAsFree
                                    ? 0
                                    : new Decimal(values.price)
                                        .mul(100)
                                        .toNumber()
                            };
                        })
                        : [
                            {
                                index: 1,
                                products: products.map((p) => ({
                                    productId: p.productId,
                                    quantity: p.amount
                                })),
                                priceInUsdCents: values.setAsFree
                                    ? 0
                                    : new Decimal(values.price)
                                        .mul(100)
                                        .toNumber()
                            }
                        ],
                dynamicOfferUi: {
                    badges: []
                }
            };
            if (edit && popupId && !dup) {
                updatePopUpOffer.mutate(
                    { offerId: popupId, form: newOffer },
                    {
                        onSuccess: () => {
                            enqueueSnackbar(
                                'Popup edited successfully',
                                ENotificationType.SUCCESS
                            );
                            navigate('../');
                        },
                        onError: (error) => {
                            if (error instanceof AxiosError) {
                                enqueueSnackbar(
                                    errorResponse(error),
                                    ENotificationType.ERROR
                                );
                            }
                        }
                    }
                );
            } else {
                await addPopUpOffer.mutate(newOffer, {
                    onSuccess: () => {
                        enqueueSnackbar(
                            'New Popup added successfully',
                            ENotificationType.SUCCESS
                        );
                        navigate('../');
                    },
                    onError: (error) => {
                        if (error instanceof AxiosError) {
                            enqueueSnackbar(
                                errorResponse(error),
                                ENotificationType.ERROR
                            );
                        }
                    }
                });
            }
        }
    });

    useEffect(() => {
        if (edit && data && data.hasOwnProperty('startOver')) {
            setFieldValue('startOver', Boolean(data.startOver), false);
        }
    }, [edit, data, setFieldValue, values.subType]);

    useEffect(() => {
        setCurrentOfferUI(values.offerUiId);
    }, [values?.offerUiId]);

    useEffect(() => {
        setIdValue(values.offerExternalIds ?? []);
    }, [values?.offerExternalIds]);

    useEffect(() => {
        if (!hoveredProduct.length && enrichedProductDetails.length) {
            setHoveredProduct(enrichedProductDetails);
        }
    }, [productSequence, hoveredProduct]);

    useEffect(() => {
        setOfferMetadata(getOfferMetadata?.data)
    }, [getOfferMetadata?.data])

    const enrichedProductDetails = productSequence?.flatMap((sequence: any) => {
        return sequence.products.length > 0
            ? sequence.products.map((product: any) => {
                return {
                    _id: sequence.id,
                    quantity: product?.quantity || 0,
                    images: {
                        product: product.product?.images?.[0]?.url || '',
                        productPrefix:
                            product.product?.images?.find(
                                (image: any) =>
                                    image.type === EAssetType.PRODUCT_PREFIX
                            )?.url || ''
                    },
                    textFontColorHex: product.product?.textFontColorHex || '',
                    type: 't' as EProductType,
                    prefix: product.product?.prefix,
                    suffix: product.product?.suffix
                };
            })
            : [];
    });

    const handleTabChange = (
        event: any,
        newValue:
            | EPopupsFormState
            | ((prevState: EPopupsFormState) => EPopupsFormState)
    ) => {
        setTab(newValue);
    };

    useUnsavedChanges({ dirty });


    const hasProductsAndTriggers = () => {
        return values?.subType === EPopupSubType.POST_PURCHASE ? idValue?.length > 0 && products?.length > 0 : true
    };

    const updateContent = async () => {
        if (hasProductsAndTriggers()) {
            await submitForm();
        } else {
            if (edit && values.active) {
                setIsUpdateDialogOpen(true);
            } else {
                setIsSaveDialogOpen(true);
            }
        }
    };

    const formikProps = {
        values,
        handleChange,
        handleBlur,
        submitForm,
        isValid,
        dirty,
        errors,
        touched,
        setFieldValue,
        setTouched,
        validateField,
    };

    // @ts-ignore
    return (
        <>
            <AcViewWrapper
                header={
                    <>
                        <PageTopBar
                            withTabsDesign={true}
                            disable={false}
                            headline={`${edit ? 'Edit' : 'New'} Popup ${edit
                                ? `(${!getOffer.isLoading &&
                                    getOffer.data?.result
                                    ? getOffer.data?.result?.name
                                    : '...'
                                })`
                                : ''
                                }`}
                            buttons={[
                                {
                                    text: 'Save',
                                    action: () => updateContent(),
                                    disabled: edit
                                      ? !isValid || !permissionsUtil.canUserEdit() || !dirty
                                      : !permissionsUtil.canUserEdit() || !isValid,
                                  },
                            ]}
                            backFunction={() => navigate('../')}
                        />
                        <Box pl={'3rem'} pr={'3rem'}>
                            <AcTabs value={tab} onChange={handleTabChange}>
                                <Tab
                                    label="Settings"
                                    value={EPopupsFormState.GENERAL}
                                />
                                <Tab
                                    label="Products"
                                    value={EPopupsFormState.PRODUCTS}
                                />
                                {values.subType === EPopupSubType.POST_PURCHASE && <Tab
                                    label="Triggers"
                                    value={EPopupsFormState.TRIGGERS}
                                />}
                            </AcTabs>
                        </Box>
                    </>
                }
            >
                {!getImages.isLoading && getImages.data && (
                    <AcContentWrapper>
                        <Grid container>
                            <Grid item xs={5}>
                                <TabContext value={tab}>
                                    <TabPanel
                                        value={EPopupsFormState.GENERAL}
                                        sx={{ padding: 0 }}
                                    >
                                        <SettingsTab formikProps={formikProps} values={values} getOffers={getOffers} edit={edit} currentOfferUI={currentOfferUI} setCurrentOfferUI={setCurrentOfferUI} currentSubType={currentSubType} setCurrentSubType={setCurrentSubType} chosenSegment={chosenSegment} setChosenSegment={setChosenSegment} offerMetadata={offerMetadata} setOfferMetadata={setOfferMetadata} popupId={popupId} hasFeatureFlagMetadata={hasFeatureFlagMetadata} />
                                    </TabPanel>

                                    <TabPanel
                                        value={EPopupsFormState.PRODUCTS}
                                        sx={{ padding: 0 }}
                                    >
                                        <ProductsTab formikProps={formikProps} values={values} products={products} setProducts={setProducts} currentPublisherId={currentPublisherId} enrichedProductDetails={enrichedProductDetails} setHoveredProduct={setHoveredProduct} productSequence={productSequence} setProductSequence={setProductSequence} />
                                    </TabPanel>
                                    {values.subType === EPopupSubType.POST_PURCHASE && <TabPanel
                                        value={EPopupsFormState.TRIGGERS}
                                        sx={{ padding: 0 }}
                                    >
                                        <TriggersTab formikProps={formikProps} values={values} idValue={idValue} setIdValue={setIdValue} setRepeatValue={setRepeatValue} offersIds={offersIds} edit={edit} />
                                    </TabPanel>}
                                </TabContext>
                            </Grid>
                            {tab !== EPopupsFormState.TRIGGERS && (
                                <Grid item xs={7} className="iphone-mock-wrapper">
                                    <div className="iphone-mock" id={'iphone-mock'}>
                                        <Background
                                            backgroundImageMobile={
                                                !getStoreTheme?.isLoading &&
                                                getStoreTheme?.data?.general
                                                    ?.backgroundImageMobile
                                            }
                                            backgroundImageDesktop={
                                                !getStoreTheme.isLoading &&
                                                getStoreTheme.data.general
                                                    ?.backgroundImageDesktop
                                            }
                                            width="100%"
                                            height="100%"
                                            position="absolute"
                                        />
                                        {currentSubType === EPopupSubType.DAILY_BONUS ?
                                        <DailyBonus
                                            isPreview
                                            title={
                                                getOfferUI?.data?.specialOffer
                                                    ?.title || ''
                                            }
                                            titleColor={
                                                getOfferUI?.data?.specialOffer
                                                    ?.fontColor.colorOne
                                            }
                                            titleWeight={
                                                getOfferUI?.data?.specialOffer
                                                    ?.fontWeight
                                            }
                                            titleSize={getOfferUI?.data?.specialOffer?.fontSize.toString()}
                                            borderWidth={
                                                getOfferUI?.data?.borderWidth
                                            }
                                            borderColor={
                                                getOfferUI?.data?.borderColor
                                            }
                                            modalContainer={() =>
                                                document.getElementById(
                                                    'iphone-mock'
                                                )
                                            }
                                            backgroundImage={
                                                getOfferUI?.data?.backgroundImage
                                            }
                                            products={hoveredProduct}
                                            onCollect={async () => true}
                                            onClose={() => console.log()}
                                            onAnimationSuccessEndNoPreview={() =>
                                                console.log()
                                            }
                                        /> :
                                        <PostPurchase
                                            isPreview
                                            title={
                                                getOfferUI?.data?.specialOffer
                                                    ?.title || ''
                                            }
                                            titleColor={
                                                getOfferUI?.data?.specialOffer
                                                    ?.fontColor.colorOne
                                            }
                                            titleWeight={
                                                getOfferUI?.data?.specialOffer
                                                    ?.fontWeight
                                            }
                                            titleSize={getOfferUI?.data?.specialOffer?.fontSize.toString()}
                                            borderWidth={
                                                getOfferUI?.data?.borderWidth
                                            }
                                            borderColor={
                                                getOfferUI?.data?.borderColor
                                            }
                                            modalContainer={() =>
                                                document.getElementById(
                                                    'iphone-mock'
                                                )
                                            }
                                            backgroundImage={
                                                getOfferUI?.data?.backgroundImage
                                            }
                                            products={hoveredProduct}
                                            onActionBtnClicked={async () => true}
                                            onClose={() => console.log()}
                                            price={{price: values.price, isCents: true, spacing: false, symbolPosition: "left", currencySymbol: '$', currencyCode: "USD", milSeparator: '', fractionalSeparator: '.'}}
                                        />}
                                    </div>
                                </Grid>)}
                        </Grid>
                    </AcContentWrapper>
                )}
            </AcViewWrapper>
            <DialogModal
                isOpen={isUpdateDialogOpen}
                headline="Popup is currently live"
                text="Your changes will update automatically"
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            setIsUpdateDialogOpen(false);
                        }
                    },
                    {
                        text: 'Update',
                        color: EButtonColor.PRIMARY,
                        variant: 'contained',
                        func: submitForm
                    }
                ]}
                closeDialog={() => setIsUpdateDialogOpen(false)}
            />
            {values.subType === EPopupSubType.POST_PURCHASE && <DialogModal
                isOpen={isSaveDialogOpen}
                headline="Please note"
                text="No products or triggers were selected"
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            setIsSaveDialogOpen(false);
                        }
                    },
                    {
                        text: 'Save',
                        color: EButtonColor.PRIMARY,
                        variant: 'contained',
                        func: submitForm
                    }
                ]}
                closeDialog={() => setIsSaveDialogOpen(false)}
            />}
        </>
    );
};

export default PopupsForm;
