import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Box, IconButton, Tab, Tabs } from '@mui/material';

import { UISliceState } from '../../store/store.types';
import { uiActions } from '../../store/uiSlice';
import { permissionsUtil } from '../../utils/permissionsUtil';
import { unsavedChangesUtil } from '../../utils/unsavedChangesUtil';
import AcSearch from '../AcSearch/AcSearch';
import ActionButton from '../ActionButton/ActionButton';
import AcTooltip from '../AcTooltip/AcTooltip';

import { ViewHeaderProps } from './PageTopbar.types';

import './style.scss';

const PageTopBar = ({
    headline,
    tabs,
    backFunction,
    disable,
    buttons,
    search,
    withTabsDesign
}: ViewHeaderProps) => {
    const [tabsValue, setTabsValue] = useState(0);
    const uiState = useSelector(({ ui }: { ui: UISliceState }) => ui);
    const dispatch = useDispatch();

    const handleTabsChange = (_: unknown, newValue: number) => {
        setTabsValue(newValue);
        tabs && tabs.callback && tabs.callback(newValue);
    };

    const handleBackPress = () => {
        if (uiState.isUnsavedChanges) {
            dispatch(uiActions.setUnsavedChangesModalOpen(true));
            unsavedChangesUtil.func = backFunction as any;
        } else {
            backFunction?.();
        }
    };

    return (
        <>
            <Box className="viewHeader">
                <Box
                    className={`topbar ${withTabsDesign ? 'tabs-state' : 'base-state'}`}
                >
                    <Box className="headline">
                        {backFunction && (
                            <IconButton
                                onClick={handleBackPress}
                                className="backButton"
                            >
                                <ChevronLeft fontSize="large" />
                            </IconButton>
                        )}
                        <h1 className="headline_text">{headline}</h1>
                    </Box>
                    <Box className="header-buttons">
                        {buttons?.map((button: any, i: number) =>
                            !button.hidden ? (
                                <ActionButton
                                    key={i}
                                    variant={button.variant || 'contained'}
                                    size="small"
                                    onClick={button.action}
                                    sx={{
                                        textTransform: 'capitalize'
                                    }}
                                    disabled={button.disabled || disable}
                                    icon={button.icon}
                                    text={button.text}
                                    tooltip={
                                        !permissionsUtil.canUserEdit() ? (
                                            <AcTooltip
                                                content={permissionsUtil.generateTooltipEditPermissionString()}
                                            />
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            ) : null
                        )}
                        {search && (
                            <AcSearch
                                categories={search.searchCategories}
                                callback={search.searchCallback}
                            />
                        )}
                    </Box>
                </Box>
                {tabs && (
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabsValue} onChange={handleTabsChange}>
                            {tabs.list.map((tab, i) => (
                                <Tab
                                    style={{ textTransform: 'capitalize' }}
                                    key={i}
                                    label={tab.text}
                                />
                            ))}
                        </Tabs>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default PageTopBar;
