import { toast } from 'react-toastify';

import Notification from '../components/Notification/Notification';
import { ENotificationType } from '../constants/enums';

export const useNotifications = () => {
    const enqueueSnackbar = (
        text: string,
        type: ENotificationType = ENotificationType.SUCCESS,
        description?: string
    ) => {
        (toast as unknown as Record<ENotificationType, any>)[type](
            <Notification text={text} description={description} />
        );
    };
    return { enqueueSnackbar };
};
