import { Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

import AcTooltip from '../AcTooltip/AcTooltip';

import './style.scss';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#6109FF',
            '& + .MuiSwitch-track': {
                backgroundColor: '#6109FF',
                opacity: 1,
                border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
            }
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#fff',
            border: '6px solid #fff'
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100]
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#A8A9B2',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500
        })
    }
}));

export default function CustomizedSwitch({
    status,
    texts,
    functions,
    disabled,
    tooltip
}: {
    status: boolean;
    texts: string[];
    functions: any[];
    disabled?: boolean;
    tooltip?: string;
}) {
    const clickHandler = () => {
        status ? functions[0]() : functions[1]();
    };
    return (
        <Tooltip arrow title={tooltip && <AcTooltip content={tooltip} />}>
            <FormControlLabel
                className="switchButton"
                control={
                    <IOSSwitch
                        disabled={disabled}
                        sx={{ m: 1 }}
                        checked={status}
                    />
                }
                label={status ? texts[0] : texts[1]}
                onClick={!disabled ? clickHandler : undefined}
            />
        </Tooltip>
    );
}
