import './style.scss';

const TableHeaderButton = ({
    cb,
    text,
    icon
}: {
    cb: () => void;
    text: string;
    icon: any;
}) => {
    return (
        <button className="table-header-button" onClick={cb}>
            {icon}
            {text}
        </button>
    );
};

export default TableHeaderButton;
