export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATETIME_FORMAT_12HR_AM_PM = 'DD/MM/YYYY hh:mm:ss A';
export const TIME_TO_STORE_JWT_TOKEN = 12 * 60 * 60 * 1000; // 12 hours
export const PAGINATION_ROWS_PER_PAGE = 100;
export const MIN_USD_PRICE = 0.8;
export const QUANTITY_LIMIT = 24;
export const TRIMMED_INPUT_REGEX = /^(?=.*\S).*$/;

export const PRICE_VALUE_LIMIT = 10;

export const BadgeFields = {
    left: 'leftBadge',
    right: 'rightBadge'
};

export const DefaultAssetsURLs = {
    defaultLogo: 'https://media.appcharge.com/defaults/logo.svg'
};

export const IP_ONLY_REGEX =
    /\b(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/;

export const HTTPS_REGEX = /^https:\/\/.*/;

export const IS_NO_IP_ONLY_HTTPS = (str: string) => {
    return (
        str === undefined || (!IP_ONLY_REGEX.test(str) && HTTPS_REGEX.test(str))
    );
};

export const DEFAULT_LOGOUT_TIMEOUT_SHORT = 900000; // 15 minute
export const DEFAULT_LOGOUT_TIMEOUT_LONG = 10800000; // 3 hours

export const NEW_VERSION = 2;
export const GOOGLE_AUTH_CLIENT_ID = '976681440281-h85m9555us6o50ikv24bvogncn7fuvqb.apps.googleusercontent.com';

