import { useEffect, useState } from 'react';

import { Box, Button, Stack } from '@mui/material';
import { EOrderStatus } from 'constants/enums';
import dayjs from 'dayjs';
import {
    handleDateRangeApplied,
    handleSameDateRangeApplied
} from 'utils/getDateRange';

import AcSearchInput from 'components/AcInput/AcSearchInput';
import AcSearchSelect from 'components/AcSelect/AcSearchSelect';
import OrdersDatePicker from 'components/OrdersTable/components/OrdersDatePicker';
import { statusDictionary } from 'components/OrdersTable/OrdersTable.types';

import { FilterProps } from './FilterBar.types';

const FilterBar: React.FC<FilterProps> = ({
    projectsDetails,
    orderId,
    setOrderId,
    setPlayerId,
    setPublisherPurchaseId,
    setReceiptId,
    setStatuses,
    setFormattedRangeDates,
    playerId,
    publisherPurchaseId,
    receiptId,
    statuses,
    getSearchResult,
    isFree
}) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [, setPreviousFormattedRangeDates] = useState<string[] | null[]>([
        null,
        null
    ]);
    const endDate = dayjs();
    const [rangeDates, setRangeDates] = useState([endDate.subtract(30, 'days'), endDate]);
    const [clearFiltersButton, setClearFiltersButton] = useState<boolean>(false);

    useEffect(() => {
        setClearFiltersButton(playerId || publisherPurchaseId || receiptId || orderId || statuses.length > 0 ? true : false);
    }, [playerId, publisherPurchaseId, receiptId, orderId, statuses])

    const formattedDate = `${rangeDates[0].format(
        'DD/MM/YY'
    )} - ${rangeDates[1].format('DD/MM/YY')}`;

    const handleDateApply = (range: any, hours: any) => {
        if (!hours) {
            handleDateRangeApplied({
                range,
                setRangeDates,
                setFormattedRangeDates,
                setPreviousFormattedRangeDates
            });
            return;
        }
        handleSameDateRangeApplied({
            range,
            hours,
            setRangeDates,
            setFormattedRangeDates,
            setPreviousFormattedRangeDates
        });
    };

    const getStatus = Object.values(EOrderStatus)
        .filter(
            (value) => {
                if (isFree) return (value === EOrderStatus.created || value === EOrderStatus.charge_pending || value === EOrderStatus.charge_succeed || value === EOrderStatus.charge_failed)
                return (value !== EOrderStatus.unpaid && value !== EOrderStatus.payment_refund_cancelled) 
            }  
        )
        .map((value) => ({
            text: (isFree && value === EOrderStatus.charge_failed) ? "Failed" : statusDictionary[value as EOrderStatus]?.text,
            status: value
        }));


    const handleClearFilters = () => {
        getSearchResult('');
        setPublisherPurchaseId('');
        setReceiptId('');
        setOrderId('');
        setPlayerId('');
        setStatuses([]);
        setRangeDates([endDate.subtract(30, 'days'), endDate]);
        setFormattedRangeDates([null, null]);
    };

    return (
        <Stack pt={1} sx={{paddingTop: "16px"}}>
            {!projectsDetails && (
                <Box pb={2}>
                    <h3>Search by:</h3>
                </Box>
            )}
            <Stack direction="row" gap={1} sx={{alignItems: "center", flexWrap: "wrap"}}>
                {projectsDetails && (
                    <OrdersDatePicker
                        isOpen={isDatePickerOpen}
                        setOverlayOpen={setIsDatePickerOpen}
                        handleDateApply={handleDateApply}
                        formattedDate={formattedDate}
                        startDate={rangeDates[0]}
                        endDate={rangeDates[1]}
                    />
                )}
                <AcSearchSelect
                    header="Status"
                    name="status"
                    items={getStatus.map(({ text, status }, index: number) => ({
                        content: text,
                        key: String(index),
                        value: index,
                        filterBy: status
                    }))}
                    sx={{ flexGrow: 1 }}
                    isCheckBox
                    setSelectedItems={setStatuses}
                    value={statuses}
                    className="selector-checkbox"
                    isFree={isFree}
                />
                <AcSearchInput
                    size="small"
                    header="Transaction ID"
                    onChange={(e: any) => {
                        const value = e.target.value;
                        getSearchResult(value);
                        setPublisherPurchaseId(value);
                    }}
                    sx={{ flexGrow: 1 }}
                    value={publisherPurchaseId}
                    setValue={setPublisherPurchaseId}
                />
                <AcSearchInput
                    size="small"
                    header="Player ID"
                    onChange={(e: any) => {
                        const value = e.target.value;
                        getSearchResult(value);
                        setPlayerId(value);
                    }}
                    sx={{ flexGrow: 1 }}
                    value={playerId}
                    setValue={setPlayerId}
                />
                <AcSearchInput
                    size="small"
                    header="Order ID"
                    onChange={(e: any) => {
                        const value = e.target.value;
                        getSearchResult(value);
                        setOrderId(value);
                    }}
                    sx={{ flexGrow: 1 }}
                    value={orderId}
                    setValue={setOrderId}
                />
                {!isFree && (
                    <AcSearchInput
                        size="small"
                        header="Receipt number"
                        onChange={(e: any) => {
                            const value = e.target.value;
                            getSearchResult(value);
                            setReceiptId(value);
                        }}
                        value={receiptId}
                        setValue={setReceiptId}
                    />
                )}

                {clearFiltersButton && <Button
                    variant="text"
                    id="clearFiltersButton"
                    onClick={handleClearFilters}
                >
                    Clear filters
                </Button>}
            </Stack>
        </Stack>
    );
};

export default FilterBar;
