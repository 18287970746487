import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { useFormik } from 'formik';
import { permissionsUtil } from 'utils/permissionsUtil';
import * as yup from 'yup';

import useImages from '../../api/useImages';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcSearch from '../../components/AcSearch/AcSearch';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import ImageItem from '../../components/ImageItem/ImageItem';
import PageTopBar from '../../components/Topbar/PageTopBar';
import {ENotificationType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { AuthSliceState } from '../../store/store.types';

import { getAssetItemValue } from './modals/constants/constants';
import { AssetsCategories } from './modals/constants/enums';
import DeleteAssetModal from './modals/DeleteAssetModal';
import UploadAssetModal from './modals/UploadAssetModal';

import './style.scss';
import '../view.scss';

interface Filter {
    name: string;
    type?: string;
}

export interface AssetsFormValues {
    file: File | null,
    imageId: string,
    category: string
}

const AssetsView = () => {
    const { enqueueSnackbar } = useNotifications();
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const pictures = useImages(currentPublisherId).getImages;
    const { addImage, getImages } = useImages(currentPublisherId);
    const [isUploading, setIsUploading] = useState(false);
    const [filter, setFilter] = useState<Filter>({ name: '', type: 'All' });
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isUploadImageOpen, setUploadImageOpen] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState<string>('');

    let imageUploadSchema = yup.object().shape({
        file: yup.mixed().required('Image file is required'),
        imageId: yup
            .string()
            .required('ID / Name is required')
            .test(
                'is-not-only-spaces',
                'ID / Name cannot be only spaces',
                (value) => {
                    return (value || '').trim().length > 0;
                }
            )
    });

    const {
        values,
        handleBlur,
        submitForm,
        resetForm,
        setFieldValue,
        isValid,
        isSubmitting
    } = useFormik({
        validateOnMount: true,
        validationSchema: imageUploadSchema,
        enableReinitialize: true,
        initialValues: {
            file: null,
            imageId: '',
            category: getAssetItemValue(AssetsCategories.general)
        },
        onSubmit: async (values: AssetsFormValues , { resetForm }) => {
            const trimmedImageId = values.imageId.trim();
            const uploadConfig = {
                onUploadSuccess: async (uploadData: any) => {
                    await pictures.refetch();
                },
                uploadType: values.category,
                name: trimmedImageId
            };
            const file = values.file;
            const formData = new FormData();
            if (!file) return;
            if (file && file.size > 2000000) {
                enqueueSnackbar(
                    `File size limit is 2MB`,
                    ENotificationType.ERROR
                );
                return;
            }
            setIsUploading(true);
            formData.append('image', file as any);
            formData.append('type', uploadConfig!.uploadType as string);
            formData.append('name', uploadConfig!.name as string);
            addImage.mutate(formData, {
                onSuccess: async (data) => {
                    enqueueSnackbar(
                        `File has been uploaded successfully`,
                        ENotificationType.SUCCESS
                    );
                    uploadConfig!.onUploadSuccess?.(data);

                    resetForm();
                    setUploadImageOpen(false);
                },
                onError(error) {
                    console.log({ error });
                    enqueueSnackbar(
                        `Asset has failed to upload`,
                        ENotificationType.ERROR,
                        (
                            (error as AxiosError).response?.data as {
                                message: string;
                            }
                        ).message
                    );
                },
                onSettled() {
                    setIsUploading(false);
                }
            });
        }
    });

    if (getImages.isError) {
        enqueueSnackbar(
            'Could not load assets list from server',
            ENotificationType.ERROR
        );
    }

    const filterSearch = (value: string, category?: string) => {
        if (value !== filter.name || category !== filter.type) {
            setFilter({
                name: value,
                type: category
            });
        }
    };

    const openDeleteDialog = (imageId: string) => {
        setDeleteDialogOpen(true);
        setSelectedImageId(imageId);
    };

    const uploadAsset = () => {
        setUploadImageOpen(true);
    };

    return (
        <AcViewWrapper
            header={
                <PageTopBar
                    headline="Assets"
                    buttons={[
                        {
                            text: 'Upload Asset',
                            action: () => uploadAsset(),
                            hidden: !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.ASSET_LIBRARY,
                                null,
                                EPermissionAction.MANAGE
                            )
                        }
                    ]}
                />
            }
        >
            <AcContentWrapper>
                <AcSearch
                    categories={Object.keys(AssetsCategories).map(
                        (key) => AssetsCategories[key as keyof typeof AssetsCategories]
                    )}
                    callback={filterSearch}
                />
                <Box className="gallery">
                    {getImages.isLoading ? (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                height: '60px',
                                width: '60px'
                            }}
                        />
                    ) : getImages.data.length > 0 ? (
                        getImages.data?.map((image: any, i: number) => {
                            if (
                                (filter.type ===
                                    AssetsCategories[
                                        image.type as keyof typeof AssetsCategories
                                    ] ||
                                    filter.type === 'All') &&
                                (image.name
                                    .toLowerCase()
                                    .includes(filter.name.toLowerCase()) ||
                                    filter.name === '')
                            )
                                return (
                                    <ImageItem
                                        key={i}
                                        deleteImage={openDeleteDialog}
                                        data={image}
                                        classes={'products'}
                                        uploading={false}
                                    />
                                );
                            return null;
                        })
                    ) : (
                        <FirstActionModal
                            headline="Your asset library is still empty"
                            text="Any visual you’ll add here (art elements, background images etc.) will be available to use in your offers"
                        />
                    )}
                </Box>
                <DeleteAssetModal
                    isModalOpen={isDeleteDialogOpen}
                    onModalClosed={() => setDeleteDialogOpen(false)}
                    setDeleteDialogOpen={setDeleteDialogOpen}
                    selectedImageId={selectedImageId}
                />
                <UploadAssetModal
                    onUpload={() => submitForm()}
                    isUploadDisabled={ !isValid || isSubmitting }
                    isModalOpen={isUploadImageOpen}
                    onModalClosed={() => {
                        resetForm();
                        setUploadImageOpen(false);
                    }}
                    setFieldValue={setFieldValue}
                    isUploading={isUploading}
                    imageId={values.imageId}
                    category={values.category}
                    handleBlur={handleBlur}
                />
            </AcContentWrapper>
        </AcViewWrapper>
    );
};

export default AssetsView;
