import { Button, CircularProgress, Tooltip } from '@mui/material';

import { ActionButtonProps } from './ActionButton.types';

import './style.scss';

const ActionButton = ({
    onClick,
    text,
    icon,
    type = 'button',
    variant = 'contained',
    color = 'primary',
    style,
    disabled,
    fullWidth = false,
    tooltip = '',
    isLoading = false,
    buttonClasses
}: ActionButtonProps) => {
    return (
        <Tooltip arrow title={tooltip ? tooltip : ''}>
            <Button
                disabled={disabled}
                type={type}
                className={`button ${
                    variant === 'contained' ? 'primary' : 'secondary'
                } ${buttonClasses}`}
                onClick={onClick}
                style={{ ...style }}
                variant={variant}
                color={color ? color : undefined}
                sx={(theme) => {
                    return {
                        '& .MuiTouchRipple-child': {
                            backgroundColor: `${
                                theme.palette[color || 'primary'].main
                            } !important`
                        },
                        '&.Mui-disabled': {
                            background: theme.palette[color || 'primary'].main
                        }
                    };
                }}
                fullWidth={fullWidth}
                disableElevation={true}
            >
                {!isLoading ? (
                    <>
                        {icon && (
                            <div className="action-button__icon">{icon}</div>
                        )}
                        <span className="action-button__text">{text}</span>
                    </>
                ) : (
                    <CircularProgress
                        className={'action-button__loader'}
                        style={{
                            width: 'auto',
                            height: 'auto'
                        }}
                        size={'24px'}
                    />
                )}
            </Button>
        </Tooltip>
    );
};

export default ActionButton;
