import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export default function useUrlQuery(query: string) {
    const { search } = useLocation();
    const memo = useMemo(() => new URLSearchParams(search), [search]);
    let queryValue = memo.get(query);

    if (query === 'email' && queryValue?.includes(' ')) {
        queryValue = queryValue.replace(/ /g, '+');
    }

    return queryValue;
}
