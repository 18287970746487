import { ELocalStorageKeys } from '../constants/enums';
import { localStorageUtil } from '../utils/localStorageUtil';

const MOCK_URL = 'https://api-dev.appcharge.com/mock/mock';

export const fetchConfig = {
    general: {
        refetchOnWindowFocus: false
    },
    list: {
        refetchOnWindowFocus: false
    },
    single: {
        refetchOnWindowFocus: false,
        enabled: false
    }
};

export enum EApiRoutes {
    USERS = 'USERS',
    USERS_NEW_VERSION = 'USERS_NEW_VERSION',
    ADD_USER_NEW_VERSION = 'ADD_USER_NEW_VERSION',
    UPDATE_USER_ROLE = 'UPDATE_USER_ROLE',
    UPDATE_USER_ROLE_NEW_VERSION = 'UPDATE_USER_ROLE_NEW_VERSION',
    DELETE_USER = 'DELETE_USER',
    DELETE_USER_NEW_VERSION = 'DELETE_USER_NEW_VERSION',
    RESET_USER = 'RESET_USER',
    UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD',
    FORGOT_USER_PASSWORD = 'FORGOT_USER_PASSWORD',
    LOGIN_USER = 'LOGIN_USER',
    LOGIN_USER_GOOGLE = 'LOGIN_USER_GOOGLE',
    LOGOUT_USER = 'LOGOUT_USER',
    BUNDLES = 'BUNDLES',
    DUPLICATE_BUNDLE = 'DUPLICATE_BUNDLE',
    DELETE_IMAGES = 'DELETE_IMAGES',
    IMAGES = 'IMAGES',
    INTEGRATION = 'INTEGRATION',
    ORDERS = 'ORDERS',
    ORDERS_NEW_VERSION = 'ORDERS_NEW_VERSION',
    ORDER_DETAILS = 'ORDER_DETAILS',
    PLAYERS = 'PLAYERS',
    PLAYER = 'PLAYER',
    DELETE_PLAYERS = 'DELETE_PLAYERS',
    ADD_PLAYERS_LIST = 'ADD_PLAYERS_LIST',
    POST_IMAGE = 'POST_IMAGE',
    PRODUCTS = 'PRODUCTS',
    PRICING = 'PRICING',
    PRICING_META = 'PRICING_META',
    PRICE_POINT = 'PRICE_POINT',
    PUBLISHER = 'PUBLISHER',
    PUBLISHER_SETTINGS = 'PUBLISHER_SETTINGS',
    PUBLISHER_USERS = 'PUBLISHER_USERS',
    PUBLISHER_USERS_V2 = 'PUBLISHER_USERS_V2',
    SEGMENTS = 'SEGMENTS',
    METADATA = 'METADATA',
    GRAPHS = 'GRAPHS',
    BARS = 'BARS',
    PIES = 'PIES',
    STATS = 'STATS',
    THEME = 'THEME',
    GEO = 'GEO',
    REFUND = 'REFUND',
    PAYMENTS = 'PAYMENTS',
    PAYMENTS_CONFIG = 'PAYMENTS_CONFIG',
    OFFERS_UI = 'OFFERS_UI',
    BADGES = 'BADGES',
    GET_BADGES = 'GET_BADGES',
    OFFERS = 'OFFERS',
    POPUP_OFFERS = 'POPUP_OFFERS',
    FINANCIAL_REPORTS = 'FINANCIAL_REPORTS',
    FINANCIAL_REPORTS_NEW_VERSION = 'FINANCIAL_REPORTS_NEW_VERSION',
    DISPUTES = 'DISPUTES',
    SECTIONS = 'SECTIONS',
    UPSERT_SECTIONS = 'UPSERT_SECTIONS',
    PAYOUTS = 'PAYOUTS',
    PAYOUTS_NEW_VERSION = 'PAYOUTS_NEW_VERSION',
    FEATURE_FLAGS = 'FEATURE_FLAGS',
    PRICING_POINTS_TEMPLATE = 'PRICING_POINTS_TEMPLATE',
    ROLES = 'ROLES'
}

export const ApiRoutes = {
    USERS: '/dashboard/user',
    USERS_NEW_VERSION: '/dashboard/v2/user/me',
    ADD_USER_NEW_VERSION: '/dashboard/v2/user/invite',
    UPDATE_USER_ROLE: '/dashboard/user',
    UPDATE_USER_ROLE_NEW_VERSION: '/dashboard/v2/user',
    DELETE_USER: '/dashboard/user',
    DELETE_USER_NEW_VERSION: '/dashboard/v2/user',
    RESET_USER: '/dashboard/user/password/reset',
    UPDATE_USER_PASSWORD: '/dashboard/user/password/update',
    FORGOT_USER_PASSWORD: '/dashboard/user/password/forgot',
    LOGIN_USER: '/dashboard/user/login',
    LOGIN_USER_GOOGLE: '/dashboard/user/login/google',
    LOGOUT_USER: '/dashboard/user/logout',
    BUNDLES: '/api/offersets',
    DUPLICATE_BUNDLE: '/api/offersets/duplicate',
    DELETE_IMAGES: '/dashboard/media',
    IMAGES: '/dashboard/media',
    INTEGRATION: '/dashboard/integration',
    ORDERS: '/dashboard/order',
    ORDERS_NEW_VERSION: '/dashboard/v2/order',
    ORDER_DETAILS: '/dashboard/order',
    PLAYER: '/api/tags',
    PLAYERS: '/api/tags?full_details=true',
    DELETE_PLAYERS: '/api/tags/list/deleteBatch',
    ADD_PLAYERS_LIST: '/api/tags/batch',
    POST_IMAGE: '/dashboard/media/upload/',
    PRODUCTS: '/dashboard/product',
    PRICING: '/dashboard/pricing',
    PRICING_META: '/dashboard/pricing/pricing-meta',
    PRICE_POINT: '/dashboard/pricing/pricePoint',
    PUBLISHER: '/dashboard/publisher',
    PUBLISHER_SETTINGS: '/dashboard/publisher/publisher-settings',
    PUBLISHER_USERS: '/dashboard/publisher/users',
    PUBLISHER_USERS_V2: '/dashboard/v2/users',
    SEGMENTS: '/dashboard/segment',
    METADATA: '/dashboard/metadata',
    GRAPHS: '/analytics/graphs',
    BARS: '/analytics/bars',
    PIES: '/analytics/pies',
    STATS: '/analytics/stats',
    THEME: '/dashboard/storeTheme',
    GEO: '/dashboard/financial/geo',
    REFUND: '/dashboard/order/refund',
    PAYMENTS: '/dashboard/financial/payment-methods',
    PAYMENTS_CONFIG: '/dashboard/financial/configuration',
    OFFERS_UI: '/dashboard/offerUI',
    BADGES: '/dashboard/badge',
    GET_BADGES: '/dashboard/badges',
    OFFERS: '/dashboard/offer',
    POPUP_OFFERS: '/dashboard/offer/popup/daily-bonus',
    FINANCIAL_REPORTS: '/dashboard/report',
    FINANCIAL_REPORTS_NEW_VERSION: '/dashboard/v2/report',
    DISPUTES: '/dashboard/disputes',
    SECTIONS: '/dashboard/sections',
    UPSERT_SECTIONS: '/dashboard/sections/upsert',
    PAYOUTS: '/dashboard/payouts',
    PAYOUTS_NEW_VERSION: '/dashboard/v2/payouts',
    ROLES: '/dashboard/v2/user/roles',
    FEATURE_FLAGS: 'dashboard/dashboard-settings',
    PRICING_POINTS_TEMPLATE: '/dashboard/pricing/download'
};

export const getApiRouteOrMock = (route: EApiRoutes): string => {
    if (process.env.NODE_ENV === 'production') {
        return ApiRoutes[route];
        // return
    } else {
        const mockApis = localStorageUtil.get<Partial<typeof ApiRoutes>>(
            ELocalStorageKeys.MOCK_API
        );
        if (mockApis && mockApis[route]) {
            return `${MOCK_URL}${ApiRoutes[route]}` as string;
        }
        return ApiRoutes[route];
    }
};
