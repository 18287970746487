import { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { GOOGLE_AUTH_CLIENT_ID } from '../../constants/constants';
import { required } from '../../utils/errorsTextHelper';
import AcInput from '../AcInput/AcInput';
import ActionButton from '../ActionButton/ActionButton';

import { GoogleLogin } from './GoogleLogin/GoogleLogin';
import { LoginFormProps } from './LoginForm.types';

import './style.scss';

const LoginForm = ({
    login,
    setAnimateFailed,
    setLoginNotification,
    setModalState
}: LoginFormProps) => {
    let loginSchema = yup.object().shape({
        userName: yup
            .string()
            .required(required('Email'))
            .email('Enter a valid email'),
        password: yup.string().required(required('Password')),
        rememberMe: yup.boolean()
    });

    const onLoginFailed = () => {
        setLoginNotification({
            msg: 'There was a problem with your login credentials, please try again.',
            type: 'error'
        });
        setAnimateFailed(true);
        setTimeout(() => {
            setAnimateFailed(false);
        }, 1000);
    };

    const {
        initialValues,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        submitForm,
        isValid
    } = useFormik({
        initialValues: {
            userName: '',
            password: '',
            rememberMe: true
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            const formattedUserName = values.userName.toLowerCase().trim();
            login(
                { ...values, userName: formattedUserName },
                (success: boolean) => {
                    setIsLoading(false);
                    if (success) return;

                    onLoginFailed();
                }
            );
        }
    });

    const [isLoading, setIsLoading] = useState(false);

    return (
        <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
            <Box className="header">
                <h1>Login</h1>
            </Box>
            <Box className="body">
                <form
                    onSubmit={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                    }}
                >
                    <Box className="input">
                        <label>Email</label>
                        <AcInput
                            name="userName"
                            value={values.userName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.userName && !!errors.userName}
                            helperText={
                                (touched.userName && errors.userName) || ''
                            }
                            dataTestId={'login-email-input'}
                        />
                    </Box>
                    <Box className="input">
                        <label>Password</label>
                        <AcInput
                            name="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            dataTestId={'login-password-input'}
                        />
                    </Box>
                    <Box className="actions">
                        <span
                            onClick={() => setModalState('forgetPassword')}
                            className="forgotPassword"
                        >
                            Forgot Password?
                        </span>
                    </Box>
                </form>
                <ActionButton
                    disabled={
                        isLoading ||
                        !!Object.keys(errors).length ||
                        !isValid ||
                        initialValues === values
                    }
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    style={{
                        textTransform: 'capitalize',
                        marginTop: '1em'
                    }}
                    isLoading={isLoading}
                    text={'Login'}
                />
                <div
                    className={'login-divider'}
                    style={{ position: 'relative' }}
                >
                    <hr className={'login-divider__line'} />
                    <p className={'login-divider__text'}>Or</p>
                </div>
                <GoogleLogin login={login} onLoginFailed={onLoginFailed} />
            </Box>
        </GoogleOAuthProvider>
    );
};

export default LoginForm;
