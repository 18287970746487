import { useState } from 'react';

import {
    Autocomplete,
    FormHelperText,
    InputAdornment,
    Stack,
    TextField
} from '@mui/material';

import { AcAutoCompleteProps } from './AcAutoComplete.types';

const AcAutoComplete = ({
    options = [],
    additionalOptions = [],
    header = '',
    helperText = '',
    tooltip = '',
    label = '',
    error = '',
    onEnter = undefined,
    onChangeEvent = undefined,
    startAdornment = null,
    ...props
}: AcAutoCompleteProps) => {
    const [innerValue, setInnerValue] = useState<string>(props.value);

    return (
        <Stack
            className="select-container formContent-select"
            direction="column"
        >
            <Autocomplete
                {...props}
                freeSolo
                autoSelect
                id="free-solo-2-demo"
                disableClearable
                options={options}
                inputValue={innerValue}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label={label}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                startAdornment: startAdornment ? (
                                    <InputAdornment position="start">
                                        {startAdornment}
                                    </InputAdornment>
                                ) : undefined
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { color: '#717188' }
                            }}
                            onChange={(e) => {
                                setInnerValue(e.target.value);
                            }}
                            className="formContent-input"
                        />
                    );
                }}
                onKeyUp={(e) => {
                    const value = (e.target as HTMLInputElement).value;
                    onChangeEvent?.(value);
                    if (e.key === 'Enter' && value) {
                        if (!additionalOptions.includes(value)) {
                            onEnter?.(value);
                        }
                        setInnerValue('');
                    }
                }}
            />
            {helperText && (
                <FormHelperText style={{ fontSize: 14 }}>
                    {helperText}
                </FormHelperText>
            )}
            {error && (
                <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>
            )}
        </Stack>
    );
};

export default AcAutoComplete;
