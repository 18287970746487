import { useEffect } from 'react';

import { Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { IS_NO_IP_ONLY_HTTPS } from '../../../constants/constants';
import { required } from '../../../utils/errorsTextHelper';
import { permissionsUtil } from '../../../utils/permissionsUtil';
import AcCard from '../../AcCard/AcCard';
import AcInput from '../../AcInput/AcInput';
import AcPhoneInput from '../../AcPhoneInput/AcPhoneInput';
import ActionButton from '../../ActionButton/ActionButton';
import { CompanySettingsProps } from '../Settings.types';

import '../../../style/forms.scss';

const CompanySettings = ({
    savePublisherDetails,
    data
}: CompanySettingsProps) => {
    let companySettingsSchema = yup.object().shape({
        companyName: yup.string().required(required('Name')),
        phoneNumberPrefix: yup.string().max(3),
        supportMail: yup.string().email().required(required('Email')),
        address: yup.string(),
        domain: yup.string().required(required('Domain')),
        termsAndConditionsUrl: yup
            .string()
            .test(
                'Must be a valid URL, starting with https://, and not an IP address',
                (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
            )
            .required(required('Terms and conditions URL')),
        privacyPolicyUrl: yup
            .string()
            .test(
                'Must be a valid URL, starting with https://, and not an IP address',
                (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
            )
            .required(required('Privacy policy URL')),
        phoneNumber: yup
            .string()
            .min(5, 'Phone number must be at least 5 characters')
    });
    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        submitForm,
        resetForm,
        isSubmitting,
        dirty,
        isValid
    } = useFormik({
        initialValues: {
            termsAndConditionsUrl: data?.termsAndConditionsUrl || '',
            phoneNumberPrefix: data?.phoneNumberPrefix || '',
            privacyPolicyUrl: data?.privacyPolicyUrl || '',
            companyName: data?.companyName || '',
            phoneNumber: data?.phoneNumber || '',
            supportMail: data?.supportMail || '',
            address: data?.address || '',
            domain: data?.domain || '',
            publisherId: data?._id || ''
        },
        validationSchema: companySettingsSchema,
        onSubmit: async (values) => {
            resetForm({
                values: {
                    termsAndConditionsUrl: values.termsAndConditionsUrl,
                    phoneNumberPrefix: values.phoneNumberPrefix,
                    privacyPolicyUrl: values.privacyPolicyUrl,
                    companyName: values.companyName,
                    phoneNumber: values.phoneNumber,
                    supportMail: values.supportMail,
                    address: values.address,
                    domain: values.domain,
                    publisherId: values.publisherId
                }
            });
            savePublisherDetails(values);
        }
    });

    useEffect(() => {
        resetForm({
            values: {
                termsAndConditionsUrl: data?.termsAndConditionsUrl || '',
                phoneNumberPrefix: data?.phoneNumberPrefix || '',
                privacyPolicyUrl: data?.privacyPolicyUrl || '',
                companyName: data?.companyName || '',
                phoneNumber: data?.phoneNumber || '',
                supportMail: data?.supportMail || '',
                address: data?.address || '',
                domain: data?.domain || '',
                publisherId: data?._id || ''
            }
        });
    }, [data, resetForm]);

    return (
        <Grid container className="formContent">
            <Grid item xs={5} className="formContent-minWIdth">
                <Stack>
                    <AcCard stackContainer={false} title="General">
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={6}>
                                <AcInput
                                    header="Company Name"
                                    name="companyName"
                                    value={values.companyName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.companyName &&
                                        Boolean(errors.companyName)
                                    }
                                    helperText={
                                        touched.companyName
                                            ? errors.companyName?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcPhoneInput
                                    header="Phone Number"
                                    names={['phoneNumberPrefix', 'phoneNumber']}
                                    placeholders={['eg. 1', 'eg. 0508242111']}
                                    values={[
                                        values.phoneNumberPrefix,
                                        values.phoneNumber
                                    ]}
                                    setFieldValue={setFieldValue}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errors={[
                                        touched.phoneNumberPrefix &&
                                            Boolean(errors.phoneNumberPrefix),
                                        touched.phoneNumber &&
                                            Boolean(errors.phoneNumber)
                                    ]}
                                    helpers={[
                                        touched.phoneNumberPrefix
                                            ? errors.phoneNumberPrefix?.toString()
                                            : '',
                                        touched.phoneNumber
                                            ? errors.phoneNumber?.toString()
                                            : ''
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcInput
                                    header="Company ID"
                                    disabled
                                    name="publisherId"
                                    value={data?._id}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcInput
                                    header="Support Email"
                                    name="supportMail"
                                    value={values.supportMail}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.supportMail &&
                                        Boolean(errors.supportMail)
                                    }
                                    helperText={
                                        touched.supportMail
                                            ? errors.supportMail?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcInput
                                    header="Address"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.address &&
                                        Boolean(errors.address)
                                    }
                                    helperText={
                                        touched.address
                                            ? errors.address?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcInput
                                    header="Domain"
                                    name="domain"
                                    value={values.domain}
                                    disabled={!permissionsUtil.isSuperAdmin()}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.domain && Boolean(errors.domain)
                                    }
                                    helperText={
                                        touched.domain
                                            ? errors.domain?.toString()
                                            : ''
                                    }
                                    enableNewTabOpen
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcInput
                                    header="Terms and Conditions"
                                    name="termsAndConditionsUrl"
                                    value={values.termsAndConditionsUrl}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.termsAndConditionsUrl &&
                                        Boolean(errors.termsAndConditionsUrl)
                                    }
                                    helperText={
                                        touched.termsAndConditionsUrl
                                            ? errors.termsAndConditionsUrl?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcInput
                                    header="Privacy Policy"
                                    name="privacyPolicyUrl"
                                    value={values.privacyPolicyUrl}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.privacyPolicyUrl &&
                                        Boolean(errors.privacyPolicyUrl)
                                    }
                                    helperText={
                                        touched.privacyPolicyUrl
                                            ? errors.privacyPolicyUrl?.toString()
                                            : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                </Stack>
                <Grid item xs={6} mt={3}>
                    <ActionButton
                        variant="outlined"
                        text="Update"
                        onClick={submitForm}
                        disabled={!isValid || !dirty || isSubmitting}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CompanySettings;
