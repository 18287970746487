import { Box, Stack } from '@mui/system';

const AcViewWrapper = ({
    children,
    header
}: {
    children: React.ReactNode;
    header?: React.ReactNode;
}) => {
    return (
        <Stack direction="column" pt={'14px'} height="100%">
            {header}
            <Box overflow="auto">{children}</Box>
        </Stack>
    );
};

export default AcViewWrapper;
