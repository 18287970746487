import { createSlice } from '@reduxjs/toolkit';

import { PublisherSliceState } from './store.types';

const initialPublisherSlice: PublisherSliceState = {
    activePublisherId: '',
    permissions: {}
};

const publisherSlice = createSlice({
    name: 'publisher',
    initialState: initialPublisherSlice,
    reducers: {
        setActivePublisherId(state: PublisherSliceState, action) {
            state.activePublisherId = action.payload;
        },
        setPermissions(state: PublisherSliceState, action) {
            state.permissions = action.payload;
        }
    }
});

export const publisherActions = publisherSlice.actions;
export default publisherSlice.reducer;
