export enum EStatusLabel {
    SUCCESS = 'success',
    FAILED = 'failed',
    CANCELED = 'cancelled',
    REFUNDED = 'refunded',
    PENDING = 'pending',
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export interface StatusLabelProps {
    text: string;
    prefixIcon?: JSX.Element;
    status?: EStatusLabel;
}
