import { featureFlagUtil } from '../../../utils/featureFlagUtil';
import { permissionsUtil } from '../../../utils/permissionsUtil';
import SidebarItem from '../../SidebarItem/SidebarItem';

import { SidebarMenuProps } from './SidebarMenu.types';

const SidebarMenu = ({ items, group, name }: SidebarMenuProps) => {
    items = items.filter((item) => item?.hide !== true);
    const groupNotEmpty = items.some((item) => item.group === group);

    return (
        <>
            {groupNotEmpty && <h2>{name.toUpperCase()}</h2>}
            {items.map((item, i) => {
                const shouldShow =
                    (item.superAdminOnly && permissionsUtil.isSuperAdmin()) ||
                    !item.superAdminOnly;

                if (item.group === group) {
                    if (
                        item.featureFlag &&
                        !featureFlagUtil.isFeatureEnabled(item.featureFlag)
                    ) {
                        return null;
                    }
                    if (shouldShow) {
                        return (
                            ((permissionsUtil.isSuperAdmin() &&
                                item.superAdminOnly) ||
                                !item.superAdminOnly) && (
                                <SidebarItem
                                    key={i}
                                    data={item as any}
                                    featureFlagged={
                                        item.featureFlag &&
                                        item.featureFlag
                                            .toUpperCase()
                                            .includes('NEW')
                                    }
                                />
                            )
                        );
                    }
                }
                return null;
            })}
        </>
    );
};

export default SidebarMenu;
