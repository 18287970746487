import CountUp from 'react-countup';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Skeleton, Stack } from '@mui/material';
import Decimal from 'decimal.js';

import { EMetric } from '../../api/useAnalytics';
import { EStatisticType } from '../../views/Statistics/StatisticsView.types';

import { AcStatBoxProps } from './AcStatBox.types';

import './style.scss';

const AcStatBox = ({
    name,
    currentValue = 0,
    changePercent = 0,
    type = EStatisticType.CURRENCY,
    isLoading,
    metricType = ''
}: AcStatBoxProps) => {
    const decimalValue = currentValue
        ? new Decimal(currentValue)
        : new Decimal(0);

    const currencyTypeToSymbol = (currencyType: EStatisticType) => {
        return currencyType === EStatisticType.CURRENCY ||
            currencyType === EStatisticType.AMOUNT_AVERAGE
            ? '$'
            : '';
    };

    const getDecimalPlaces = (
        value: number,
        type: EStatisticType,
        metricType: string
    ) => {
        if (metricType === EMetric.AVERAGE_DEPOSITS_PER_DEPOSITOR) {
            return value % 1 !== 0 ? 2 : 0;
        }

        return type === EStatisticType.CURRENCY ||
            type === EStatisticType.PRECISE_NUMBER ||
            type === EStatisticType.AVERAGE ||
            type === EStatisticType.PERCENTAGE ||
            type === EStatisticType.AMOUNT_AVERAGE
            ? 2
            : 0;
    };

    return (
        <div className="acStatbox">
            <div className="stat-name">
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" height={40} />
                ) : (
                    <span>{name}</span>
                )}
            </div>
            <div className="stat-value">
                {isLoading ? (
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width={100}
                        height={60}
                    />
                ) : (
                    <span>
                        {currentValue ? (
                            <CountUp
                                prefix={currencyTypeToSymbol(type)}
                                suffix={
                                    type === EStatisticType.PERCENTAGE
                                        ? '%'
                                        : ''
                                }
                                decimal="."
                                decimals={getDecimalPlaces(
                                    currentValue,
                                    type,
                                    metricType
                                )}
                                separator=","
                                duration={1}
                                end={
                                    type === EStatisticType.AMOUNT_AVERAGE
                                        ? decimalValue.div(100).toNumber()
                                        : currentValue
                                }
                            />
                        ) : (
                            'N/A'
                        )}
                    </span>
                )}
            </div>
            <div className="stat-change-period">
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width={200} />
                ) : (
                    <Stack alignItems="center" direction="row">
                        {changePercent > 0 ? (
                            <ArrowDropUpIcon className="up" />
                        ) : changePercent < 0 ? (
                            <ArrowDropDownIcon className="down" />
                        ) : (
                            <></>
                        )}
                        {changePercent ? (
                            <span>
                                {changePercent.toFixed(2)}% VS previous period
                            </span>
                        ) : (
                            <span>No change from previous period</span>
                        )}
                    </Stack>
                )}
            </div>
        </div>
    );
};

export default AcStatBox;
