import { EFontWeight } from '../constants/enums';

export const getConvertedFontWeight = (value: string): number => {
    let newValue = 0;
    if (value === EFontWeight.Regular) {
        newValue = 300;
    } else if (value === EFontWeight.Medium) {
        newValue = 500;
    } else if (value === EFontWeight.Bold) {
        newValue = 700;
    }

    return newValue;
};
