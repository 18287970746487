import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowRight from '@mui/icons-material/ArrowRight';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material';

import useUsers from '../../../api/useUsers';
import { User } from '../../../common/contracts';
import { NEW_VERSION } from '../../../constants/constants';
import { ELocalStorageKeys } from '../../../constants/enums';
import { UserDetails } from '../../../pages/Login/Login.types';
import { authActions } from '../../../store/authSlice';
import { AuthSliceState } from '../../../store/store.types';
import { capitalizeFirstLetter } from '../../../utils/formattingUtil';
import { localStorageUtil } from '../../../utils/localStorageUtil';
import { permissionsUtil } from '../../../utils/permissionsUtil';
import AcAvatar from '../../AcAvatar/AcAvatar';

import { SidebarProfileProps } from './SidebarProfile.types';

import './style.scss';

const SidebarProfile = ({ isSidebarOpen }: SidebarProfileProps) => {
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const arrowRef = useRef(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { logout } = useUsers(
        false,
        versionDetails,
        currentPublisherId,
        false
    );

    const dispatch = useDispatch();
    const userDetails = localStorageUtil.get<UserDetails>(
        ELocalStorageKeys.USER_DETAILS
    );
    const navigate = useNavigate();

    const handleArrowClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(arrowRef.current);
        setIsPopoverOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setIsPopoverOpen(false);
    };

    return (
        <>
            <Popover
                open={isPopoverOpen}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 54
                }}
                sx={{
                    width: 200,
                    padding: 16
                }}
                elevation={2}
            >
                <ul
                    style={{
                        width: versionDetails === NEW_VERSION ? 240 : 200,
                        padding: '1em .5em'
                    }}
                    className="sidebar-profile-actions"
                >
                    <li
                        onClick={() => {
                            handlePopoverClose();
                            navigate(
                                `${currentPublisherId ? `project/${currentPublisherId}/profile` : `project/profile`}`
                            );
                        }}
                    >
                        <PersonOutlineOutlinedIcon />
                        <div className="sidebar-profile-textBlock">
                            <h3>Profile</h3>
                            {/* {versionDetails === NEW_VERSION &&
                                userDetails.role && (
                                    <span className="sidebar-profile-subText">
                                        {capitalizeFirstLetter(
                                            userDetails.role
                                        )}
                                    </span>
                                )} */}
                        </div>
                    </li>
                    {versionDetails !== NEW_VERSION && (
                        <li
                            onClick={() => {
                                handlePopoverClose();
                                navigate(
                                    `${currentPublisherId ? `project/${currentPublisherId}/settings` : `project/settings`}`
                                );
                            }}
                        >
                            <SettingsOutlinedIcon />
                            <h3>Admin</h3>
                        </li>
                    )}
                    <li
                        onClick={() => {
                            dispatch(authActions.login({ authJwt: null }));
                            logout.mutate();
                        }}
                    >
                        <LogoutOutlinedIcon />
                        <h3>Logout</h3>
                    </li>
                </ul>
            </Popover>
            <hr />
            <Stack
                flexDirection="row"
                gap={1.2}
                className="sidebar-profile"
                position="relative"
                onClick={handleArrowClick}
            >
                <Box position="absolute" sx={{ right: 8, top: 0 }}>
                    <IconButton className="arrow-down-button" ref={arrowRef}>
                        <ArrowRight />
                    </IconButton>
                </Box>
                <Box
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                >
                    <AcAvatar
                        isSuperAdmin={permissionsUtil.isSuperAdmin()}
                        content={userDetails?.firstName?.charAt(0)}
                    />
                </Box>
                <Stack direction="column">
                    <Typography
                        fontFamily="'Montserrat', sans-serif"
                        className="sidebar-profile-userName"
                    >
                        {userDetails.firstName} {userDetails.lastName}
                    </Typography>
                    <Typography
                        fontFamily="'Montserrat', sans-serif"
                        fontSize={11}
                        color="#757575"
                        className="sidebar-profile-userEmail"
                    >
                        {userDetails.email}
                    </Typography>
                </Stack>
            </Stack>
            <hr />
        </>
    );
};

export default SidebarProfile;
