import { string } from 'prop-types';

export enum EPermissionGroup {
    // Project Group
    COMPANY = 'company',
    USERS = 'users',
    INTEGRATION = 'integration',
    AUTHENTICATION = 'authentication',

    // Orders / Payments Group
    ORDERS = 'orders',
    EVIDENCE = 'evidence',
    FINANCIAL_REPORT = 'financial_report',
    PAYOUTS = 'payouts',
    ANALYTICS = 'analytics',

    // Store Group
    BUILDER = 'builder',
    PRICING = 'pricing',
    ASSET_LIBRARY = 'asset_library',
    SEGMENTS = 'segments',

    // Monetization Group
    OFFERS = 'offers',
    OFFER_DESIGN = 'offer_design',
    BUNDLES = 'bundles',
    PROMOTIONS = 'promotions',
    POPUPS = 'popups',
    PRODUCTS = 'products',
    BADGES = 'badges'
}

export interface PermissionGroupActions {
    [EPermissionGroup.COMPANY]: 'updateDetails' | 'view';
    [EPermissionGroup.USERS]?: 'manage';
    [EPermissionGroup.INTEGRATION]?: 'manage';
    [EPermissionGroup.AUTHENTICATION]?: 'manage';
    [EPermissionGroup.ORDERS]: 'refund' | 'view';
    [EPermissionGroup.EVIDENCE]: 'submit' | 'view';
    [EPermissionGroup.FINANCIAL_REPORT]: 'generate' | 'view';
    [EPermissionGroup.PAYOUTS]: 'view';
    [EPermissionGroup.ANALYTICS]: 'view';
    [EPermissionGroup.BUILDER]?: 'manage';
    [EPermissionGroup.PRICING]?: 'manage';
    [EPermissionGroup.ASSET_LIBRARY]?: 'manage';
    [EPermissionGroup.OFFERS]?: 'manage';
    [EPermissionGroup.OFFER_DESIGN]?: 'manage';
    [EPermissionGroup.BUNDLES]?: 'manage';
    [EPermissionGroup.PROMOTIONS]?: 'manage';
    [EPermissionGroup.POPUPS]?: 'manage';
    [EPermissionGroup.PRODUCTS]?: 'manage';
    [EPermissionGroup.BADGES]?: 'manage';
}

export interface IPermissions {
    [key: string]: Record<EPermissionGroup, string[]>;
}

export enum EPermissionAction {
    UPDATE_DETAILS = 'updateDetails',
    REFUND = 'refund',
    VIEW = 'view',
    SUBMIT = 'submit',
    GENERATE = 'generate',
    MANAGE = 'manage'
}
