import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useOffers from '../../api/useOffers';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../components/DataTable/DataTable';
import DialogModal from '../../components/Dialog/Dialog';
import FirstActionModal from '../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { DATE_TIME_FORMAT } from '../../constants/constants';
import { EButtonColor, ELocalStorageKeys, ENotificationType, OfferType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { usePaginationFromURL } from '../../hooks/usePaginationFromURL';
import { AuthSliceState } from '../../store/store.types';
import { getConvertedPrice } from '../../utils/getConvertedPrice';
import { permissionsUtil } from '../../utils/permissionsUtil';

const dayjsUTC = dayjs.extend(utc);

const OffersTable = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const navigate = useNavigate();
    const location = useLocation();
    const { page, rows } = usePaginationFromURL('offers');
    const [currentPage, setCurrentPage] = useState(page);
    const [rowsPerPage, setRowsPerPage] = useState(rows);
    const { getOffers: offers, deleteOffer } = useOffers(
        currentPublisherId,
        undefined,
        OfferType.SPECIAL_OFFER,
        {
            currentPage,
            rowsPerPage
        }
    );
    const totalCount = offers.data?.totalCount || 0;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
    const { enqueueSnackbar } = useNotifications();

    useEffect(() => {
        navigate(
            `${location.pathname}?page=${currentPage}&rows=${rowsPerPage}`,
            { replace: true }
        );
        offers.refetch();
    }, [currentPage, rowsPerPage]);

    const handleDeleteOffer = async () => {
        if (!selectedOffer) return;
        await deleteOffer.mutate(selectedOffer, {
            onSuccess: () => {
                enqueueSnackbar(
                    'Promotion deleted successfully',
                    ENotificationType.SUCCESS
                );
                offers.refetch();
            },
            onError: (e: any) => {
                enqueueSnackbar(
                    'Failed to delete promotion',
                    ENotificationType.ERROR,
                    e.response.data.message ?? ''
                );
            },
            onSettled: () => {
                setIsDeleteDialogOpen(false);
                setSelectedOffer(null);
            }
        });
    };

    const columns = [
        {
            field: 'offerId',
            flex: 1,
            headerName: 'ID',
            width: 100
        },
        {
            field: 'publisherOfferId',
            flex: 1,
            headerName: 'Publisher offer ID',
            width: 100
        },
        {
            field: 'offerUi.name',
            flex: 1,
            headerName: 'Offer Design',
            width: 100,
            renderCell: (params: any) => {
                if (offers.isLoading) return '';
                return <span>{params.row.offerUi?.name}</span>;
            }
        },
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            width: 100
        },
        {
            field: 'createdAt',
            flex: 1,
            headerName: 'Created at',
            width: 200,
            type: 'date',
            renderCell: (params: any) => {
                if (offers.isLoading) return '';
                return (
                    <span>
                        {dayjsUTC
                            .utc(params.row.createdAt)
                            .format(DATE_TIME_FORMAT)}
                    </span>
                );
            }
        },
        {
            field: 'type',
            flex: 1,
            headerName: 'Type',
            width: 200
        },
        {
            field: 'price',
            flex: 1,
            headerName: 'Price',
            width: 100,
            renderCell: (params: any) => {
                if (offers.isLoading) return '';
                return getConvertedPrice(params);
            }
        },
        {
            field: 'actions',
            flex: 0,
            type: 'actions',
            width: 80,
            disableReorder: true,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<EditTwoToneIcon />}
                    label="Edit"
                    onClick={() => {
                        navigate(`./form/${params.id}`);
                    }}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.OFFERS,
                                currentPublisherId,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<ContentCopyOutlinedIcon />}
                    label="Duplicate"
                    onClick={() => {
                        navigate(`./dup/${params.id}`);
                    }}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.OFFERS,
                                currentPublisherId,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />,
                <GridActionsCellItem
                    className="danger"
                    icon={<DeleteOutlineTwoToneIcon className="danger" />}
                    label="Delete"
                    onClick={() => {
                        setIsDeleteDialogOpen(true);
                        setSelectedOffer(params.id);
                    }}
                    disabled={
                        !permissionsUtil.canUserEdit() ||
                        (permissionsUtil.isNewVersion() &&
                            !permissionsUtil.isActionEnabled(
                                null,
                                EPermissionGroup.OFFERS,
                                currentPublisherId,
                                EPermissionAction.MANAGE
                            ))
                    }
                    showInMenu
                />
            ]
        }
    ];

    return (
        <>
            <AcViewWrapper
                header={
                    <PageTopBar
                        headline="Promotions"
                        buttons={[
                            {
                                text: 'Add New Promotion',
                                action: () => navigate('./form'),
                                disabled: !permissionsUtil.canUserEdit(),
                                hidden:
                                    permissionsUtil.isNewVersion() &&
                                    !permissionsUtil.isActionEnabled(
                                        null,
                                        EPermissionGroup.OFFERS,
                                        currentPublisherId,
                                        EPermissionAction.MANAGE
                                    )
                            }
                        ]}
                    />
                }
            >
                <AcContentWrapper>
                    <DataTable
                        pagination={true}
                        rowIdIdentifier="offerId"
                        columns={columns}
                        rows={offers.data?.offers}
                        loading={offers.isLoading || offers.isFetching}
                        onPageChange={(newPage) => setCurrentPage(newPage)}
                        onRowsPerPageChange={(newRowsPerPage) => {
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(0);
                        }}
                        currentPage={currentPage}
                        totalCount={totalCount}
                        defaultHiddenFields={[]}
                        localStorageColumnsKey={
                            ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY
                        }
                        hideFooter={false}
                        error={false}
                        initialSorting={{
                            sortModel: [{ field: 'createdAt', sort: 'desc' }]
                        }}
                        onNoData={
                            <FirstActionModal
                                headline="Promotions convert better"
                                text="Create your first one by hitting the “Add new Promotion” button on the top right corner of the screen"
                            />
                        }
                    />
                </AcContentWrapper>
            </AcViewWrapper>

            <DialogModal
                isOpen={isDeleteDialogOpen}
                headline="Delete Promotion"
                text="Are you sure you want to delete this promotion?"
                buttons={[
                    {
                        text: 'Cancel',
                        color: EButtonColor.SECONDARY,
                        variant: 'outlined',
                        func: () => {
                            setIsDeleteDialogOpen(false);
                        }
                    },
                    {
                        text: 'Delete',
                        color: EButtonColor.ERROR,
                        variant: 'contained',
                        func: handleDeleteOffer
                    }
                ]}
                closeDialog={() => setIsDeleteDialogOpen(false)}
            />
        </>
    );
};

export default OffersTable;
