import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { useProduct } from '../../api/useProduct';
import { User } from '../../common/contracts';
import { NEW_VERSION } from '../../constants/constants';
import { ELocalStorageKeys } from '../../constants/enums';
import { AuthSliceState } from '../../store/store.types';
import { localStorageUtil } from '../../utils/localStorageUtil';
import ProductsForm from '../ProductsForm/ProductsForm';
import { Product } from '../ProductsForm/ProductsForm.types';

import { ProductFormEditWrapperProps } from './ProductFormEditWrapper.types';

const ProductFormEditWrapper = ({
    dup = false,
    updateProduct,
    postProduct
}: ProductFormEditWrapperProps) => {
    const { id } = useParams();
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const product = useProduct(currentPublisherId, id).getProduct;
    const updateMiddleware = (data: Product, onMutationComplete: () => void) => {
        if (!updateProduct) return;
        const form = {
            id,
            data
        };

        updateProduct(form as { id: string; data: Product }, onMutationComplete);
    };
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    useEffect(() => {
        if ((versionDetails === NEW_VERSION && id && currentPublisherId) || (versionDetails !== NEW_VERSION && id)) {
            product.refetch();
        }
    }, [id, currentPublisherId, versionDetails]);

    return (
        <>
            {product.isLoading ? (
                <CircularProgress
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        height: '100px',
                        width: '100px'
                    }}
                />
            ) : product.isError ? (
                <span>
                    An error has occurred, {JSON.stringify(product.error)}
                </span>
            ) : (
                product.data && (
                    <ProductsForm
                        data={product.data.result}
                        isLoading={product.isRefetching}
                        emptyCache={product.remove}
                        postProduct={dup ? postProduct : updateMiddleware}
                        headline={dup ? 'New Product' : 'Edit Product'}
                        buttonText={`${dup ? "Save" : "Update"} Product`}
                        edit={dup ? false : true}
                        dup={dup}
                    />
                )
            )}
        </>
    );
};

export default ProductFormEditWrapper;
