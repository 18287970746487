import usePermissions from 'api/usePermissions';
import { permissionsUtil } from 'utils/permissionsUtil';

import { getSelectProjectOptions } from '../../utils/projectsUtil';
import AcSelect from '../AcSelect/AcSelect';

import { ProjectsSelectProps } from './ProjectsSelect.types';

const ProjectsSelect = ({
    projectsDetails,
    selectedProjects,
    setSelectedProjects,
    isApplyBtnEnabled = false,
    allowedPublishers = []
}: ProjectsSelectProps) => {
    const projectsOptions = getSelectProjectOptions(
        projectsDetails,
        allowedPublishers
    );

    const handleRenderProjectValue = (selected: any) => {
        const selectedLength = selected?.length;
        const selectedValue= projectsOptions.find((option) => option.value === selected[0])?.content;
        if (selectedLength < projectsOptions?.length && selectedLength > 1) return "Multiple projects";
        if (selectedLength === 1) return selectedValue;
        return "All projects"
    };

    return (
        <>
            <AcSelect
                name="projectName"
                items={projectsOptions}
                sx={{ flexGrow: 1, minWidth: '200px' }}
                isCheckBox
                setSelectedItems={setSelectedProjects}
                value={selectedProjects || []}
                className="selector-project"
                renderValue={handleRenderProjectValue}
                isSelectAllEnabled={true}
                isApplyBtnEnabled
            />
        </>
    );
};

export default ProjectsSelect;
