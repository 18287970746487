import React from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography
} from '@mui/material';

import { EPublisherSolutionModel, User } from '../../common/contracts';
import { IPermissions } from '../../common/permissions.types';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { ELocalStorageKeys } from '../../constants/enums';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { permissionsUtil } from '../../utils/permissionsUtil';

import '../view.scss';

const ProjectsView = () => {
    const navigate = useNavigate();
    const projectsDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.projects;

    if (!projectsDetails) return null;

    const isCheckoutUser =
        localStorageUtil.get(ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL) ===
        EPublisherSolutionModel.CHECKOUT;

    const handleProjectClick = (publisherId: string) => {
        const route = permissionsUtil.canAccessBuilder(
            localStorageUtil.get<IPermissions>(
                ELocalStorageKeys.PERMISSIONS
            ) || {},
            publisherId
        ) ? isCheckoutUser ?
                `${publisherId}/statistics/?range=2`
                : `${publisherId}/theme`
            : `${publisherId}/offers`
        navigate(route)
    }

    return (
        <AcViewWrapper header={<PageTopBar headline="Projects" />}>
            <AcContentWrapper>
                <Box className="projects">
                    <Grid container spacing={2}>
                        {projectsDetails.map((item) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                                key={item.publisherId}
                                columnGap={10}
                            >
                                <Card
                                    className="projects-card"
                                    sx={{
                                        background: '#F4F4F8',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => handleProjectClick(item.publisherId)}
                                >
                                    <CardMedia
                                        className="projects-card-media"
                                        component="img"
                                        sx={{
                                            width: 64,
                                            height: 64,
                                            objectFit: 'contain'
                                        }}
                                        image={item.publisherLogo}
                                        alt={item.publisherName}
                                    />
                                    <CardContent>
                                        <Typography
                                            className="projects-card-title"
                                            variant="h6"
                                            component="div"
                                            sx={{
                                                fontFamily:
                                                    'Montserrat, sans-serif'
                                            }}
                                        >
                                            {item.publisherName}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </AcContentWrapper>
        </AcViewWrapper>
    );
};

export default ProjectsView;
