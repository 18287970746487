import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Alert, Box } from '@mui/material';
import usePermissions from 'api/usePermissions';
import { permissionsUtil } from 'utils/permissionsUtil';

import useUsers from '../../api/useUsers';
import { User } from '../../common/contracts';
import { NEW_VERSION } from '../../constants/constants';
import { ELocalStorageKeys } from '../../constants/enums';
import { useLogin } from '../../hooks/useLogin';
import useUrlQuery from '../../hooks/useUrlQuery';
import { authActions } from '../../store/authSlice';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { ResetPasswordData } from '../../views/Profile/ProfileView.types';
import FinishAccountForm from '../FinishAccountForm/FinishAccountForm';
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import LoginForm from '../LoginForm/LoginForm';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';

import {
    ELoginAuthMethod,
    LoginData,
    LoginNotificationData,
    LoginPayload,
    ModalState,
} from './LoginModal.types';

import 'animate.css';
import './style.scss';

const LoginModal = () => {
    const mode = useUrlQuery('mode') as ModalState;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { saveLoginDetails, isLoggedIn } = useLogin();
    const { forgotPassword, resetPassword, login, loginWithGoogle } =
        useUsers();
    const [animateFailed, setAnimateFailed] = useState(false);
    const [loginNotification, setLoginNotification] =
        useState<LoginNotificationData | null>();
    const [modalState, setModalState] = useState<ModalState>(mode || 'login');
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const { getPermissions } = usePermissions();
    const setNotificationHandler = (data: LoginNotificationData) => {
        setLoginNotification(data);
        setTimeout(() => setLoginNotification(null), 5000);
    };

    const setAnimationHandler = (active: boolean) => {
        setAnimateFailed(active);
    };

    const setModalStateHandler = (state: ModalState) => {
        setModalState(state);
    };

    const makeLogin = async (
        loginData: LoginData,
        cb: Function,
        loginType: ELoginAuthMethod = ELoginAuthMethod.USERNAME_PASSWORD
    ) => {
        const onLoginSuccess = (loginResponse: any) => {
            const data = loginResponse?.result || loginResponse;
            const loginPayload: LoginPayload = { userId: data.userId };
            if (data?.token) loginPayload.authJwt = data.token;
            dispatch(authActions.login(loginPayload));

            saveLoginDetails({
                email: data.email,
                firstName: data.userName,
                lastName: '',
                userId: data.userId,
                isSession: !loginData.rememberMe,
                token: data?.token,
                role: data?.role,
                typeId: data?.typeId,
                projects: data?.projects,
                version: data?.version,
                company: data?.company
            });
            cb(true);
            const navigationPath =
                data?.version === NEW_VERSION
                    ? permissionsUtil.isAnyOrdersOrPayoutsEnabled(
                          getPermissions()
                      )
                        ? '/project/orders'
                        : '/project'
                    : `/project/statistics?range=2`;
            navigate(navigationPath);
        };
        if (loginType === ELoginAuthMethod.GOOGLE) {
            loginWithGoogle.mutate(loginData, {
                onSuccess: onLoginSuccess,
                onError: () => {
                    cb(false);
                }
            });
        } else {
            login.mutate(loginData, {
                onSuccess: onLoginSuccess,
                onError: () => {
                    cb(false);
                }
            });
        }
    };

    const sendResetEmail = async (email: string, cb: Function) => {
        forgotPassword.mutate(email, {
            onSuccess: () => {
                setNotificationHandler({
                    msg: 'An email has been sent successfully.',
                    type: 'success'
                });
                cb();
            },
            onError: (err: any) => {
                setNotificationHandler({
                    msg:
                        err?.response?.data?.message ||
                        'An email has not been sent, please try again.',
                    type: 'error'
                });
                cb();
            }
        });
    };

    const setNewPassword = (data: ResetPasswordData, cb: Function) => {
        resetPassword.mutate(data, {
            onSuccess: () => {
                setNotificationHandler({
                    msg: 'Password updated successfully.',
                    type: 'success'
                });
                cb(true);
            },
            onError: (err) => {
                setNotificationHandler({
                    msg:
                        (err as any)?.response?.data?.message ||
                        'Error in saving password, please try again.',
                    type: 'error'
                });
                cb(false);
            }
        });
    };

    useEffect(() => {
        if (isLoggedIn()) {
            let navigationPath = '';

            if (versionDetails === NEW_VERSION) {
                if (
                    permissionsUtil.isAnyOrdersOrPayoutsEnabled(
                        getPermissions()
                    )
                ) {
                    navigationPath = '/project/orders';
                } else {
                    navigationPath = '/project';
                }
            } else {
                navigationPath = './project/statistics?range=2';
            }
            navigate(navigationPath);
        }
    }, [getPermissions, isLoggedIn, navigate, versionDetails]);

    return (
        <div className="login-form-container">
            <Box
                id="loginModal"
                className={
                    animateFailed ? 'animate__animated animate__headShake' : ''
                }
            >
                {modalState === 'login' ? (
                    <LoginForm
                        login={makeLogin}
                        setLoginNotification={setNotificationHandler}
                        setAnimateFailed={setAnimationHandler}
                        setModalState={setModalStateHandler}
                    />
                ) : modalState === 'forgetPassword' ? (
                    <ForgotPasswordForm
                        sendResetEmail={sendResetEmail}
                        setModalState={setModalState}
                    />
                ) : modalState === 'finishAccount' ? (
                    <FinishAccountForm
                        setNewPassword={setNewPassword}
                        setModalState={setModalState}
                    />
                ) : (
                    modalState === 'resetPassword' && (
                        <ResetPasswordForm
                            setNewPassword={setNewPassword}
                            setModalState={setModalState}
                        />
                    )
                )}
            </Box>
            {loginNotification && (
                <Alert
                    className={`login-alert visible`}
                    severity={loginNotification?.type}
                >
                    {loginNotification?.msg}
                </Alert>
            )}
        </div>
    );
};

export default LoginModal;
