import { BundlesLayout } from '@appcharge/shared-ui';
import { ELayout } from '@appcharge/shared-ui/lib/components/Layouts/BundlesLayout/Layouts.types';
import { FormikValues } from 'formik';

import { EBundlesInternalViewModel, EBundlesViewModel } from '../../../../constants/enums';
import getBundleComponent from '../../../../utils/getBundleInternalLayout';

interface StoreThemeBundlesProps {
    values: FormikValues;
}
const StoreThemeBundles = ({ values }: StoreThemeBundlesProps) => {
    const bundlesViewModel = values.general.bundlesViewModel as ELayout;
    const BundlesInternalLayout = getBundleComponent(
        values.general.bundlesInternalViewModel
    );
    const isTwoInARow =
        values.general.bundlesViewModel === EBundlesViewModel.TWO_IN_ONE;

    return (
        <BundlesLayout bundlesViewModel={bundlesViewModel} isPreview={true}>
            {Array.from(
                {
                    length: isTwoInARow
                        ? 2
                        : values.general.bundlesViewModel ===
                            EBundlesViewModel.THREE_IN_ONE
                          ? 3
                          : 1
                },
                () => null
            ).map((i, index) => (
                <div className="bundle" key={index}>
                    <BundlesInternalLayout
                        bundleBorderColor={values.storeScreen.bundleBorderColor}
                        productList={[]}
                        bundleButtonColor={values.general.buttonColor}
                        backgroundSrc={values.general.bundlesInternalViewModel !== EBundlesInternalViewModel.PLAY_SIMPLE ? "https://media.appcharge.com/defaults/background.png" : ""}
                        bundleButtonTextColor={values.general.buttonTextColor}
                        fontFamily={values.general.font}
                        price={{
                            price: 0,
                            isCents: true,
                            fractionalSeparator: '.',
                            milSeparator: ',',
                            spacing: false,
                            symbolPosition: 'left',
                            currencySymbol: '$',
                            currencyCode: 'USD'
                        }}
                        inMobileMock={true}
                    />
                </div>
            ))}
        </BundlesLayout>
    );
};

export default StoreThemeBundles;
