import { useMutation,useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig,getApiRouteOrMock } from './api.utils';

export default function useTheme(currentPublisherId?: string | null) {
    const axios = useAxios();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getStoreTheme = useQuery<any, Error>({
        ...fetchConfig.general,
        retry: false,
        queryKey: [
            EQueryKeys.STORE_THEME + currentPublisherId
                ? currentPublisherId
                : ''
        ],
        queryFn: async () => {
            const res = await axios.get(getApiRouteOrMock(EApiRoutes.THEME));
            if (res?.publisherId) {
                localStorageUtil.set(
                    ELocalStorageKeys.PUBLISHER_ID,
                    res.publisherId
                );
            }
            return res;
        },
        enabled:  versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const updateStoreTheme = useMutation(async (theme: any) => {
        return await axios
            .put(getApiRouteOrMock(EApiRoutes.THEME), theme)
            .catch((err) => {
                return err;
            });
    }, {});

    return {
        getStoreTheme,
        updateStoreTheme
    };
}
