import { createSlice } from '@reduxjs/toolkit';

import { UISliceState } from './store.types';

const initialUISliceState: UISliceState = {
    globalLoading: false,
    isUnsavedChanges: false,
    isUnsavedChangesModalOpen: false
};

const uiSlice = createSlice({
    name: 'ui',
    initialState: initialUISliceState,
    reducers: {
        setGlobalLoading(state: UISliceState, action) {
            state.globalLoading = action.payload;
        },
        setUnsavedChanges(state: UISliceState, action) {
            state.isUnsavedChanges = action.payload;
        },
        setUnsavedChangesModalOpen(
            state: UISliceState,
            { payload }: { payload: boolean }
        ) {
            state.isUnsavedChangesModalOpen = payload;
        }
    }
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
