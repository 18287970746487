import { useMutation, useQuery } from '@tanstack/react-query';
import Decimal from 'decimal.js';

import { OrderDetails } from '../common/contracts';
import { NEW_VERSION, PAGINATION_ROWS_PER_PAGE } from '../constants/constants';
import { EQueryKeys, SortingDirection, SortingOrderValue } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useOrders(
    query?: string | number,
    pagination = { currentPage: 0, rowsPerPage: PAGINATION_ROWS_PER_PAGE },
    sorting = {
        sortValue: SortingOrderValue.DATE,
        direction: SortingDirection.DESC
    },
    filters?: {
        playerId?: string;
        publisherPurchaseId?: string;
        receiptId?: string;
        orderId?: string;
        statuses?: string[];
        isFree?: boolean;
        startDate?: string;
        endDate?: string;
        selectedProjects?: string
    },
    versionDetails?: number
) {
    const axios = useAxios();
    const offset = pagination.currentPage * pagination.rowsPerPage;
    const recordLimit = pagination.rowsPerPage;
    const { sortValue, direction } = sorting;

    const getOrders = useQuery({
        ...fetchConfig.list,
        queryKey: [
            EQueryKeys.ORDERS,
            offset,
            recordLimit,
            sortValue,
            direction,
            filters
        ],
        queryFn: async () => {
            if (versionDetails === NEW_VERSION &&  filters?.selectedProjects?.length === 0) {
                return {
                    orders: [],
                    currentPage: pagination.currentPage,
                    rowsPerPage: pagination.rowsPerPage,
                    totalCount: 0
                };
            }
            const ordersRoute = versionDetails === NEW_VERSION ? EApiRoutes.ORDERS_NEW_VERSION : EApiRoutes.ORDERS;
            const response = await axios.get(
                getApiRouteOrMock(ordersRoute),
                {
                    offset,
                    recordLimit,
                    sortValue,
                    direction,
                    ...filters
                },
                filters?.selectedProjects ? { 'x-publishers': filters?.selectedProjects } : undefined
            );

            // BUG: amountTotal is not int32 as expected, so round it
            const orders = response.results.map((order: any) => ({
                ...order,
                amountTotal: new Decimal(Math.round(order.amountTotal)),
                amountFormatted: `${order.currencySymbol}${new Decimal(
                    order.amountTotal
                )
                    .div(100)
                    .toNumber()}`
            }));

            const totalCount = response.totalCount;

            return {
                orders: [...orders],
                currentPage: pagination.currentPage,
                rowsPerPage: pagination.rowsPerPage,
                totalCount
            };
        }
    });

    const getOrderDetails = useQuery({
        ...fetchConfig.single,
        enabled: !!query,
        refetchInterval: 5000,
        retry: false,
        queryKey: [EQueryKeys.ORDER_DETAILS, query],
        queryFn: async () => {
            const response = await axios.get(
                `${getApiRouteOrMock(EApiRoutes.ORDER_DETAILS)}/${query}`,
                undefined,
                filters?.selectedProjects ? { 'x-publishers': filters?.selectedProjects } : undefined
            );

            const order: OrderDetails = {
                ...response,
                history: response.history.reverse(),
                amountInDollar: new Decimal(response.amountInDollar),
                amountNetInDollar: new Decimal(response.amountNetInDollar),
                amountNetTotal: new Decimal(response.amountNetTotal),
                taxRatePoints: new Decimal(response.taxRatePoints),
                taxTotal: new Decimal(response.taxTotal)
            };
            return order;
        }
    });

    const makeRefund = useMutation(async ({orderId, publisherId} : {orderId: string, publisherId?: string}) => {
        let customHeader
        if (versionDetails === NEW_VERSION) {
            customHeader = publisherId ? { 'x-publishers': publisherId } : filters?.selectedProjects ? { 'x-publishers': filters?.selectedProjects } : undefined;
        }
        return await axios.post(
            `${getApiRouteOrMock(EApiRoutes.REFUND)}/${orderId}`,
            {},
            customHeader
        );
    }, {});

    return {
        getOrders,
        getOrderDetails,
        makeRefund
    };
}
