import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, Chip, Divider, FormControlLabel, Grid, InputAdornment, Stack } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import useOffersUI from 'api/useOffersUI';
import useSegments from 'api/useSegments';
import useUsers from 'api/useUsers';
import { OfferUI, User } from 'common/contracts';
import { MIN_USD_PRICE } from 'constants/constants';
import { EFeatureFlag, ELocalStorageKeys, ENotificationType, EPopupSubType } from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';

import AcAutoComplete from 'components/AcAutoComplete/AcAutoComplete';
import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import useMetadata from '../../api/useMetadata';

const SettingsTab = (props: any) => {
    const { formikProps, values, getOffers, edit, currentOfferUI, setCurrentOfferUI, currentSubType, setCurrentSubType, chosenSegment, setChosenSegment, offerMetadata, setOfferMetadata, popupId, hasFeatureFlagMetadata } = props;
    const { handleChange, handleBlur, touched, errors, setFieldValue } = formikProps;

    const [isNameExists, setIsNameExists] = useState<boolean>(false);
    const [skuExists, setSkuExists] = useState<boolean>(false);
    const [priceInputValue, setPriceInputValue] = useState<number>(0);
    const [segmentError, setSegmentError] = useState('');
    const [metadataError, setMetadataError] = useState('');
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );

    const { getOffersUI } = useOffersUI(currentPublisherId, currentOfferUI);
    const { getSegments } = useSegments(currentPublisherId);
    const { getPublisherMetadata, updateOfferMetadata, deleteOfferMetadata } = useMetadata(currentPublisherId, popupId);
    const { enqueueSnackbar } = useNotifications();

    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;
    const { fetchFeatureFlags } = useUsers(
        false,
        versionDetails,
        currentPublisherId,
        true
    );
    
    useEffect(() => {
        setIsNameExists(
            getOffers.data?.offers.find((o: any) => {
                return o.name === values.name;
            }) && !edit
        );
    }, [values.name]);

    useEffect(() => {
        setSkuExists(
            getOffers.data?.offers.find((o: any) => {
                return o.publisherOfferId === values.publisherOfferId;
            }) && !edit
        );
    }, [values.publisherOfferId]);

    useEffect(() => {
        if (values.subType === EPopupSubType.POST_PURCHASE) {
            values.setAsFree ? setPriceInputValue(0) : setPriceInputValue(values.price);
        } else {
            setPriceInputValue(0);
        }
    }, [values.subType, values.setAsFree, currentSubType]);

    const handlePriceChange = (e: any) => {
        const inputValue = e.target.value;
        if (inputValue === 'Free' || inputValue === 0) {
            setPriceInputValue(0);
            setFieldValue('price', 0);
        } else {
            const numericValue = parseFloat(inputValue);
            setPriceInputValue(isNaN(numericValue) ? 0 : numericValue);
            setFieldValue('price', isNaN(numericValue) ? 0 : numericValue);
        }
    };

    const handleUpdateOfferMetadata = (val: string) => {
        updateOfferMetadata.mutate(
            { metadata: val, offerId: popupId },
            {
                onSuccess: () => {
                    setOfferMetadata((oldMetadata: string[]) => {
                        const newMetadata = [...oldMetadata, val];
                        setFieldValue('metadata', newMetadata)
                        return newMetadata;
                    });
                    enqueueSnackbar(
                        'Metadata added successfully',
                        ENotificationType.SUCCESS
                    );
                },
                onError: () => {
                    enqueueSnackbar(
                        'Error adding metadata',
                        ENotificationType.ERROR
                    );
                }
            }
        );
    }

    const handleDeleteOfferMetadata = (val: string) => {
        deleteOfferMetadata.mutate(
            { metadata: val, offerId: popupId },
            {
                onSuccess: () => {
                    setOfferMetadata((oldMetadata: string[]) => {
                        const newMetadata = oldMetadata.filter((existingVal: string) => existingVal !== val);
                        setFieldValue('metadata', newMetadata);
                        return newMetadata;
                    })
                    enqueueSnackbar(
                        'Metadata deleted successfully',
                        ENotificationType.SUCCESS
                    );
                },
                onError: () => {
                    enqueueSnackbar(
                        'Error deleting metadata',
                        ENotificationType.ERROR
                    );
                }
            }
        );
    }

    const hasFeatureFlagPostPurchase =
    fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_POST_PURCHASE
    ];

    return (
        <Stack gap={2}>
            <AcCard stackContainer={false} title="General">
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <AcInput
                            header="Name"
                            name="name"
                            required
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={(touched.name && Boolean(errors.name)) || isNameExists}
                            helperText={(touched.name ? errors.name?.toString() : '') || (isNameExists ? 'Name already exists' : '')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AcInput
                            header="SKU"
                            name="publisherOfferId"
                            required
                            value={values.publisherOfferId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={(touched.publisherOfferId && Boolean(errors.publisherOfferId)) || skuExists}
                            helperText={(touched.publisherOfferId ? errors.publisherOfferId?.toString() : '') || (skuExists ? 'SKU already exists' : '')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AcSelect
                            header="Offer design"
                            name="offerUiId"
                            required
                            value={values.offerUiId}
                            onChange={(e) => {
                                setCurrentOfferUI(e.target.value as string);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            items={getOffersUI.data?.result
                                .filter((oi: OfferUI) => oi.offerUiType === 'PopUp')
                                .map((offerUI: OfferUI) => {
                                    return {
                                        content: offerUI.name,
                                        key: offerUI._id,
                                        value: offerUI._id
                                    };
                                })}
                            error={touched.offerUiId && Boolean(errors.offerUiId)}
                            helperText={touched.offerUiId ? errors.offerUiId?.toString() : ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AcInput
                            header="Priority"
                            name="priority"
                            required
                            tooltip="Priority decides which popups show first, based on their importance. The lower the number, the higher the priority."
                            value={values.priority}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="number"
                            error={touched.priority && Boolean(errors.priority)}
                            helperText={touched.priority ? errors.priority?.toString() : ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AcSelect
                            header="Type"
                            name="subType"
                            value={currentSubType || values.subType}
                            onChange={(e) => {
                                const newSubType: any = e.target.value;
                                setFieldValue('subType', newSubType);
                                setCurrentSubType(newSubType);
                                if (newSubType === EPopupSubType.DAILY_BONUS) {
                                    setFieldValue('price', 0);
                                    setPriceInputValue(0);
                                    setFieldValue('setAsFree', false);
                                } else {
                                    setFieldValue('price', 0.8);
                                    setPriceInputValue(0.8);
                                    setFieldValue('setAsFree', false);
                                }
                            }}
                            onBlur={handleBlur}
                            items= {hasFeatureFlagPostPurchase ? [
                                { content: 'Bonus', key: EPopupSubType.DAILY_BONUS, value: EPopupSubType.DAILY_BONUS },
                                { content: 'Post Purchase', key: EPopupSubType.POST_PURCHASE, value: EPopupSubType.POST_PURCHASE }
                            ] : [{ content: 'Bonus', key: EPopupSubType.DAILY_BONUS, value: EPopupSubType.DAILY_BONUS }]}
                            error={touched.subType && Boolean(errors.subType)}
                            helperText={touched.subType ? errors.subType?.toString() : ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AcInput
                            header="Price"
                            name="price"
                            value={priceInputValue === 0 ? 'Free' : priceInputValue}
                            onChange={handlePriceChange}
                            onBlur={handleBlur}
                            type={values.subType === EPopupSubType.POST_PURCHASE && !values.setAsFree ? 'number' : 'text'}
                            disabled={values.subType === EPopupSubType.DAILY_BONUS || values.setAsFree}
                            InputProps={{
                                startAdornment:
                                    values.subType === EPopupSubType.POST_PURCHASE && !values.setAsFree ? (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ) : undefined,
                                inputProps: {
                                    min: currentSubType === EPopupSubType.POST_PURCHASE && !values.setAsFree ? MIN_USD_PRICE : 0,
                                    readOnly: !(currentSubType === EPopupSubType.POST_PURCHASE && !values.setAsFree)
                                }
                            }}
                            error={touched.price && Boolean(errors.price)}
                            helperText={touched.price ? errors.price?.toString() : ''}
                        />
                        {values.subType === EPopupSubType.POST_PURCHASE && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="setAsFree"
                                        checked={values.setAsFree}
                                        onBlur={handleBlur}
                                        onChange={async (e) => {
                                            const isChecked = e.target.checked;
                                            await setFieldValue('setAsFree', isChecked);
                                            await setFieldValue('price', isChecked ? 0 : 0.8);
                                            setPriceInputValue(isChecked ? 0 : 0.8);
                                        }}
                                    />
                                }
                                label="Set as Free"
                            />
                        )}
                    </Grid>
                </Grid>
            </AcCard>
            <Divider sx={{ width: '100%' }} />
            <AcCard stackContainer={false} title="Segments">
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <AcAutoComplete
                            onEnter={(val) => {
                                setChosenSegment((prev: string[]) => {
                                    const newSegments = [...prev, val];
                                    setFieldValue('segments', newSegments);
                                    return newSegments;
                                });
                            }}
                            onChangeEvent={(val) => {
                                if (chosenSegment.includes(val)) {
                                    setSegmentError("You can't add the same segment twice");
                                } else {
                                    setSegmentError('');
                                }
                            }}
                            options={
                                (!getSegments?.isLoading &&
                                    getSegments?.data.filter((s: string) => !chosenSegment.includes(s))) ||
                                []
                            }
                            additionalOptions={chosenSegment}
                            startAdornment={<GridSearchIcon />}
                            onChange={(e) => {
                                const selected = (e.target as HTMLElement).textContent;
                                if (selected) {
                                    setChosenSegment((prev: string[]) => {
                                        const newSegments = [...prev, selected];
                                        setFieldValue('segments', newSegments);
                                        return newSegments;
                                    });
                                }
                            }}
                            helperText={'Press ‘Enter’ to add a new segment'}
                            error={segmentError}
                        />
                        {chosenSegment && (
                            <Stack marginTop={1.5} gap={1} direction="row" flexWrap="wrap">
                                {chosenSegment.map((val: any) => (
                                    <Chip
                                        className="segment-chip"
                                        key={val}
                                        onClick={() => {
                                            setChosenSegment((oldSegments: any) => {
                                                const newSegments = oldSegments.filter((existingVal: string) => existingVal !== val);
                                                setFieldValue('segments', newSegments);
                                                return newSegments;
                                            });
                                        }}
                                        label={
                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={0.5}>
                                                <span>{val}</span>
                                                <CloseIcon sx={{ width: 14, height: 14 }} />
                                            </Stack>
                                        }
                                    />
                                ))}
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </AcCard>
            <Divider sx={{ width: '100%' }} />
            {hasFeatureFlagMetadata && (<AcCard stackContainer={false} title="Metadata">
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <AcAutoComplete
                            onEnter={(val) => handleUpdateOfferMetadata(val)}
                            onChangeEvent={(val) => {
                                if (offerMetadata.includes(val)) {
                                    setMetadataError("You can't add the same metadata twice");
                                } else {
                                    setMetadataError('');
                                }
                            }}
                            options={
                                (!getPublisherMetadata?.isLoading &&
                                    getPublisherMetadata?.data.filter((s: string) => !offerMetadata?.includes(s))) ||
                                []
                            }
                            additionalOptions={offerMetadata}
                            startAdornment={<GridSearchIcon />}
                            onChange={(e) => {
                                const selected = String((e.target as HTMLElement).textContent);
                                if (offerMetadata.includes(selected)) {
                                    setMetadataError("You can't add the same metadata twice")
                                } else if (selected) {
                                    handleUpdateOfferMetadata(selected)
                                }
                            }}
                            helperText={'Press ‘Enter’ to add a new metadata'}
                            error={metadataError}
                        />
                        {offerMetadata && (
                            <Stack marginTop={1.5} gap={1} direction="row" flexWrap="wrap">
                                {offerMetadata.map((val: string) => (
                                    <Chip
                                        className="metadata-chip"
                                        key={val}
                                        onClick={() => handleDeleteOfferMetadata(val)}
                                        label={
                                            <Stack direction="row" justifyContent="center" alignItems="center" gap={0.5}>
                                                <span>{val}</span>
                                                <CloseIcon sx={{ width: 14, height: 14 }} />
                                            </Stack>
                                        }
                                    />
                                ))}
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </AcCard>)}
        </Stack>
    );
};

export default SettingsTab;
