import { useEffect, useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    Button,
    CircularProgress,
    createTheme,
    FormControl,
    Select,
    Stack,
    TextField,
    ThemeProvider} from '@mui/material';
import { Box } from '@mui/system';

import { AcSearchInputProps } from './AcInput.types';

import './style.scss';

const AcSearchInput = ({
    className = '',
    renderValue,
    header = '',
    setValue,
    loading = false,
    defaultCheckedItems,
    isApplyBtnEnabled = true,
    value,
    onClick=()=> {},
    isDate = false,
    ...props
}: AcSearchInputProps) => {
    const [selectedItemsState, setSelectedItemsState] = useState<string>("");    
    const [inputValue, setInputValue]= useState<string>("")
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setInputValue(selectedItemsState)
    }, [selectedItemsState]);

   useEffect(()=> {
        setInputValue(value)
    }, [value])

    const handleApply = () => {
        if (isDate) setValue(false);
        else {
            setValue(inputValue);
            setIsOpen(false);
        }
    };

    const handleSearchChange = (value: any) => {
        setSelectedItemsState(value.toLowerCase());
    };

    const handleClickSelect = () => {
        if (isDate) {
            setIsOpen(false);
            onClick(value);          
        } else if (!isOpen) {
            setIsOpen(true)
        }
    }

    const renderInputValue = (selected: string) => selected;

    const theme = createTheme({
        components: {
            MuiSelect: {
                styleOverrides: {
                    select: {
                        '&::before': {
                            content: value ? '"|"' : '""',
                            position: 'relative',
                            color: '#CACBD4',
                            margin: "5px",
                        },
                    },
                }
            }
        }
    });

    return (
        <>
            {loading && <CircularProgress size={12} />}
            <Stack className="formContent-input">
                <ThemeProvider theme={theme}>
                    <FormControl variant="outlined" fullWidth>
                        <Select
                            open={isDate ? false : isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            label={header}
                            disabled={props.disabled || loading}
                            autoWidth={true}
                            startAdornment={value ? <HighlightOffIcon onClick={() => {setInputValue(""); setValue("")}} className="search-open-icon" /> : <AddCircleOutlineIcon onClick={() => setIsOpen(true)} className="search-open-icon" />}
                            className="formContent-searchInput-searchField"
                            value={value}
                            renderValue={renderInputValue}
                            onClick={handleClickSelect}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                        >
                            <Box className="search-dropdown">
                                <Stack className='search-dropdown-header'>Filter by {header}</Stack>
                                <Box sx={{ display: 'flex', alignItems: "center", gap: "10px", padding: "0" }}>
                                    <Stack sx={{ fontSize: "14px"}}>Search</Stack>
                                    <TextField
                                        variant="outlined"
                                        value={inputValue || ""}
                                        onChange={(e: any) => handleSearchChange(e.target.value)}
                                        className="formContent-searchInput-searchField-searchValue"
                                    />
                                </Box>
                                {isApplyBtnEnabled && (
                                    <div className="apply-button--container" onClick={()=> setIsOpen(false)}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleApply}
                                            disableElevation={true}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                )}
                            </Box>
                        </Select>
                    </FormControl>
                </ThemeProvider>
            </Stack>
        </>
    );
};

export default AcSearchInput;
