import { ESalePercentageDisplayType } from '../../constants/enums';
import AcSelect from '../AcSelect/AcSelect';

import { DisplayTypeSelectProps } from './DisplayTypeSelect.types';

const DisplayTypeSelect = ({handleChange, handleBlur, value = ESalePercentageDisplayType.PERCENTAGE }: DisplayTypeSelectProps) => {
    return (
        <AcSelect
            header="Display"
            name="salePercentageDisplayType"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            items={[
                {
                    content: 'Percentage',
                    key: ESalePercentageDisplayType.PERCENTAGE,
                    value: ESalePercentageDisplayType.PERCENTAGE
                },
                {
                    content: 'Multiplier',
                    key: ESalePercentageDisplayType.MULTIPLIER,
                    value: ESalePercentageDisplayType.MULTIPLIER
                },
                {
                    content: 'Fixed amount',
                    key: ESalePercentageDisplayType.FIXED_AMOUNT,
                    value: ESalePercentageDisplayType.FIXED_AMOUNT
                }
            ]}
        />
    )
}

export default DisplayTypeSelect
