import React, { useState } from 'react';

import { Box, Card, Collapse, Stack } from '@mui/material';

import CustomizedSwitch from '../SwitchButton/SwitchButton';

import { AcCardProps } from './AcCard.types';

const AcCard = ({
    children,
    gap,
    maxWidth,
    stackContainer = true,
    title,
    closeableOptions,
    disabled = false,
    padded = true,
    className,
    description = '',
    required = false
}: AcCardProps) => {
    const [isOpen, setIsOpen] = useState(
        closeableOptions?.openByDefault ? true : false
    );

    return (
        <Card
            variant="outlined"
            sx={{
                boxShadow: 0,
                border: 0,
                maxWidth: maxWidth || '100%',
                opacity: disabled ? 0.5 : 1,
                backgroundColor: 'transparent'
            }}
            className={className ? className : ''}
        >
            {title && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="titleBlock formContent-card-title"
                >
                    <h3>
                        {required && (
                            <span
                                style={{
                                    color: 'red',
                                    marginRight: '6px',
                                    fontSize: '12px',
                                    fontWeight: 'normal'
                                }}
                            >
                                *
                            </span>
                        )}
                        {title}
                    </h3>
                    {closeableOptions && (
                        <Box mr={-3}>
                            <CustomizedSwitch
                                status={isOpen}
                                texts={[]}
                                functions={[
                                    () => {
                                        setIsOpen(!isOpen);
                                        closeableOptions?.onOpen?.();
                                    },
                                    () => {
                                        setIsOpen(!isOpen);
                                        closeableOptions?.onClose?.();
                                    }
                                ]}
                            />
                        </Box>
                    )}
                </Stack>
            )}
            {description && (
                <Stack className="formContent-card-description">
                    <p>{description}</p>
                </Stack>
            )}
            <Collapse in={closeableOptions ? isOpen : true}>
                {stackContainer ? (
                    <Stack gap={gap}>{children}</Stack>
                ) : (
                    <>{children}</>
                )}
            </Collapse>
        </Card>
    );
};

export default AcCard;
