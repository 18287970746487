import { Box } from '@mui/material';

import './style.scss';

const BlockOverlay = () => {
    return (
        <Box id="blockOverlay">
            <img
                alt="resize"
                src={`${process.env.PUBLIC_URL}/assets/images/resize_icon.svg`}
            />
            <h2>Your browser is too small</h2>
            <p>
                Resize your browser to at least 1024px wide to get back into
                work mode.
            </p>
        </Box>
    );
};

export default BlockOverlay;
