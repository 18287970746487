import { useSelector } from 'react-redux';

import { Divider, Grid, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { AuthSliceState } from 'store/store.types';
import * as yup from 'yup';

import usePublisher from '../../../api/usePublisher';
import useTheme from '../../../api/useTheme';
import { ELogoSize, ENotificationType } from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import { required } from '../../../utils/errorsTextHelper';
import AcCard from '../../AcCard/AcCard';
import AcSelect from '../../AcSelect/AcSelect';
import ActionButton from '../../ActionButton/ActionButton';
import { defaultTheme } from '../../Theme/Theme';

import '../../../style/forms.scss';
import '../../Theme/style.scss';

const separatorOptions = [
    {
        content: 'Comma',
        key: ',',
        value: ','
    },
    {
        content: 'Dot',
        key: '.',
        value: '.'
    },
    {
        content: 'Space',
        key: ' ',
        value: ' '
    }
];

const trueFalseOptions = [
    {
        content: 'Yes',
        key: 'true',
        value: 'true'
    },
    {
        content: 'No',
        key: 'false',
        value: 'false',
        selected: true
    }
];

const StoreLayout = () => {
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const themeActions = useTheme(currentPublisherId);
    const { data, isFetching } = themeActions.getStoreTheme;
    const publisherActions = usePublisher(currentPublisherId || undefined);
    const publisher = publisherActions.getPublisher;

    const { enqueueSnackbar } = useNotifications();
    let themeSchema = yup.object().shape({
        login: yup.object().shape({
            text: yup.string().required(required('Header')),
            textColor: yup.string().required(required('Font color')),
            textSize: yup.string().required(required('Font size')),
            textWeight: yup.string().required(required('Font weight')),
            otpButton: yup.object().shape({
                backgroundColor: yup.object().shape({
                    colorOne: yup
                        .string()
                        .required(required('Button main color')),
                    colorTwo: yup.string()
                }),
                text: yup.string(),
                icon: yup.string(),
                textColor: yup.object().shape({
                    colorOne: yup
                        .string()
                        .required(required('Text main color')),
                    colorTwo: yup.string()
                })
            })
        }),
        general: yup.object().shape({
            productsQuantityFormat: yup.object().shape({
                milSeparator: yup
                    .string()
                    .required(required('Million separator'))
            }),
            specialOfferQuantityFormat: yup.object().shape({
                milSeparator: yup
                    .string()
                    .required(required('Million separator'))
            })
        }),
        loaderScreen: yup.object().shape({
            headerText: yup.string().required(required('Header')),
            headerColor: yup.object().shape({
                colorOne: yup.string().required(required('Button main color')),
                colorTwo: yup.string()
            }),
            headerSize: yup.string().required(required('Header font size')),
            headerWeight: yup.string().required(required('Header font weight')),
            text: yup.string(),
            textColor: yup.string(),
            textSize: yup.string(),
            textWeight: yup.string()
        }),
        completedScreen: yup.object().shape({
            headerText: yup.string().required(required('Header')),
            headerColor: yup.object().shape({
                colorOne: yup.string().required(required('Button main color')),
                colorTwo: yup.string()
            }),
            headerSize: yup.string().required(required('Header font size')),
            headerWeight: yup.string().required(required('Header font weight')),
            text: yup.string(),
            textColor: yup.string(),
            textSize: yup.string(),
            textWeight: yup.string(),
            backToStoreText: yup
                .string()
                .required(required('Back to store text')),
            backToGameButtonText: yup
                .string()
                .required(required('Back to game text'))
        }),
        storeScreen: yup.object().shape({
            bundleBorderColor: yup.object().shape({
                colorOne: yup
                    .string()
                    .required(required('Bundle frame main color')),
                colorTwo: yup.string()
            })
        }),
        integration: yup.object().shape({
            deepLinks: yup.object().shape({
                ios: yup
                    .string()
                    .matches(
                        /.+:\/\/.*/,
                        'Apple deep link must be a valid url'
                    ),
                android: yup
                    .string()
                    .matches(
                        /.+:\/\/.*/,
                        'Android deep link must be a valid url'
                    ),
                web: yup
                    .string()
                    .matches(/.+:\/\/.*/, 'Web deep link must be a valid url')
            })
        })
    });
    const {
        values,
        errors,
        touched,
        dirty,
        isValid,
        isSubmitting,
        handleChange,
        handleBlur,
        submitForm,
        setSubmitting
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: {
                logo: data?.general?.logo || defaultTheme.general.logo,
                logoSize: data?.general?.logoSize || ELogoSize.SMALL,
                font: data?.general?.font || defaultTheme.general.font,
                tabName:
                    publisher.data?.storeTabName ||
                    defaultTheme.general.tabName,
                favicon: data?.general?.favicon || defaultTheme.general.favicon,
                buttonColor: {
                    colorOne:
                        data?.general?.buttonColor.colorOne ||
                        defaultTheme.general.buttonColor.colorOne,
                    colorTwo:
                        data?.general?.buttonColor.colorTwo ||
                        defaultTheme.general.buttonColor.colorTwo,
                    gradientDirection:
                        data?.general?.buttonColor.gradientDirection ||
                        defaultTheme.general.buttonColor.gradientDirection
                },
                backgroundImageMobile:
                    data?.general?.backgroundImageMobile ||
                    defaultTheme.general.backgroundImageMobile,
                backgroundImageDesktop:
                    data?.general?.backgroundImageDesktop ||
                    defaultTheme.general.backgroundImageDesktop,
                buttonTextColor:
                    data?.general?.buttonTextColor ||
                    defaultTheme.general.buttonTextColor,
                bundlesViewModel:
                    data?.general?.bundlesViewModel ||
                    defaultTheme.general.bundlesViewModel,
                bundlesInternalViewModel:
                    data?.general?.bundlesInternalViewModel ||
                    defaultTheme.general.bundlesInternalViewModel,
                specialOffersViewModel:
                    data?.general?.specialOffersViewModel ||
                    defaultTheme.general.specialOffersViewModel,
                specialOffersInternalViewModel:
                    data?.general?.specialOffersInternalViewModel ||
                    defaultTheme.general.specialOffersInternalViewModel,
                balancesTheme:
                    data?.general?.balancesTheme ||
                    defaultTheme.general.balancesTheme,
                sectionViewModel:
                    data?.general?.sectionViewModel ||
                    defaultTheme.general.sectionViewModel,
                popupRatio:
                    data?.general?.popupRatio ||
                    defaultTheme.general.popupRatio,
                productsQuantityFormat: {
                    milSeparator:
                        data?.general?.productsQuantityFormat?.milSeparator ||
                        defaultTheme.general?.productQuantityFormat
                            ?.milSeparator,
                    fractionalSeparator:
                        data?.general?.productsQuantityFormat
                            .fractionalSeparator ||
                        defaultTheme.general?.productQuantityFormat
                            ?.fractionalSeparator,
                    thousandShorthand:
                        data?.general?.productsQuantityFormat
                            ?.thousandShorthand ||
                        defaultTheme.general.productQuantityFormat
                            ?.thousandShorthand,
                    millionShorthand:
                        data?.general?.productsQuantityFormat
                            ?.millionShorthand ||
                        defaultTheme.general.productQuantityFormat
                            ?.millionShorthand,
                    billionShorthand:
                        data?.general?.productsQuantityFormat
                            ?.billionShorthand ||
                        defaultTheme.general?.productQuantityFormat
                            ?.billionShorthand
                },
                specialOfferQuantityFormat: {
                    milSeparator:
                        data?.general?.specialOfferQuantityFormat
                            ?.milSeparator ||
                        defaultTheme.general?.specialOfferQuantityFormat
                            ?.milSeparator,
                    fractionalSeparator:
                        data?.general?.specialOfferQuantityFormat
                            ?.fractionalSeparator ||
                        defaultTheme.general?.specialOfferQuantityFormat
                            ?.fractionalSeparator,
                    thousandShorthand:
                        data?.general?.specialOfferQuantityFormat
                            ?.thousandShorthand ||
                        defaultTheme.general.specialOfferQuantityFormat
                            ?.thousandShorthand,
                    millionShorthand:
                        data?.general?.specialOfferQuantityFormat
                            ?.millionShorthand ||
                        defaultTheme.general.specialOfferQuantityFormat
                            ?.millionShorthand,
                    billionShorthand:
                        data?.general?.specialOfferQuantityFormat
                            ?.billionShorthand ||
                        defaultTheme.general.specialOfferQuantityFormat
                            ?.billionShorthand
                },
                balanceQuantityFormat: {
                    milSeparator:
                        data?.general?.balanceQuantityFormat?.milSeparator ||
                        defaultTheme.general.balanceQuantityFormat
                            ?.milSeparator,
                    fractionalSeparator:
                        data?.general?.balanceQuantityFormat
                            ?.fractionalSeparator ||
                        defaultTheme.general.balanceQuantityFormat
                            ?.fractionalSeparator,
                    thousandShorthand:
                        data?.general?.balanceQuantityFormat
                            ?.thousandShorthand ||
                        defaultTheme.general.balanceQuantityFormat
                            ?.thousandShorthand,
                    millionShorthand:
                        data?.general?.balanceQuantityFormat
                            ?.millionShorthand ||
                        defaultTheme.general.balanceQuantityFormat
                            .millionShorthand,
                    billionShorthand:
                        data?.general?.balanceQuantityFormat
                            ?.billionShorthand ||
                        defaultTheme.general.balanceQuantityFormat
                            ?.billionShorthand
                }
            },
            login: {
                text: data?.login?.text || defaultTheme.login.text,
                font: data?.login?.font || defaultTheme.login.font,
                textSize: data?.login?.textSize || defaultTheme.login.textSize,
                textColor:
                    data?.login?.textColor || defaultTheme.login.textColor,
                textWeight:
                    data?.login?.textWeight || defaultTheme.login.textWeight,
                otpButton: {
                    backgroundColor: {
                        colorOne:
                            data?.login?.otpButton?.backgroundColor?.colorOne ||
                            defaultTheme.login.otpButton.backgroundColor
                                .colorOne,
                        colorTwo:
                            data?.login?.otpButton?.backgroundColor?.colorTwo ||
                            defaultTheme.login.otpButton.backgroundColor
                                .colorTwo,
                        gradientDirection:
                            data?.login?.otpButton?.backgroundColor
                                ?.gradientDirection ||
                            defaultTheme.login.otpButton.backgroundColor
                                .gradientDirection
                    },
                    icon:
                        data?.login?.otpButton?.icon ||
                        defaultTheme.login.otpButton.icon,
                    text:
                        data?.login?.otpButton?.text ||
                        defaultTheme.login.otpButton.text,
                    textColor: {
                        colorOne:
                            data?.login?.otpButton?.textColor?.colorOne ||
                            defaultTheme.login.otpButton.textColor.colorOne,
                        colorTwo:
                            data?.login?.otpButton?.textColor?.colorTwo ||
                            defaultTheme.login.otpButton.textColor.colorTwo,
                        gradientDirection:
                            data?.login?.otpButton?.textColor
                                ?.gradientDirection ||
                            defaultTheme.login.otpButton.textColor
                                .gradientDirection
                    }
                }
            },
            loaderScreen: {
                headerText:
                    data?.loaderScreen?.headerText ||
                    defaultTheme.loaderScreen.headerText,
                headerColor: {
                    colorOne:
                        data?.loaderScreen?.headerColor.colorOne ||
                        defaultTheme.loaderScreen.headerColor.colorOne,
                    colorTwo:
                        data?.loaderScreen?.headerColor.colorTwo ||
                        defaultTheme.loaderScreen.headerColor.colorTwo,
                    gradientDirection:
                        data?.loaderScreen?.headerColor.gradientDirection ||
                        defaultTheme.loaderScreen.headerColor.gradientDirection
                },
                headerSize:
                    data?.loaderScreen?.headerSize ||
                    defaultTheme.loaderScreen.headerSize,
                headerWeight:
                    data?.loaderScreen?.headerWeight ||
                    defaultTheme.loaderScreen.headerWeight,
                text:
                    data?.loaderScreen?.text || defaultTheme.loaderScreen.text,
                textColor:
                    data?.loaderScreen?.textColor ||
                    defaultTheme.loaderScreen.textColor,
                textSize:
                    data?.loaderScreen?.textSize ||
                    defaultTheme.loaderScreen.textSize,
                textWeight:
                    data?.loaderScreen?.textWeight ||
                    defaultTheme.loaderScreen.textWeight
            },
            completedScreen: {
                headerText:
                    data?.completedScreen?.headerText ||
                    defaultTheme.completedScreen.headerText,
                headerColor: {
                    colorOne:
                        data?.completedScreen?.headerColor.colorOne ||
                        defaultTheme.completedScreen.headerColor.colorOne,
                    colorTwo:
                        data?.completedScreen?.headerColor.colorTwo ||
                        defaultTheme.completedScreen.headerColor.colorTwo,
                    gradientDirection:
                        data?.completedScreen?.headerColor.gradientDirection ||
                        defaultTheme.completedScreen.headerColor
                            .gradientDirection
                },
                headerSize:
                    data?.completedScreen?.headerSize ||
                    defaultTheme.completedScreen.headerSize,
                headerWeight:
                    data?.completedScreen?.headerWeight ||
                    defaultTheme.completedScreen.headerWeight,
                text:
                    data?.completedScreen?.text ||
                    defaultTheme.completedScreen.text,
                textColor:
                    data?.completedScreen?.textColor ||
                    defaultTheme.completedScreen.textColor,
                textSize:
                    data?.completedScreen?.textSize ||
                    defaultTheme.completedScreen.textSize,
                textWeight:
                    data?.completedScreen?.textWeight ||
                    defaultTheme.completedScreen.textWeight,
                backToStoreText:
                    data?.completedScreen?.backToStoreText ||
                    defaultTheme.completedScreen.backToStoreText,
                backToGameButtonText:
                    data?.completedScreen?.backToGameButtonText ||
                    defaultTheme.completedScreen.backToGameButtonText
            },
            storeScreen: {
                bundleBorderColor: {
                    colorOne:
                        data?.storeScreen?.bundleBorderColor.colorOne ||
                        defaultTheme.storeScreen.bundleBorderColor.colorOne,
                    colorTwo:
                        data?.storeScreen?.bundleBorderColor.colorTwo ||
                        defaultTheme.storeScreen.bundleBorderColor.colorTwo,
                    gradientDirection:
                        data?.storeScreen?.bundleBorderColor
                            .gradientDirection ||
                        defaultTheme.storeScreen.bundleBorderColor
                            .gradientDirection
                },
                noOffersTitleText:
                    data?.storeScreen.noOffersTitleText ||
                    defaultTheme.storeScreen,
                noOffersMessageText: data?.storeScreen.noOffersMessageText
            }
        },
        validationSchema: themeSchema,
        onSubmit: async (values) => {
            try {
                await themeActions.updateStoreTheme.mutateAsync(values);
                enqueueSnackbar('Theme updated', ENotificationType.SUCCESS);
                setSubmitting(false);
            } catch (error) {
                enqueueSnackbar(
                    'Error updating theme',
                    ENotificationType.ERROR,
                    (
                        (error as AxiosError).response?.data as {
                            message: string;
                        }
                    ).message || 'Unknown error occurred'
                );
            }
        }
    });

    return (
        <Grid container className="formContent">
            <Grid item xs={5}>
                <Stack>
                    <AcCard
                        stackContainer={false}
                        title="Product Quantity Format"
                    >
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={6}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Million Separator"
                                    name="general.productsQuantityFormat.milSeparator"
                                    value={
                                        values.general.productsQuantityFormat
                                            .milSeparator
                                    }
                                    items={separatorOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.productsQuantityFormat
                                            ?.milSeparator &&
                                        Boolean(
                                            errors.general
                                                ?.productsQuantityFormat
                                                ?.milSeparator
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Fractional Separator"
                                    name="general.productsQuantityFormat.fractionalSeparator"
                                    value={
                                        values.general.productsQuantityFormat
                                            .fractionalSeparator
                                    }
                                    items={separatorOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.productsQuantityFormat
                                            ?.fractionalSeparator &&
                                        Boolean(
                                            errors.general
                                                ?.productsQuantityFormat
                                                ?.fractionalSeparator
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Thousands (K)"
                                    name="general.productsQuantityFormat.thousandShorthand"
                                    value={
                                        values.general.productsQuantityFormat
                                            .thousandShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.productsQuantityFormat
                                            ?.thousandShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.productsQuantityFormat
                                                ?.thousandShorthand
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Millions (M)"
                                    name="general.productsQuantityFormat.millionShorthand"
                                    value={
                                        values.general.productsQuantityFormat
                                            .millionShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.productsQuantityFormat
                                            ?.millionShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.productsQuantityFormat
                                                ?.millionShorthand
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Billions (B)"
                                    name="general.productsQuantityFormat.billionShorthand"
                                    value={
                                        values.general.productsQuantityFormat
                                            .billionShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.productsQuantityFormat
                                            ?.billionShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.productsQuantityFormat
                                                ?.billionShorthand
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                    <Divider />
                    <AcCard
                        stackContainer={false}
                        title="Balance Quantity Format"
                    >
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={6}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Million Separator"
                                    name="general.balanceQuantityFormat.milSeparator"
                                    value={
                                        values.general.balanceQuantityFormat
                                            .milSeparator
                                    }
                                    items={separatorOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.balanceQuantityFormat
                                            ?.milSeparator &&
                                        Boolean(
                                            errors.general
                                                ?.balanceQuantityFormat
                                                ?.milSeparator
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Fractional Separator"
                                    name="general.balanceQuantityFormat.fractionalSeparator"
                                    value={
                                        values.general.balanceQuantityFormat
                                            .fractionalSeparator
                                    }
                                    items={separatorOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.balanceQuantityFormat
                                            ?.fractionalSeparator &&
                                        Boolean(
                                            errors.general
                                                ?.balanceQuantityFormat
                                                ?.fractionalSeparator
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Thousands (K)"
                                    name="general.balanceQuantityFormat.thousandShorthand"
                                    value={
                                        values.general.balanceQuantityFormat
                                            .thousandShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.balanceQuantityFormat
                                            ?.thousandShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.balanceQuantityFormat
                                                ?.thousandShorthand
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Millions (M)"
                                    name="general.balanceQuantityFormat.millionShorthand"
                                    value={
                                        values.general.balanceQuantityFormat
                                            .millionShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.balanceQuantityFormat
                                            ?.millionShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.balanceQuantityFormat
                                                ?.millionShorthand
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Billions (B)"
                                    name="general.balanceQuantityFormat.billionShorthand"
                                    value={
                                        values.general.balanceQuantityFormat
                                            .billionShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general?.balanceQuantityFormat
                                            ?.billionShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.balanceQuantityFormat
                                                ?.billionShorthand
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                    <Divider />
                    <AcCard
                        stackContainer={false}
                        title="Special Offer Quantity Format"
                    >
                        <Grid container rowSpacing={2} columnSpacing={1.5}>
                            <Grid item xs={6}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Million Separator"
                                    name="general.specialOfferQuantityFormat.milSeparator"
                                    value={
                                        values.general
                                            .specialOfferQuantityFormat
                                            .milSeparator
                                    }
                                    items={separatorOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general
                                            ?.specialOfferQuantityFormat
                                            ?.milSeparator &&
                                        Boolean(
                                            errors.general
                                                ?.specialOfferQuantityFormat
                                                ?.milSeparator
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Fractional Separator"
                                    name="general.specialOfferQuantityFormat.fractionalSeparator"
                                    value={
                                        values.general
                                            .specialOfferQuantityFormat
                                            .fractionalSeparator
                                    }
                                    items={separatorOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general
                                            ?.specialOfferQuantityFormat
                                            ?.fractionalSeparator &&
                                        Boolean(
                                            errors.general
                                                ?.specialOfferQuantityFormat
                                                ?.fractionalSeparator
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Thousands (K)"
                                    name="general.specialOfferQuantityFormat.thousandShorthand"
                                    value={
                                        values.general
                                            .specialOfferQuantityFormat
                                            .thousandShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general
                                            ?.specialOfferQuantityFormat
                                            ?.thousandShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.specialOfferQuantityFormat
                                                ?.thousandShorthand
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Millions (M)"
                                    name="general.specialOfferQuantityFormat.millionShorthand"
                                    value={
                                        values.general
                                            .specialOfferQuantityFormat
                                            .millionShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general
                                            ?.specialOfferQuantityFormat
                                            ?.millionShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.specialOfferQuantityFormat
                                                ?.millionShorthand
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcSelect
                                    loading={isFetching}
                                    header="Shorten Billions (B)"
                                    name="general.specialOfferQuantityFormat.billionShorthand"
                                    value={
                                        values.general
                                            .specialOfferQuantityFormat
                                            .billionShorthand
                                    }
                                    items={trueFalseOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                        touched.general
                                            ?.specialOfferQuantityFormat
                                            ?.billionShorthand &&
                                        Boolean(
                                            errors.general
                                                ?.specialOfferQuantityFormat
                                                ?.billionShorthand
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AcCard>
                </Stack>
                <Grid item xs={6} mt={3}>
                    <ActionButton
                        variant="outlined"
                        text="Update"
                        onClick={submitForm}
                        disabled={!isValid || !dirty || isSubmitting}
                    />
                </Grid>
            </Grid>
            {/* <Grid item xs={7} className="iphone-mock-wrapper">
                <div
                    className="store-preview-wrapper iphone-mock"
                    style={{ overflow: 'hidden' }}
                >
                    <Background
                        backgroundImageMobile={
                            values.general.backgroundImageMobile
                        }
                        backgroundImageDesktop={
                            values.general.backgroundImageMobile
                        }
                        width="100%"
                        height="100%"
                        position="absolute"
                    />
                    <img
                        style={{ position: 'relative' }}
                        alt="logo"
                        className={`storeLogo ${values.general.logoSize}`}
                        src={values.general.logo}
                    />
                    <div className="bundles-wrapper">
                        <SectionLayout sectionName={''} />
                        <BundlesLayout mobileView={bundlesLayoutMobile}>
                            {Array.from(
                                {
                                    length:
                                        values.general.bundlesViewModel ===
                                        EBundlesViewModel.THREE_IN_ONE
                                            ? 3
                                            : 1
                                },
                                () => null
                            ).map((i, index) => (
                                <div className="bundle" key={index}>
                                    <BundlesInternalLayout
                                        bundleBorderColor={
                                            values.storeScreen.bundleBorderColor
                                        }
                                        productList={[]}
                                        bundleButtonColor={
                                            values.general.buttonColor
                                        }
                                        backgroundSrc="https://media.appcharge.com/defaults/background.png"
                                        bundleButtonTextColor={
                                            values.general.buttonTextColor
                                        }
                                        fontFamily={values.general.font}
                                        price={{
                                            price: 0,
                                            isCents: true,
                                            fractionalSeparator: '.',
                                            milSeparator: ',',
                                            spacing: false,
                                            symbolPosition: 'left',
                                            currencySymbol: '$',
                                            currencyCode: 'USD'
                                        }}
                                        backgroundColor={{
                                            colorOne: 'transparent'
                                        }}
                                        inMobileMock={true}
                                    />
                                </div>
                            ))}
                        </BundlesLayout>
                    </div>
                </div>
            </Grid> */}
        </Grid>
    );
};

export default StoreLayout;
