import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ErrorFallback } from '@appcharge/shared-ui';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LogRocket from 'logrocket';

import BlockOverlay from './components/BlockOverlay/BlockOverlay';
import { LOG_ROCKET_KEY } from './conf';
import BackOffice from './pages/BackOffice/BackOffice';
import LoginPage from './pages/Login/Login';
import PrivateRoute from './utils/privateRoute';

if (process.env.REACT_APP_ENV === 'production') {
    LogRocket.init(LOG_ROCKET_KEY);
}
LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_KEY as string);

function App() {
    const queryClient = new QueryClient();
    const navigate = useNavigate();
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536
            }
        },
        palette: {
            primary: {
                main: '#7122FF'
            },
            secondary: {
                main: '#26282B'
            },
            error: {
                main: '#FF7C7C'
            }
        }
    });

    const handleNavigate = () => {
        navigate('../');
    };

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <BlockOverlay />
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route
                        path="/*"
                        element={
                            <PrivateRoute>
                                <ErrorBoundary
                                    FallbackComponent={() => (
                                        <ErrorFallback
                                            handleClick={handleNavigate}
                                        />
                                    )}
                                >
                                    <BackOffice />
                                </ErrorBoundary>
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
