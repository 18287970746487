import { useMutation, useQuery } from '@tanstack/react-query';

import { pricingToNumber } from 'components/Pricing/Pricing.utils';

import { User } from '../common/contracts';
import { Pricing } from '../components/Pricing/Pricing.types';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export interface CurrencyDetails {
    _id: string;
    currencyName: string;
    currencyCode: string;
    currencySymbol: string;
    createdAt: string;
    updatedAt: string;
    rate: number;
    codePlusSymbol: string;
    isCents: boolean;
    spacing: boolean;
    symbolPosition: string;
    maxChargeAmount: number;
    minChargeAmount: number;
    multiplier: number;
}

export interface GeoItem {
    _id: string;
    country: string;
    countryCode: string;
    countryCode3: string;
    createdAt: string;
    updatedAt: string;
    currencyDetails: CurrencyDetails;
    fractionalSeparator: string;
    milSeparator: string;
    taxRatePoints: number;
    paymentProvider: string;
    taxModel: string;
    taxName: string;
}

export default function usePricing(
    currentPublisherId: string | null,
    pricePointId?: string,
    fetchPricePointTemplate = true
) {
    const axios = useAxios();
    const versionDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.version;

    const getPricings = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.PRICING],
        queryFn: async () => {
            const response = await axios.get(
                `${getApiRouteOrMock(EApiRoutes.PRICING_META)}`,
                {},
                currentPublisherId
                    ? { 'x-publishers': currentPublisherId }
                    : undefined
            );

            return response;
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const getGeo = useQuery({
        ...fetchConfig.list,
        queryKey: [EQueryKeys.GEO],
        queryFn: async () => {
            const response = await axios.get(
                getApiRouteOrMock(EApiRoutes.GEO),
                {},
                currentPublisherId
                    ? { 'x-publishers': currentPublisherId }
                    : undefined
            );
            return response as GeoItem[];
        },
        enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
    });

    const getPricePoint = useQuery({
        ...fetchConfig.single,
        queryKey: [`${EQueryKeys.BUNDLES}_${pricePointId}`],
        queryFn: async () => {
            return await axios.get(
                `${getApiRouteOrMock(EApiRoutes.PRICE_POINT)}/${pricePointId}`,
                {},
                currentPublisherId
                    ? { 'x-publishers': currentPublisherId }
                    : undefined
            );
        },
        enabled: false
    });

    const getPricingPointsTemplate = useQuery({
        ...fetchConfig.single,
        queryKey: [EQueryKeys.PRICING_POINTS_TEMPLATE],
        queryFn: async () => {
            return await axios.get(
                getApiRouteOrMock(EApiRoutes.PRICING_POINTS_TEMPLATE),
                {},
                currentPublisherId
                    ? { 'x-publishers': currentPublisherId }
                    : undefined
            );
        },
        enabled:
            versionDetails === NEW_VERSION
                ? !!currentPublisherId
                : fetchPricePointTemplate
    });

    const addPricing = useMutation(async (newPricing: Omit<Pricing, '_id'>) => {
        const dataToSave = pricingToNumber(newPricing);
        return await axios.post(
            getApiRouteOrMock(EApiRoutes.PRICING),
            dataToSave
        );
    }, {});

    const deletePricing = useMutation(async (pricingId: string) => {
        return await axios.del(
            `${getApiRouteOrMock(EApiRoutes.PRICING)}/${pricingId}`
        );
    }, {});

    const updatePricing = useMutation(async (newPricing: Pricing) => {
        const dataToSave = {
            ...pricingToNumber(newPricing),
            pricingId: newPricing._id
        };
        return await axios.put(
            getApiRouteOrMock(EApiRoutes.PRICING),
            dataToSave
        );
    }, {});

    const calculatePricing = useMutation(async (anchors: any) => {
        const response = await axios.post(
            `${getApiRouteOrMock(EApiRoutes.PRICING)}/calculate`,
            { anchors }
        );
        return response.data;
    }, {});

    const importCsvFile = useMutation(async (newFile: FormData) => {
        const response = await axios.post(
            `${getApiRouteOrMock(EApiRoutes.PRICING)}/import`,
            newFile
        );
        return response.data;
    });

    const updateExchangeRates = useMutation(async () => {
        return await axios.put(
            `${getApiRouteOrMock(EApiRoutes.PRICING)}/update-exchange-rates`, {}
        );
    }, {});

    return {
        getPricePoint,
        getPricings,
        getPricingPointsTemplate,
        addPricing,
        deletePricing,
        updatePricing,
        getGeo,
        calculatePricing,
        importCsvFile,
        updateExchangeRates
    };
}
