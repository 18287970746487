import { Route,Routes } from 'react-router-dom';

import PopupsForm from './PopupsForm';
import PopupsTable from './PopupsTable';

import '../../views/view.scss';

const PopupsView = () => {
    return (
        <Routes>
            <Route path="/" element={<PopupsTable />} />
            <Route path="/form" element={<PopupsForm />} />
            <Route path="/form/:popupId" element={<PopupsForm edit={true} />} />
            <Route
                path="/dup/:popupId"
                element={<PopupsForm edit={false} dup={true} />}
            />
        </Routes>
    );
};

export default PopupsView;
